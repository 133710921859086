import { clsx, EMPTY_STRING, type WithClassName, type WithDataTestId } from "@regrello/core-utils";
import {
  RegrelloIcon,
  type RegrelloIconName,
  RegrelloPopover,
  type RegrelloTooltipProps,
  RegrelloTypography,
} from "@regrello/ui-core";
import React, { useCallback, useRef, useState } from "react";

import { RegrelloFormFieldLabelPlacement } from "./RegrelloFormFieldBaseProps";
import { RegrelloFormFieldHelperText } from "./RegrelloFormFieldHelperText";
import { RegrelloFormLabel } from "./RegrelloFormLabel";
import { RegrelloFormFieldSelfContainedFormDisplayValue } from "./selfContainedForm/RegrelloFormFieldSelfContainedFormDisplayValue";
import { RegrelloFormFieldSelfContainedFormInline } from "./selfContainedForm/RegrelloFormFieldSelfContainedFormInline";
import { RegrelloFormFieldSelfContainedFormPopover } from "./selfContainedForm/RegrelloFormFieldSelfContainedFormPopover";
import { CSS_FULL_WIDTH } from "../../../../constants/globalConstants";

export interface RegrelloFormFieldSelfContainedFormProps<T> {
  /**
   * Callback to customize how the `savedValue` will render. If omitted, the `savedValue` will be
   * rendered as a string by default.
   */
  getDisplayValue?: (savedValue: T) => React.ReactNode;

  isHoverBackgroundDisabled?: boolean;
  isEditButtonInline?: boolean;
  isEditingDisabled?: boolean;

  /**
   * Whether to visually emphasize this field with a yellow bar on the start side. This yellow bar
   * is meant to attract user attention and indicate that the user needs to enter a value.
   */
  isEmphasized?: boolean;

  /** If supplied, this overrides the default open/close behavior of the selfContainedForm. */
  isOpen?: boolean;

  isSubmitting: boolean;

  /**
   * The maximum number of lines to permit for the display value. Display values longer than this
   * will be accessible in full via a tooltip on hover.
   */
  maxLines?: number;

  /**
   * The maximum number of rows shown. This only applies when the underlining component is
   * RegrelloFormFieldTextMultiline. Pass `-1` to disable clamping.
   */
  maxRowWhenCollapsed?: -1 | 1 | 2 | 3 | 4 | 5;

  /**
   * The manner in which to display the self-contained form after the user clicks the "Edit" button.
   *
   * - `inline`: Displays the form inline, in place of the display value.
   * - 'popover`: Displays the form in a popover.
   */
  mode: "inline" | "popover";

  onClose?: () => void;
  onOpen?: () => void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;

  /** The last saved value, displayed as a read-only value when the form is not open. */
  savedValue: T;

  /** The typography to use for the display value when not in editing mode. */
  typography?: "h3" | "h5" | "body";
}

export interface RegrelloFormFieldLayoutProps<T> extends WithClassName, WithDataTestId {
  /** A child form field is required. */
  children: React.ReactNode;

  /** An error message to display. */
  error?: string;

  /** Explanatory text to display beneath the field. */
  helperText?: React.ReactNode;

  /** Used to connect a label with a field */
  htmlFor?: string;

  /**
   * If defined, an info icon will be rendered to the right of the field label with this text as
   * tooltip content.
   */
  infoTooltipText?: string;

  /**
   * The name of the tooltip triggering icon
   */
  infoTooltipIconName?: RegrelloIconName;

  /**
   * The visual treatment to give to the tooltip.
   * - `"default"`: A standard dark-background tooltip.
   * - `"popover"`: A light-background tooltip with a border.
   * @default "default"
   */
  infoTooltipVariant?: RegrelloTooltipProps["variant"];

  /**
   * Whether to zero out default margins around the form field. Should only be set to `false` if
   * rendering multiple checkboxes in a row, or if rendering a custom component consisting of
   * multiple fields.
   *
   * @default true // if selfContainedForm is provided, for convenience
   * @default false // otherwise
   */
  isDefaultMarginsOmitted?: boolean;

  /** Whether to show a tooltiped deleted badge besides the label. */
  isDeleted?: boolean;

  /** Whether the label should be followed by a red asterisk, indicating the field is required. */
  isRequiredAsteriskShown?: boolean;

  /** Whether the label is vertically center-aligned with the input. If not provided, the input is
   * aligned with the top of the input by default. */
  isLabelVerticallyCentered?: boolean;

  /**
   * Whether the label is stacked above the input field or is in the same line with the input
   */
  labelPlacement?: RegrelloFormFieldLabelPlacement;

  /**
   * The display label. Optional because not all fields will have one rendered in the same way
   * (e.g. checkboxes).
   */
  label?: React.ReactNode;

  /**
   * How to align the label text in relation to the form field.
   */
  labelAlignment?: "left" | "center" | "right";

  /** The width to allot to the form field's label. */
  labelWidth?: number | string;

  /**
   * Whether to display an explicit 'Edit' button that opens a self-contained form on click.
   *
   * If this object is provided, the field value will be read-only and non-interactive until the
   * 'Edit' button is clicked. The editable form field will then be shown in a popover or inline
   * form, depending on the `type` provided.
   */
  selfContainedForm?: RegrelloFormFieldSelfContainedFormProps<T>;

  /** Whether the form field is rendered by a spectrum form. */
  variant?: "default" | "spectrum";

  /** A warning message to display. */
  warning?: string;
}

/**
 * A reusable component that renders a form field and its peripheral content, such as labels, helper
 * text, and errors. Can also render the form field in some alternate styles, including in an opt-in
 * popover form or inline form.
 */
function RegrelloFormFieldLayoutInternal<T>({
  children,
  className,
  dataTestId,
  error,
  infoTooltipText,
  infoTooltipIconName,
  infoTooltipVariant,
  isDefaultMarginsOmitted,
  isDeleted = false,
  helperText,
  htmlFor,
  isLabelVerticallyCentered = false,
  isRequiredAsteriskShown,
  labelPlacement = RegrelloFormFieldLabelPlacement.START,
  label,
  labelAlignment,
  labelWidth,
  selfContainedForm,
  variant = "default",
  warning,
}: RegrelloFormFieldLayoutProps<T>) {
  const [isEditPopoverOpen, setIsEditPopoverOpen] = useState<boolean>(false);
  const isSelfContainedFormEditingDisabled = selfContainedForm?.isEditingDisabled || selfContainedForm?.isOpen != null;

  const rootRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const editButtonRef = useRef<HTMLButtonElement | null>(null);

  const hasLabel = label != null && label !== EMPTY_STRING;

  const openEditPopover = useCallback(() => {
    setIsEditPopoverOpen(true);
    selfContainedForm?.onOpen?.();
  }, [selfContainedForm]);

  const closeEditPopover = useCallback(() => {
    // (clewis): UX Nicety: Re-focus the edit button so the user can pick up from where they were
    // before. Without this, focus moves to the first focusable element in the DOM, which is
    // probably really far away from where the user's attention is now.
    editButtonRef.current?.focus();
    setIsEditPopoverOpen(false);
    selfContainedForm?.onClose?.();
  }, [selfContainedForm]);

  const handleEditButtonClick = useCallback(() => {
    if (!isEditPopoverOpen) {
      openEditPopover();
    } else {
      closeEditPopover();
    }
  }, [closeEditPopover, isEditPopoverOpen, openEditPopover]);

  const hasError = error != null;
  const hasWarning = warning != null;

  // A field element consists of the editable form field, the helper text, and the error message.
  const fieldElement = (
    <div className="grow min-w-0">
      {children}
      {(hasError || hasWarning) && (
        <div
          className={clsx("flex items-start mt-1", {
            "text-warning-textMuted": hasWarning,
            "text-danger-textMuted": hasError,
          })}
        >
          <RegrelloIcon className="mr-1" iconName="alert-outline" size="x-small" />
          <RegrelloTypography variant="body-xs">{error ?? warning}</RegrelloTypography>
        </div>
      )}
      {helperText != null && <RegrelloFormFieldHelperText>{helperText}</RegrelloFormFieldHelperText>}
    </div>
  );

  if (selfContainedForm != null) {
    const displayValue =
      selfContainedForm.getDisplayValue != null
        ? selfContainedForm.getDisplayValue(selfContainedForm.savedValue)
        : String(selfContainedForm.savedValue);

    if (selfContainedForm.mode === "inline") {
      if (isEditPopoverOpen || selfContainedForm.isOpen) {
        // Show the standalone form inline on edit.
        return (
          <RegrelloFormFieldSelfContainedFormInline
            dataTestId={dataTestId}
            error={error}
            fieldElement={fieldElement}
            isRequired={isRequiredAsteriskShown}
            label={label}
            labelWidth={labelWidth}
            onClose={closeEditPopover}
            onSubmit={selfContainedForm.onSubmit}
            variant={variant}
          />
        );
      }

      // Show the display value and an edit button.
      return (
        <div
          ref={rootRef}
          className={clsx(
            "flex min-w-0",
            {
              "relative hover:bg-backgroundSoft":
                !isSelfContainedFormEditingDisabled && !selfContainedForm.isHoverBackgroundDisabled,
              "mb-2": isDefaultMarginsOmitted != null ? !isDefaultMarginsOmitted : selfContainedForm == null,
              "pl-4.5": label == null && variant !== "spectrum",
              "shadow-[inset_8px_0_0] shadow-warning-solid": selfContainedForm.isEmphasized,
              "pl-6 pr-4": variant === "default",
              "flex-col": labelPlacement === RegrelloFormFieldLabelPlacement.TOP,
              "px-4": variant === "spectrum",
            },
            className,
          )}
          data-testid={dataTestId}
        >
          {label != null && (
            <RegrelloFormLabel
              htmlFor={htmlFor}
              infoTooltipIconName={infoTooltipIconName}
              infoTooltipText={infoTooltipText}
              infoTooltipVariant={infoTooltipVariant}
              isDefaultMarginsOmitted={isDefaultMarginsOmitted}
              isDeleted={isDeleted}
              isRequiredAsteriskShown={isRequiredAsteriskShown}
              label={label}
              labelAlignment={labelAlignment}
              labelWidth={labelWidth}
              variant={variant}
            />
          )}
          <div
            className={clsx("grow min-w-0", {
              "ml-2": hasLabel && labelPlacement !== RegrelloFormFieldLabelPlacement.TOP,
            })}
          >
            <RegrelloFormFieldSelfContainedFormDisplayValue
              editButtonRef={editButtonRef}
              isEditButtonInline={selfContainedForm.isEditButtonInline}
              isEditingDisabled={isSelfContainedFormEditingDisabled}
              isEditSaving={selfContainedForm.isSubmitting}
              maxLines={selfContainedForm.maxLines}
              onEditButtonClick={handleEditButtonClick}
              typography={selfContainedForm.typography}
              variant={variant}
            >
              {displayValue}
            </RegrelloFormFieldSelfContainedFormDisplayValue>
          </div>
        </div>
      );
    }

    // Show the standalone form in a popover on edit.
    return (
      <div
        ref={rootRef}
        className={clsx(
          "flex min-w-0",
          {
            "pl-6 pr-4": variant === "default",
            "relative hover:bg-backgroundSoft": !selfContainedForm.isHoverBackgroundDisabled,
            "pl-4.5": label == null && variant !== "spectrum",
            transition: selfContainedForm != null && !selfContainedForm?.isEditButtonInline,
            "mb-2": isDefaultMarginsOmitted != null ? !isDefaultMarginsOmitted : selfContainedForm == null,
          },
          className,
        )}
        data-testid={dataTestId}
      >
        {label != null && (
          <RegrelloFormLabel
            htmlFor={htmlFor}
            infoTooltipIconName={infoTooltipIconName}
            infoTooltipText={infoTooltipText}
            infoTooltipVariant={infoTooltipVariant}
            isDefaultMarginsOmitted={isDefaultMarginsOmitted}
            isDeleted={isDeleted}
            isRequiredAsteriskShown={isRequiredAsteriskShown}
            label={label}
            labelAlignment={labelAlignment}
            labelWidth={labelWidth}
            variant={variant}
          />
        )}

        <RegrelloPopover
          align="start"
          content={
            <RegrelloFormFieldSelfContainedFormPopover
              error={error}
              fieldElement={fieldElement}
              onClose={closeEditPopover}
              onSubmit={selfContainedForm.onSubmit}
            />
          }
          contentProps={{
            "data-testid": dataTestId,
          }}
          open={isEditPopoverOpen || (selfContainedForm.isOpen ?? false)}
        >
          <div
            ref={contentRef}
            className={clsx("grow min-w-0", {
              "ml-2": hasLabel && labelPlacement !== RegrelloFormFieldLabelPlacement.TOP,
            })}
          >
            <RegrelloFormFieldSelfContainedFormDisplayValue
              editButtonRef={editButtonRef}
              isEditButtonInline={true}
              isEditingDisabled={isSelfContainedFormEditingDisabled}
              isEditSaving={selfContainedForm.isSubmitting}
              maxLines={selfContainedForm.maxLines}
              onEditButtonClick={handleEditButtonClick}
              typography={selfContainedForm.typography}
              variant={variant}
            >
              {displayValue}
            </RegrelloFormFieldSelfContainedFormDisplayValue>
          </div>
        </RegrelloPopover>
      </div>
    );
  }

  // (hchen): If Spectrum is enabled, show form field layout that is tamed to spectrum form designs
  // and is implemented with UnoCSS.
  if (variant === "spectrum") {
    return (
      <div
        ref={rootRef}
        className={clsx({
          "mb-2": !isDefaultMarginsOmitted,
          flex: labelPlacement === RegrelloFormFieldLabelPlacement.START,
          "text-textMuted": variant === "spectrum",
        })}
        data-testid={dataTestId}
      >
        {label != null && (
          <div
            className={clsx({
              "mr-1": labelPlacement === RegrelloFormFieldLabelPlacement.START,
            })}
          >
            <RegrelloFormLabel
              htmlFor={htmlFor}
              infoTooltipIconName={infoTooltipIconName}
              infoTooltipText={infoTooltipText}
              infoTooltipVariant={infoTooltipVariant}
              isDefaultMarginsOmitted={isDefaultMarginsOmitted}
              isDeleted={isDeleted}
              isLabelVerticallyCentered={isLabelVerticallyCentered}
              isRequiredAsteriskShown={isRequiredAsteriskShown}
              label={label}
              labelAlignment={labelAlignment}
              labelWidth={labelPlacement === RegrelloFormFieldLabelPlacement.TOP ? CSS_FULL_WIDTH : labelWidth}
              variant={variant}
            />
          </div>
        )}
        <div className="pt-1.5">{fieldElement}</div>
      </div>
    );
  }

  // By default, show the form field inline, without having to click an "Edit" button first.
  return (
    <div
      ref={rootRef}
      className={clsx(
        "flex min-w-0",
        {
          "mb-2": !isDefaultMarginsOmitted,
          "items-center": isLabelVerticallyCentered,
          "flex-col": labelPlacement === RegrelloFormFieldLabelPlacement.TOP,
        },
        className,
      )}
      data-testid={dataTestId}
    >
      {label != null && (
        <RegrelloFormLabel
          htmlFor={htmlFor}
          infoTooltipIconName={infoTooltipIconName}
          infoTooltipText={infoTooltipText}
          infoTooltipVariant={infoTooltipVariant}
          isDefaultMarginsOmitted={isDefaultMarginsOmitted}
          isDeleted={isDeleted}
          isLabelVerticallyCentered={isLabelVerticallyCentered}
          isRequiredAsteriskShown={isRequiredAsteriskShown}
          label={label}
          labelAlignment={labelAlignment}
          labelWidth={labelPlacement === RegrelloFormFieldLabelPlacement.TOP ? CSS_FULL_WIDTH : labelWidth}
        />
      )}
      <div
        className={clsx("grow min-w-0", {
          "ml-2": hasLabel && labelPlacement !== RegrelloFormFieldLabelPlacement.TOP,
          "pt-1": labelPlacement === RegrelloFormFieldLabelPlacement.TOP,
        })}
      >
        {fieldElement}
      </div>
    </div>
  );
}

export const RegrelloFormFieldLayout = React.memo(
  RegrelloFormFieldLayoutInternal,
) as typeof RegrelloFormFieldLayoutInternal;
