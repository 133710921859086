import type * as Types from "../../../generated/graphqlBaseTypes";

import { FormsQueryFormFields } from "../../fragments/generated/FormsQueryFormFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { FormMinimalFields } from "../../fragments/generated/FormMinimalFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FormSelectFormsQueryVariables = Types.Exact<{
  isPublic?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
}>;

export type FormSelectFormsQuery = { forms: Array<FormsQueryFormFields> };

export const FormSelectFormsQueryDocument = gql`
    query FormSelectFormsQuery($isPublic: Boolean) {
  forms(isPublic: $isPublic) {
    ...FormsQueryFormFields
  }
}
    ${FormsQueryFormFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${FormMinimalFields}`;

/**
 * __useFormSelectFormsQuery__
 *
 * To run a query within a React component, call `useFormSelectFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSelectFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSelectFormsQuery({
 *   variables: {
 *      isPublic: // value for 'isPublic'
 *   },
 * });
 */
export function useFormSelectFormsQuery(
  baseOptions?: Apollo.QueryHookOptions<FormSelectFormsQuery, FormSelectFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormSelectFormsQuery, FormSelectFormsQueryVariables>(FormSelectFormsQueryDocument, options);
}
export function useFormSelectFormsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FormSelectFormsQuery, FormSelectFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormSelectFormsQuery, FormSelectFormsQueryVariables>(
    FormSelectFormsQueryDocument,
    options,
  );
}
export function useFormSelectFormsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FormSelectFormsQuery, FormSelectFormsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FormSelectFormsQuery, FormSelectFormsQueryVariables>(
    FormSelectFormsQueryDocument,
    options,
  );
}
export type FormSelectFormsQueryHookResult = ReturnType<typeof useFormSelectFormsQuery>;
export type FormSelectFormsQueryLazyQueryHookResult = ReturnType<typeof useFormSelectFormsQueryLazyQuery>;
export type FormSelectFormsQuerySuspenseQueryHookResult = ReturnType<typeof useFormSelectFormsQuerySuspenseQuery>;
export type FormSelectFormsQueryResult = Apollo.QueryResult<FormSelectFormsQuery, FormSelectFormsQueryVariables>;
