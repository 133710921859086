import type * as Types from "../../../generated/graphqlBaseTypes";

import type { AutomationOAuth2ConfigFields } from "./AutomationOAuth2ConfigFields.generated";
import type { AutomationRequestParamFields } from "./AutomationRequestParamFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { FieldFields } from "./FieldFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import { gql } from "@apollo/client";
export type AutomationRequestFields = {
  id: number;
  url: string;
  method: Types.AutomationRequestMethod;
  authorizationType: Types.AuthorizationType;
  createNewUsersFromResponse: boolean;
  token?: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  oauth2Config?: AutomationOAuth2ConfigFields | null;
  queryParams: Array<AutomationRequestParamFields>;
  headerParams: Array<AutomationRequestParamFields>;
  formDataParams: Array<AutomationRequestParamFields>;
  jsonDataParams: Array<AutomationRequestParamFields>;
  responseJsonExtractorConfigs: Array<{
    id: number;
    jsonExpression: string;
    isAllowedToAppendAllowedValues?: boolean | null;
    field: FieldFields;
    fieldInstance?: FieldInstanceFields | null;
  }>;
  paginationConfig?: {
    paginationType: Types.PaginationType;
    limit?: number | null;
    initialOffset?: number | null;
  } | null;
  healthCheckConfig?: {
    id: number;
    isPaused: boolean;
    healthCheckRequest: { url: string };
    scheduledJob?: {
      id: number;
      type: Types.ScheduledJobType;
      interval: Types.ScheduledJobInterval;
      lastRunAt?: string | null;
      nextRunAt: string;
      runAt?: string | null;
      createdAt: string;
      updatedAt: string;
      errorNotificationConfig?: {
        id: number;
        notificationType: Types.ScheduledJobErrorNotificationType;
        createdAt: string;
        updatedAt: string;
        notifyParties: Array<{ id: number; user?: UserBaseFields | null }>;
      } | null;
    } | null;
    lastHealthCheckResult?: { id: number; statusCode: number; details: string; createdAt: string } | null;
  } | null;
};

export const AutomationRequestFields = gql`
    fragment AutomationRequestFields on AutomationRequest {
  id
  url
  method
  authorizationType
  createNewUsersFromResponse
  token
  oauth2Config {
    ...AutomationOAuth2ConfigFields
  }
  queryParams {
    ...AutomationRequestParamFields
  }
  headerParams {
    ...AutomationRequestParamFields
  }
  formDataParams {
    ...AutomationRequestParamFields
  }
  jsonDataParams {
    ...AutomationRequestParamFields
  }
  responseJsonExtractorConfigs {
    id
    jsonExpression
    field {
      ...FieldFields
    }
    fieldInstance {
      ...FieldInstanceFields
    }
    isAllowedToAppendAllowedValues
  }
  paginationConfig {
    paginationType
    limit
    initialOffset
  }
  healthCheckConfig {
    id
    isPaused
    healthCheckRequest {
      url
    }
    scheduledJob {
      id
      type
      interval
      lastRunAt
      nextRunAt
      runAt
      createdAt
      updatedAt
      errorNotificationConfig {
        id
        notificationType
        notifyParties {
          id
          user {
            ...UserBaseFields
          }
        }
        createdAt
        updatedAt
      }
    }
    lastHealthCheckResult {
      id
      statusCode
      details
      createdAt
    }
  }
  createdAt
  updatedAt
  deletedAt
}
    `;
