import type { FieldInstanceMultiValueIntBaseFields } from "./FieldInstanceMultiValueIntBaseFields.generated";
import type { AcyclicFieldInstanceMultiValueIntFields } from "./AcyclicFieldInstanceMultiValueIntFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueIntFields = {
  sourceFieldInstanceMultiValueInt?: AcyclicFieldInstanceMultiValueIntFields | null;
  sinksFieldInstanceMultiValueInt: Array<AcyclicFieldInstanceMultiValueIntFields>;
} & FieldInstanceMultiValueIntBaseFields;

export const FieldInstanceMultiValueIntFields = gql`
    fragment FieldInstanceMultiValueIntFields on FieldInstanceMultiValueInt {
  ...FieldInstanceMultiValueIntBaseFields
  sourceFieldInstanceMultiValueInt {
    ...AcyclicFieldInstanceMultiValueIntFields
  }
  sinksFieldInstanceMultiValueInt {
    ...AcyclicFieldInstanceMultiValueIntFields
  }
}
    `;
