import type { FieldInstanceMultiValueTimeBaseFields } from "./FieldInstanceMultiValueTimeBaseFields.generated";
import type { AcyclicFieldInstanceMultiValueTimeFields } from "./AcyclicFieldInstanceMultiValueTimeFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueTimeFields = {
  sourceFieldInstanceMultiValueTime?: AcyclicFieldInstanceMultiValueTimeFields | null;
  sinksFieldInstanceMultiValueTime: Array<AcyclicFieldInstanceMultiValueTimeFields>;
} & FieldInstanceMultiValueTimeBaseFields;

export const FieldInstanceMultiValueTimeFields = gql`
    fragment FieldInstanceMultiValueTimeFields on FieldInstanceMultiValueTime {
  ...FieldInstanceMultiValueTimeBaseFields
  sourceFieldInstanceMultiValueTime {
    ...AcyclicFieldInstanceMultiValueTimeFields
  }
  sinksFieldInstanceMultiValueTime {
    ...AcyclicFieldInstanceMultiValueTimeFields
  }
}
    `;
