import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import { gql } from "@apollo/client";
export type LineFields = {
  regrelloObjectInstanceId: number;
  lineNumber: number;
  fieldInstances: Array<FieldInstanceFields>;
};

export const LineFields = gql`
    fragment LineFields on Line {
  regrelloObjectInstanceId
  lineNumber
  fieldInstances {
    ...FieldInstanceFields
  }
}
    `;
