import type { UserBaseFields } from "./UserBaseFields.generated";
import type { TeamWithoutMembersFields } from "./TeamWithoutMembersFields.generated";
import { gql } from "@apollo/client";
export type PartyBaseFields = {
  __typename: "Party";
  id: number;
  user?: UserBaseFields | null;
  team?: TeamWithoutMembersFields | null;
};

export const PartyBaseFields = gql`
    fragment PartyBaseFields on Party {
  __typename
  id
  user {
    ...UserBaseFields
  }
  team {
    ...TeamWithoutMembersFields
  }
}
    `;
