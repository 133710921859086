import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FieldUnitsQueryVariables = Types.Exact<{
  fieldUnitType?: Types.InputMaybe<Types.FieldUnitType>;
}>;

export type FieldUnitsQuery = {
  fieldUnits: Array<{ id: number; type: Types.FieldUnitType; name: string; symbol: string }>;
};

export const FieldUnitsQueryDocument = gql`
    query FieldUnitsQuery($fieldUnitType: FieldUnitType) {
  fieldUnits(fieldUnitType: $fieldUnitType) {
    id
    type
    name
    symbol
  }
}
    `;

/**
 * __useFieldUnitsQuery__
 *
 * To run a query within a React component, call `useFieldUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldUnitsQuery({
 *   variables: {
 *      fieldUnitType: // value for 'fieldUnitType'
 *   },
 * });
 */
export function useFieldUnitsQuery(baseOptions?: Apollo.QueryHookOptions<FieldUnitsQuery, FieldUnitsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FieldUnitsQuery, FieldUnitsQueryVariables>(FieldUnitsQueryDocument, options);
}
export function useFieldUnitsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FieldUnitsQuery, FieldUnitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FieldUnitsQuery, FieldUnitsQueryVariables>(FieldUnitsQueryDocument, options);
}
export function useFieldUnitsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FieldUnitsQuery, FieldUnitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FieldUnitsQuery, FieldUnitsQueryVariables>(FieldUnitsQueryDocument, options);
}
export type FieldUnitsQueryHookResult = ReturnType<typeof useFieldUnitsQuery>;
export type FieldUnitsQueryLazyQueryHookResult = ReturnType<typeof useFieldUnitsQueryLazyQuery>;
export type FieldUnitsQuerySuspenseQueryHookResult = ReturnType<typeof useFieldUnitsQuerySuspenseQuery>;
export type FieldUnitsQueryResult = Apollo.QueryResult<FieldUnitsQuery, FieldUnitsQueryVariables>;
