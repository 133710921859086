import { gql } from "@apollo/client";
export type TagTypeFields = { id: number; name: string; backgroundColor: string; borderColor: string };

export const TagTypeFields = gql`
    fragment TagTypeFields on TagType {
  id
  name
  backgroundColor
  borderColor
}
    `;
