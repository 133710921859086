import type * as Types from "../../../generated/graphqlBaseTypes";

import { CustomFieldTableV2Field } from "../../fragments/generated/CustomFieldTableV2Field.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FieldsQueryV2Variables = Types.Exact<{
  fieldId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  columnCustomizationTableSpecifier?: Types.InputMaybe<Types.ColumnCustomizationTableSpecifier>;
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  sortBy?: Types.InputMaybe<Types.CustomFieldDefaultColumnOption>;
  sortOrder?: Types.InputMaybe<Types.SortOrder>;
  searchQuery?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type FieldsQueryV2 = { fieldsV2: { totalCount: number; fields: Array<CustomFieldTableV2Field> } };

export const FieldsQueryV2Document = gql`
    query FieldsQueryV2($fieldId: ID, $columnCustomizationTableSpecifier: ColumnCustomizationTableSpecifier, $limit: Int, $offset: Int, $sortBy: CustomFieldDefaultColumnOption, $sortOrder: SortOrder, $searchQuery: String) {
  fieldsV2(
    fieldId: $fieldId
    columnCustomizationTableSpecifier: $columnCustomizationTableSpecifier
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortOrder: $sortOrder
    searchQuery: $searchQuery
  ) {
    fields {
      ...CustomFieldTableV2Field
    }
    totalCount
  }
}
    ${CustomFieldTableV2Field}
${FieldFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${PropertyTypeFields}
${FieldUnitFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}`;

/**
 * __useFieldsQueryV2__
 *
 * To run a query within a React component, call `useFieldsQueryV2` and pass it any options that fit your needs.
 * When your component renders, `useFieldsQueryV2` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldsQueryV2({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      columnCustomizationTableSpecifier: // value for 'columnCustomizationTableSpecifier'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useFieldsQueryV2(baseOptions?: Apollo.QueryHookOptions<FieldsQueryV2, FieldsQueryV2Variables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FieldsQueryV2, FieldsQueryV2Variables>(FieldsQueryV2Document, options);
}
export function useFieldsQueryV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FieldsQueryV2, FieldsQueryV2Variables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FieldsQueryV2, FieldsQueryV2Variables>(FieldsQueryV2Document, options);
}
export function useFieldsQueryV2SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FieldsQueryV2, FieldsQueryV2Variables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FieldsQueryV2, FieldsQueryV2Variables>(FieldsQueryV2Document, options);
}
export type FieldsQueryV2HookResult = ReturnType<typeof useFieldsQueryV2>;
export type FieldsQueryV2LazyQueryHookResult = ReturnType<typeof useFieldsQueryV2LazyQuery>;
export type FieldsQueryV2SuspenseQueryHookResult = ReturnType<typeof useFieldsQueryV2SuspenseQuery>;
export type FieldsQueryV2QueryResult = Apollo.QueryResult<FieldsQueryV2, FieldsQueryV2Variables>;
