import type { FieldInstanceMultiValueFloatBaseFields } from "./FieldInstanceMultiValueFloatBaseFields.generated";
import type { AcyclicFieldInstanceMultiValueFloatFields } from "./AcyclicFieldInstanceMultiValueFloatFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueFloatFields = {
  sourceFieldInstanceMultiValueFloat?: AcyclicFieldInstanceMultiValueFloatFields | null;
  sinksFieldInstanceMultiValueFloat: Array<AcyclicFieldInstanceMultiValueFloatFields>;
} & FieldInstanceMultiValueFloatBaseFields;

export const FieldInstanceMultiValueFloatFields = gql`
    fragment FieldInstanceMultiValueFloatFields on FieldInstanceMultiValueFloat {
  ...FieldInstanceMultiValueFloatBaseFields
  sourceFieldInstanceMultiValueFloat {
    ...AcyclicFieldInstanceMultiValueFloatFields
  }
  sinksFieldInstanceMultiValueFloat {
    ...AcyclicFieldInstanceMultiValueFloatFields
  }
}
    `;
