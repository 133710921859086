import type { SpectrumFieldValidationTypeFields } from "./SpectrumFieldValidationTypeFields.generated";
import type { PropertyTypeFields } from "./PropertyTypeFields.generated";
import { gql } from "@apollo/client";
export type SpectrumValueConstraintFields = {
  id: number;
  uuid: string;
  createdAt: string;
  valueConstraintRule: string;
  spectrumFieldValidationType?: SpectrumFieldValidationTypeFields | null;
  spectrumFieldPropertyType?: PropertyTypeFields | null;
};

export const SpectrumValueConstraintFields = gql`
    fragment SpectrumValueConstraintFields on SpectrumValueConstraint {
  id
  uuid
  createdAt
  valueConstraintRule
  spectrumFieldValidationType {
    ...SpectrumFieldValidationTypeFields
  }
  spectrumFieldPropertyType {
    ...PropertyTypeFields
  }
}
    `;
