import type { FieldInstanceMultiValueIntFields } from "./FieldInstanceMultiValueIntFields.generated";
import type { AcyclicFieldInstanceMultiValueIntFields } from "./AcyclicFieldInstanceMultiValueIntFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueIntWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceMultiValueInt?: AcyclicFieldInstanceMultiValueIntFields | null;
  crossWorkflowSinksFieldInstanceMultiValueInt?: Array<AcyclicFieldInstanceMultiValueIntFields> | null;
} & FieldInstanceMultiValueIntFields;

export const FieldInstanceMultiValueIntWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueIntWithCrossWorkflowFields on FieldInstanceMultiValueInt {
  ...FieldInstanceMultiValueIntFields
  crossWorkflowSourceFieldInstanceMultiValueInt {
    ...AcyclicFieldInstanceMultiValueIntFields
  }
  crossWorkflowSinksFieldInstanceMultiValueInt {
    ...AcyclicFieldInstanceMultiValueIntFields
  }
}
    `;
