import { t } from "@lingui/macro";
import { ComparatorResult, EMPTY_ARRAY, EMPTY_STRING, stringComparator } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  ConditionOperator,
  type CreateFieldInstanceValueInputs,
  type FieldAllowedValueString,
  type FieldFields,
  type FieldInstanceFields,
  type FieldInstanceFieldsWithBaseValues,
  type FieldInstanceValueInputType,
  PropertyDataType,
  type PropertyTypeFields,
  type UpdateFieldInstanceValueInputs,
  type UpdateStartingConditionsInputs,
  type ViewFilterFields,
} from "@regrello/graphql-api";
import { RegrelloIcon } from "@regrello/ui-core";
import type { FieldPath, FieldValues } from "react-hook-form";

import { CustomFieldPluginRegistrar } from "./registry/customFieldPluginRegistrar";
import {
  type CustomFieldFrontendSelectableOption,
  INACTIVE_SELECTABLE_OPTION_ID,
} from "./types/CustomFieldFrontendSelectableOption";
import type { CustomFieldPlugin, CustomFieldPluginV2RenderFormFieldProps } from "./types/CustomFieldPlugin";
import { createViewColumnsFromField } from "./utils/createViewColumnsFromField";
import { DEFAULT_INPUT_TYPE_IF_NO_VALUE, getConditionOperatorsByType } from "./utils/customFieldConstants";
import { extractAllowedValuesForFrontend } from "./utils/extractAllowedValuesForFrontend";
import { extractSelectedValueOrThrow } from "./utils/extractSelectedValueOrThrow";
import {
  getIsFieldInstanceFields,
  getIsFieldInstanceValueWithCrossWorkflowFields,
} from "./utils/fieldInstanceTypeguards";
import { getUpdateStartingConditionsInputsForEmptyOperators } from "./utils/getUpdateStartingConditionsInputsForEmptyOperators";
import type { FieldInstanceBaseFields } from "../../../../types";
import { getFieldInstanceId } from "../../../../utils/customFields/getFieldInstanceId";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import {
  getRegrelloDefaultFilterDefinitionSingleSelectValue,
  getRegrelloFilterDefinitionSingleSelectValue,
} from "../../../molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Constants";
import {
  RegrelloControlledFormFieldMultiSelect,
  RegrelloControlledFormFieldSelect,
} from "../../formFields/controlled/regrelloControlledFormFields";
import { RegrelloInactiveBadge } from "../../inactiveBadge/RegrelloInactiveBadge";
import { RegrelloCustomFieldMultiValuePopover } from "../components/RegrelloCustomFieldMultiValuePopover";

// TODO Misc: Turn the following into warnings and handle gracefully so the app doesn't crash in
// case of field misconfiguration.
const ERROR_INVALID_FIELD = "Provided 'select' field is invalid";
const WARNING_INVALID_FORM_VALUE = "Provided 'text'-field form value is not a valid selectable option";
const ERROR_INVALID_VALUE_COUNT = "Provided 'select' field instance cannot have multiple values";
const ERROR_INVALID_VALUE_TYPE = "Provided 'select' field instance value must have type 'FieldInstanceValueString'";
const WARNING_UNEXPECTED_EMPTY_FORM_VALUE =
  "Provided 'select' field form value must not be empty given the provided operator type";
const WARNING_INVALID_OPERATOR_TYPE = "Provided operator type is invalid for 'select' fields";
const WARNING_UNEXPECTED_DEFINED_FORM_VALUE =
  "Provided 'select' field form value must not be defined given the provided operator type";

function canProcessPropertyDataType(propertyDataType: PropertyDataType): boolean {
  return propertyDataType === PropertyDataType.STRING;
}

function canProcessField(field: FieldFields): boolean {
  return (
    !field.isMultiValued && field.allowedValues.length > 0 && canProcessPropertyDataType(field.propertyType.dataType)
  );
}

function isItemsEqual(a: CustomFieldFrontendSelectableOption, b: CustomFieldFrontendSelectableOption) {
  return a.id === b.id;
}

// (zstanik): Added `CustomFieldFrontendSelectableOption[]` to the union type here to reflect that a
// `multiselect` input needs to be rendered for certain `select` start condition operator types.
// This won't result in any misconfigured field instances because we still validate that the form
// value is of the correct type before returning any mutation inputs.
type SelectFieldPluginSingleValuedFrontendValue = CustomFieldFrontendSelectableOption | null;
type SelectFieldPluginType = CustomFieldPlugin<
  SelectFieldPluginSingleValuedFrontendValue | CustomFieldFrontendSelectableOption[]
>;

const getConditionOperators: SelectFieldPluginType["getConditionOperators"] = () =>
  getConditionOperatorsByType("SELECT");

const renderDisplayValue: SelectFieldPluginType["renderDisplayValue"] = (fieldInstance) => {
  const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance, {
    includeInactiveOptions: true,
  });

  const frontendValue = frontendFieldInstance.value;

  return (
    <>
      {frontendValue?.value}
      {frontendValue?.id === INACTIVE_SELECTABLE_OPTION_ID && <RegrelloInactiveBadge />}
    </>
  );
};

const sortComparator: SelectFieldPluginType["sortComparator"] = (
  fieldInstance1,
  fieldInstance2,

  direction = "asc",
): ComparatorResult => {
  if (direction === "desc") {
    return SelectFieldPlugin.sortComparator(fieldInstance2, fieldInstance1, "asc");
  }

  if (fieldInstance1 == null) {
    return ComparatorResult.BEFORE;
  }

  if (fieldInstance2 == null) {
    return ComparatorResult.AFTER;
  }

  const fieldPlugin = CustomFieldPluginRegistrar.getPluginForField(fieldInstance1.field);

  const value1 = String(fieldPlugin.renderDisplayValue(fieldInstance1));
  const value2 = String(fieldPlugin.renderDisplayValue(fieldInstance2));

  return stringComparator(value1, value2);
};

/**
 * Describes a custom field that allows the user to select one value from an approved list.
 */
export const SelectFieldPlugin: SelectFieldPluginType = {
  uri: "com.regrello.customField.select",
  version: "1.0.0",

  canProcessField: (field: FieldFields): boolean => {
    return canProcessField(field);
  },

  canProcessFieldInstance: (fieldInstance: FieldInstanceBaseFields): boolean => {
    try {
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
      return true;
    } catch (_error) {
      return false;
    }
  },

  canProcessPropertyDataType,

  findPropertyTypeIdFromLoadedPropertyTypeIds: (loadedPropertyTypes: PropertyTypeFields[]): number | undefined => {
    // (clewis): 'Select' fields always hold text/string values.
    return loadedPropertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.STRING)?.id;
  },

  getColumnsForTable: createViewColumnsFromField,

  getConditionOperators,

  getCreateFieldInstanceValueInputsFromFormValue: (
    field: FieldFields,
    inputType: FieldInstanceValueInputType,
    value: unknown,
    displayOrder?: number,
    spectrumFieldVersionId?: number,
  ): CreateFieldInstanceValueInputs => {
    if (!isValueValidAndSingleValued(value)) {
      throw new Error(getErrorMessageWithPayload(WARNING_INVALID_FORM_VALUE, { field, inputType, value }));
    }
    if (
      value != null &&
      !field.allowedValues.some(
        (allowedValue) =>
          allowedValue.__typename === "FieldAllowedValueString" &&
          allowedValue.id === value.id &&
          allowedValue.stringValue === value.value,
      )
    ) {
      console.warn(
        "[SelectFieldPlugin] Received a value that does not appear in allowedValues. Silently ignoring it and using an empty value instead.",
        { field, value: value },
      );
      return {
        fieldId: field.id,
        inputType,
        displayOrder,
        spectrumFieldVersionId,
      };
    }
    return {
      fieldId: field.id,
      stringValue: value?.value ?? undefined,
      inputType,
      displayOrder,
      spectrumFieldVersionId,
    };
  },

  getCrossWorkflowSinksFieldInstanceIds: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number[] => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSinksFieldInstanceIds;
  },

  getCrossWorkflowSourceFieldInstanceIdFromValue: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSourceFieldInstanceId;
  },

  getFieldDisplayName: (): string => {
    return t`Select`;
  },

  getFilterDefinition: (field: FieldFields) => {
    return getRegrelloDefaultFilterDefinitionSingleSelectValue({
      type: "default",
      values: field.allowedValues.filter(isFieldAllowedValueString).map((value) => ({
        id: value.id,
        label: value.stringValue,
        value: value.stringValue,
      })),
    });
  },

  getFilterDefinitionWithValues: (field: FieldFields, filter: ViewFilterFields) => {
    const values = filter.values ?? EMPTY_ARRAY;
    const allowedValues = field.allowedValues.filter(isFieldAllowedValueString).map((value) => ({
      id: value.id,
      label: value.stringValue,
      value: value.stringValue,
    }));
    return getRegrelloFilterDefinitionSingleSelectValue(filter.operator, values, allowedValues);
  },

  getEmptyValueForFrontend: (
    options:
      | {
          operator: ConditionOperator;
        }
      | undefined,
  ): CustomFieldFrontendSelectableOption | CustomFieldFrontendSelectableOption[] | null => {
    if (options != null) {
      if (!isOperatorValid(options.operator)) {
        console.warn(WARNING_INVALID_OPERATOR_TYPE, {
          operator: options.operator,
        });
        return null;
      }
      if (
        options.operator === ConditionOperator.CONTAINS_ANY_OF ||
        options.operator === ConditionOperator.CONTAINS_NONE_OF
      ) {
        return EMPTY_ARRAY;
      }
    }
    return null;
  },

  getHelperText: (): string => {
    return t`Select allows you to select a single option from predefined options in a dropdown.`;
  },

  getIconName: () => {
    return "select-field";
  },

  getNameTemplateDisplayValueFromFormValue: (value) => {
    if (!isValueValidAndSingleValued(value) || value == null || value.value === EMPTY_STRING) {
      return undefined;
    }
    return value.value;
  },

  getSourceFieldInstance: (_fieldInstance: FieldInstanceFields): FieldInstanceFields | undefined => {
    return undefined;
  },

  getSourceFieldInstanceId: (fieldInstance: FieldInstanceFields): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstanceId;
  },

  getSourceFieldInstanceInputType: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): FieldInstanceValueInputType | undefined => {
    const sourceValue = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return sourceValue.sourceFieldInstanceInputType;
  },

  getUpdateStartingConditionsInputsFromFormValues: (
    leftFieldInstance: FieldInstanceFields,
    value: unknown,
    operator: ConditionOperator,
  ): UpdateStartingConditionsInputs | undefined => {
    if (!isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        leftFieldInstance,
        operator,
      });
      return undefined;
    }

    if (operator === ConditionOperator.EMPTY || operator === ConditionOperator.NOT_EMPTY) {
      if (value != null) {
        console.warn(WARNING_UNEXPECTED_DEFINED_FORM_VALUE, {
          leftFieldInstance,
          value,
        });
      }
      return getUpdateStartingConditionsInputsForEmptyOperators(getFieldInstanceId(leftFieldInstance), operator);
    }

    // (zstanik): For "Is in" and "Is not in", represented by CONTAINS_ANY and CONTAINS_NONE
    // respectively, the right side of the conditional expression is treated as `stringMultiValue`.
    if (operator === ConditionOperator.CONTAINS_ANY_OF || operator === ConditionOperator.CONTAINS_NONE_OF) {
      if (value == null || (Array.isArray(value) && value.length === 0)) {
        console.warn(WARNING_UNEXPECTED_EMPTY_FORM_VALUE, {
          leftFieldInstance,
          value,
        });
        return undefined;
      }
      if (
        !Array.isArray(value) ||
        value.some((element) => typeof element.id !== "number" || typeof element.value !== "string")
      ) {
        console.warn(WARNING_INVALID_FORM_VALUE, { leftFieldInstance, value });
        return undefined;
      }

      // (clewis): O(N^2) but this method will be called rarely, so no big deal.
      const validOptions = value.filter((selectedOption) =>
        leftFieldInstance.field.allowedValues.some((allowedValue) =>
          allowedValue.id === selectedOption.id && allowedValue.__typename === "FieldAllowedValueString"
            ? allowedValue.stringValue === selectedOption.value
            : false,
        ),
      );
      if (validOptions.length !== value.length) {
        console.warn(
          "[MultiSelectFieldPlugin] Received one or more values that do not appear in allowedValues. Silently ignoring them.",
          { leftFieldInstance, value: value, validOptions },
        );
      }

      return {
        leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
        operatorV2: operator,
        rightFloatMultiValue: EMPTY_ARRAY,
        rightIntMultiValue: EMPTY_ARRAY,
        rightPartyIDMultiValue: EMPTY_ARRAY,
        rightStringMultiValue: validOptions.map((option) => option.value),
        rightTimeMultiValue: EMPTY_ARRAY,
      };
    }

    // (zstanik): For all other operators, the value is expected to be a single
    // `CustomFieldFrontendSelectableOption`
    const castedValue = value as CustomFieldFrontendSelectableOption | null | undefined;
    if (castedValue == null) {
      console.warn(WARNING_UNEXPECTED_EMPTY_FORM_VALUE, {
        leftFieldInstance,
        value,
      });
      return undefined;
    }
    if (
      typeof castedValue !== "object" ||
      typeof castedValue.id !== "number" ||
      typeof castedValue.value !== "string"
    ) {
      console.warn(WARNING_INVALID_FORM_VALUE, { leftFieldInstance, value });
      return undefined;
    }

    return {
      leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
      operatorV2: operator,
      rightStringValue: castedValue.value,
      rightFloatMultiValue: EMPTY_ARRAY,
      rightIntMultiValue: EMPTY_ARRAY,
      rightPartyIDMultiValue: EMPTY_ARRAY,
      rightStringMultiValue: EMPTY_ARRAY,
      rightTimeMultiValue: EMPTY_ARRAY,
    };
  },
  // (hchen): Currently this is only used for handling the edge case when flipping the field
  // instance input type from OPTIONAL to REQUESTED (i.e. required) when the a previously OPTIONAL
  // field is set as a native field on a future task. It's not necessary to implement for field
  // types other than date and user, since we only support those as native field now.
  getUpdateFieldInstanceValueInputsFromFieldInstance: (
    _fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): UpdateFieldInstanceValueInputs[] => {
    throw new Error("not implmented yet");
  },

  getValueForFrontend: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
    options?: { includeInactiveValues: boolean },
  ): CustomFieldFrontendSelectableOption | null => {
    return translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance, {
      includeInactiveOptions: options?.includeInactiveValues ?? false,
    }).value;
  },

  hasAllowedValues: (): boolean => {
    return true;
  },

  isCreateAndEditAllowed: true,

  isFeatureFlagEnabled: (): boolean => {
    return true;
  },

  isFieldInstanceEmpty: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    options?: { includeInactiveOptions: boolean },
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance, {
      includeInactiveOptions: options?.includeInactiveOptions ?? false,
    });
    return frontendFieldInstance.value == null;
  },

  isFieldInstanceValueUnchanged: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    proposedChange: CreateFieldInstanceValueInputs,
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    if (frontendFieldInstance.sourceFieldInstanceId !== proposedChange.sourceFieldInstanceValueId) {
      return false;
    }

    if (frontendFieldInstance.inputType !== proposedChange.inputType) {
      return false;
    }

    return (
      (frontendFieldInstance.value == null && proposedChange.stringValue == null) ||
      frontendFieldInstance.value?.value === proposedChange.stringValue
    );
  },

  renderDisplayValue: renderDisplayValue,

  renderFormField: <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    field: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
  ): React.ReactNode => {
    if (!canProcessField(field)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
    }
    const isDeleted = props.fieldInstance?.field.deletedAt != null;

    const { operator } = props;
    if (operator != null) {
      if (!isOperatorValid(operator)) {
        console.warn(WARNING_INVALID_OPERATOR_TYPE, {
          operator,
        });
        return undefined;
      }

      // (zstanik): For "Is in" and "Is not in", represented by CONTAINS_ANY and CONTAINS_NONE
      // respectively, the right side of the conditional expression is treated as
      // `stringMultiValue`, thus a MultiSelect input needs to be rendered.
      if (operator === ConditionOperator.CONTAINS_ANY_OF || operator === ConditionOperator.CONTAINS_NONE_OF) {
        return (
          <RegrelloControlledFormFieldMultiSelect
            {...props}
            key={props.controllerProps.name}
            dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
            getSelectedItemProps={getSelectedItemProps}
            infoTooltipText={props.description}
            isDeleted={isDeleted}
            isItemsEqual={isItemsEqual}
            itemPredicate={itemPredicate}
            items={extractAllowedValuesForFrontend(field)}
            renderItem={renderItem}
          />
        );
      }
    }

    return (
      <RegrelloControlledFormFieldSelect
        {...props}
        key={props.controllerProps.name}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        getOptionLabel={getOptionLabel}
        infoTooltipText={props.description}
        isClearButtonEnabled={true}
        isDeleted={isDeleted}
        options={extractAllowedValuesForFrontend(field)}
      />
    );
  },

  renderIcon: (props) => {
    return <RegrelloIcon {...props} iconName="select-field" />;
  },

  renderMultipleDisplayValuesForDataGrid: (fieldInstances, options) => {
    if (fieldInstances.length === 0) {
      return null;
    }
    if (fieldInstances.length === 1) {
      return renderDisplayValue(fieldInstances[0], { context: options?.context ?? "table" });
    }

    const instancesWithSource = fieldInstances.map((fieldInstance) => {
      return {
        content: renderDisplayValue(fieldInstance, { context: options?.context ?? "table" }),
        workflowName: fieldInstance.workflow?.name,
        stageName: fieldInstance.actionItem?.workflowReference?.stageName,
        taskName: fieldInstance.actionItem?.name,
      };
    });

    return <RegrelloCustomFieldMultiValuePopover instancesWithSource={instancesWithSource} />;
  },

  sortComparator,
};

interface FrontendSelectFieldInstance {
  name: string;
  allowedValues: CustomFieldFrontendSelectableOption[];
  inputType: FieldInstanceValueInputType;
  crossWorkflowSinksFieldInstanceIds: number[];
  crossWorkflowSourceFieldInstanceId: number | undefined;
  sinksFieldInstanceIds: number[];
  sourceFieldInstanceId: number | undefined;
  sourceFieldInstanceInputType: FieldInstanceValueInputType | undefined;
  value: CustomFieldFrontendSelectableOption | null;
}

function getOptionLabel(allowedValue: { id: number; value: string }) {
  return allowedValue.value;
}

function getSelectedItemProps(allowedValue: { id: number; value: string }) {
  return {
    children: allowedValue.value,
  };
}

function itemPredicate(query: string, allowedValue: { id: number; value: string }) {
  return allowedValue.value.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}

function renderItem(allowedValue: { id: number; value: string }) {
  return {
    key: allowedValue.id,
    text: allowedValue.value,
  };
}

function translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  options?: {
    includeInactiveOptions: boolean;
  },
): FrontendSelectFieldInstance {
  const { field } = fieldInstance;

  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  const isFieldInstanceFields = getIsFieldInstanceFields(fieldInstance);

  return extractSelectedValueOrThrow<FrontendSelectFieldInstance>({
    fieldInstance,
    errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
    errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
    getterIfNoValue: (allowedValues) => ({
      name: field.name,
      allowedValues,
      inputType: DEFAULT_INPUT_TYPE_IF_NO_VALUE,
      crossWorkflowSinksFieldInstanceIds: EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: undefined,
      sinksFieldInstanceIds: EMPTY_ARRAY,
      sourceFieldInstanceId: undefined,
      sourceFieldInstanceInputType: undefined,
      value: null,
    }),
    getterIfValue: (fieldInstanceValues, frontendSelectedOptions, allowedValues) => ({
      name: field.name,
      allowedValues,
      inputType: fieldInstanceValues[0].inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValues[0]) &&
        fieldInstanceValues[0].crossWorkflowSinksFieldInstanceValueString != null
          ? fieldInstanceValues[0].crossWorkflowSinksFieldInstanceValueString.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValues[0])
        ? fieldInstanceValues[0].crossWorkflowSourceFieldInstanceValueString?.id
        : undefined,
      sinksFieldInstanceIds: isFieldInstanceFields
        ? fieldInstanceValues[0].sinksFieldInstanceValueString?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstanceId: isFieldInstanceFields
        ? (fieldInstanceValues[0].sourceFieldInstanceValueString?.id ?? undefined)
        : undefined,
      sourceFieldInstanceInputType: isFieldInstanceFields
        ? fieldInstanceValues[0].sourceFieldInstanceValueString?.inputType
        : undefined,
      value: frontendSelectedOptions.length === 0 ? null : frontendSelectedOptions[0],
    }),
    options: {
      includeInactiveOptions: options?.includeInactiveOptions ?? false,
    },
  });
}

function isOperatorValid(
  operator: ConditionOperator,
): operator is
  | ConditionOperator.EMPTY
  | ConditionOperator.NOT_EMPTY
  | ConditionOperator.EQUALS
  | ConditionOperator.NOT_EQUALS
  | ConditionOperator.CONTAINS_ANY_OF
  | ConditionOperator.CONTAINS_NONE_OF {
  return getConditionOperators().find((stageStartOperator) => stageStartOperator.operator === operator) != null;
}

function isFieldAllowedValueString(
  value: FieldFields["allowedValues"][0],
): value is FieldAllowedValueString & { __typename: "FieldAllowedValueString" } {
  return value.__typename === "FieldAllowedValueString";
}

function isValueValidAndSingleValued(value: unknown): value is SelectFieldPluginSingleValuedFrontendValue {
  const castedValue = value as CustomFieldFrontendSelectableOption | null;
  return (
    castedValue == null ||
    (typeof castedValue === "object" && typeof castedValue.id === "number" && typeof castedValue.value === "string")
  );
}
