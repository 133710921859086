import type { GenericWorkflowStageFields } from "./GenericWorkflowStageFields.generated";
import type { WorkflowGraphActionItemFields } from "./WorkflowGraphActionItemFields.generated";
import { gql } from "@apollo/client";
export type WorkflowGraphStageFields = {
  id: number;
  stage: GenericWorkflowStageFields;
  next: Array<{ stage: GenericWorkflowStageFields }>;
  rootActionItems: Array<WorkflowGraphActionItemFields>;
  headlessActionItems: Array<WorkflowGraphActionItemFields>;
};

export const WorkflowGraphStageFields = gql`
    fragment WorkflowGraphStageFields on WorkflowGraphStage {
  id
  stage {
    ...GenericWorkflowStageFields
  }
  next {
    stage {
      ...GenericWorkflowStageFields
    }
  }
  rootActionItems {
    ...WorkflowGraphActionItemFields
  }
  headlessActionItems {
    ...WorkflowGraphActionItemFields
  }
}
    `;
