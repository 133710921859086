import type { PureQueryOptions } from "@apollo/client";
import {
  assertNever,
  EMPTY_ARRAY,
  EMPTY_STRING,
  type FormOpenedInNewTabOption,
  localStorageGetTyped,
  localStorageSetTyped,
  RegrelloLocalStorageKey,
} from "@regrello/core-utils";
import type {
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  WorkflowScheduleStatus,
} from "@regrello/graphql-api";
import type { RegrelloButtonProps, RegrelloIconName } from "@regrello/ui-core";
import { atom } from "jotai";
import { atomWithReducer } from "jotai/utils";
import Cookies from "js-cookie";

import {
  RegrelloSessionStorageKey,
  sessionStorageGetTyped,
  sessionStorageRemoveTyped,
  sessionStorageSetTyped,
} from "../utils/getFromSessionStorage";

// Types
// =====

/**
 * A generic type representing an action payload for atomWithReducer.
 */
type RegrelloAtomAction<T extends string, P> = { type: T; payload: P };

export const IS_AUTHENTICATED_COOKIE_KEY = "is_authenticated";

export type RefetchQueryPoolAction =
  | RegrelloAtomAction<"add", PureQueryOptions>
  | RegrelloAtomAction<"clear", undefined>;

// Atoms
// =====

export const breadCrumbStateAtom =
  atom<
    Array<{
      name: string;
      url?: string;
    }>
  >(EMPTY_ARRAY);

/**
 * The current state of the dialog that appears when the user downloads a blank 'bulk start
 * workflows' CSV for a template that contains a document field.
 */
export const bulkStartWorkflowsDocumentFieldWarningDialogStateAtom = atom<
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      documentFieldInstances: Array<FieldInstanceFields | FieldInstanceFieldsWithBaseValues>;
    }
>({ isOpen: false });

export const bulkStartWorkflowsV2DialogStateAtom = atom<
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      workflowTemplateId: number;
      workflowTemplateName: string;
    }
>({ isOpen: false });

/**
 * The current state of the dialog that appears when a 'bulk start workflows' job is taking a long
 * time (via CSV upload for a particular workflow template).
 */
export const bulkStartWorkflowsLongWaitDialogStateAtom = atom<{ isOpen: boolean }>({ isOpen: false });

/**
 * The current state of the dialog that appears when a 'bulk start workflows' job finishes.
 */
export const bulkStartWorkflowsSummaryDialogStateAtom = atom<
  | { isOpen: false }
  | {
      isOpen: true;
      workflowTemplateName: string;
      numStarted: number;
      numFailed: number;
      startedCsvUrl: string | undefined;
      failedCsvUrl: string | undefined;
    }
>({ isOpen: false });

/**
 * The client ID of Regrello's Box account, loaded via an `/environment` request
 * on app load. This is needed for authenticating integrations with our Box app.
 */
export const boxClientIdAtom = atom<string>(EMPTY_STRING);

/**
 * The client ID of Regrello's DocuSign account for development, loaded via an `/environment`
 * request on app load. This is needed for authenticating integrations with our DocuSign app.
 */
export const docusignDeveloperClientIdAtom = atom<string>(EMPTY_STRING);

/**
 * The client ID of Regrello's DocuSign account for production, loaded via an `/environment` request
 * on app load. This is needed for authenticating integrations with our DocuSign app.
 */
export const docusignProductionClientIdAtom = atom<string>(EMPTY_STRING);

/**
 * The current version number of the home-table data. Data should be reloaded when this changes.
 */
export const homeTableDataLoadVersionAtom = atom<number>(0);

export const inboxMiniViewsAtom = atomWithReducer<number[], number[]>(
  localStorageGetTyped(RegrelloLocalStorageKey.INBOX_MINI_VIEW_IDS) ?? [],

  (_prevValue, newValue: number[]) => {
    localStorageSetTyped(RegrelloLocalStorageKey.INBOX_MINI_VIEW_IDS, newValue);
    return newValue;
  },
);

/**
 * Whether the app is waiting for auth0 authentication to finish. This is necessary because auth0
 * api is not a proper async call. The api doesn't provide a way for us to know exactly when the
 * authentication is finished.
 */
export const isWaitingForAuth0AuthenticationAtom = atomWithReducer<boolean, boolean>(
  sessionStorageGetTyped(RegrelloSessionStorageKey.IS_WAITING_FOR_AUTH0_AUTHENTICATION) === "true",
  (_prevValue, newValue: boolean) => {
    // (hchen): Store this in session storage because a navigation happens after Auth0
    // authentication and it can't be disabled.
    if (newValue == null || newValue === false) {
      sessionStorageRemoveTyped(RegrelloSessionStorageKey.IS_WAITING_FOR_AUTH0_AUTHENTICATION);
      return false;
    }
    // eslint-disable-next-line lingui/no-unlocalized-strings
    sessionStorageSetTyped(RegrelloSessionStorageKey.IS_WAITING_FOR_AUTH0_AUTHENTICATION, "true");
    return newValue;
  },
);

/**
 * Whether the 'Add Action Dialog' is currently open.
 */
export const isAddActionDialogOpenAtom = atom(false);

/**
 * Options for how the global create workflow dialog should be opened.
 *
 * - A value of `open` will open the creation dialog with no permission constraints.
 * - A value of `openDisableCreateFromScratch` will open the creation dialog, but disable the ability
 *   for the user to create a workflow from scratch. This is useful when the user can start a workflow
 *   from any of their shared blueprints, but _not_ create one from scratch (i.e., without a template).
 * - A value of `closed` will prevent the dialog from appearing.
 */
export type AddWorkflowDialogOpenAtomOptions = "open" | "openDisableCreateFromScratch" | "closed";

/**
 * Whether the 'Add Workflow Dialog' is currently open. Refer to {@link AddWorkflowDialogOpenAtomOptions}
 * for more information about the dialog's possible states, and this atom's possible values.
 */
// eslint-disable-next-line lingui/no-unlocalized-strings
export const isAddWorkflowDialogOpenAtom = atom<AddWorkflowDialogOpenAtomOptions>("closed");

/** Whether the user is logged in. */
export const isAuthenticatedAtom = atomWithReducer<boolean, boolean>(
  Cookies.get(IS_AUTHENTICATED_COOKIE_KEY) === "true",
  (_prevValue, newValue: boolean) => {
    if (!newValue) {
      Cookies.remove(IS_AUTHENTICATED_COOKIE_KEY);
    } else {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      Cookies.set(IS_AUTHENTICATED_COOKIE_KEY, "true");
    }
    return newValue;
  },
);

export const isAiMonitorDialogOpenAtom = atom(false);
export const isCreateUserDialogOpenAtom = atom(false);
export const isCreateTagDialogOpenAtom = atom(false);
export const isCreateFormDialogOpenAtom = atom(false);
export const isCreateTeamDialogOpenAtom = atom(false);
export const isCreateRoleDialogOpenAtom = atom(false);
export const isCreateWorkflowDialogOpenAtom = atom(false);
export const isCreateWorkflowTemplateDialogOpenAtom = atom(false);
export const isCreateWorkflowTemplateFromScratchDialogOpenAtom = atom(false);
export const isDetailDialogOpenAtom = atom(false);
export const isImportBlueprintDialogOpenAtom = atom(false);
export const isGrantAccessToBlueprintDialogOpenAtom = atom(false);
export const isSearchOpenAtom = atom(false);
export const isSidebarCollapsedAtom = getLocalStorageBooleanAtom(RegrelloLocalStorageKey.IS_SIDEBAR_COLLAPSED);

export const formOpenedInNewTabAtom = atomWithReducer<
  FormOpenedInNewTabOption | undefined,
  FormOpenedInNewTabOption | undefined
>(
  localStorageGetTyped(RegrelloLocalStorageKey.FORM_CREATED_VIA_FORM_SELECT_CREATE_OPTION),

  (_prevValue, newValue: FormOpenedInNewTabOption | undefined) => {
    if (newValue == null) {
      localStorage.removeItem(RegrelloLocalStorageKey.FORM_CREATED_VIA_FORM_SELECT_CREATE_OPTION);
      return undefined;
    }
    localStorageSetTyped(RegrelloLocalStorageKey.FORM_CREATED_VIA_FORM_SELECT_CREATE_OPTION, newValue);
    return newValue;
  },
);

export const pageHeaderNavigationButtonsPropsAtom = atom<
  | {
      backButtonProps: { disabled?: boolean; onClick?: () => void };
      forwardButtonProps: { disabled?: boolean; onClick?: () => void };
    }
  | undefined
>(undefined);

/**
 * This atom allows for any component in the app to set props for the `RegrelloPageContentHeader`'s create
 * button (the one on the right) to show it, hide it, or control other events for it. It's basically a
 * tunnel between `RegrelloPageContentHeader`'s button and any other component that needs to use it.
 *
 * Supplying an array of `RegrelloButtonProps` will cause the `RegrelloPageContentHeader` to render multiple.
 */
export const pageHeaderCreateButtonPropsAtom = atom<RegrelloButtonProps | RegrelloButtonProps[] | undefined>(undefined);

/**
 * This atom allows all pages to show a update message such as "Saved at <timestamp>" message on the
 * page header.
 */
export const pageHeaderUpdatePropsAtom = atom<
  | {
      iconName: RegrelloIconName;
      isLoading: false;
      message: React.ReactNode;
    }
  | {
      isLoading: true;
      message: React.ReactNode;
    }
  | undefined
>(undefined);

/**
 * The atom allows the workflow page to show a banner indicating the current status of a workflow.
 */
export const pageHeaderWorkflowStatusAtom = atom<
  | {
      updatedBy: string;
      updatedAt: string;
      status: WorkflowScheduleStatus;
    }
  | undefined
>(undefined);

/**
 * The id of the most recently added Action Item. This is useful for the nicety of highlighting the
 * Action Item that was just added if it belongs to the current screen the user is on.
 */
export const recentlyAddedActionItemIdAtom = atom<number | undefined>(undefined);

/**
 * The IDs of workflows successfully created via CSV upload. These workflows will be hightlighted
 * for a short period of time.
 */
export const recentlyCreatedWorkflowIdsAtom = atom<number[]>(EMPTY_ARRAY);

/**
 * @deprecated 17 Aug 2023. Please don't use this. It's a legacy mechanism from 2021 that leads to
 * lots of over-fetching.
 */
export const refetchQueriesPoolAtom = atomWithReducer(
  EMPTY_ARRAY as PureQueryOptions[],
  (refetchQueriesPool, action: RefetchQueryPoolAction) => {
    let nextRefetchQueriesPool: PureQueryOptions[];

    switch (action.type) {
      case "add":
        nextRefetchQueriesPool = [action.payload, ...refetchQueriesPool];
        break;
      case "clear":
        nextRefetchQueriesPool = [];
        break;
      default:
        assertNever(action);
    }
    return nextRefetchQueriesPool;
  },
);

/**
 * Invoke this to trigger a reload of home-table data.
 */
// TODO (clewis): Replace with GraphQL subscriptions?
export const reloadHomeTableDataAtom = atom(undefined, (get, set) => {
  set(homeTableDataLoadVersionAtom, get(homeTableDataLoadVersionAtom) + 1);
});

/**
 * The Action Item ID that is currently selected and being displayed in the Detail dialog.
 */
export const selectedActionItemUuidAtom = atom<string | undefined>(undefined);

/**
 * The Action Item template ID that is currently selected and being displayed in the Detail dialog.
 */
export const selectedActionItemTemplateIdAtom = atom<number | undefined>(undefined);

export type UserTheme = "light" | "dark" | "system";

/** The user's preferred visusal theme for their own Regrello UI. */
export const userThemeAtom = atomWithReducer<UserTheme, UserTheme>(
  localStorageGetTyped(RegrelloLocalStorageKey.USER_THEME) ?? "light", // Default is "light" theme.

  (_prevValue, newValue: UserTheme) => {
    localStorageSetTyped(RegrelloLocalStorageKey.USER_THEME, newValue);
    return newValue;
  },
);

/**
 * An atom to hold performance-report related context so that UserContext doesn't need to wrap
 * everything.
 */
export const fpsPerformanceReportUserAtom = atom<{ userEmail: string; tenantName: string }>({
  userEmail: EMPTY_STRING,
  tenantName: EMPTY_STRING,
});

/**
 * Atom that is driven by the `useAsyncEnvironmentRequest` hook. It contains the result of the
 * privacy acknowledgement banner environment variable.
 */
export const isPrivacyAcknowledgementBannerEnabledAtom = atom<boolean>(false);
export const privacyAcknowledgementBannerLinkAtom = atom<string>(EMPTY_STRING);

/**
 * Atom that is driven by the `useAsyncEnvironmentRequest` hook. It contains the result of the
 * PENDO_ENABLED environment variable.
 */
export const isPendoEnabledAtom = atom<boolean>(false);

function getLocalStorageBooleanAtom(localStorageKey: RegrelloLocalStorageKey) {
  return atomWithReducer<boolean, boolean>(
    localStorageGetTyped(localStorageKey) === "true",

    (_prevValue, newValue: boolean) => {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      localStorageSetTyped(localStorageKey, newValue ? "true" : "false");
      return newValue;
    },
  );
}
