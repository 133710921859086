import type { FieldInstanceMultiValueRegrelloObjectInstanceFields } from "./FieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import type { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from "./AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceMultiValueRegrelloObjectInstance?: AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields | null;
  crossWorkflowSinksFieldInstanceMultiValueRegrelloObjectInstance?: Array<AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields> | null;
} & FieldInstanceMultiValueRegrelloObjectInstanceFields;

export const FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields on FieldInstanceMultiValueRegrelloObjectInstance {
  ...FieldInstanceMultiValueRegrelloObjectInstanceFields
  crossWorkflowSourceFieldInstanceMultiValueRegrelloObjectInstance {
    ...AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields
  }
  crossWorkflowSinksFieldInstanceMultiValueRegrelloObjectInstance {
    ...AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields
  }
}
    `;
