import { t } from "@lingui/macro";
import {
  clsx,
  EMPTY_ARRAY,
  EMPTY_STRING,
  getScrollParentElement,
  isDefined,
  isElementInViewport,
  queueMacrotask,
  useConfirmationDialog,
  useSimpleDialog,
} from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  type CreateFieldInstanceValueInputs,
  type FieldFields,
  type FieldInstanceFields,
  type FieldInstanceFieldsWithBaseValues,
  FieldInstanceValueInputType,
  FieldType,
  type SpectrumFieldVersionFields,
} from "@regrello/graphql-api";
import {
  RegrelloButton,
  RegrelloChip,
  RegrelloConfirmationDialog,
  RegrelloIcon,
  RegrelloTooltip,
  RegrelloTypography,
} from "@regrello/ui-core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { type DefaultValues, useFieldArray, type UseFormReturn } from "react-hook-form";
import { useMount, usePrevious, useUnmount } from "react-use";

import { CSS_CLASS_COLUMN_GAP, CSS_CLASS_WIDTH_IS_REQUIRED } from "./_internal/fieldInstanceRowConstants";
import { FieldInstanceRowItem } from "./_internal/FieldInstanceRowItem";
import { RegrelloObjectProjectionSettings } from "./_internal/RegrelloObjectProjection";
import type { RegrelloConfigureCustomFieldsInputMappingForm } from "./RegrelloConfigureCustomFieldsInputMappingForm";
import { type RegrelloConfigureSpectrumFormsFormFields, useConfigureSpectrumForms } from "./useConfigureSpectrumForms";
import { ValidationRules, WORKFLOW_OWNER_FIELD_NAME } from "../../../../../constants/globalConstants";
import { FeatureFlagService } from "../../../../../services/FeatureFlagService";
import { isFieldFormFieldRequired } from "../../../../../utils/customFields/customFieldFormUtils";
import { retainFieldInstancesByInputType } from "../../../../../utils/customFields/customFieldInputTypeUtils";
import { getCustomFieldInstanceInputType } from "../../../../../utils/customFields/getCustomFieldInstanceInputType";
import { getFieldInstanceId } from "../../../../../utils/customFields/getFieldInstanceId";
import { consoleWarnInDevelopmentModeOnly } from "../../../../../utils/environmentUtils";
import { useUser } from "../../../../app/authentication/userContextUtils";
import { Permissions } from "../../../../app/authorization/permissions";
import { CustomFieldPluginRegistrar } from "../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { RegrelloControlledFormFieldCustomFieldSelectV2 } from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldSpectrumFieldSelect } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldSpectrumFieldSelect";
import type {
  FormSelectFormVersionFields,
  RegrelloFormFieldSpectrumFormSelectProps,
} from "../../../../molecules/formFields/RegrelloFormFieldSpectrumFormSelect";
import { RegrelloFormFieldText } from "../../../../molecules/formFields/RegrelloFormFieldText";
import { RegrelloFormSection } from "../../../../molecules/formSection/RegrelloFormSection";
import { SpectrumFieldPluginRegistrar } from "../../../../molecules/spectrumFields/registry/spectrumFieldPluginRegistrar";
import { getAllValidationRulesFromFieldConstraints } from "../../../../molecules/spectrumFields/utils/spectrumFieldConstraintUtils";

const DEFAULT_INPUT_TYPE = FieldInstanceValueInputType.PROVIDED;

/**
 * A form in which the user can add and edit custom field instances belonging to a workflow,
 * template, or task.
 */
export namespace RegrelloConfigureCustomFieldsForm {
  export enum InputKeys {
    CUSTOM_FIELD_SELECT = "customFieldSelect",
    CUSTOM_FIELD_FORM_FIELD = "customFieldFormField",
    DELETE_BUTTON = "deleteButton",
    EDIT_REGRELLO_OBJECT_BUTTON = "editRegrelloObjectButton",
    INPUT_TYPE_SWITCH = "inputTypeSwitch",
  }

  export interface Fields {
    customFields: Array<{
      fieldInstanceId: number;
      field: FieldFields | null;
      spectrumField: SpectrumFieldVersionFields | null;
      // biome-ignore lint/suspicious/noExplicitAny: (clewis): Can't use `unknown` because DefaultValues<Fields> would coerce it to `{} | undefined`.
      values: any;
      inputType: FieldInstanceValueInputType;
      isCopy?: boolean;
      isEditDisabled: boolean;
      isMultiValued: boolean;
      displayOrder?: number;
      projection?: {
        selectedRegrelloObjectPropertyIds: number[];
      };
    }>;
  }

  export interface InputTypeProps {
    /**
     * The initial input type to specify when the user adds a new custom field in the form.
     * @default FieldInstanceValueInputType.PROVIDED
     */
    defaultInputType?: FieldInstanceValueInputType.REQUESTED | FieldInstanceValueInputType.PROVIDED;

    /**
     * Whether the input type cannot be changed for any row in the form.
     * @default false
     */
    disallowSelectInputType?: boolean;
  }

  export function getDefaultValues(
    fieldInstances: Array<FieldInstanceFields | FieldInstanceFieldsWithBaseValues> | undefined,
  ): DefaultValues<Fields> {
    if (fieldInstances != null) {
      return {
        customFields:
          retainFieldInstancesByInputType(fieldInstances, [
            FieldInstanceValueInputType.REQUESTED,
            FieldInstanceValueInputType.PROVIDED,
            FieldInstanceValueInputType.OPTIONAL,
          ])
            .map((fieldInstance) => ({
              fieldInstanceId: getFieldInstanceId(fieldInstance),
              field: fieldInstance.field,
              spectrumField: fieldInstance.spectrumFieldVersion || null,
              inputType: getCustomFieldInstanceInputType(fieldInstance),
              isCopy: fieldInstance.isCopy ?? undefined,
              isEditDisabled: false,
              values:
                CustomFieldPluginRegistrar.getPluginForFieldInstance(fieldInstance).getValueForFrontend(fieldInstance),
              isMultiValued: fieldInstance.isMultiValued ?? false,
              projection: fieldInstance.projection || undefined,
              displayOrder: fieldInstance.displayOrder || undefined,
            }))
            .sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0)) ?? EMPTY_ARRAY,
      } satisfies Fields;
    }
    return {
      customFields: EMPTY_ARRAY,
    };
  }

  export type FieldWithMetadata = {
    field: FieldFields;
    spectrumField?: SpectrumFieldVersionFields;
    isMultiValued?: boolean;
    projection?: {
      selectedRegrelloObjectPropertyIds: number[];
    };
  };

  // TODO
  export function getDefaultValuesFromFieldsAndMetadata(
    fieldsMetadata: FieldWithMetadata[],
    defaultInputType: FieldInstanceValueInputType = DEFAULT_INPUT_TYPE,
  ): DefaultValues<Fields> {
    return {
      // Follow the same data shape used when adding an empty row to the form
      customFields: fieldsMetadata.map((fieldMetadata) => ({
        field: fieldMetadata.field,
        inputType: defaultInputType,
        isEditDisabled: fieldMetadata.field.deletedAt != null,
        isMultiValued: fieldMetadata.isMultiValued ?? false,
        projection: fieldMetadata.projection,
        values: CustomFieldPluginRegistrar.getPluginForField(fieldMetadata.field).getEmptyValueForFrontend(),
      })),
    };
  }

  /** Convert custom fields from form values into mutation inputs. */
  export function getMutationCreatePayloadFromFormValues(formValues: Fields): CreateFieldInstanceValueInputs[] {
    return formValues.customFields.reduce<CreateFieldInstanceValueInputs[]>(
      (inputsArr, { spectrumField, field, inputType, values, isMultiValued, projection }, index) => {
        const currentField = getFieldFromVersionOrFallbackToField(field, spectrumField);
        if (currentField == null) {
          consoleWarnInDevelopmentModeOnly(
            "Received a non-defined 'field' while creating CreateFieldInstanceValueInputs on form submit. " +
              "The form shouldn't have been allowed to submit with non-defined 'field'",
            formValues.customFields,
          );
          return inputsArr;
        }
        // Skip system fields
        // (akager): Do not skip the workflow owner system field. Hack: Relies on the field name.
        if (currentField?.fieldType === FieldType.SYSTEM && currentField.name !== WORKFLOW_OWNER_FIELD_NAME) {
          return inputsArr;
        }
        const customFieldPlugin = CustomFieldPluginRegistrar.getPluginForField(currentField);
        const newInputs = customFieldPlugin.getCreateFieldInstanceValueInputsFromFormValue(
          currentField,
          inputType,
          values,
          index + 1,
          spectrumField?.id,
          isMultiValued,
          projection,
        );
        inputsArr.push(newInputs);
        return inputsArr;
      },
      [],
    );
  }

  export interface Props extends InputTypeProps {
    /** If defined, the add field button will be disabled with the provided tooltip text. */
    addFieldButtonDisabledTooltipText?: string;

    // RegrelloConfigureCustomFieldsFormSectionV2Props
    allowCreateUsers?: boolean;

    /** @default false */
    allowCreateFields?: boolean;

    /**
     * Whether to allow fields to have empty values.
     * @default false
     */
    allowEmptyValues?: boolean;

    /**
     * Whether to allow selecting the workflow owner system field
     * @default false
     */
    allowSelectWorkflowOwner?: boolean;

    /**
     * A banner rendered above the add custom fields form. Useful for adding additional context and
     * actions to the form.
     */
    customFieldsBanner?: React.ReactElement;

    /**
     * Whether field rows cannot be deleted from the form.
     * @default false
     */
    disallowDeleteFields?: boolean;

    /**
     * Whether to show a input form field for providing field value.
     * @default false
     */
    disallowEditFieldValue?: boolean;

    /**
     * The parent form. Must include a `customFields` key with an array value that conforms to the
     * structure in `AddCustomFieldFormSectionFields`.
     */
    form: UseFormReturn<RegrelloConfigureCustomFieldsForm.Fields>;

    spectrumConfigurationFormProps: {
      /**
       * The spectrum form configuration form. Must include a `customFields` key with an array value
       * that conforms to the structure in `AddCustomFieldFormSectionFields`.
       */
      spectrumFormManager: UseFormReturn<RegrelloConfigureSpectrumFormsFormFields>;

      /**
       * Whether forms cannot be added to the task.
       * @default false
       */
      disallowAddForms?: boolean;

      /**
       * The tooltip text to display if the add forms button is disabled.
       * @default FormAndFieldsCannotBeProvidedAtTheSameTime
       */
      disallowAddFormsTooltipText?: string;

      /**
       * Whether the form-selection is locked and cannot be changed. This functions similarly to
       * disable, but will display the lock-associated messages/icons.
       * @default false
       */
      isLocked?: boolean;

      /**
       * The existing spectrum spectrumFormManager that were added to an action-item template at creation time. If
       * provided, the component will prepopulate this data to related Form Fields.
       */
      initialSpectrumForms?: FormSelectFormVersionFields[];

      inputMappingFormProps?: RegrelloConfigureCustomFieldsInputMappingForm.Props;

      /**
       * Indicates whether spectrum form subsection is loading.
       */
      isSpectrumLoading?: boolean;

      /**
       * Invoked when loading of necessary data happens. On slow connections we should prevent form
       * submit until it's done.
       */
      onSpectrumLoadingChange?: (isLoading: boolean) => void;
    };

    /**
     * Title that is displayed above the 'Add fields' section and helper text.
     * @default "Fields"
     */
    formSectionTitle?: string;

    /**
     * The context of where the forms are being used.
     */
    formContext?: RegrelloFormFieldSpectrumFormSelectProps["context"];

    /** Callback invoked to determine which inputs to disable for a given field instance. */
    getDisabledInputsByFieldInstance?: (
      fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    ) => InputKeys[];

    /**
     * Determines which values, by ID, are corrupted or otherwise have entered an invalid state
     * (and require intervention from the user to resolve).
     */
    getCorruptedValuesByFieldInstance?: (
      fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    ) => number[];

    /**
     * The helper text that is displayed above the 'Add field' section.
     */
    helperTextForFormSection?: string;

    /**
     * Helper text to display in the "field-value" input when they are disabled because "Requested" is
     * selected.
     */
    helperTextForValuePlaceholder?: string;

    /**
     * The existing custom fields that were added to an action-item template at creation time. If
     * provided, the component will prepopulate this data to related Form Fields.
     */
    initialFieldInstances?: Array<FieldInstanceFields | FieldInstanceFieldsWithBaseValues>;

    /**
     * The initial fields to be used to populate this form. A form field will be rendered for each
     * provided field. Provide the initial values for this form via this prop if the user is
     * configuring field instances that haven't been created yet, but it's known which fields they
     * need to fill out.
     */
    initialFieldsWithMetadata?: FieldWithMetadata[];

    /**
     * Whether the "Add fields" button should not be displayed. Useful if you want the fields
     * section to be read-only and/or controlled by another component.
     */
    isAddFieldsButtonHidden?: boolean;

    /**
     * Whether the "Add spectrumFormManager" button should not be displayed. Useful if you want the spectrumFormManager
     * section to be read-only and/or controlled by another component.
     */
    isAddFormButtonHidden?: boolean;

    /**
     * Whether to use state only, without rendering the fields block.
     */
    isCustomFieldSectionHidden?: boolean;

    /**
     * Whether the entire form is disabled.
     * @default false
     */
    isDisabled?: boolean;

    /**
     * Whether drag to reorder is enabled.
     */
    isDragEnabled?: boolean;

    /**
     * Whether editing preexisting fields (i.e., existed on the object's template before
     * materialization) should be disabled from editing.
     * @default false
     */
    isEditingPreexistingFieldsDisabled?: boolean;

    /**
     * Whether the form is being rendered solely to edit the values of the provided custom fields.
     * Affects styling and which controls are rendered or enabled.
     * @default false
     */
    isOnlyEditingValues?: boolean;

    /**
     * Whether the custom fields form is being rendered as a standalone form section (i.e., no
     * native fields). Affects certain styles and rendered elements to make the form section appear
     * nicer.
     *
     * @default false
     */
    isStandaloneFormSection?: boolean;

    nameTemplateFields?: FieldFields[];

    onRowAdd?: () => void;

    /**
     * Callback invoked when a field value is finished changing (e.g., if it had to wait for an async
     * operation to complete).
     */
    onValueChangeFinish: (customFieldInstanceId: number) => void;

    /**
     * Callback invoked when a field value starts changing (e.g., if it has to wait for an async
     * operation to complete).
     */
    onValueChangeStart: (customFieldInstanceId: number) => void;

    roleFields?: FieldFields[];

    /**
     * Field instances that are "selected and passed" from previous stages or action items in the
     * current workflow. Fields that appear in this list will be ommitted from the "Field" selector in
     * this form section.
     */
    selectedInheritableFieldInstances?: FieldInstanceFields[];
  }

  export const Component = React.memo(
    RegrelloConfigureCustomFieldsFormSectionInternal as typeof RegrelloConfigureCustomFieldsFormSectionInternal,
  );
}

// (dosipiuk): false-positive, cause actual component is exported from within `namespace`?
// eslint-disable-next-line react-refresh/only-export-components
function RegrelloConfigureCustomFieldsFormSectionInternal({
  addFieldButtonDisabledTooltipText,
  allowCreateUsers = false,
  allowEmptyValues = false,
  allowSelectWorkflowOwner,
  defaultInputType = DEFAULT_INPUT_TYPE,
  disallowEditFieldValue = false,
  disallowDeleteFields = false,
  disallowSelectInputType = false,
  form,
  getDisabledInputsByFieldInstance,
  isDragEnabled = false,
  isCustomFieldSectionHidden = false,
  formSectionTitle = t`Request information`,
  formContext,
  getCorruptedValuesByFieldInstance,
  helperTextForFormSection,
  roleFields,
  onRowAdd,
  initialFieldInstances = EMPTY_ARRAY,
  initialFieldsWithMetadata = EMPTY_ARRAY,
  isAddFieldsButtonHidden = false,
  isAddFormButtonHidden = false,
  isDisabled = false,
  isEditingPreexistingFieldsDisabled = false,
  isOnlyEditingValues = false,
  isStandaloneFormSection = false,
  nameTemplateFields = EMPTY_ARRAY,
  onValueChangeFinish,
  onValueChangeStart,
  selectedInheritableFieldInstances = EMPTY_ARRAY,
  spectrumConfigurationFormProps,
  customFieldsBanner,
}: RegrelloConfigureCustomFieldsForm.Props) {
  const { currentUser } = useUser();

  const {
    spectrumFormManager,
    disallowAddForms,
    disallowAddFormsTooltipText,
    inputMappingFormProps,
    isLocked: formSelectionIsLocked = false,
  } = spectrumConfigurationFormProps;
  const initialSpectrumForms = spectrumConfigurationFormProps.initialSpectrumForms ?? EMPTY_ARRAY;

  const projectionDialog = useSimpleDialog();
  const [selectedField, setSelectedField] = useState<FieldInstanceFields>();

  const {
    fields: rows,
    append: appendRow,
    remove: removeRow,
    update: updateRow,
    move: moveRow,
  } = useFieldArray({ control: form.control, name: "customFields" });

  form.watch();

  const initialFieldInstancesToShowInForm = useMemo(
    () =>
      initialSpectrumForms.length > 0
        ? EMPTY_ARRAY
        : retainFieldInstancesByInputType(initialFieldInstances, [
            FieldInstanceValueInputType.PROVIDED,
            FieldInstanceValueInputType.REQUESTED,
            FieldInstanceValueInputType.OPTIONAL,
          ]),
    [initialFieldInstances, initialSpectrumForms.length],
  );

  // Omit fields that already appear elsewhere in this form. The backend will otherwise
  // throw if we add multiple instances of the same field to one action item.
  const fieldsToHideFromFieldSelector: FieldFields[] = useMemo(
    () => [
      ...(selectedInheritableFieldInstances?.map(
        (fieldInstance) => fieldInstance.spectrumFieldVersion?.field ?? fieldInstance.field,
      ) ?? EMPTY_ARRAY),
      ...rows.map((row) => getFieldFromVersionOrFallbackToField(row.field, row.spectrumField)).filter(isDefined),
    ],
    [selectedInheritableFieldInstances, rows],
  );

  const spectrumFieldsToHideFromFieldSelector: SpectrumFieldVersionFields[] = useMemo(
    () => [
      ...(selectedInheritableFieldInstances
        ?.map((fieldInstance) => fieldInstance.spectrumFieldVersion)
        .filter(isDefined) ?? EMPTY_ARRAY),
      ...rows.map((row) => row.spectrumField).filter(isDefined),
    ],
    [rows, selectedInheritableFieldInstances],
  );

  const shouldScrollToAddFieldButton = useRef(false);

  const rootElementRef = useRef<HTMLDivElement | null>(null);
  const addButtonRef = useRef<HTMLButtonElement | null>(null);

  const customFieldRefs = useRef<Record<string, HTMLButtonElement | null>>({});
  const createCustomFieldRefHandler = useCallback((rowId: number) => {
    return (element: HTMLButtonElement | null) => {
      if (element != null && customFieldRefs.current != null) {
        customFieldRefs.current[rowId] = element;
      }
    };
  }, []);

  const handleAddRowClick = useCallback(() => {
    appendRow(
      {
        field: null,
        inputType: defaultInputType,
        isEditDisabled: false,
        values: undefined,
        spectrumField: null,
        isMultiValued: false,
        fieldInstanceId: 0,
      },
      {
        // (clewis): I'm not sure why, but focus is throwing errors on append without this.
        shouldFocus: false,
      },
    );
    onRowAdd?.();
    shouldScrollToAddFieldButton.current = true;
    queueMacrotask(() => {
      // (wsheehan): Auto-open the select component when adding a new field.
      customFieldRefs.current[rows.length]?.click();
    });
  }, [appendRow, defaultInputType, onRowAdd, rows]);

  const handleAddRowFromField = useCallback(
    (field: FieldFields) => {
      appendRow(
        {
          field: field,
          inputType: defaultInputType,
          isEditDisabled: field.deletedAt != null,
          isMultiValued: field.isMultiValued ?? false,
          values: undefined,
          spectrumField: null,
          fieldInstanceId: 0,
        },
        {
          shouldFocus: false,
        },
      );
    },
    [appendRow, defaultInputType],
  );

  const scrollToAddFieldButton = useCallback(() => {
    const rootElement = rootElementRef.current;
    const addButton = addButtonRef.current;
    if (rootElement == null || addButton == null) {
      return;
    }
    const scrollParent = getScrollParentElement(rootElementRef.current);
    if (scrollParent == null) {
      return;
    }

    // (wsheehan): Don't auto-scroll if the button is already in view.
    if (isElementInViewport(addButton, scrollParent)) {
      return;
    }

    // (clewis): Auto-scroll to keep the 'Add' button in view with a bit of padding beneath it.
    const rootElementBottom = rootElement.offsetTop + rootElement.clientHeight;
    const rootElementFakeBottomPadding = 20;
    scrollParent.scrollTop =
      rootElementBottom +
      rootElementFakeBottomPadding -
      scrollParent.clientHeight +
      Number.parseFloat(window.getComputedStyle(scrollParent).paddingTop);

    // (clewis): Apparently we need to flush the event queue so that the element will be defined
    // before we focus it.
    queueMacrotask(() => {
      form.setFocus(`customFields.${rows.length - 1}.field`);
      shouldScrollToAddFieldButton.current = false;
    });
  }, [form, rows.length]);

  const handleCustomFieldSelectClose = useCallback(() => {
    if (shouldScrollToAddFieldButton.current) {
      scrollToAddFieldButton();
    }
  }, [scrollToAddFieldButton]);

  const deleteFieldInstanceAsync = useCallback(
    async (index: number | undefined) => {
      if (index != null) {
        removeRow(index);
      }
      return true;
    },
    [removeRow],
  );

  const changeToOptionalInputTypeAsync = useCallback(
    async (index: number | undefined) => {
      if (index != null) {
        const row = rows[index];
        const customField = getFieldFromVersionOrFallbackToField(row.field, row.spectrumField);

        if (customField != null) {
          // (hchen): Use `form.getValues()` instead of `row.value` because `row.value` is stale in
          // some cases. This line also must execute before unregister, otherwise the value is lost.
          const latestValue = form.getValues().customFields[index].values;

          // Clear all validation rules on the previous value input. Without this, stale validation
          // rules can keep the form from submitting even though the corresponding input no longer
          // exists in the form.
          // (hchen): However, doing this for multivalued fields such as multiselect
          // and party will cause the form to crash because when being unregistered, the field value
          // become `undefined` for short period, yet the component still attmpts to access
          // `value.length`
          if (CustomFieldPluginRegistrar.getPluginForField(customField)?.isMultiValued?.() !== true) {
            form.unregister(`customFields.${index}.values`);
          }

          updateRow(index, {
            ...row,
            inputType: FieldInstanceValueInputType.OPTIONAL,
            values: latestValue,
          });

          // Re-register this row's value input to potentially adjust how we validate it.
          form.register(
            `customFields.${index}.values`,
            !allowEmptyValues ? ValidationRules.REQUIRED : ValidationRules.NOT_REQUIRED,
          );
        }
      }
      return true;
    },
    [allowEmptyValues, form, rows, updateRow],
  );

  const deleteConfirmationDialog = useConfirmationDialog({ submitAsync: deleteFieldInstanceAsync });
  const changeToOptionalInputTypeConfirmationDialog = useConfirmationDialog({
    submitAsync: changeToOptionalInputTypeAsync,
  });

  const handleDeleteRowClick = useCallback(
    (index: number) => {
      const row = rows[index];
      if (row == null) {
        consoleWarnInDevelopmentModeOnly(
          "Cannot delete custom field: target row index is out of bounds",
          `(index=${index}, length=${rows.length})`,
        );
        return;
      }

      const initialFieldInstanceToDelete = initialFieldInstances.find(
        ({ field: { id } }) => id === (row.spectrumField?.field?.id ?? row.field?.id),
      );
      if (initialFieldInstanceToDelete == null) {
        // If we're deleting a custom field that we added during the lifetime of this form, it won't
        // been saved to the backend yet and can thus be deleted without confirmation.
        removeRow(index);
      } else {
        // Else, we know the field instance already existed on this entity (action item, workflow,
        // template, etc.), so we confirm before deleting.
        deleteConfirmationDialog.open(index);
      }
    },
    [deleteConfirmationDialog, initialFieldInstances, removeRow, rows],
  );

  const handleFieldChange = useCallback(
    (
      row: RegrelloConfigureCustomFieldsForm.Fields["customFields"][number],
      index: number,
      newValue: FieldFields | null,
      newSpectrumValue: SpectrumFieldVersionFields | null,
    ) => {
      if (newValue == null) {
        return;
      }

      // Clear all validation rules on the previous value input. Without this, stale validation
      // rules can run on value inputs for which those rules don't make sense, which causes all
      // kinds of chaos at runtime.
      form.unregister(`customFields.${index}.values`);

      const initialField = initialFieldInstances.find(
        (initialInstance) =>
          (initialInstance.spectrumFieldVersion?.field?.id ?? initialInstance.field.id) === newValue.id,
      );

      // Reset the contents of the 'value' input when the field changes, because the old field's value type
      // may be incompatible with the new field's value type.
      updateRow(index, {
        ...row,
        field: newValue,
        spectrumField: newSpectrumValue,
        values: CustomFieldPluginRegistrar.getPluginForField(newValue).getEmptyValueForFrontend(),
        isMultiValued: initialField?.isMultiValued ?? false,
        projection: initialField?.projection || undefined,
      });

      // Re-register this row's value input to potentially adjust how we validate it.
      form.register(
        `customFields.${index}.values`,
        !allowEmptyValues && row.inputType === FieldInstanceValueInputType.PROVIDED
          ? isFieldFormFieldRequired(newValue)
            ? ValidationRules.REQUIRED
            : ValidationRules.NOT_REQUIRED
          : ValidationRules.NOT_REQUIRED,
      );
    },
    [allowEmptyValues, initialFieldInstances, form, updateRow],
  );

  const handleInputTypeChange = useCallback(
    (
      row: RegrelloConfigureCustomFieldsForm.Fields["customFields"][number],
      index: number,
      newValue: FieldInstanceValueInputType | null,
    ) => {
      if (newValue == null) {
        consoleWarnInDevelopmentModeOnly("Unexpected empty inputType selection");
        return;
      }

      const customField = getFieldFromVersionOrFallbackToField(row.field, row.spectrumField);

      // (zstanik): If the field is in use in the naming convention, show a confirmation dialog
      // before changing the input type to `OPTIONAL`.
      if (
        newValue === FieldInstanceValueInputType.OPTIONAL &&
        nameTemplateFields.find((field) => field.id === customField?.id)
      ) {
        changeToOptionalInputTypeConfirmationDialog.open(index);
        return;
      }

      if (customField == null || disallowEditFieldValue) {
        updateRow(index, {
          ...row,
          inputType: newValue,
        });
        return;
      }

      // (hchen): Use `form.getValues()` instead of `row.value` because `row.value` is stale in
      // some cases. This line also must execute before unregister, otherwise the value is lost.
      const latestValue = form.getValues().customFields[index].values;

      // Clear all validation rules on the previous value input. Without this, stale validation
      // rules can keep the form from submitting even though the corresponding input no longer
      // exists in the form.
      // (hchen): However, doing this for multivalued fields such as multiselect
      // and party will cause the form to crash because when being unregistered, the field value
      // become `undefined` for short period, yet the component still attmpts to access
      // `value.length`
      if (CustomFieldPluginRegistrar.getPluginForField(customField)?.isMultiValued?.() !== true) {
        form.unregister(`customFields.${index}.values`);
      }

      updateRow(index, {
        ...row,
        inputType: newValue,
        values: latestValue,
      });

      // Re-register this row's value input to potentially adjust how we validate it.
      form.register(
        `customFields.${index}.values`,
        !allowEmptyValues && newValue !== FieldInstanceValueInputType.OPTIONAL
          ? ValidationRules.REQUIRED
          : ValidationRules.NOT_REQUIRED,
      );
    },
    [
      allowEmptyValues,
      changeToOptionalInputTypeConfirmationDialog,
      disallowEditFieldValue,
      form,
      nameTemplateFields,
      updateRow,
    ],
  );

  const onProjectionChange = useCallback(
    (isMultiValued: boolean, projection: number[]) => {
      const fieldIndex = rows.findIndex(
        (row) => (row.spectrumField?.field?.id ?? row.field?.id) === selectedField?.field.id,
      );
      if (fieldIndex >= 0) {
        const previousRow = rows[fieldIndex];
        const newValues =
          // If
          // - isMultiValued did not change
          // - we changed from single to multi select
          // - we had only one value selected
          // keep selected values, otherwise reset cause we do not know which value to keep
          previousRow.isMultiValued === isMultiValued ||
          isMultiValued ||
          (previousRow.values != null && previousRow.values.length === 1)
            ? previousRow.values
            : [];

        updateRow(fieldIndex, {
          ...previousRow,
          isMultiValued,
          projection: { selectedRegrelloObjectPropertyIds: projection },
          values: newValues,
        });
      }

      setSelectedField(undefined);
    },
    [rows, selectedField?.field.id, updateRow],
  );

  useMount(() => {
    // Prepopulate rows for all existing custom fields:
    form.reset(
      initialFieldsWithMetadata.length > 0
        ? RegrelloConfigureCustomFieldsForm.getDefaultValuesFromFieldsAndMetadata(
            initialFieldsWithMetadata,
            FieldInstanceValueInputType.REQUESTED,
          )
        : RegrelloConfigureCustomFieldsForm.getDefaultValues(initialFieldInstances),
    );

    const initialFieldIdSet = new Set(
      retainFieldInstancesByInputType(initialFieldInstances, [
        FieldInstanceValueInputType.REQUESTED,
        FieldInstanceValueInputType.PROVIDED,
        FieldInstanceValueInputType.OPTIONAL,
      ]).map((fieldInstance) => fieldInstance.spectrumFieldVersion?.field?.id ?? fieldInstance.field.id),
    );
    nameTemplateFields.forEach((field) => {
      if (!initialFieldIdSet.has(field.id) && field.fieldType !== FieldType.SYSTEM) {
        handleAddRowFromField(field);
      }
    });
  });

  // (zstanik): keep track of the previous name template field IDs so that newly added fields (that
  // aren't already present) can be added to the rows.
  const previousNameTemplateFieldIdSet = usePrevious(new Set(nameTemplateFields.map((field) => field.id)));
  useEffect(() => {
    if (previousNameTemplateFieldIdSet != null) {
      const rowFieldIdSet = new Set(rows.map((row) => row.spectrumField?.field?.id ?? row.field?.id));
      nameTemplateFields
        .filter(
          (field) =>
            !previousNameTemplateFieldIdSet.has(field.id) &&
            !rowFieldIdSet.has(field.id) &&
            field.fieldType !== FieldType.SYSTEM,
        )
        .forEach((field) => {
          handleAddRowFromField(field);
        });
    }
  }, [handleAddRowFromField, nameTemplateFields, previousNameTemplateFieldIdSet, rows]);

  // (hchen): This is necessary because this component rerenders one more time after the dialog is
  // closed. So the effect above is executed after the outer component tries to reset the form,
  // resulting in lingering values. This ensures resetting is the last thing executed.
  useUnmount(() => removeRow());

  const isChildrenVisible = rows.length > 0 || !isAddFieldsButtonHidden || selectedField != null;

  const isTableHeaderVisible = rows.length > 0 && !(isOnlyEditingValues && disallowSelectInputType);

  const isPermissionsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01);

  const disallowAddFormsInternal = rows.length > 0 || disallowAddForms;

  const { spectrumForms, renderFormRows, renderAddFormButton, renderInputMappingForm } = useConfigureSpectrumForms({
    context: formContext,
    disallowAddForms: disallowAddFormsInternal,
    disallowAddFormsTooltipText,
    roleFields,
    initialSpectrumForms,
    isLocked: formSelectionIsLocked,
    spectrumFormManager,
    inputMappingFormProps,
    selectedInheritableFieldInstances,
    handleFormDelete: removeRow,
  });

  const children = useMemo(() => {
    return (
      <>
        {spectrumForms.length === 0 && (
          <>
            {customFieldsBanner}
            {isTableHeaderVisible && (
              <div className={clsx("flex mb-2", CSS_CLASS_COLUMN_GAP, { "ml-5": isDragEnabled })}>
                {!isOnlyEditingValues && (
                  <RegrelloTypography className="flex-1" variant="h7">
                    {t`Name`}
                  </RegrelloTypography>
                )}
                {!disallowSelectInputType && (
                  <RegrelloTypography
                    className={clsx(CSS_CLASS_WIDTH_IS_REQUIRED, "flex-none flex items-center")}
                    variant="h7"
                  >
                    {t`Required`}
                    <RegrelloTooltip
                      align="end"
                      content={t`Fields are set to required by default. Flip the toggle to make them optional.`}
                      side="top"
                    >
                      {/* (hchen): Passing in ref with forwardRef doesn't seem to work, thus this approach */}
                      <div>
                        <RegrelloIcon className="ml-1" iconName="help-outline" intent="neutral" size="x-small" />
                      </div>
                    </RegrelloTooltip>
                  </RegrelloTypography>
                )}
                <div className="w-9" />
              </div>
            )}

            {rows.map((row, index) => {
              const { fieldInstanceId, field, spectrumField, inputType, isCopy, isEditDisabled } = row;
              const currentField = getFieldFromVersionOrFallbackToField(field, spectrumField);

              // If the field instance was copied from another, that indicates that it was preexisting
              // on the object's template before materialization.
              const isPreexistingAndDisabled = isEditingPreexistingFieldsDisabled && (isCopy ?? false);

              const maybeInitialFieldInstance = initialFieldInstancesToShowInForm.find(
                (fieldInstance) => getFieldInstanceId(fieldInstance) === fieldInstanceId,
              );
              const disabledInputs =
                maybeInitialFieldInstance != null && getDisabledInputsByFieldInstance != null
                  ? getDisabledInputsByFieldInstance(maybeInitialFieldInstance)
                  : EMPTY_ARRAY;

              const isFieldInNameTemplate =
                nameTemplateFields.find((nameTemplateField) => nameTemplateField.id === currentField?.id) != null;

              const isSyncedObjectField = field?.regrelloObject != null;

              const deleteButton = (
                <RegrelloTooltip
                  content={
                    isPreexistingAndDisabled
                      ? t`This field cannot be edited`
                      : isFieldInNameTemplate
                        ? t`This field is in use in the blueprint's naming convention and cannot be deleted`
                        : t`Remove field`
                  }
                  side="right"
                >
                  <div>
                    <RegrelloButton
                      aria-label="Remove this field"
                      dataTestId={DataTestIds.CUSTOM_FIELD_DELETE_FROM_ACTION_ITEM_BUTTON}
                      disabled={
                        isDisabled ||
                        isFieldInNameTemplate ||
                        isPreexistingAndDisabled ||
                        disabledInputs.includes(RegrelloConfigureCustomFieldsForm.InputKeys.DELETE_BUTTON)
                      }
                      iconOnly={true}
                      onClick={() => handleDeleteRowClick(index)}
                      startIcon={isPreexistingAndDisabled ? "locked" : "delete"}
                      variant="ghost"
                    />
                  </div>
                </RegrelloTooltip>
              );

              const fieldInstanceFormFieldCss = clsx("flex-none w-68.75", {
                "w-57.75": isSyncedObjectField,
              });

              const fieldInstanceFormField = disallowEditFieldValue ? null : currentField == null ? (
                <RegrelloFormFieldText
                  className={fieldInstanceFormFieldCss}
                  disabled={true}
                  isDefaultMarginsOmitted={true}
                  value={EMPTY_STRING}
                />
              ) : spectrumField != null &&
                SpectrumFieldPluginRegistrar.getPluginForSpectrumField(spectrumField).renderSpectrumFormField !=
                  null ? (
                SpectrumFieldPluginRegistrar.getPluginForSpectrumField(spectrumField).renderSpectrumFormField?.(
                  currentField,
                  {
                    allowCreate: allowCreateUsers,
                    className: fieldInstanceFormFieldCss,
                    corruptDocumentIds:
                      maybeInitialFieldInstance != null
                        ? getCorruptedValuesByFieldInstance?.(maybeInitialFieldInstance)
                        : undefined,
                    helperText: spectrumField.helperText,
                    // Disallow editing if the field instance is coming from a parent workflow.
                    disabled:
                      isDisabled ||
                      isEditDisabled ||
                      disabledInputs.includes(RegrelloConfigureCustomFieldsForm.InputKeys.CUSTOM_FIELD_FORM_FIELD),
                    controllerProps: {
                      control: form.control,
                      defaultValue: row.values,
                      name: `customFields.${index}.values`,
                      // (zstanik): Although validation rules are applied by `form.register` above,
                      // still need to include them here for the case in which default fields are
                      // present and the user just edits their values. Otherwise, no validation was
                      // being performed on such field inputs because the handle change callbacks
                      // above were never called.
                      rules: getAllValidationRulesFromFieldConstraints(
                        !allowEmptyValues &&
                          inputType !== FieldInstanceValueInputType.OPTIONAL &&
                          isFieldFormFieldRequired(currentField),
                        spectrumField.fieldConstraints,
                      ),
                    },
                    isDefaultMarginsOmitted: true,
                    onChangeFinish: () => onValueChangeFinish(currentField.id),
                    onChangeStart: () => onValueChangeStart(currentField.id),
                    // (dosipiuk): This cast is necessary as form uses trimmed down version of the type
                    fieldInstance: row as unknown as FieldInstanceFields,
                  },
                )
              ) : (
                // Render an input (which may be disabled):
                CustomFieldPluginRegistrar.getPluginForField(currentField).renderFormField(
                  currentField,
                  {
                    allowCreate: allowCreateUsers,
                    className: fieldInstanceFormFieldCss,
                    corruptDocumentIds:
                      maybeInitialFieldInstance != null
                        ? getCorruptedValuesByFieldInstance?.(maybeInitialFieldInstance)
                        : undefined,
                    // Disallow editing if the field instance is coming from a parent workflow.
                    disabled:
                      isDisabled ||
                      isEditDisabled ||
                      disabledInputs.includes(RegrelloConfigureCustomFieldsForm.InputKeys.CUSTOM_FIELD_FORM_FIELD),
                    helperText: spectrumField?.helperText,
                    controllerProps: {
                      control: form.control,
                      defaultValue: row.values,
                      name: `customFields.${index}.values`,
                      // (zstanik): Although validation rules are applied by `form.register` above,
                      // still need to include them here for the case in which default fields are
                      // present and the user just edits their values. Otherwise, no validation was
                      // being performed on such field inputs because the handle change callbacks
                      // above were never called.
                      rules: getAllValidationRulesFromFieldConstraints(
                        !allowEmptyValues &&
                          inputType !== FieldInstanceValueInputType.OPTIONAL &&
                          isFieldFormFieldRequired(currentField),
                        spectrumField?.fieldConstraints || [],
                      ),
                    },
                    isDefaultMarginsOmitted: true,
                    onChangeFinish: () => onValueChangeFinish(currentField.id),
                    onChangeStart: () => onValueChangeStart(currentField.id),
                    // (dosipiuk): This cast is necessary as form uses trimmed down version of the type
                    fieldInstance: row as unknown as FieldInstanceFields,
                  },
                  { context: "RegrelloConfigureCustomFieldsFormSection" },
                )
              );

              const customFieldInstanceSelectComponent =
                isOnlyEditingValues && currentField != null ? (
                  <RegrelloChip
                    className="grow-0 shrink-0 w-52.5 h-9 mr-4"
                    icon={{
                      type: "iconName",
                      iconName: CustomFieldPluginRegistrar.getPluginForField(currentField).getIconName(
                        currentField.fieldType,
                        currentField,
                      ),
                    }}
                    isVisibleChipFullSize={true}
                  >
                    {currentField.name}
                  </RegrelloChip>
                ) : spectrumField != null || field == null ? (
                  <RegrelloControlledFormFieldSpectrumFieldSelect
                    allowCreateFields={Permissions.Create.canCreateCustomFields(currentUser)}
                    allowCreateRoles={Permissions.Create.canCreateRoles(currentUser)}
                    allowSelectWorkflowOwner={allowSelectWorkflowOwner}
                    className="flex-1"
                    controllerProps={{ control: form.control, name: `customFields.${index}.spectrumField` }}
                    dataTestId={DataTestIds.CUSTOM_FIELD_NAME_SELECT}
                    // Disallow editing if the field instance is coming from a parent workflow, is in use
                    // in the naming convention, or is preexisting on a locked task.
                    disabled={
                      isDisabled ||
                      isEditDisabled ||
                      isFieldInNameTemplate ||
                      isPreexistingAndDisabled ||
                      disabledInputs.includes(RegrelloConfigureCustomFieldsForm.InputKeys.CUSTOM_FIELD_SELECT)
                    }
                    isDefaultMarginsOmitted={true}
                    omittedOptions={spectrumFieldsToHideFromFieldSelector}
                    onClose={handleCustomFieldSelectClose}
                    onValueChange={(_formFieldName, newValue) =>
                      handleFieldChange(row, index, newValue?.field || null, newValue || null)
                    }
                    placeholder={t`Select field`}
                    selectRef={createCustomFieldRefHandler(index)}
                  />
                ) : (
                  <RegrelloControlledFormFieldCustomFieldSelectV2
                    allowCreateFields={Permissions.Create.canCreateCustomFields(currentUser)}
                    allowCreateRoles={Permissions.Create.canCreateRoles(currentUser)}
                    allowSelectWorkflowOwner={allowSelectWorkflowOwner}
                    className="flex-1 min-w-0"
                    controllerProps={{ control: form.control, name: `customFields.${index}.field` }}
                    dataTestId={DataTestIds.CUSTOM_FIELD_NAME_SELECT}
                    // Disallow editing if the field instance is coming from a parent workflow, is in use
                    // in the naming convention, or is preexisting on a locked task.
                    disabled={
                      isDisabled ||
                      isEditDisabled ||
                      isFieldInNameTemplate ||
                      isPreexistingAndDisabled ||
                      disabledInputs.includes(RegrelloConfigureCustomFieldsForm.InputKeys.CUSTOM_FIELD_SELECT)
                    }
                    isDefaultMarginsOmitted={true}
                    omittedOptions={fieldsToHideFromFieldSelector}
                    onClose={handleCustomFieldSelectClose}
                    onValueChange={(_formFieldName, newValue) => handleFieldChange(row, index, newValue, null)}
                    placeholder={t`Select field`}
                    selectRef={createCustomFieldRefHandler(index)}
                  />
                );

              const editRegrelloObjectButton =
                isOnlyEditingValues || isPreexistingAndDisabled ? null : (
                  <RegrelloButton
                    key="edit"
                    dataTestId={DataTestIds.SYNCED_OBJECTS_PROJECTION_BUTTON}
                    // Disallow editing if the field instance is coming from a parent workflow.
                    disabled={
                      isDisabled ||
                      disabledInputs.includes(RegrelloConfigureCustomFieldsForm.InputKeys.EDIT_REGRELLO_OBJECT_BUTTON)
                    }
                    iconOnly={true}
                    onClick={() => {
                      // (dosipiuk): This cast is necessary as form uses trimmed down version of the type
                      setSelectedField(row as unknown as FieldInstanceFields);
                      projectionDialog.open();
                    }}
                    startIcon="edit-outline"
                    variant="ghost"
                  />
                );

              return (
                <FieldInstanceRowItem
                  key={index}
                  customFieldInstanceSelectComponent={customFieldInstanceSelectComponent}
                  deleteButton={deleteButton}
                  disallowDeleteFields={disallowDeleteFields}
                  disallowEditFieldValue={disallowEditFieldValue}
                  disallowSelectInputType={disallowSelectInputType}
                  editRegrelloObjectButton={editRegrelloObjectButton}
                  fieldInstanceFormField={fieldInstanceFormField}
                  handleInputTypeChange={handleInputTypeChange}
                  index={index}
                  isDragEnabled={isDragEnabled}
                  isInputTypeSwitchDisabled={
                    isDisabled ||
                    isPreexistingAndDisabled ||
                    disabledInputs.includes(RegrelloConfigureCustomFieldsForm.InputKeys.INPUT_TYPE_SWITCH)
                  }
                  moveRow={moveRow}
                  row={row}
                />
              );
            })}
          </>
        )}

        {!isAddFormButtonHidden && renderFormRows()}
        {renderInputMappingForm()}

        <div className="flex pt-1">
          {!isAddFieldsButtonHidden && !isOnlyEditingValues && (
            <RegrelloTooltip
              content={
                addFieldButtonDisabledTooltipText != null
                  ? addFieldButtonDisabledTooltipText
                  : spectrumForms.length > 0
                    ? t`Form and fields cannot be provided at the same time.`
                    : undefined
              }
            >
              {/* (hchen): This <span> is required for the tooltip to work with a disabled button. */}
              <span>
                <RegrelloButton
                  ref={addButtonRef}
                  className={clsx({ "ml-5": isDragEnabled && rows.length > 0 })}
                  dataTestId={DataTestIds.ADD_CUSTOM_FIELD_BUTTON}
                  disabled={isDisabled || spectrumForms.length > 0 || addFieldButtonDisabledTooltipText != null}
                  intent="primary"
                  onClick={handleAddRowClick}
                  startIcon="add"
                  variant="ghost"
                >
                  {isPermissionsV2Enabled ? t`Add field or role` : t`Add fields`}
                </RegrelloButton>
              </span>
            </RegrelloTooltip>
          )}
          {!isAddFormButtonHidden && spectrumForms.length <= 0 && renderAddFormButton()}
        </div>

        {selectedField != null ? (
          <RegrelloObjectProjectionSettings
            key={selectedField.field.id}
            fieldInstance={selectedField}
            isOpen={projectionDialog.isOpen}
            onClose={projectionDialog.close}
            onSubmit={onProjectionChange}
            showMultiValuedSelector={true}
          />
        ) : null}
      </>
    );
  }, [
    spectrumForms.length,
    customFieldsBanner,
    isTableHeaderVisible,
    isDragEnabled,
    isOnlyEditingValues,
    disallowSelectInputType,
    rows,
    isAddFormButtonHidden,
    renderFormRows,
    renderInputMappingForm,
    isAddFieldsButtonHidden,
    addFieldButtonDisabledTooltipText,
    isDisabled,
    handleAddRowClick,
    isPermissionsV2Enabled,
    renderAddFormButton,
    selectedField,
    projectionDialog,
    onProjectionChange,
    isEditingPreexistingFieldsDisabled,
    initialFieldInstancesToShowInForm,
    getDisabledInputsByFieldInstance,
    nameTemplateFields,
    disallowEditFieldValue,
    allowCreateUsers,
    getCorruptedValuesByFieldInstance,
    form.control,
    allowEmptyValues,
    currentUser,
    allowSelectWorkflowOwner,
    spectrumFieldsToHideFromFieldSelector,
    handleCustomFieldSelectClose,
    createCustomFieldRefHandler,
    fieldsToHideFromFieldSelector,
    disallowDeleteFields,
    handleInputTypeChange,
    moveRow,
    handleDeleteRowClick,
    onValueChangeFinish,
    onValueChangeStart,
    handleFieldChange,
  ]);

  if (isCustomFieldSectionHidden) {
    return null;
  }

  return (
    <div ref={rootElementRef}>
      {!isChildrenVisible ? undefined : isStandaloneFormSection ? (
        <div>{children}</div>
      ) : (
        <RegrelloFormSection description={helperTextForFormSection} title={formSectionTitle}>
          {children}
        </RegrelloFormSection>
      )}

      <RegrelloConfirmationDialog
        confirmIntent="danger"
        confirmText={t`Delete`}
        content={t`Deleting this field will also delete all fields that inherit from it and any conditions using it to start a stage. Do you want to proceed?`}
        isOpen={deleteConfirmationDialog.isOpen}
        onClose={deleteConfirmationDialog.close}
        onConfirm={deleteConfirmationDialog.confirm}
        title={t`Delete the field?`}
      />
      <RegrelloConfirmationDialog
        confirmIntent="primary"
        confirmText={t`Confirm`}
        content={t`This field is in use in the blueprint's naming convention. Changing its type to optional may result in an empty value for that field in the autogenerated workflow name. Do you want to proceed?`}
        isOpen={changeToOptionalInputTypeConfirmationDialog.isOpen}
        onClose={changeToOptionalInputTypeConfirmationDialog.close}
        onConfirm={changeToOptionalInputTypeConfirmationDialog.confirm}
        title={t`Change the field type to optional?`}
      />
    </div>
  );
}

export function getFieldFromVersionOrFallbackToField(
  field: FieldFields | null,
  spectrumField: SpectrumFieldVersionFields | null,
) {
  return spectrumField?.field ?? field;
}
