// (clewis): Heavily inspired by Blueprint's TagInput
// (https://blueprintjs.com/docs/#core/components/tag-input)

import { Slot } from "@radix-ui/react-slot";
import { assertNever, clsx, KeyNames, mergeRefs, useSimpleFocus, type WithClassName } from "@regrello/core-utils";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { RegrelloTooltip } from "../..";
import type { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import type { RegrelloSize } from "../../utils/enums/RegrelloSize";
import { RegrelloButton } from "../button/RegrelloButton";
import { RegrelloChip, type RegrelloChipProps } from "../chip/RegrelloChip";
import { RegrelloIcon, type RegrelloIconName } from "../icons/RegrelloIcon";
import { getInputV2ButtonSizeForInputSize, getInputV2IconSizeForInputSize } from "../input/inputV2Utils";

export interface RegrelloChipInputProps<T> extends WithClassName {
  /**
   * Whether to invoke `onAdd` when the input loses focus. If `false`, `onAdd` will be invoked only
   * when `Enter` is pressed.
   *
   * @default false
   */
  addOnBlur?: boolean;

  /**
   * Whether to invoke `onAdd` immediately after pasting text into the input. Values will be split
   * by `separator`. By default, `onAdd` will not be invoked on paste, and the pasted text will
   * simply remain in the input.
   *
   * @default false
   */
  addOnPaste?: boolean;

  /**
   * If `true`, the internal input props that would have been passed to an internal `<input>` will
   * instead be passed to the component passed via `children`. (Any `children` are otherwise ignored.)
   *
   * This is useful when you need to render a custom inputs provided by another library.
   *
   * @warning This is not yet tested and may not work yet.
   *
   * @default false
   */
  asChild?: boolean;

  /**
   * A custom input to render instead of the default one. Must provide `asChild={true}`, otherwise
   * this will be ignored.
   */
  children?: React.ReactElement;

  /** Props to pass to each `RegrelloChip`. */
  chipProps?: (
    value: T,
    index: number,
  ) => RegrelloChipProps & {
    /**
     * If provided, shows a tooltip with this content when this chip is hovered.
     *
     * ___Warning:___ This is a bit of a hack and should be avoided in almost all cases.
     */
    tooltip?: React.ReactNode;
  };

  /** An array of event.key names that control when an input is registered as a tag.
   * @default [KeyNames.ENTER]
   */
  delimiters?: KeyNames[];

  /**
   * Whether the component is non-interactive.
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * An arbitrary element to show inside the component after the input (and after the clear button
   * if the clear button is shown).
   */
  endElement?: RegrelloIconName | React.ReactElement;

  /**
   * Whether the component should fill the width of its container.
   *
   * @default false
   */
  fullWidth?: boolean;

  /** Props to pass to the input. `ref` is not supported here; use `inputRef` instead. */
  inputProps?: React.HTMLProps<HTMLInputElement>;

  /** A ref to pass to the `<input>` element. */
  inputRef?: React.Ref<HTMLInputElement>;

  /**
   * A semantic intent color to apply to the component.
   *
   * @default "neutral"
   */
  intent?: Extract<RegrelloIntent, "neutral" | "warning" | "danger">;

  /**
   * Callback invoked when the user adds a new chip by pressing `Enter` in the input, or by
   * blurring, if `addOnBlur` is enabled, or by pasting multiple values at once (split by
   * `separator`).
   */
  onAdd?: (value: string[]) => void;

  /**
   * Callback invoked when the user clicks the X button on the input. This should remove all
   * non-disabled `values`.
   */
  onClear?: () => void;

  /**
   * Callback invoked when the user clicks the X button on a chip. Receives the value and the index
   * of the removed chip.
   */
  onRemove?: (value: T, index: number) => void;

  /**
   * Callback invoked to render the text for the specified chip. By default, `String(...)` will be
   * used.
   */
  renderChipText?: (value: T, index: number) => React.ReactNode;

  /** A ref to pass to the root element. */
  rootRef?: React.Ref<HTMLDivElement>;

  /**
   * A separator pattern to split input text into multiple values.
   *
   * @default /[,\n\r]+/g
   */
  separator?: string | RegExp;

  /**
   * The size of the input.
   * @default "large"
   */
  size?: RegrelloSize;

  /** An icon to show inside the component before the input. */
  startIcon?: RegrelloIconName;

  /**
   * Controlled values to show as chips in the component. These chips can be customized using
   * `chipProps`. Falsey values will not be rendered.
   */
  values: readonly T[];
}

const NO_INDEX_SELECTED = -1;

export function RegrelloChipInputInternal<T>({
  addOnBlur = false,
  addOnPaste = false,
  asChild = false,
  chipProps,
  className,
  delimiters = [KeyNames.ENTER],
  disabled: isDisabled = false,
  endElement,
  fullWidth: isFullWidth = false,
  inputProps,
  inputRef: propsInputRef,
  intent = "neutral",
  onAdd,
  onClear,
  onRemove,
  renderChipText,
  rootRef,
  separator = /[,\n\r]+/g,
  size = "large",
  startIcon,
  values,
}: RegrelloChipInputProps<T>) {
  const [selectedIndex, setSelectedIndex] = useState<number>(NO_INDEX_SELECTED);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { isFocused, onFocus, onBlur } = useSimpleFocus();

  const { disabledIndices, firstEnabledIndex, lastEnabledIndex } = useDisabledIndicesCache({ chipProps, values });

  const canAdd = onAdd != null;

  const addChips = useCallback(
    (value: string) => {
      const newValues = value.split(separator).filter((v) => v.length > 0);
      onAdd?.(newValues);

      // (clewis): Keep the input text in place if we don't have any custom onAdd logic.
      if (onAdd != null && inputRef.current != null) {
        inputRef.current.value = "";
      }
    },
    [onAdd, separator],
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onFocus();
      inputProps?.onFocus?.(event);
    },
    [inputProps, onFocus],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (addOnBlur && event.currentTarget.value.length > 0) {
        addChips(event.currentTarget.value);
      }

      onBlur();
      inputProps?.onBlur?.(event);
      setSelectedIndex(NO_INDEX_SELECTED);
    },
    [addChips, addOnBlur, inputProps, onBlur],
  );

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const key = event.key;

      const { value, selectionEnd } = event.currentTarget;

      // (clewis): Do you like nuanced microinteractions? Because this is where you get nuanced
      // microinteractions. I've defined variables and functions to make the logic easier to follow.

      const isCursorAtBeginningOfInput = selectionEnd === 0;
      const isCursorAtEndOfInput = selectionEnd === value.length;
      const isInputEmpty = value.length === 0;
      const isChipsPresent = values.length > 0;
      const isChipSelected = selectedIndex !== NO_INDEX_SELECTED;

      const deselect = () => {
        setSelectedIndex(NO_INDEX_SELECTED);
      };

      const selectFirstEnabled = () => {
        // Select the first non-disabled chip from the beginning of the list, rightward.
        for (let i = 0; i < values.length; i += 1) {
          if (!disabledIndices.has(i)) {
            setSelectedIndex(i);
            return;
          }
        }
        deselect();
      };

      const selectLastEnabled = () => {
        // Select the first non-disabled chip from the end of the list, leftward.
        for (let i = values.length - 1; i >= 0; i -= 1) {
          if (!disabledIndices.has(i)) {
            setSelectedIndex(i);
            return;
          }
        }
      };

      // Select the first non-disabled chip to the left of the current selection, or deselect if
      // there are no non-disabled chips to the left.
      const selectPreviousEnabled = (): boolean => {
        for (let i = selectedIndex - 1; i >= 0; i -= 1) {
          if (!disabledIndices.has(i)) {
            setSelectedIndex(i);
            return false;
          }
        }
        deselect();
        return true;
      };

      // Select the first non-disabled chip to the right of the current selection, or deselect if
      // there are no non-disabled chips to the right.
      const selectNextEnabled = (): boolean => {
        for (let i = selectedIndex + 1; i < values.length; i += 1) {
          if (!disabledIndices.has(i)) {
            setSelectedIndex(i);
            return false;
          }
        }
        deselect();
        return true;
      };

      const ignoreKeystrokeInInput = () => event.preventDefault();
      const forceMoveCursorToStartOfInput = () => event.currentTarget.setSelectionRange(0, 0);
      const forceMoveCursorToEndOfInput = () => event.currentTarget.setSelectionRange(value.length, value.length);

      if (delimiters.includes(key as KeyNames) && !isInputEmpty && canAdd) {
        addChips(value);
        // (dosipiuk): Prevent form submission when pressing ENTER which should be treated as delimiter
        event.preventDefault();
        return;
      }

      if (isChipsPresent) {
        if (key === KeyNames.BACKSPACE) {
          if (!isChipSelected) {
            if (isCursorAtBeginningOfInput) {
              selectLastEnabled();
            }
          } else {
            onRemove?.(values[selectedIndex], selectedIndex);
            if (selectedIndex > firstEnabledIndex) {
              selectPreviousEnabled();
            } else {
              deselect();
            }
          }
        } else if (key === KeyNames.DELETE) {
          if (isChipSelected) {
            onRemove?.(values[selectedIndex], selectedIndex);
            deselect();
            ignoreKeystrokeInInput();
          }
        } else if (key === KeyNames.ARROW_LEFT) {
          // Cycle leftward through the chips.
          if (isCursorAtBeginningOfInput && !isChipSelected) {
            selectLastEnabled();
          } else if (isChipSelected && selectedIndex > firstEnabledIndex) {
            const didLoop = selectPreviousEnabled();
            ignoreKeystrokeInInput();
            if (didLoop) {
              forceMoveCursorToEndOfInput();
            }
          } else if (selectedIndex === firstEnabledIndex) {
            deselect();
            ignoreKeystrokeInInput();
            forceMoveCursorToEndOfInput();
          }
        } else if (key === KeyNames.ARROW_RIGHT) {
          // Cycle rightward through the chips.
          if (isCursorAtEndOfInput && !isChipSelected) {
            selectFirstEnabled();
          } else if (isChipSelected && selectedIndex < lastEnabledIndex) {
            const didLoop = selectNextEnabled();
            ignoreKeystrokeInInput();
            if (didLoop) {
              forceMoveCursorToStartOfInput();
            }
          } else if (selectedIndex === lastEnabledIndex) {
            deselect();
            ignoreKeystrokeInInput();
            forceMoveCursorToStartOfInput();
          }
        } else if (isChipSelected) {
          // De-select any selected chip when the user starts typing normally in the input.
          deselect();
        }
      }

      inputProps?.onKeyDown?.(event);
    },
    [
      addChips,
      canAdd,
      delimiters,
      disabledIndices,
      firstEnabledIndex,
      inputProps,
      lastEnabledIndex,
      onRemove,
      selectedIndex,
      values,
    ],
  );

  const handleInputPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const value = event.clipboardData.getData("text");
      if (addOnPaste) {
        addChips(value);

        // (clewis): Prevent pasting the text into the input, since we're adding chips instead.
        event.preventDefault();
      }

      inputProps?.onPaste?.(event);
    },
    [addChips, addOnPaste, inputProps],
  );

  const handleClear = useCallback(() => {
    onClear?.();
    inputRef.current?.focus();
  }, [onClear]);

  const handleOnDeleteInternal = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: T, index: number) => {
      onRemove?.(value, index);

      // We must `preventDefault` because deleting the non-last chip will cause form submits when
      // the delete button is clicked wherever this input is used.
      event.preventDefault();
    },
    [onRemove],
  );

  const maybeClearButton =
    values.length > 0 && disabledIndices.size < values.length && !isDisabled ? (
      <RegrelloButton
        aria-label="clear" // <- Crucial for E2E tests!
        className={clsx({
          // HACKHACK (clewis): Custom button sizes tuned especially for fitting within this input:
          "w-4 h-4": size === "x-small",
          "w-5 h-5": size === "small",
        })}
        iconOnly={true}
        onClick={handleClear}
        size={getInputV2ButtonSizeForInputSize(size)}
        startIcon="close"
        variant="ghost"
      />
    ) : undefined;

  const isNoStartContent = startIcon == null && values.length === 0;
  const isNoEndContent = endElement == null && maybeClearButton == null;

  const InputComp = asChild ? Slot : "input";

  return (
    <div
      ref={rootRef}
      className={clsx(
        "flex items-start rg-box-border rounded p-1 bg-background",
        {
          "ring-inset ring-2 ring-primary-solid/100": isFocused,

          "min-h-6 gap-1": size === "x-small",
          "min-h-7 gap-1.5": size === "small",
          "min-h-8 gap-1.5 py-1 pl-1.5": size === "medium",
          "min-h-9 gap-1.5": size === "large",
          "min-h-10 gap-2 pl-1.5": size === "x-large",

          // (clewis): Increase some horizontal paddings when there's no start or end content, else
          // the cursor is too close to the edge.
          "pl-1": isNoStartContent && size === "small",
          "pr-1": isNoEndContent && size === "small",
          "pl-1.5": isNoStartContent && size === "large",
          "pr-1.5": isNoEndContent && size === "large",
          "pl-2": isNoStartContent && size === "x-large",
          "pr-2": isNoEndContent && size === "x-large",

          "shadow-warning-solid ring-warning-solid": intent === "warning",
          "shadow-danger-solid ring-danger-solid": intent === "danger",

          "opacity-30": isDisabled,

          "w-60": !isFullWidth,
          "w-full": isFullWidth,
        },
        className,
      )}
    >
      {/* Start icon */}
      {startIcon != null && (
        <RegrelloIcon
          className={clsx("shrink-0 flex", {
            "ml-0.5": size === "small",
            "mt-0.5": size === "medium",
            "ml-1 mt-1": size === "large",
            "ml-1 mt-1.5": size === "x-large",
          })}
          iconName={startIcon}
          intent="neutral"
          size={getInputV2IconSizeForInputSize(size)}
        />
      )}

      {/* Chips and ghost input */}
      <div
        className={clsx("flex flex-wrap flex-auto gap-0.5 min-w-0", {
          "gap-px": size === "x-small", // (clewis): gap-0.5 is a little too roomy for x-small.
          "my-0.5": size === "medium" || size === "x-large",
        })}
      >
        {values.map((value, index) => {
          const { tooltip, ...resolvedChipProps } = chipProps?.(value, index) ?? {};

          const chip = (
            <RegrelloChip
              key={index}
              onDelete={isDisabled ? undefined : (event) => handleOnDeleteInternal(event, value, index)}
              {...resolvedChipProps}
              selected={selectedIndex === index}
              size={getInputV2ChipSizeForInputSize(size)}
              // (clewis): This component provide alternate keyboard navigation for highlighting and
              // deleting selected chips, and removing them from the tab order makes it easier to
              // focus the <input> itself, which needs focus in order for these alternate keyboard
              // interactions to work.
              tabIndexForDeleteButton={-1}
            >
              {renderChipText != null ? renderChipText(value, index) : String(value)}
            </RegrelloChip>
          );
          if (tooltip != null) {
            return (
              <RegrelloTooltip key={index} content={tooltip} side="top">
                <span>{chip}</span>
              </RegrelloTooltip>
            );
          }
          return chip;
        })}
        <InputComp
          {...inputProps}
          ref={mergeRefs(inputRef, propsInputRef)}
          className={clsx(
            "px-0.5 flex-auto focus:outline-none bg-transparent disabled:bg-transparent min-w-0",
            {
              "text-sm": size !== "x-small",
              "text-xs": size === "x-small",
              // (clewis): Match chip sizes to ensure no jitter as we add more lines.
              "h-4": size === "x-small",
              "h-5": size === "small" || size === "medium",
              "h-7": size === "large" || size === "x-large",
            },
            inputProps?.className,
          )}
          disabled={isDisabled}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleInputKeyDown}
          onPaste={handleInputPaste}
          size={8} // (clewis): Reduce from the default of 20 to make the input more compact. (It will still stretch to fill available space on the row.)
        />
      </div>

      {/* End elements */}
      <div className="flex flex-none">
        {/* Clear button */}
        {maybeClearButton}

        {/* Custom end element */}
        {endElement != null && (
          <div className="shrink-0 flex">
            {typeof endElement === "string" ? (
              <RegrelloIcon
                className="shrink-0 my-1 mr-1"
                iconName={endElement}
                intent="neutral"
                size={getInputV2IconSizeForInputSize(size)}
              />
            ) : (
              endElement
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function getInputV2ChipSizeForInputSize(size: RegrelloSize) {
  /* eslint-disable lingui/no-unlocalized-strings */
  switch (size) {
    case "x-small":
      return "x-small";
    case "small":
      return "small";
    case "medium":
      return "small";
    case "large":
      return "medium";
    case "x-large":
      return "medium";
    default:
      assertNever(size);
  }
  /* eslint-ensable lingui/no-unlocalized-strings */
}

/**
 * Returns the indexes of all disabled values in `values`, as determined by `chipProps`. Will be
 * recomputed when the `items` change.
 */
function useDisabledIndicesCache<T>({
  chipProps,
  values,
}: {
  chipProps?: (value: T, index: number) => RegrelloChipProps;
  values: readonly T[];
}): {
  disabledIndices: Set<number>;
  firstEnabledIndex: number;
  lastEnabledIndex: number;
} {
  const disabledIndicesRef = useRef<Set<number>>(new Set());
  const firstEnabledIndexRef = useRef<number>(-1);
  const lastEnabledIndexRef = useRef<number>(-1);

  useEffect(() => {
    disabledIndicesRef.current.clear();
    for (let i = 0; i < values.length; i += 1) {
      const value = values[i];
      if (chipProps?.(value, i).disabled === true) {
        disabledIndicesRef.current.add(i);
      } else if (firstEnabledIndexRef.current === -1) {
        // Track the first enabled index.
        firstEnabledIndexRef.current = i;
      }
    }

    // Track the last enabled index.
    for (let i = values.length - 1; i >= 0; i -= 1) {
      if (!disabledIndicesRef.current.has(i)) {
        lastEnabledIndexRef.current = i;
        break;
      }
    }
  }, [chipProps, values]);

  return {
    disabledIndices: disabledIndicesRef.current,
    lastEnabledIndex: lastEnabledIndexRef.current,
    firstEnabledIndex: firstEnabledIndexRef.current,
  };
}

/**
 * An form element that displays `RegrelloChip` elements inside an input, followed by a text input that
 * affords adding more chips. Supports keyboard interactions to easily remove chips as well.
 */
export const RegrelloChipInput = React.memo(RegrelloChipInputInternal) as typeof RegrelloChipInputInternal;
