import type { ActionItemTemplateFields } from "./ActionItemTemplateFields.generated";
import type { ActionItemTemplateActionItemFields } from "./ActionItemTemplateActionItemFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import { gql } from "@apollo/client";
export type ApprovalActionItemTemplateWithActionItemFields = {
  fieldInstances: Array<FieldInstanceFields>;
  actionItems: Array<{ hasDocuments: boolean } & ActionItemTemplateActionItemFields>;
} & ActionItemTemplateFields;

export const ApprovalActionItemTemplateWithActionItemFields = gql`
    fragment ApprovalActionItemTemplateWithActionItemFields on ActionItemTemplate {
  ...ActionItemTemplateFields
  fieldInstances {
    ...FieldInstanceFields
  }
  actionItems {
    ...ActionItemTemplateActionItemFields
    hasDocuments
  }
}
    `;
