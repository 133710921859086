import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type PropertyTypeFields = { id: number; name: string; dataType: Types.PropertyDataType };

export const PropertyTypeFields = gql`
    fragment PropertyTypeFields on PropertyType {
  id
  name
  dataType
}
    `;
