import type { FieldInstanceValueFloatFields } from "./FieldInstanceValueFloatFields.generated";
import type { AcyclicFieldInstanceValueFloatFields } from "./AcyclicFieldInstanceValueFloatFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueFloatWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceValueFloat?: AcyclicFieldInstanceValueFloatFields | null;
  crossWorkflowSinksFieldInstanceValueFloat?: Array<AcyclicFieldInstanceValueFloatFields> | null;
} & FieldInstanceValueFloatFields;

export const FieldInstanceValueFloatWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueFloatWithCrossWorkflowFields on FieldInstanceValueFloat {
  ...FieldInstanceValueFloatFields
  crossWorkflowSourceFieldInstanceValueFloat {
    ...AcyclicFieldInstanceValueFloatFields
  }
  crossWorkflowSinksFieldInstanceValueFloat {
    ...AcyclicFieldInstanceValueFloatFields
  }
}
    `;
