import type { FieldInstanceValueBooleanBaseFields } from "./FieldInstanceValueBooleanBaseFields.generated";
import type { AcyclicFieldInstanceValueBooleanFields } from "./AcyclicFieldInstanceValueBooleanFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueBooleanFields = {
  sourceFieldInstanceValueBoolean?: AcyclicFieldInstanceValueBooleanFields | null;
  sinksFieldInstanceValueBoolean: Array<AcyclicFieldInstanceValueBooleanFields>;
} & FieldInstanceValueBooleanBaseFields;

export const FieldInstanceValueBooleanFields = gql`
    fragment FieldInstanceValueBooleanFields on FieldInstanceValueBoolean {
  ...FieldInstanceValueBooleanBaseFields
  sourceFieldInstanceValueBoolean {
    ...AcyclicFieldInstanceValueBooleanFields
  }
  sinksFieldInstanceValueBoolean {
    ...AcyclicFieldInstanceValueBooleanFields
  }
}
    `;
