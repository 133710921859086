import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type DocumentVersionFields = {
  id: number;
  uid: string;
  createdAt: string;
  deletedAt?: string | null;
  externalLink?: string | null;
  createdBy: PartyBaseFields;
};

export const DocumentVersionFields = gql`
    fragment DocumentVersionFields on DocumentVersion {
  id
  uid
  createdAt
  createdBy {
    ...PartyBaseFields
  }
  deletedAt
  externalLink
}
    `;
