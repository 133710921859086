import type { FormSectionBaseFields } from "./FormSectionBaseFields.generated";
import { gql } from "@apollo/client";
export type FormSectionFields = {
  formSections: Array<{ formSections: Array<FormSectionBaseFields> } & FormSectionBaseFields>;
} & FormSectionBaseFields;

export const FormSectionFields = gql`
    fragment FormSectionFields on FormSection {
  ...FormSectionBaseFields
  formSections {
    ...FormSectionBaseFields
    formSections {
      ...FormSectionBaseFields
    }
  }
}
    `;
