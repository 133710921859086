import { gql } from "@apollo/client";
export type SpectrumFieldFields = { id: number; uuid: string; createdAt: string };

export const SpectrumFieldFields = gql`
    fragment SpectrumFieldFields on SpectrumField {
  id
  uuid
  createdAt
}
    `;
