import type { RootCommentFields } from "./RootCommentFields.generated";
import type { DocumentFields } from "./DocumentFields.generated";
import { gql } from "@apollo/client";
export type ActionItemAdditionalFields = {
  description?: string | null;
  comments: Array<RootCommentFields>;
  documents: Array<DocumentFields>;
};

export const ActionItemAdditionalFields = gql`
    fragment ActionItemAdditionalFields on ActionItem {
  comments {
    ...RootCommentFields
  }
  documents {
    ...DocumentFields
  }
  description
}
    `;
