import { t } from "@lingui/macro";
import { clsx, EMPTY_ARRAY, EMPTY_STRING, isDefined, sortIgnoreCaseWithExactMatchFirst } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  type FieldInstanceFields,
  type FormFieldFields,
  type FormVersionFields,
  useCreateFormAndVersionMutation,
  useFormSelectFormsForBlueprintsQueryLazyQuery,
  useFormSelectFormsForWorkflowsQueryLazyQuery,
  useFormSelectFormsQueryLazyQuery,
  useFormSelectLatestFormFieldInstancesQueryLazyQuery,
} from "@regrello/graphql-api";
import { RegrelloButton, RegrelloLinkV2, RegrelloTooltip, RegrelloTypography } from "@regrello/ui-core";
import { useAtom } from "jotai";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync, useDeepCompareEffect, useUnmount } from "react-use";

import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloSelectV2AddOption } from "./_internal/selectOptions/RegrelloSelectV2AddOption";
import { RegrelloSelectV2ErrorOption } from "./_internal/selectOptions/RegrelloSelectV2ErrorOption";
import {
  type RegrelloFormFieldSelectPropsV2,
  RegrelloFormFieldSelectV2,
  type RegrelloSelectChangeReason,
} from "./RegrelloFormFieldSelectV2";
import { FeatureFlagService } from "../../../services/FeatureFlagService";
import { formOpenedInNewTabAtom } from "../../../state/applicationState";
import { consoleWarnInDevelopmentModeOnly } from "../../../utils/environmentUtils";
import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { useWindowFocus } from "../../../utils/hooks/useWindowFocus";
import { AsyncLoaded } from "../../../utils/typescript/AsyncLoaded";
import { useUser } from "../../app/authentication/userContextUtils";
import { getRouteToFormBuilder } from "../../app/routes/routeCreatorUtils";
import {
  getSpectrumFieldNamesByIds,
  recursivelyPairFieldInstancesWithFormFields,
} from "../../views/modals/spectrumTaskDetailView/_internal/form/spectrumFormInstanceUtils";

export type FormSelectFormVersionFields = Pick<FormVersionFields, "description" | "id" | "name" | "uuid"> & {
  /**
   * Map from IDs to names of the spectrum fields that are used in the current version of the form.
   *
   * ___Note:___ This is not field-version ID because we will use this information to determine
   * which fields in `Shared Information` and `Request Information` need to be disabled. We will
   * also determine which form should be disabled if an ad-hoc field is selected.
   */
  spectrumFieldNamesById: Map<number, string>;
  createdBy: {
    id: number;
  };

  /**
   * `compositeFieldInstances` are only populated when the form is being selected because we need to
   * minimize the payload size when loading all selectable forms.
   */
  compositeFieldInstances?: Array<{
    formField: FormFieldFields;
    fieldInstance: FieldInstanceFields;
  }>;
  isPublic?: boolean;
  form: Omit<FormVersionFields["form"], "usedBy">;
};

// eslint-disable-next-line react-refresh/only-export-components
export enum FormContext {
  Workflow = "workflow",
  Blueprint = "blueprint",
}

export interface RegrelloFormFieldSpectrumFormSelectProps
  extends RegrelloFormFieldBaseProps<FormSelectFormVersionFields | null>,
    Pick<
      RegrelloFormFieldSelectPropsV2<FormSelectFormVersionFields>,
      "inputRef" | "onChange" | "onClearClick" | "placeholder" | "size" | "value"
    > {
  /**
   * Whether to allow creating new forms.
   * @default false
   */
  allowCreateForms?: boolean;

  /**
   * The context of where the select are being used. The options are scoped to either a workflow
   * or a blueprint.
   */
  context?:
    | {
        type: FormContext.Workflow;
        workflowId: number;
      }
    | {
        type: FormContext.Blueprint;
        blueprintId: number;
      };

  /**
   * The list of field IDs that would cause any spectrum form to be excluded from
   * the list.
   */
  excludedFieldIds?: number[];

  /**
   * Whether the form-selection is locked and cannot be changed. This functions similarly to a simple
   * `disable`, but will display the associated messages/icons to users.
   * @default false
   */
  isLocked?: boolean;

  /**
   * Whether the specturm form is being loaded. If `true`, The preview button will be in the
   * loading state.
   */
  isSpectrumLoading?: boolean;

  /**
   * The list of spectrum field ids that are already occupied by other fields. Forms having spectrum
   * fields in this set will be disabled in the dropdown.
   */
  occupiedSpectrumFieldIds?: number[];
}

/**
 * This component renders a multiselect input field where the user can select from all fields in
 * the system. It is a wrapper around RegrelloFormFieldSelect. It loads the filed data from the
 * graphql query Fields.
 */
export const RegrelloFormFieldSpectrumFormSelect = React.memo<RegrelloFormFieldSpectrumFormSelectProps>(
  function RegrelloFormFieldSpectrumFormSelectFn({
    context,
    allowCreateForms = true,
    disabled = false,
    excludedFieldIds,
    isLocked = false,
    isSpectrumLoading = false,
    size,
    onChange,
    occupiedSpectrumFieldIds,
    ...multiselectProps
  }) {
    const { handleError } = useErrorHandler();
    const { currentUser } = useUser();
    const isFormsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.REMOVE_FORMS_PAGE_2024_08);

    const [getFormsAsync, formsQueryResult] = useFormSelectFormsQueryLazyQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        handleError(error, {
          toastMessage: t`Unable to fetch data, please try again or contact a Regrello admin if you continue to encounter this problem`,
        });
      },
    });

    const [getFormsForWorkflowsAsync, formsForWorkflowsQueryResult] = useFormSelectFormsForWorkflowsQueryLazyQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        handleError(error, {
          toastMessage: t`Unable to fetch data, please try again or contact a Regrello admin if you continue to encounter this problem`,
        });
      },
    });

    const [getFormsForBlueprintsAsync, formsForBlueprintsQueryResult] = useFormSelectFormsForBlueprintsQueryLazyQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        handleError(error, {
          toastMessage: t`Unable to fetch data, please try again or contact a Regrello admin if you continue to encounter this problem`,
        });
      },
    });

    const [getLatestFormFieldInstancesAsync] = useFormSelectLatestFormFieldInstancesQueryLazyQuery({
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        handleError(error, {
          toastMessage: t`Unable to fetch data, please try again or contact a Regrello admin if you continue to encounter this problem`,
        });
      },
    });

    const asyncLoadedFormsQueryResult = useMemo(
      () => AsyncLoaded.fromGraphQlQueryResult(formsQueryResult),
      [formsQueryResult],
    );

    const asyncLoadedFormsForBlueprintsQueryResult = useMemo(
      () => AsyncLoaded.fromGraphQlQueryResult(formsForBlueprintsQueryResult),
      [formsForBlueprintsQueryResult],
    );

    const asyncLoadedFormsForWorkflowsQueryResult = useMemo(
      () => AsyncLoaded.fromGraphQlQueryResult(formsForWorkflowsQueryResult),
      [formsForWorkflowsQueryResult],
    );

    const [createFormAndVersionAsync] = useCreateFormAndVersionMutation({
      onError: (error) => {
        handleError(error, { toastMessage: t`Unable to create a new form` });
      },
    });
    const [formOpenedInNewTab, setFormOpenedInNewTab] = useAtom(formOpenedInNewTabAtom);

    const [loadedOptionsV2, setLoadedOptions] = useState<FormSelectFormVersionFields[]>(EMPTY_ARRAY);
    const isSelectedForm = useMemo(() => {
      return multiselectProps.value?.uuid !== "";
    }, [multiselectProps.value?.uuid]);

    const isSelectedFormDeleted = multiselectProps.value?.form?.deletedAt != null;
    const isSelectedFormPrivate =
      multiselectProps.value?.form?.createdBy?.id !== currentUser.partyId &&
      !multiselectProps.value?.isPublic &&
      !FeatureFlagService.isEnabled(FeatureFlagKey.REMOVE_FORMS_PAGE_2024_08);
    const isSelectedFormAccessible = !isSelectedFormPrivate && !isSelectedFormDeleted;

    const onChangeInternal = useCallback(
      async (option: FormSelectFormVersionFields | null, action: RegrelloSelectChangeReason) => {
        if (option == null) {
          onChange(option, action);
          return;
        }

        const latestFormFieldInstancesQueryResult = await getLatestFormFieldInstancesAsync({
          variables: { formUUID: option.form.uuid },
        });
        const asyncLoadedlatestFormFieldInstancesQueryResult = AsyncLoaded.fromGraphQlQueryResult(
          latestFormFieldInstancesQueryResult,
        );
        if (
          AsyncLoaded.isNotLoaded(asyncLoadedlatestFormFieldInstancesQueryResult) ||
          AsyncLoaded.isLoading(asyncLoadedlatestFormFieldInstancesQueryResult) ||
          AsyncLoaded.isError(asyncLoadedlatestFormFieldInstancesQueryResult)
        ) {
          return;
        }

        onChange(
          {
            ...option,
            compositeFieldInstances: recursivelyPairFieldInstancesWithFormFields(
              asyncLoadedlatestFormFieldInstancesQueryResult.value.latestFormVersion.defaultFormSection,
              asyncLoadedlatestFormFieldInstancesQueryResult.value.latestFormVersion.fieldInstances,
            ),
          },
          action,
        );
      },
      [getLatestFormFieldInstancesAsync, onChange],
    );

    useEffect(() => {
      if (isFormsV2Enabled) {
        return;
      }
      if (AsyncLoaded.isLoading(asyncLoadedFormsQueryResult)) {
        return;
      }

      if (AsyncLoaded.isError(asyncLoadedFormsQueryResult) || AsyncLoaded.isNotLoaded(asyncLoadedFormsQueryResult)) {
        setLoadedOptions([]);
        return;
      }

      const options = asyncLoadedFormsQueryResult.value.forms.map((form) => ({
        ...form.latestFormVersion,
        spectrumFieldNamesById: getSpectrumFieldNamesByIds(form.latestFormVersion.occupiedSpectrumFieldNamesById),
        createdBy: form.createdBy,
        isPublic: form.publishedFromFormID != null,
      }));
      // (clewis): Need to spread before sorting, because the loaded array is readonly. Also need to
      // include the "Add" option in order for it to emit successfully via onChange.
      setLoadedOptions(sortOptions(options));
    }, [asyncLoadedFormsQueryResult, isFormsV2Enabled]);

    useEffect(() => {
      if (!isFormsV2Enabled) {
        return;
      }
      if (AsyncLoaded.isLoading(asyncLoadedFormsForBlueprintsQueryResult)) {
        return;
      }

      if (
        AsyncLoaded.isError(asyncLoadedFormsForBlueprintsQueryResult) ||
        AsyncLoaded.isNotLoaded(asyncLoadedFormsForBlueprintsQueryResult)
      ) {
        setLoadedOptions([]);
        return;
      }

      const options = asyncLoadedFormsForBlueprintsQueryResult.value.formsForWorkflowTemplate.map((form) => ({
        ...form.latestFormVersion,
        spectrumFieldNamesById: getSpectrumFieldNamesByIds(form.latestFormVersion.occupiedSpectrumFieldNamesById),
        createdBy: form.createdBy,
        isPublic: form.publishedFromFormID != null,
      }));
      // (clewis): Need to spread before sorting, because the loaded array is readonly. Also need to
      // include the "Add" option in order for it to emit successfully via onChange.
      setLoadedOptions(sortOptions(options));
    }, [asyncLoadedFormsForBlueprintsQueryResult, isFormsV2Enabled]);

    useEffect(() => {
      if (!isFormsV2Enabled) {
        return;
      }
      if (AsyncLoaded.isLoading(asyncLoadedFormsForWorkflowsQueryResult)) {
        return;
      }

      if (
        AsyncLoaded.isError(asyncLoadedFormsForWorkflowsQueryResult) ||
        AsyncLoaded.isNotLoaded(asyncLoadedFormsForWorkflowsQueryResult)
      ) {
        setLoadedOptions([]);
        return;
      }

      const options = asyncLoadedFormsForWorkflowsQueryResult.value.formsForWorkflow.map((form) => ({
        ...form.latestFormVersion,
        spectrumFieldNamesById: getSpectrumFieldNamesByIds(form.latestFormVersion.occupiedSpectrumFieldNamesById),
        createdBy: form.createdBy,
        isPublic: form.publishedFromFormID != null,
      }));

      setLoadedOptions(sortOptions(options));
    }, [asyncLoadedFormsForWorkflowsQueryResult, isFormsV2Enabled]);

    const handleAutocompleteOpen = useCallback(async () => {
      // Reload the fields when the autocomplete opens.
      if (isFormsV2Enabled) {
        if (context == null) {
          consoleWarnInDevelopmentModeOnly(
            "Context is not provided for the form select. This should never happen when the REMOVE_FORMS_PAGE_2024_08 flag is on",
          );
          return;
        }

        if (context.type === FormContext.Blueprint) {
          await getFormsForBlueprintsAsync({ variables: { workflowTemplateID: context.blueprintId } });
        } else {
          await getFormsForWorkflowsAsync({ variables: { workflowID: context.workflowId } });
        }
      } else {
        await getFormsAsync();
      }
    }, [context, getFormsForBlueprintsAsync, getFormsForWorkflowsAsync, getFormsAsync, isFormsV2Enabled]);

    const onAddForm = useCallback(async () => {
      if (isFormsV2Enabled) {
        if (context == null) {
          consoleWarnInDevelopmentModeOnly(
            "Context is not provided for the form select. This should never happen when the REMOVE_FORMS_PAGE_2024_08 flag is on",
          );
          return;
        }

        if (context.type === FormContext.Blueprint) {
          const result = await createFormAndVersionAsync({
            variables: {
              input: {
                name: EMPTY_STRING, // (hchen): BE will give it a placeholder name
                description: EMPTY_STRING,
                workflowTemplateID: context.blueprintId,
              },
            },
          });
          if (result.errors != null || result.data?.createFormAndVersion.form == null) {
            handleError(t`Unable to create a new form`, { toastMessage: t`Unable to create a new form` });
            return;
          }

          setFormOpenedInNewTab({ uuid: result.data.createFormAndVersion.form.uuid });
          const url = getRouteToFormBuilder(result.data?.createFormAndVersion.form.uuid);
          window.open(url, "_blank");
        } else {
          const result = await createFormAndVersionAsync({
            variables: {
              input: {
                name: EMPTY_STRING, // (hchen): BE will give it a placeholder name
                description: EMPTY_STRING,
                workflowID: context.workflowId,
              },
            },
          });
          if (result.errors != null || result.data?.createFormAndVersion.form == null) {
            handleError(t`Unable to create a new form`, { toastMessage: t`Unable to create a new form` });
            return;
          }

          setFormOpenedInNewTab({ uuid: result.data.createFormAndVersion.form.uuid });
          const url = getRouteToFormBuilder(result.data?.createFormAndVersion.form.uuid);
          window.open(url, "_blank");
        }
      } else {
        const result = await createFormAndVersionAsync({
          variables: {
            input: {
              name: EMPTY_STRING, // (hchen): BE will give it a placeholder name
              description: EMPTY_STRING,
            },
          },
        });
        if (result.errors != null || result.data?.createFormAndVersion.form.uuid == null) {
          handleError(t`Unable to create a new form`, { toastMessage: t`Unable to create a new form` });
          return;
        }
        setFormOpenedInNewTab({ uuid: result.data.createFormAndVersion.form.uuid });

        window.open(`${window.location.origin}${getRouteToFormBuilder(result.data.createFormAndVersion.form.uuid)}`);
      }
    }, [context, createFormAndVersionAsync, handleError, isFormsV2Enabled, setFormOpenedInNewTab]);

    const refetchCallback = useCallback(() => {
      if (isFormsV2Enabled) {
        if (context?.type === FormContext.Blueprint) {
          void formsForBlueprintsQueryResult.refetch({ workflowTemplateID: context.blueprintId });
        } else if (context != null) {
          void formsForWorkflowsQueryResult.refetch({ workflowID: context.workflowId });
        }
      } else {
        void formsQueryResult.refetch();
      }
    }, [context, formsForBlueprintsQueryResult, formsForWorkflowsQueryResult, formsQueryResult, isFormsV2Enabled]);

    const { isWindowFocused } = useWindowFocus({ onFocus: refetchCallback });

    useAsync(async () => {
      if (isWindowFocused && formOpenedInNewTab != null) {
        const formVersionToSelect = loadedOptionsV2.find(
          (formVersion) => formVersion.form.uuid === formOpenedInNewTab.uuid,
        );
        if (formVersionToSelect != null) {
          await onChangeInternal(formVersionToSelect, "select-option");
        }
      }
    }, [formOpenedInNewTab, isWindowFocused, loadedOptionsV2, onChangeInternal, setFormOpenedInNewTab]);

    // (hchen): Only reset the formOpenedInNewTab when we are sure the form is selected.
    useEffect(() => {
      if (formOpenedInNewTab != null && multiselectProps.value?.form.uuid === formOpenedInNewTab.uuid) {
        setFormOpenedInNewTab(undefined);
      }
    }, [formOpenedInNewTab, multiselectProps.value?.form.uuid, setFormOpenedInNewTab]);

    // (hchen): This effect is responsible for auto populate the form when the form is already
    // created and it's changed in a different tab or someone else. When the user refocus this tab,
    // we detect that the form is the same but the latest version is different. We will auto select
    // the latest version.
    useDeepCompareEffect(() => {
      if (multiselectProps.value?.form == null) {
        return;
      }
      const latestFormVersion = loadedOptionsV2.find(
        (formVersion) =>
          formVersion.form.uuid === multiselectProps.value?.form.uuid &&
          formVersion.uuid !== multiselectProps.value?.uuid,
      );
      if (latestFormVersion == null) {
        return;
      }
      void onChangeInternal(latestFormVersion, "select-option");
    }, [loadedOptionsV2, multiselectProps.value, onChangeInternal]);

    useUnmount(() => {
      setFormOpenedInNewTab(undefined);
    });

    const getOptionDisabledOrReason = useCallback(
      (option: FormSelectFormVersionFields): false | "occupiedField" | "excludedField" => {
        if (occupiedSpectrumFieldIds?.some((fieldId) => option.spectrumFieldNamesById.has(fieldId))) {
          // eslint-disable-next-line lingui/no-unlocalized-strings
          return "occupiedField";
        }
        if (excludedFieldIds?.some((fieldId) => option.spectrumFieldNamesById.has(fieldId))) {
          // eslint-disable-next-line lingui/no-unlocalized-strings
          return "excludedField";
        }
        return false;
      },
      [excludedFieldIds, occupiedSpectrumFieldIds],
    );
    const getOptionDisabled = useCallback(
      (option: FormSelectFormVersionFields): boolean => getOptionDisabledOrReason(option) !== false,
      [getOptionDisabledOrReason],
    );

    const getOptionTooltip = useCallback(
      (option: FormSelectFormVersionFields) => {
        switch (getOptionDisabledOrReason(option)) {
          case "occupiedField": {
            if (occupiedSpectrumFieldIds == null) {
              return t`This form contains fields that are already passed to this task as shared fields.`;
            }
            // Show a list of fields that are preventing this form from being selected.
            const occupiedNames = (occupiedSpectrumFieldIds ?? EMPTY_ARRAY)
              .map((fieldId) => option.spectrumFieldNamesById.get(fieldId))
              .filter(isDefined)
              .sort();

            return (
              <div>
                <div>{t`This form contains fields that are already passed to this task as shared fields. To add the form, please remove the following fields:`}</div>
                <ul className="pt-2">
                  {occupiedNames.map((fieldName, i) => (
                    <li key={i}>{fieldName}</li>
                  ))}
                </ul>
              </div>
            );
          }

          case "excludedField": {
            if (excludedFieldIds == null) {
              return t`This form contains fields that have already been used in this blueprint.`;
            }

            const excludedNames = excludedFieldIds
              .map((fieldId) => option.spectrumFieldNamesById.get(fieldId))
              .filter(isDefined)
              .sort();

            return (
              <div>
                <div>{t`This form contains fields that have already been used in this blueprint. To add the form, please remove the following fields:`}</div>
                <ul className="pt-2">
                  {excludedNames.map((fieldName, i) => (
                    <li key={i}>{fieldName}</li>
                  ))}
                </ul>
              </div>
            );
          }

          default:
            return EMPTY_STRING;
        }
      },
      [excludedFieldIds, getOptionDisabledOrReason, occupiedSpectrumFieldIds],
    );

    const renderSelectedFormName = useCallback(() => {
      if (!isSelectedForm) {
        return EMPTY_STRING;
      }

      if (isSelectedFormPrivate) {
        return (
          <RegrelloTypography className="flex">
            {getFormLabel(multiselectProps.value)}{" "}
            <RegrelloTypography className="ml-1" muted={true}>{`(${t`No access`})`}</RegrelloTypography>
          </RegrelloTypography>
        );
      }
      if (isSelectedFormDeleted) {
        return (
          <RegrelloTypography className="flex">
            {getFormLabel(multiselectProps.value)}{" "}
            <RegrelloTypography className="ml-1" muted={true}>{`(${t`Deleted`})`}</RegrelloTypography>
          </RegrelloTypography>
        );
      }

      return getFormLabel(multiselectProps.value);
    }, [isSelectedForm, isSelectedFormPrivate, isSelectedFormDeleted, multiselectProps.value]);

    return (
      <>
        <RegrelloFormFieldSelectV2
          disabled={isLocked || disabled}
          extraEndOptions={[
            (!isFormsV2Enabled && AsyncLoaded.isError(asyncLoadedFormsQueryResult)) ||
            (isFormsV2Enabled && AsyncLoaded.isError(asyncLoadedFormsForBlueprintsQueryResult)) ||
            AsyncLoaded.isError(asyncLoadedFormsForWorkflowsQueryResult) ? (
              <RegrelloSelectV2ErrorOption key="option-error" />
            ) : (
              <RegrelloSelectV2AddOption
                key="option-add"
                allowCreateDisabledText={t`Please contact an admin if you would like to create a new form`}
                allowCreateOptions={allowCreateForms}
                iconName="add"
                message={t`Create new form`}
                onSelect={onAddForm}
              />
            ),
          ]}
          getOptionDisabled={getOptionDisabled}
          getOptionLabel={getFormLabel}
          getOptionTooltip={getOptionTooltip}
          getOptionValue={getFormValue}
          groupBy={isFormsV2Enabled ? undefined : (option) => (option.isPublic ? t`Published forms` : t`My forms`)}
          isLoading={
            (!isFormsV2Enabled && AsyncLoaded.isLoading(asyncLoadedFormsQueryResult)) ||
            (isFormsV2Enabled && AsyncLoaded.isLoading(asyncLoadedFormsForBlueprintsQueryResult)) ||
            AsyncLoaded.isLoading(asyncLoadedFormsForWorkflowsQueryResult)
          }
          onChange={onChangeInternal}
          onOpen={handleAutocompleteOpen}
          options={loadedOptionsV2}
          renderSelectedValue={renderSelectedFormName}
          size={size}
          {...multiselectProps}
        />
        {multiselectProps.value?.form?.uuid != null && multiselectProps.value.form.uuid.length > 0 && (
          <RegrelloTooltip
            content={
              isSelectedFormPrivate
                ? t`You cannot view someone else's non-published form.`
                : isSelectedFormDeleted
                  ? t`You cannot view a deleted form.`
                  : EMPTY_STRING
            }
          >
            {/* (hchen): This div is necessary for showing the tooltip when disabled. */}
            <div>
              <RegrelloLinkV2
                className={clsx({
                  "pointer-events-none": !isSelectedFormAccessible,
                })}
                target="_blank"
                to={getRouteToFormBuilder(
                  multiselectProps.value.form.uuid,
                  multiselectProps.value.form.publishedFromFormID != null,
                )}
                unstyled={true}
              >
                <RegrelloButton
                  className="h-9"
                  dataTestId={DataTestIds.ADD_ACTION_ITEM_DIALOG_VIEW_FORM_BUTTON}
                  disabled={!isSelectedFormAccessible}
                  endIcon="launch"
                  intent="primary"
                  loading={isSpectrumLoading}
                  size="small"
                  variant="ghost"
                >
                  {isFormsV2Enabled && !isLocked ? t`View/Edit` : t`View form`}
                </RegrelloButton>
              </RegrelloLinkV2>
            </div>
          </RegrelloTooltip>
        )}
      </>
    );
  },
);

function getFormLabel(form: FormSelectFormVersionFields | null): string {
  return form?.name ?? EMPTY_STRING;
}

function getFormValue(form: FormSelectFormVersionFields): string {
  return `${form.name}|${form.isPublic ? t`Published forms` : t`My forms`}`;
}

function sortOptions(options: FormSelectFormVersionFields[]): FormSelectFormVersionFields[] {
  // (dosipiuk): Merge `isPublic` with `name` to achieve proper grouping
  return sortIgnoreCaseWithExactMatchFirst([...options], (option) => `${!option.isPublic}${option.name}`, EMPTY_STRING);
}
