import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import type { ActionItemTemplateActionItemFields } from "./ActionItemTemplateActionItemFields.generated";
import type { TagFields } from "./TagFields.generated";
import type { DocumentFields } from "./DocumentFields.generated";
import type { AutomationRequestFields } from "./AutomationRequestFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { IntegrationAutomationInstanceFields } from "./IntegrationAutomationInstanceFields.generated";
import type { ActionItemTemplatePermissionsFields } from "./ActionItemTemplatePermissionsFields.generated";
import type { ActionItemTemplateStartAfterActionItemTemplateChain } from "./ActionItemTemplateStartAfterActionItemTemplateChain.generated";
import type { RejectActionFields } from "./RejectActionFields.generated";
import { gql } from "@apollo/client";
export type ActionItemTemplateFields = {
  __typename: "ActionItemTemplate";
  uuid: string;
  id: number;
  name: string;
  description?: string | null;
  dueOn?: string | null;
  dueOnIntervalType?: Types.ActionItemTemplateDueOnIntervalType | null;
  displayOrder: number;
  type: Types.ActionItemType;
  requiresAck: boolean;
  createdAt: string;
  updatedAt: string;
  dueOnIntervalSeconds?: number | null;
  dueOnIntervalSecondsAfterTrigger?: Types.ActionItemDueOnIntervalTrigger | null;
  startAt?: string | null;
  integrationType?: Types.IntegrationType | null;
  isLocked: boolean;
  plannedDueOn?: string | null;
  plannedStartAt?: string | null;
  conditionalDueOn?: string | null;
  conditionalStartAt?: string | null;
  adjustedStartAt?: string | null;
  assignedBy: PartyBaseFields;
  actionItems: Array<ActionItemTemplateActionItemFields>;
  approvalActionItemTemplates: Array<{
    __typename: "ActionItemTemplate";
    uuid: string;
    id: number;
    name: string;
    displayOrder: number;
    type: Types.ActionItemType;
    dueOn?: string | null;
    dueOnIntervalSeconds?: number | null;
    isLocked: boolean;
    assignees: Array<PartyBaseFields>;
    fieldInstancesControllingAssignees?: Array<FieldInstanceFields> | null;
    permissions: ActionItemTemplatePermissionsFields;
  }>;
  assignees: Array<PartyBaseFields>;
  aiAgentBackupAssignees: Array<PartyBaseFields>;
  reviewers: Array<PartyBaseFields>;
  cc: Array<PartyBaseFields>;
  documents: Array<DocumentFields>;
  tags: Array<TagFields>;
  createdBy: UserBaseFields;
  automationRequests: Array<AutomationRequestFields>;
  fieldInstancesControllingAssignees?: Array<FieldInstanceFields> | null;
  fieldInstancesControllingCc?: Array<FieldInstanceFields> | null;
  fieldInstanceControllingDueOn?: FieldInstanceFields | null;
  integrationAutomationInstance?: IntegrationAutomationInstanceFields | null;
  permissions: ActionItemTemplatePermissionsFields;
  rejectAction?: RejectActionFields | null;
} & ActionItemTemplateStartAfterActionItemTemplateChain;

export const ActionItemTemplateFields = gql`
    fragment ActionItemTemplateFields on ActionItemTemplate {
  __typename
  uuid
  id
  name
  description
  dueOn
  dueOnIntervalType
  displayOrder
  type
  assignedBy {
    ...PartyBaseFields
  }
  actionItems {
    ...ActionItemTemplateActionItemFields
  }
  approvalActionItemTemplates {
    __typename
    uuid
    id
    name
    displayOrder
    type
    assignees {
      ...PartyBaseFields
    }
    dueOn
    dueOnIntervalSeconds
    fieldInstancesControllingAssignees {
      ...FieldInstanceFields
    }
    permissions {
      ...ActionItemTemplatePermissionsFields
    }
    isLocked
  }
  assignees {
    ...PartyBaseFields
  }
  aiAgentBackupAssignees {
    ...PartyBaseFields
  }
  reviewers {
    ...PartyBaseFields
  }
  cc {
    ...PartyBaseFields
  }
  documents {
    ...DocumentFields
  }
  tags {
    ...TagFields
  }
  requiresAck
  createdAt
  createdBy {
    ...UserBaseFields
  }
  updatedAt
  dueOnIntervalSeconds
  dueOnIntervalSecondsAfterTrigger
  startAt
  ...ActionItemTemplateStartAfterActionItemTemplateChain
  automationRequests {
    ...AutomationRequestFields
  }
  fieldInstancesControllingAssignees {
    ...FieldInstanceFields
  }
  fieldInstancesControllingCc {
    ...FieldInstanceFields
  }
  fieldInstanceControllingDueOn {
    ...FieldInstanceFields
  }
  integrationType
  integrationAutomationInstance {
    ...IntegrationAutomationInstanceFields
  }
  permissions {
    ...ActionItemTemplatePermissionsFields
  }
  isLocked
  rejectAction {
    ...RejectActionFields
  }
  plannedDueOn
  plannedStartAt
  conditionalDueOn
  conditionalStartAt
  adjustedStartAt
}
    `;
