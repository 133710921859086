import type * as Types from "../../../generated/graphqlBaseTypes";

import { RoleFields } from "../../fragments/generated/RoleFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type RolesQueryVariables = Types.Exact<{
  input: Types.RolesQueryInput;
}>;

export type RolesQuery = { roles: { totalCount: number; roles: Array<{ role: RoleFields }> } };

export const RolesQueryDocument = gql`
    query RolesQuery($input: RolesQueryInput!) {
  roles(input: $input) {
    roles {
      role {
        ...RoleFields
      }
    }
    totalCount
  }
}
    ${RoleFields}`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRolesQuery(
  baseOptions: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables> &
    ({ variables: RolesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesQueryDocument, options);
}
export function useRolesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesQueryDocument, options);
}
export function useRolesQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RolesQuery, RolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RolesQuery, RolesQueryVariables>(RolesQueryDocument, options);
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesQueryLazyQueryHookResult = ReturnType<typeof useRolesQueryLazyQuery>;
export type RolesQuerySuspenseQueryHookResult = ReturnType<typeof useRolesQuerySuspenseQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
