import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type AcyclicFieldInstanceMultiValuePartyFields = {
  __typename: "FieldInstanceMultiValueParty";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  partyMultiValue: Array<PartyBaseFields>;
};

export const AcyclicFieldInstanceMultiValuePartyFields = gql`
    fragment AcyclicFieldInstanceMultiValuePartyFields on FieldInstanceMultiValueParty {
  __typename
  id
  inputType
  partyMultiValue {
    ...PartyBaseFields
  }
}
    `;
