import type { FormFieldConstraintRuleFields } from "./FormFieldConstraintRuleFields.generated";
import type { FormConstraintConditionGroupFields } from "./FormConstraintConditionGroupFields.generated";
import { gql } from "@apollo/client";
export type FormFieldConstraintFields = {
  id: number;
  uuid: string;
  createdAt: string;
  formFieldConstraintRule: FormFieldConstraintRuleFields;
  condition: FormConstraintConditionGroupFields;
};

export const FormFieldConstraintFields = gql`
    fragment FormFieldConstraintFields on FormFieldConstraint {
  id
  uuid
  createdAt
  formFieldConstraintRule {
    ...FormFieldConstraintRuleFields
  }
  condition {
    ...FormConstraintConditionGroupFields
  }
}
    `;
