import type * as Types from "../../../generated/graphqlBaseTypes";

import { TagTableTagFields } from "../../fragments/generated/TagTableTagFields.generated";
import { TagTypeFields } from "../../fragments/generated/TagTypeFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateTagMutationVariables = Types.Exact<{
  input: Types.CreateTagInputs;
}>;

export type CreateTagMutation = { createTag?: { tag?: TagTableTagFields | null } | null };

export const CreateTagMutationDocument = gql`
    mutation CreateTagMutation($input: CreateTagInputs!) {
  createTag(input: $input) {
    tag {
      ...TagTableTagFields
    }
  }
}
    ${TagTableTagFields}
${TagTypeFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}`;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagMutationDocument, options);
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
