import { gql } from "@apollo/client";
export type RegrelloObjectInstanceFields = {
  id: number;
  deletedAt?: string | null;
  dataObjectCells: Array<{ key: string; value: { stringValue?: string | null } }>;
};

export const RegrelloObjectInstanceFields = gql`
    fragment RegrelloObjectInstanceFields on RegrelloObjectInstanceV2 {
  id
  dataObjectCells {
    key
    value {
      ... on DataObjectCellValueString {
        stringValue
      }
    }
  }
  deletedAt
}
    `;
