import type * as Types from "../../../generated/graphqlBaseTypes";

import { ActionItemAuditHistoryFields } from "../../fragments/generated/ActionItemAuditHistoryFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { ActionItemAuditHistoryDeltaFields } from "../../fragments/generated/ActionItemAuditHistoryDeltaFields.generated";
import { SpectrumFieldVersionFields } from "../../fragments/generated/SpectrumFieldVersionFields.generated";
import { SpectrumFieldFields } from "../../fragments/generated/SpectrumFieldFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { SpectrumFieldValidationTypeFields } from "../../fragments/generated/SpectrumFieldValidationTypeFields.generated";
import { SpectrumFieldConstraintFields } from "../../fragments/generated/SpectrumFieldConstraintFields.generated";
import { SpectrumValueConstraintFields } from "../../fragments/generated/SpectrumValueConstraintFields.generated";
import { SpectrumFieldAllowedValueFields } from "../../fragments/generated/SpectrumFieldAllowedValueFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { RegrelloObjectInstanceFields } from "../../fragments/generated/RegrelloObjectInstanceFields.generated";
import { ActionItemAuditHistoryDocumentRelatedRecordFields } from "../../fragments/generated/ActionItemAuditHistoryDocumentRelatedRecordFields.generated";
import { DocumentFields } from "../../fragments/generated/DocumentFields.generated";
import { DocumentVersionFields } from "../../fragments/generated/DocumentVersionFields.generated";
import { TagFields } from "../../fragments/generated/TagFields.generated";
import { TagTypeFields } from "../../fragments/generated/TagTypeFields.generated";
import { WorkflowPermissionsFields } from "../../fragments/generated/WorkflowPermissionsFields.generated";
import { ActionItemAuditHistoryFieldInstanceRelatedRecordFields } from "../../fragments/generated/ActionItemAuditHistoryFieldInstanceRelatedRecordFields.generated";
import { RootCommentFields } from "../../fragments/generated/RootCommentFields.generated";
import { CommentFields } from "../../fragments/generated/CommentFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ApprovalAttemptsForActionItemQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
}>;

export type ApprovalAttemptsForActionItemQuery = {
  approvalAttemptsForActionItem?: Array<{
    actionItemsWithAttemptData: Array<{
      actionItem?: { id: number } | null;
      auditHistory: Array<ActionItemAuditHistoryFields>;
      comments: Array<RootCommentFields>;
    }>;
  }> | null;
};

export const ApprovalAttemptsForActionItemQueryDocument = gql`
    query ApprovalAttemptsForActionItemQuery($id: ID) {
  approvalAttemptsForActionItem(id: $id) {
    actionItemsWithAttemptData {
      actionItem {
        id
      }
      auditHistory {
        ...ActionItemAuditHistoryFields
      }
      comments {
        ...RootCommentFields
      }
    }
  }
}
    ${ActionItemAuditHistoryFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${ActionItemAuditHistoryDeltaFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${PropertyTypeFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldUnitFields}
${FieldFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}
${RegrelloObjectInstanceFields}
${ActionItemAuditHistoryDocumentRelatedRecordFields}
${DocumentFields}
${DocumentVersionFields}
${TagFields}
${TagTypeFields}
${WorkflowPermissionsFields}
${ActionItemAuditHistoryFieldInstanceRelatedRecordFields}
${RootCommentFields}
${CommentFields}`;

/**
 * __useApprovalAttemptsForActionItemQuery__
 *
 * To run a query within a React component, call `useApprovalAttemptsForActionItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalAttemptsForActionItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalAttemptsForActionItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApprovalAttemptsForActionItemQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApprovalAttemptsForActionItemQuery,
    ApprovalAttemptsForActionItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApprovalAttemptsForActionItemQuery, ApprovalAttemptsForActionItemQueryVariables>(
    ApprovalAttemptsForActionItemQueryDocument,
    options,
  );
}
export function useApprovalAttemptsForActionItemQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApprovalAttemptsForActionItemQuery,
    ApprovalAttemptsForActionItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApprovalAttemptsForActionItemQuery, ApprovalAttemptsForActionItemQueryVariables>(
    ApprovalAttemptsForActionItemQueryDocument,
    options,
  );
}
export function useApprovalAttemptsForActionItemQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ApprovalAttemptsForActionItemQuery,
    ApprovalAttemptsForActionItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ApprovalAttemptsForActionItemQuery, ApprovalAttemptsForActionItemQueryVariables>(
    ApprovalAttemptsForActionItemQueryDocument,
    options,
  );
}
export type ApprovalAttemptsForActionItemQueryHookResult = ReturnType<typeof useApprovalAttemptsForActionItemQuery>;
export type ApprovalAttemptsForActionItemQueryLazyQueryHookResult = ReturnType<
  typeof useApprovalAttemptsForActionItemQueryLazyQuery
>;
export type ApprovalAttemptsForActionItemQuerySuspenseQueryHookResult = ReturnType<
  typeof useApprovalAttemptsForActionItemQuerySuspenseQuery
>;
export type ApprovalAttemptsForActionItemQueryResult = Apollo.QueryResult<
  ApprovalAttemptsForActionItemQuery,
  ApprovalAttemptsForActionItemQueryVariables
>;
