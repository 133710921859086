import type * as Types from "../../../generated/graphqlBaseTypes";

import type { DocumentBaseFields } from "./DocumentBaseFields.generated";
import { gql } from "@apollo/client";
export type AcyclicFieldInstanceMultiValueDocumentFields = {
  __typename: "FieldInstanceMultiValueDocument";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  documentMultiValue: Array<DocumentBaseFields>;
};

export const AcyclicFieldInstanceMultiValueDocumentFields = gql`
    fragment AcyclicFieldInstanceMultiValueDocumentFields on FieldInstanceMultiValueDocument {
  __typename
  id
  inputType
  documentMultiValue {
    ...DocumentBaseFields
  }
}
    `;
