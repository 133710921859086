import { t, Trans } from "@lingui/macro";
import { arrayRemoveAtIndex, EMPTY_ARRAY, EMPTY_STRING, sortIgnoreCase } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { type FieldUnit, type SpectrumValueConstraintFields, useFieldUnitsQueryLazyQuery } from "@regrello/graphql-api";
import {
  RegrelloButton,
  RegrelloCallout,
  RegrelloChip,
  RegrelloIcon,
  RegrelloTooltip,
  RegrelloTooltippedInfoIcon,
} from "@regrello/ui-core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, type UseFormReturn, useWatch } from "react-hook-form";

import { RegrelloDraggableRowItem } from "./RegrelloDraggableRowItem";
import { ValidationRules } from "../../../../../../constants/globalConstants";
import { FeatureFlagService } from "../../../../../../services/FeatureFlagService";
import { useErrorHandler } from "../../../../../../utils/hooks/useErrorHandler";
import { UserFieldPlugin } from "../../../../../molecules/customFields/plugins/UserFieldPlugin";
import { RegrelloFormFieldSelectOption } from "../../../../../molecules/formFields/_internal/selectOptions/RegrelloFormFieldSelectOption";
import {
  RegrelloControlledFormFieldSelect,
  RegrelloControlledFormFieldSwitch,
  RegrelloControlledFormFieldTextMultiline,
} from "../../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { SpectrumUserFieldPluginDecorator } from "../../../../../molecules/spectrumFields/SpectrumUserFieldPluginDecorator";
import type { SpectrumFieldPluginDecorator } from "../../../../../molecules/spectrumFields/types/SpectrumFieldPluginDecorator";
import {
  ALLOW_MULTIPLE_PARTY_ARG,
  FrontendValueConstraintRuleName,
  getNumberOfArgsByConstraint,
} from "../../../../../molecules/spectrumFields/utils/spectrumFieldConstraintUtils";

export interface ConfigureSpectrumFieldFormFormFields {
  name: string;
  helpText: string;
  pluginUri: string | undefined;
  allowedValues: Array<{ value: string }>;
  fieldUnit?: FieldUnit;
  isValueConstraintsEnabled: boolean;
  valueConstraints: Array<{
    constraint: SpectrumValueConstraintFields;
    args: string[];
  }>;
}

export type ConfigureSpectrumFieldFormProps = {
  spectrumFieldPlugins: Array<SpectrumFieldPluginDecorator<unknown>>;

  /** Callback that retrieves the full plugin class with the uri. */
  getPluginByUri: (pluginUri: string) => SpectrumFieldPluginDecorator<unknown> | undefined;

  /**
   * The name of the form field that will be focused when the dialog is open. Currently only "name"
   * and "helpText" is supported
   */
  focusField?: string;

  form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;

  loadedValueConstraints: SpectrumValueConstraintFields[];
} & (
  | {
      /**
       * Whether to show the dialog in "create" mode or "edit" mode. The field type will be editable
       * only in "create" mode.
       *
       * @default "create"
       */
      mode?: "create";

      /** The latest version number for this field. Will be displayed for reference. */
      versionNumber?: never;
    }
  | {
      /**
       * Whether to show the dialog in "create" mode or "edit" mode. The field type will be editable
       * only in "create" mode.
       *
       * @default "create"
       */
      mode: "edit";

      /** The latest version number for this field. Will be displayed for reference. */
      versionNumber: number;
    }
);

export const ConfigureSpectrumFieldForm = React.memo<ConfigureSpectrumFieldFormProps>(
  function ConfigureSpectrumFieldFormFn(props) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    const { getPluginByUri, spectrumFieldPlugins, form, loadedValueConstraints, focusField = "name" } = props;
    const { handleError } = useErrorHandler();
    const selectedPluginUri = useWatch({ control: form.control, name: "pluginUri" });
    const isValueConstraintsEnabled = useWatch({ control: form.control, name: "isValueConstraintsEnabled" });
    useWatch({ control: form.control });

    const selectedPlugin = useMemo(
      () => (selectedPluginUri != null ? getPluginByUri(selectedPluginUri) : undefined),
      [getPluginByUri, selectedPluginUri],
    );

    const isSelectedPluginUriNeedsFieldUnit = selectedPlugin?.isNeedsFieldUnit?.();
    const isSelectedPluginUriNeedsAllowedValues = selectedPlugin?.hasAllowedValues?.();

    const [getFieldUnitsAsync, fieldUnitsResult] = useFieldUnitsQueryLazyQuery({
      onError: () =>
        handleError(
          t`Unable to fetch data, please try again or contact a Regrello admin if you continue to encounter this problem`,
        ),
    });
    const fieldUnits = fieldUnitsResult?.data?.fieldUnits ?? EMPTY_ARRAY;

    useEffect(() => {
      if (isSelectedPluginUriNeedsFieldUnit) {
        void getFieldUnitsAsync();
      }
    }, [isSelectedPluginUriNeedsFieldUnit, getFieldUnitsAsync]);

    const {
      fields: allowedValueRows,
      append: appendAllowedValue,
      remove: removeAllowedValue,
      replace: replaceAllowedValues,
      move: moveAllowedValue,
    } = useFieldArray({
      control: form.control,
      name: "allowedValues",
    });

    const {
      fields: constraintRows,
      append: appendConstraint,
      update: updateConstraint,
      remove: removeConstraint,
    } = useFieldArray({
      control: form.control,
      name: "valueConstraints",
    });

    const updateApplicableConstraints = useCallback(
      (nextPlugin: SpectrumFieldPluginDecorator<unknown> | null) => {
        const applicableConstraints =
          nextPlugin?.findValueConstraintsFromLoadedValueConstraints(loadedValueConstraints) ?? EMPTY_ARRAY;

        // (hchen): Remove all previous constraints
        removeConstraint();

        appendConstraint(
          applicableConstraints.map((constraint) => {
            if (constraint.valueConstraintRule === FrontendValueConstraintRuleName.MAX_PARTY) {
              return {
                constraint,
                args: new Array(getNumberOfArgsByConstraint(constraint)).fill(ALLOW_MULTIPLE_PARTY_ARG),
              };
            }
            return {
              constraint,
              args: new Array(getNumberOfArgsByConstraint(constraint)).fill(EMPTY_STRING),
            };
          }),
        );
      },
      [appendConstraint, loadedValueConstraints, removeConstraint],
    );

    const sortedPluginUris = useMemo(() => {
      const creationAllowedFieldPlugins = spectrumFieldPlugins.filter((plugin) => plugin.isCreateAndEditAllowed);
      return sortIgnoreCase(creationAllowedFieldPlugins, (plugin) => plugin.getFieldDisplayName()).map(
        (plugin) => plugin.uri,
      );
    }, [spectrumFieldPlugins]);

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        // (dosipiuk): `meta` sends the form. `shift` allows to enter multi-line value.
        if (!event.metaKey && !event.shiftKey && event.key === "Enter" && isSelectedPluginUriNeedsAllowedValues) {
          appendAllowedValue({ value: "" });
          event.preventDefault();
        }
      },
      [appendAllowedValue, isSelectedPluginUriNeedsAllowedValues],
    );

    // (hchen): Add a input field at the first time a select type is chosen. This is not only a UX
    // nicety but also necessary because there must be at least one option for the select types.
    useEffect(() => {
      if (isSelectedPluginUriNeedsAllowedValues && allowedValueRows.length === 0) {
        appendAllowedValue({ value: "" });
      }
    }, [allowedValueRows.length, appendAllowedValue, isSelectedPluginUriNeedsAllowedValues]);

    const renderFieldUnitOption = useCallback((fieldUnit: FieldUnit) => {
      return (
        <RegrelloFormFieldSelectOption
          mainSnippets={[{ highlight: false, text: getFieldUnitDisplayName(fieldUnit) }]}
        />
      );
    }, []);

    const renderPluginOption = useCallback(
      (spectrumFieldUri?: string | null) => {
        if (spectrumFieldUri == null) {
          return null;
        }

        const plugin = getPluginByUri(spectrumFieldUri);
        if (plugin == null) {
          return null;
        }
        return (
          <RegrelloFormFieldSelectOption
            mainSnippets={[{ highlight: false, text: plugin.getFieldDisplayName() }]}
            startAdornment={
              <div className="mr-1">
                <RegrelloIcon iconName={plugin.getIconName()} />
              </div>
            }
          />
        );
      },
      [getPluginByUri],
    );

    const renderPluginConstraints = useCallback(() => {
      const shouldDisableConstraintForUserField =
        selectedPlugin?.uri === new SpectrumUserFieldPluginDecorator(UserFieldPlugin).uri && props.mode === "edit";
      return selectedPlugin?.renderValueConstraints({
        constraints: constraintRows,
        disabled: !(selectedPlugin?.isCreateAndEditAllowed ?? true) || shouldDisableConstraintForUserField,
        form,
        focusField: focusField as `valueConstraints.${number}.args.${number}`,
        updateConstraint,
      });
    }, [constraintRows, focusField, form, props.mode, selectedPlugin, updateConstraint]);

    const handlePluginChange = useCallback(
      (_name: string, nextPluginUri: string | null) => {
        const nextPlugin = (nextPluginUri != null ? getPluginByUri(nextPluginUri) : null) ?? null;
        if (selectedPlugin?.hasAllowedValues?.() && !nextPlugin?.hasAllowedValues?.()) {
          form.resetField("allowedValues", { defaultValue: EMPTY_ARRAY });
        }
        if (selectedPlugin?.isNeedsFieldUnit?.() && !nextPlugin?.isNeedsFieldUnit?.()) {
          form.resetField("fieldUnit", { defaultValue: undefined });
        }
        updateApplicableConstraints(nextPlugin);
      },
      [form, getPluginByUri, selectedPlugin, updateApplicableConstraints],
    );

    useEffect(() => {
      // (hchen): If the form has default values, don't try to reset the applicable constraints with
      // `handlePluginChange`
      const valueConstraints = form.getValues("valueConstraints");
      if (valueConstraints?.length > 0) {
        return;
      }

      const pluginUri = form.getValues("pluginUri");
      if (pluginUri == null) {
        return;
      }
      // eslint-disable-next-line lingui/no-unlocalized-strings
      handlePluginChange("pluginUri", pluginUri);
    }, [form, handlePluginChange]);

    const [isAddingViaPaste, setIsAddingViaPaste] = useState(false);

    const handleSelectOptionPaste = useCallback(
      (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
        const pastedText = event.clipboardData.getData("text");
        if (pastedText == null) {
          return;
        }
        if (pastedText.split("\n").length > 1) {
          setIsAddingViaPaste(true);
          // Insert all pasted values as new options.
          let nextAllowedValues: Array<{ value: string }> = [];
          const currAllowedValues = form.getValues("allowedValues");
          if (event.currentTarget.value.length === 0) {
            // (clewis): Prevent default to avoid pasting the text into this field. Instead, remove
            // this field because we're about to add N new options below it.
            event.preventDefault();
            nextAllowedValues = arrayRemoveAtIndex(currAllowedValues, index);
          }

          // (clewis): Try not to block the UI thread when pasting lots of values. The real fix here
          // would be virtualized rendering, but this is a rare enough case that I'm not
          // implementing that here yet.
          requestAnimationFrame(() => {
            const pastedLines = pastedText.split("\n");
            const pastedAllowedValues = pastedLines.map((value) => ({
              value: value.trim(),
            }));
            nextAllowedValues.push(...pastedAllowedValues);
            replaceAllowedValues(nextAllowedValues);
            setIsAddingViaPaste(false);
          });
        }
      },
      [form, replaceAllowedValues],
    );

    const alphabetizer = useAlphabetizer({ form });

    // (clewis): Don't use useMemo here because values can be stale in allowedValueRows.
    const latestAllowedValueRows = form.getValues("allowedValues");

    const infoIcon = (
      <span className="align-bottom">
        <RegrelloTooltippedInfoIcon
          inline={true}
          isDefaultMarginsOmitted={true}
          tooltipText={t`Field versions are pinned within a blueprint when that blueprint is published. In all other contexts, the latest field version will be used.`}
        />
      </span>
    );

    return (
      <div onKeyDown={onKeyDown}>
        {FeatureFlagService.isEnabled(FeatureFlagKey.SPECTRUM_VERSION_AWARENESS_IN_UI) && (
          <RegrelloCallout
            className="mb-4"
            size="small"
            text={(() => {
              if (props.mode === "edit") {
                const currentVersion = props.versionNumber;
                const nextVersion = props.versionNumber + 1;

                return (
                  <Trans>
                    This field is on version {currentVersion}. Saving will create version {nextVersion}. {infoIcon}
                  </Trans>
                );
              }

              return undefined;
            })()}
          />
        )}

        {/* Field name */}
        <RegrelloControlledFormFieldText
          autoFocus={focusField === "name"}
          controllerProps={{
            control: form.control,
            name: "name",
            rules: ValidationRules.REQUIRED,
          }}
          dataTestId={DataTestIds.CONFIGURE_SPECTRUM_FIELD_DIALOG_NAME}
          disabled={!(selectedPlugin?.isCreateAndEditAllowed ?? true)}
          isRequiredAsteriskShown={true}
          label={t`Name`}
        />

        {/* Field helper text */}
        <RegrelloControlledFormFieldTextMultiline
          autoFocus={focusField === "helpText"}
          controllerProps={{
            control: form.control,
            name: "helpText",
          }}
          dataTestId={DataTestIds.CONFIGURE_SPECTRUM_FIELD_DIALOG_HELPER_TEXT}
          disabled={!(selectedPlugin?.isCreateAndEditAllowed ?? true)}
          label={t`Helper text`}
        />

        {/* Field type */}
        <RegrelloControlledFormFieldSelect
          controllerProps={{
            control: form.control,
            name: "pluginUri",
            rules: ValidationRules.REQUIRED,
          }}
          dataTestId={DataTestIds.CONFIGURE_SPECTRUM_FIELD_DIALOG_FIELD_TYPE}
          disabled={props.mode === "edit"}
          getOptionLabel={(option) => {
            return getPluginByUri(option)?.getFieldDisplayName() ?? EMPTY_STRING;
          }}
          helperText={selectedPlugin?.getHelperText?.() ?? t`e.g. Date, Text, Document`}
          isRequiredAsteriskShown={true}
          label={t`Type`}
          onValueChange={handlePluginChange}
          options={sortedPluginUris}
          renderOption={renderPluginOption}
          renderSelectedValue={renderPluginOption}
        />

        {/* (If needed) Allowed values */}
        {isSelectedPluginUriNeedsAllowedValues && (
          <>
            {allowedValueRows.map((row, index) => {
              return (
                <div key={row.id} className="pl-20">
                  <RegrelloDraggableRowItem
                    index={index}
                    isDragEnabled={true}
                    moveRow={moveAllowedValue}
                    preview={<RegrelloChip>{row.value}</RegrelloChip>}
                    row={row}
                  >
                    <div className="flex flex-1 items-start">
                      <RegrelloControlledFormFieldTextMultiline
                        className="flex-1 mr-2"
                        controllerProps={{
                          control: form.control,
                          name: `allowedValues.${index}.value`,
                          rules: {
                            ...ValidationRules.REQUIRED,
                            ...ValidationRules.UNIQUE_IN({
                              otherValues: arrayRemoveAtIndex(latestAllowedValueRows, index).map(({ value }) => value),
                            }),
                          },
                        }}
                        dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_ALLOWED_VALUE}
                        helperText={
                          index === 0
                            ? t`Enter a value or create multiple by pasting text that has multiple lines.`
                            : undefined
                        }
                        isDefaultMarginsOmitted={index !== allowedValueRows.length - 1}
                        minimumRowCount={1}
                        onPaste={(event) => handleSelectOptionPaste(event, index)}
                      />

                      {/* Delete */}
                      <RegrelloButton
                        dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_DELETE_OPTION_BUTTON}
                        disabled={index === 0 && allowedValueRows.length === 1}
                        iconOnly={true}
                        intent="neutral"
                        onClick={() => removeAllowedValue(index)}
                        startIcon="close"
                        variant="ghost"
                      />
                    </div>
                  </RegrelloDraggableRowItem>
                </div>
              );
            })}

            {/* Add */}
            <div className="ml-26 flex justify-between">
              <RegrelloButton
                dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_ADD_OPTION_BUTTON}
                disabled={!selectedPlugin?.isCreateAndEditAllowed}
                intent="primary"
                loading={isAddingViaPaste}
                onClick={() => appendAllowedValue({ value: EMPTY_STRING })}
                startIcon="add"
                variant="ghost"
              >
                {t`Add option`}
              </RegrelloButton>

              {/* UX Nicety: Provide this button that alphabetizes the options on click. */}
              {selectedPlugin?.isCreateAndEditAllowed && allowedValueRows.length > 1 && (
                // (clewis): Put the margin on this outer div so that align="end" aligns properly.
                <div className="mr-11">
                  {alphabetizer.canUndo ? (
                    <RegrelloButton
                      loading={alphabetizer.isSorting}
                      onClick={alphabetizer.undo}
                      startIcon="undo"
                      variant="ghost"
                    >
                      {t`Undo alphabetize`}
                    </RegrelloButton>
                  ) : (
                    <RegrelloTooltip
                      align="end"
                      content={alphabetizer.isSorted ? t`Options are already alphabetized` : undefined}
                      maxWidth={260}
                      side="top"
                    >
                      {/* (clewis): This span is necessary for the tooltip to work when the button is disabled. */}
                      <div>
                        <RegrelloButton
                          disabled={alphabetizer.isSorted}
                          loading={alphabetizer.isSorting}
                          onClick={alphabetizer.sort}
                          startIcon="text-field"
                          variant="ghost"
                        >
                          {t`Alphabetize options`}
                        </RegrelloButton>
                      </div>
                    </RegrelloTooltip>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        {/* (If needed) Field unit */}
        {isSelectedPluginUriNeedsFieldUnit && (
          <RegrelloControlledFormFieldSelect
            controllerProps={{
              control: form.control,
              name: "fieldUnit",
              rules: ValidationRules.REQUIRED,
            }}
            dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_UNIT}
            getOptionLabel={(fieldUnit) => getFieldUnitDisplayName(fieldUnit)}
            isRequiredAsteriskShown={true}
            label={t`Currency symbol`}
            options={fieldUnits}
            renderOption={renderFieldUnitOption}
          />
        )}

        {selectedPlugin?.isValueConstraintEnabled() && selectedPlugin?.isDataFormatToggleVisible() && (
          <RegrelloControlledFormFieldSwitch
            className="ml-2"
            controllerProps={{
              control: form.control,
              name: "isValueConstraintsEnabled",
            }}
            dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_DATA_FORMAT_SWITCH}
            disabled={!(selectedPlugin?.isCreateAndEditAllowed ?? true)}
            label={EMPTY_STRING}
            secondaryLabel={t`Data format`}
          />
        )}
        {/* show the value constraints section if data format toggle is visible and checked, or if data format toggle is not visible but value constraints exist */}
        {((selectedPlugin?.isDataFormatToggleVisible() && isValueConstraintsEnabled) ||
          (!selectedPlugin?.isDataFormatToggleVisible() && selectedPlugin?.isValueConstraintEnabled())) &&
          renderPluginConstraints()}
      </div>
    );
  },
);

function getFieldUnitDisplayName(fieldUnit: FieldUnit) {
  return `${fieldUnit.symbol} ${fieldUnit.name}`;
}

function allowUnusedVariable(_value: unknown) {
  return;
}

/**
 * Hook that manages behaviors for sorting options into alphatical order - and undoing this
 * operation after the fact.
 */
function useAlphabetizer({ form }: { form: UseFormReturn<ConfigureSpectrumFieldFormFormFields> }) {
  const [isSorted, setIsSorted] = useState(false);
  const [isSorting, setIsSorting] = useState(false);
  const [canUndo, setCanUndoSort] = useState(false);
  const [unsortedAllowedValues, setUnsortedAllowedValues] = useState<Array<{ value: string }> | null>(null);

  const numInitialRerendersSinceSortRef = useRef<number>(0);

  const allowedValues = useWatch({ control: form.control, name: "allowedValues" });

  useEffect(() => {
    allowUnusedVariable(allowedValues); // Allow us to watch formWatch without lint complaining.

    // HACKHACK (clewis): The component will re-render *twice* after the initial sort operation. We
    // want to invoke setCanUndoSort only on user edits that happen after these first two
    // re-renders.
    if (numInitialRerendersSinceSortRef.current < 2) {
      numInitialRerendersSinceSortRef.current += 1;
    } else {
      setCanUndoSort(false);
    }

    setIsSorted(
      allowedValues.every(
        (option, index) => index === 0 || option.value.localeCompare(allowedValues[index - 1].value) >= 0,
      ),
    );
  }, [allowedValues]);

  const sort = useCallback(() => {
    // (clewis): Use requestAnimationFrame to avoid blocking the UI if there are many options.
    setIsSorting(true);
    requestAnimationFrame(() => {
      const currentOptions = form.getValues("allowedValues"); // Get the latest value, else there can be weird bugs.
      setUnsortedAllowedValues([...currentOptions]);
      const sortedOptions = sortIgnoreCase(currentOptions, (option) => option.value);
      numInitialRerendersSinceSortRef.current = 0;
      form.setValue("allowedValues", sortedOptions);
      setIsSorting(false);
      setCanUndoSort(true);
    });
  }, [form]);

  const undo = useCallback(() => {
    setCanUndoSort(false);
    if (unsortedAllowedValues == null) {
      console.warn("Cannot undo sort because unsortedAllowedValues is null.");
      return;
    }
    form.setValue("allowedValues", unsortedAllowedValues);
    setUnsortedAllowedValues(null);
    return null;
  }, [form, unsortedAllowedValues]);

  return {
    canUndo,
    isSorted,
    isSorting,
    sort,
    undo,
  };
}
