import type * as Types from "../../../generated/graphqlBaseTypes";

import type { AutomationOAuth2ConfigFields } from "./AutomationOAuth2ConfigFields.generated";
import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import type { PropertyTypeFields } from "./PropertyTypeFields.generated";
import type { RegrelloObjectPropertyFields } from "./RegrelloObjectPropertyFields.generated";
import { gql } from "@apollo/client";
export type RegrelloObjectFields = {
  id: number;
  name: string;
  type: Types.RegrelloObjectType;
  createdAt: string;
  updatedAt: string;
  dataUpdatedAt?: string | null;
  deletedAt?: string | null;
  syncConfig?: {
    source: Types.RegrelloObjectSource;
    isSyncEnabled: boolean;
    httpRequestConfig?: {
      id: number;
      url: string;
      method: Types.AutomationRequestMethod;
      authorizationType: Types.AuthorizationType;
      createNewUsersFromResponse: boolean;
      token?: string | null;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
      oauth2Config?: AutomationOAuth2ConfigFields | null;
      queryParams: Array<{
        id: number;
        key: string;
        valueType: Types.AutomationRequestParamValueType;
        location: Types.AutomationRequestParamLocation;
        valueV2?:
          | { valueFloat?: number | null }
          | { valueInt?: number | null }
          | { valueString?: string | null }
          | null;
      }>;
      headerParams: Array<{
        id: number;
        key: string;
        valueType: Types.AutomationRequestParamValueType;
        location: Types.AutomationRequestParamLocation;
        valueV2?:
          | { valueFloat?: number | null }
          | { valueInt?: number | null }
          | { valueString?: string | null }
          | null;
      }>;
      formDataParams: Array<{
        id: number;
        key: string;
        valueType: Types.AutomationRequestParamValueType;
        location: Types.AutomationRequestParamLocation;
        valueV2?:
          | { valueFloat?: number | null }
          | { valueInt?: number | null }
          | { valueString?: string | null }
          | null;
      }>;
      jsonDataParams: Array<{
        id: number;
        key: string;
        valueType: Types.AutomationRequestParamValueType;
        location: Types.AutomationRequestParamLocation;
        valueV2?:
          | { valueFloat?: number | null }
          | { valueInt?: number | null }
          | { valueString?: string | null }
          | null;
      }>;
      responseJsonExtractorConfigs: Array<{
        id: number;
        jsonExpression: string;
        field: {
          id: number;
          name: string;
          isMultiValued: boolean;
          fieldType: Types.FieldType;
          allowedValues: Array<
            | { __typename: "FieldAllowedValueParty"; id: number; displayOrder: number; partyValue: PartyBaseFields }
            | { __typename: "FieldAllowedValueString"; id: number; displayOrder: number; stringValue: string }
          >;
          propertyType: PropertyTypeFields;
        };
      }>;
      paginationConfig?: {
        paginationType: Types.PaginationType;
        limit?: number | null;
        initialOffset?: number | null;
      } | null;
    } | null;
    scheduledJob?: {
      id: number;
      type: Types.ScheduledJobType;
      interval: Types.ScheduledJobInterval;
      lastRunAt?: string | null;
      nextRunAt: string;
      runAt?: string | null;
      createdAt: string;
      updatedAt: string;
      errorNotificationConfig?: {
        id: number;
        notificationType: Types.ScheduledJobErrorNotificationType;
        createdAt: string;
        updatedAt: string;
        notifyParties: Array<{ id: number; user?: UserBaseFields | null }>;
      } | null;
    } | null;
  } | null;
  syncEvents: Array<{
    id: number;
    startedAt: string;
    finishedAt?: string | null;
    action: Types.SyncEventAction;
    status: Types.SyncEventStatus;
    rowsCreated: number;
    rowsUpdated: number;
    rowsDeleted: number;
    dataSourceUrl?: string | null;
    triggerType?: Types.RegrelloObjectSyncEventTriggerType | null;
    errorType?: Types.RegrelloObjectSyncEventErrorType | null;
    error?: string | null;
    document?: { id: number; name: string; currentVersion: { id: number } } | null;
    triggeredBy: UserBaseFields;
  }>;
  createdBy: UserBaseFields;
  properties: Array<RegrelloObjectPropertyFields>;
};

export const RegrelloObjectFields = gql`
    fragment RegrelloObjectFields on RegrelloObject {
  id
  name
  type
  syncConfig {
    source
    isSyncEnabled
    httpRequestConfig {
      id
      url
      method
      authorizationType
      createNewUsersFromResponse
      token
      oauth2Config {
        ...AutomationOAuth2ConfigFields
      }
      queryParams {
        id
        key
        valueType
        valueV2 {
          ... on AutomationRequestParamValueFloat {
            valueFloat
          }
          ... on AutomationRequestParamValueInt {
            valueInt
          }
          ... on AutomationRequestParamValueString {
            valueString
          }
        }
        location
      }
      headerParams {
        id
        key
        valueType
        valueV2 {
          ... on AutomationRequestParamValueFloat {
            valueFloat
          }
          ... on AutomationRequestParamValueInt {
            valueInt
          }
          ... on AutomationRequestParamValueString {
            valueString
          }
        }
        location
      }
      formDataParams {
        id
        key
        valueType
        valueV2 {
          ... on AutomationRequestParamValueFloat {
            valueFloat
          }
          ... on AutomationRequestParamValueInt {
            valueInt
          }
          ... on AutomationRequestParamValueString {
            valueString
          }
        }
        location
      }
      jsonDataParams {
        id
        key
        valueType
        valueV2 {
          ... on AutomationRequestParamValueFloat {
            valueFloat
          }
          ... on AutomationRequestParamValueInt {
            valueInt
          }
          ... on AutomationRequestParamValueString {
            valueString
          }
        }
        location
      }
      responseJsonExtractorConfigs {
        id
        jsonExpression
        field {
          id
          name
          isMultiValued
          fieldType
          allowedValues {
            ... on FieldAllowedValueString {
              __typename
              id
              displayOrder
              stringValue
            }
            ... on FieldAllowedValueParty {
              __typename
              id
              displayOrder
              partyValue {
                ...PartyBaseFields
              }
            }
          }
          propertyType {
            ...PropertyTypeFields
          }
        }
      }
      paginationConfig {
        paginationType
        limit
        initialOffset
      }
      createdAt
      updatedAt
      deletedAt
    }
    scheduledJob {
      id
      type
      interval
      lastRunAt
      nextRunAt
      runAt
      createdAt
      updatedAt
      errorNotificationConfig {
        id
        notificationType
        notifyParties {
          id
          user {
            ...UserBaseFields
          }
        }
        createdAt
        updatedAt
      }
    }
  }
  syncEvents(limit: 2) {
    id
    startedAt
    finishedAt
    action
    status
    rowsCreated
    rowsUpdated
    rowsDeleted
    dataSourceUrl
    document {
      id
      name
      currentVersion {
        id
      }
    }
    triggerType
    errorType
    error
    triggeredBy {
      ...UserBaseFields
    }
  }
  createdBy {
    ...UserBaseFields
  }
  properties {
    ...RegrelloObjectPropertyFields
  }
  createdAt
  updatedAt
  dataUpdatedAt
  deletedAt
}
    `;
