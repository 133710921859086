import type * as Types from "../../../generated/graphqlBaseTypes";

import { WorkflowFields } from "../../fragments/generated/WorkflowFields.generated";
import { NameTemplateWithSuffixFields } from "../../fragments/generated/NameTemplateWithSuffixFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { WorkflowStageFields } from "../../fragments/generated/WorkflowStageFields.generated";
import { WorkflowQueryActionItemTemplateFields } from "../../fragments/generated/WorkflowQueryActionItemTemplateFields.generated";
import { WorkflowQueryActionItemFields } from "../../fragments/generated/WorkflowQueryActionItemFields.generated";
import { AutomationRequestFields } from "../../fragments/generated/AutomationRequestFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { AutomationRequestParamFields } from "../../fragments/generated/AutomationRequestParamFields.generated";
import { FieldInstanceFields } from "../../fragments/generated/FieldInstanceFields.generated";
import { FieldInstanceBaseFields } from "../../fragments/generated/FieldInstanceBaseFields.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { FieldInstanceSpectrumMetadataFields } from "../../fragments/generated/FieldInstanceSpectrumMetadataFields.generated";
import { FieldInstanceSpectrumFields } from "../../fragments/generated/FieldInstanceSpectrumFields.generated";
import { SpectrumFieldVersionFields } from "../../fragments/generated/SpectrumFieldVersionFields.generated";
import { SpectrumFieldFields } from "../../fragments/generated/SpectrumFieldFields.generated";
import { SpectrumFieldValidationTypeFields } from "../../fragments/generated/SpectrumFieldValidationTypeFields.generated";
import { SpectrumFieldConstraintFields } from "../../fragments/generated/SpectrumFieldConstraintFields.generated";
import { SpectrumValueConstraintFields } from "../../fragments/generated/SpectrumValueConstraintFields.generated";
import { SpectrumFieldAllowedValueFields } from "../../fragments/generated/SpectrumFieldAllowedValueFields.generated";
import { FieldInstanceValueStringFields } from "../../fragments/generated/FieldInstanceValueStringFields.generated";
import { FieldInstanceValueStringBaseFields } from "../../fragments/generated/FieldInstanceValueStringBaseFields.generated";
import { AcyclicFieldInstanceValueStringFields } from "../../fragments/generated/AcyclicFieldInstanceValueStringFields.generated";
import { FieldInstanceMultiValueStringFields } from "../../fragments/generated/FieldInstanceMultiValueStringFields.generated";
import { FieldInstanceMultiValueStringBaseFields } from "../../fragments/generated/FieldInstanceMultiValueStringBaseFields.generated";
import { AcyclicFieldInstanceMultiValueStringFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueStringFields.generated";
import { FieldInstanceValueIntFields } from "../../fragments/generated/FieldInstanceValueIntFields.generated";
import { FieldInstanceValueIntBaseFields } from "../../fragments/generated/FieldInstanceValueIntBaseFields.generated";
import { AcyclicFieldInstanceValueIntFields } from "../../fragments/generated/AcyclicFieldInstanceValueIntFields.generated";
import { FieldInstanceValueFloatFields } from "../../fragments/generated/FieldInstanceValueFloatFields.generated";
import { FieldInstanceValueFloatBaseFields } from "../../fragments/generated/FieldInstanceValueFloatBaseFields.generated";
import { AcyclicFieldInstanceValueFloatFields } from "../../fragments/generated/AcyclicFieldInstanceValueFloatFields.generated";
import { FieldInstanceValueBooleanFields } from "../../fragments/generated/FieldInstanceValueBooleanFields.generated";
import { FieldInstanceValueBooleanBaseFields } from "../../fragments/generated/FieldInstanceValueBooleanBaseFields.generated";
import { AcyclicFieldInstanceValueBooleanFields } from "../../fragments/generated/AcyclicFieldInstanceValueBooleanFields.generated";
import { FieldInstanceValueTimeFields } from "../../fragments/generated/FieldInstanceValueTimeFields.generated";
import { FieldInstanceValueTimeBaseFields } from "../../fragments/generated/FieldInstanceValueTimeBaseFields.generated";
import { AcyclicFieldInstanceValueTimeFields } from "../../fragments/generated/AcyclicFieldInstanceValueTimeFields.generated";
import { AcyclicFieldInstanceFields } from "../../fragments/generated/AcyclicFieldInstanceFields.generated";
import { AcyclicFieldInstanceValuePartyFields } from "../../fragments/generated/AcyclicFieldInstanceValuePartyFields.generated";
import { AcyclicFieldInstanceMultiValuePartyFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValuePartyFields.generated";
import { AcyclicFieldInstanceMultiValueDocumentFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueDocumentFields.generated";
import { DocumentBaseFields } from "../../fragments/generated/DocumentBaseFields.generated";
import { TagFields } from "../../fragments/generated/TagFields.generated";
import { TagTypeFields } from "../../fragments/generated/TagTypeFields.generated";
import { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { RegrelloObjectInstanceFields } from "../../fragments/generated/RegrelloObjectInstanceFields.generated";
import { AcyclicFieldInstanceMultiValueIntFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueIntFields.generated";
import { AcyclicFieldInstanceMultiValueFloatFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueFloatFields.generated";
import { AcyclicFieldInstanceMultiValueTimeFields } from "../../fragments/generated/AcyclicFieldInstanceMultiValueTimeFields.generated";
import { FieldInstanceValuePartyFields } from "../../fragments/generated/FieldInstanceValuePartyFields.generated";
import { FieldInstanceMultiValuePartyFields } from "../../fragments/generated/FieldInstanceMultiValuePartyFields.generated";
import { FieldInstanceMultiValueDocumentFields } from "../../fragments/generated/FieldInstanceMultiValueDocumentFields.generated";
import { DocumentFields } from "../../fragments/generated/DocumentFields.generated";
import { DocumentVersionFields } from "../../fragments/generated/DocumentVersionFields.generated";
import { WorkflowPermissionsFields } from "../../fragments/generated/WorkflowPermissionsFields.generated";
import { FieldInstanceMultiValueRegrelloObjectInstanceFields } from "../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import { FieldInstanceMultiValueIntFields } from "../../fragments/generated/FieldInstanceMultiValueIntFields.generated";
import { FieldInstanceMultiValueIntBaseFields } from "../../fragments/generated/FieldInstanceMultiValueIntBaseFields.generated";
import { FieldInstanceMultiValueFloatFields } from "../../fragments/generated/FieldInstanceMultiValueFloatFields.generated";
import { FieldInstanceMultiValueFloatBaseFields } from "../../fragments/generated/FieldInstanceMultiValueFloatBaseFields.generated";
import { FieldInstanceMultiValueTimeFields } from "../../fragments/generated/FieldInstanceMultiValueTimeFields.generated";
import { FieldInstanceMultiValueTimeBaseFields } from "../../fragments/generated/FieldInstanceMultiValueTimeBaseFields.generated";
import { ActionItemApprovalFields } from "../../fragments/generated/ActionItemApprovalFields.generated";
import { ActionItemAuditHistoryFields } from "../../fragments/generated/ActionItemAuditHistoryFields.generated";
import { ActionItemAuditHistoryDeltaFields } from "../../fragments/generated/ActionItemAuditHistoryDeltaFields.generated";
import { ActionItemAuditHistoryDocumentRelatedRecordFields } from "../../fragments/generated/ActionItemAuditHistoryDocumentRelatedRecordFields.generated";
import { ActionItemAuditHistoryFieldInstanceRelatedRecordFields } from "../../fragments/generated/ActionItemAuditHistoryFieldInstanceRelatedRecordFields.generated";
import { RootCommentFields } from "../../fragments/generated/RootCommentFields.generated";
import { CommentFields } from "../../fragments/generated/CommentFields.generated";
import { IntegrationAutomationInstanceFields } from "../../fragments/generated/IntegrationAutomationInstanceFields.generated";
import { IntegrationAutomationSummaryFields } from "../../fragments/generated/IntegrationAutomationSummaryFields.generated";
import { IntegrationAuthenticationFields } from "../../fragments/generated/IntegrationAuthenticationFields.generated";
import { IntegrationAutomationInputFields } from "../../fragments/generated/IntegrationAutomationInputFields.generated";
import { IntegrationAutomationOutputFields } from "../../fragments/generated/IntegrationAutomationOutputFields.generated";
import { ActionItemPermissionsFields } from "../../fragments/generated/ActionItemPermissionsFields.generated";
import { LineFields } from "../../fragments/generated/LineFields.generated";
import { WorkflowQueryChildActionItemTemplateFields } from "../../fragments/generated/WorkflowQueryChildActionItemTemplateFields.generated";
import { ActionItemTemplatePermissionsFields } from "../../fragments/generated/ActionItemTemplatePermissionsFields.generated";
import { ActionItemTemplateStartAfterActionItemTemplateChain } from "../../fragments/generated/ActionItemTemplateStartAfterActionItemTemplateChain.generated";
import { ActionItemTemplateStartAfterActionItemTemplateFields } from "../../fragments/generated/ActionItemTemplateStartAfterActionItemTemplateFields.generated";
import { RejectActionFields } from "../../fragments/generated/RejectActionFields.generated";
import { NameTemplateFields } from "../../fragments/generated/NameTemplateFields.generated";
import { ConditionalExpressionGroupFields } from "../../fragments/generated/ConditionalExpressionGroupFields.generated";
import { FieldInstanceConditionalExpressionFields } from "../../fragments/generated/FieldInstanceConditionalExpressionFields.generated";
import { FieldInstanceWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceWithCrossWorkflowFields.generated";
import { FieldInstanceValueStringWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueStringWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueStringWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueStringWithCrossWorkflowFields.generated";
import { FieldInstanceValueIntWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueIntWithCrossWorkflowFields.generated";
import { FieldInstanceValueFloatWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueFloatWithCrossWorkflowFields.generated";
import { FieldInstanceValueBooleanWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueBooleanWithCrossWorkflowFields.generated";
import { FieldInstanceValueTimeWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueTimeWithCrossWorkflowFields.generated";
import { FieldInstanceValuePartyWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValuePartyWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValuePartyWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValuePartyWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueDocumentWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueDocumentWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueIntWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueIntWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueFloatWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueFloatWithCrossWorkflowFields.generated";
import { FieldInstanceMultiValueTimeWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceMultiValueTimeWithCrossWorkflowFields.generated";
import { WorkflowCollaborationFields } from "../../fragments/generated/WorkflowCollaborationFields.generated";
import { PartyFields } from "../../fragments/generated/PartyFields.generated";
import { UserFields } from "../../fragments/generated/UserFields.generated";
import { UserFieldsWithoutRoles } from "../../fragments/generated/UserFieldsWithoutRoles.generated";
import { AccessRoleFields } from "../../fragments/generated/AccessRoleFields.generated";
import { StarWithoutEntityFields } from "../../fragments/generated/StarWithoutEntityFields.generated";
import { WorkflowGraphFields } from "../../fragments/generated/WorkflowGraphFields.generated";
import { WorkflowGraphStageFields } from "../../fragments/generated/WorkflowGraphStageFields.generated";
import { GenericWorkflowStageFields } from "../../fragments/generated/GenericWorkflowStageFields.generated";
import { GenericWorkflowActionItemFields } from "../../fragments/generated/GenericWorkflowActionItemFields.generated";
import { WorkflowGraphActionItemFields } from "../../fragments/generated/WorkflowGraphActionItemFields.generated";
import { NotificationCardBaseFields } from "../../fragments/generated/NotificationCardBaseFields.generated";
import { gql } from "@apollo/client";
import { FieldInstanceValueFields } from "../../fragments/generated/FieldInstanceValueFields.generated";
import { AcyclicFieldInstanceValueFields } from "../../fragments/generated/AcyclicFieldInstanceValueFields.generated";
import { FieldInstanceValueWithCrossWorkflowFields } from "../../fragments/generated/FieldInstanceValueWithCrossWorkflowFields.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type WorkflowQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  referenceNumber?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type WorkflowQuery = { workflows: Array<WorkflowFields> };

export const WorkflowQueryDocument = gql`
    query WorkflowQuery($id: ID, $referenceNumber: String) {
  workflows(id: $id, referenceNumber: $referenceNumber) {
    ...WorkflowFields
  }
}
    ${WorkflowFields}
${NameTemplateWithSuffixFields}
${UserBaseFields}
${PartyBaseFields}
${TeamWithoutMembersFields}
${WorkflowStageFields}
${WorkflowQueryActionItemTemplateFields}
${WorkflowQueryActionItemFields}
${AutomationRequestFields}
${AutomationOAuth2ConfigFields}
${AutomationRequestParamFields}
${FieldInstanceFields}
${FieldInstanceBaseFields}
${FieldFields}
${PropertyTypeFields}
${FieldUnitFields}
${RegrelloObjectFields}
${RegrelloObjectPropertyFields}
${FieldInstanceSpectrumMetadataFields}
${FieldInstanceSpectrumFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldInstanceValueFields}
${FieldInstanceValueStringFields}
${FieldInstanceValueStringBaseFields}
${AcyclicFieldInstanceValueStringFields}
${FieldInstanceMultiValueStringFields}
${FieldInstanceMultiValueStringBaseFields}
${AcyclicFieldInstanceMultiValueStringFields}
${FieldInstanceValueIntFields}
${FieldInstanceValueIntBaseFields}
${AcyclicFieldInstanceValueIntFields}
${FieldInstanceValueFloatFields}
${FieldInstanceValueFloatBaseFields}
${AcyclicFieldInstanceValueFloatFields}
${FieldInstanceValueBooleanFields}
${FieldInstanceValueBooleanBaseFields}
${AcyclicFieldInstanceValueBooleanFields}
${FieldInstanceValueTimeFields}
${FieldInstanceValueTimeBaseFields}
${AcyclicFieldInstanceValueTimeFields}
${AcyclicFieldInstanceFields}
${AcyclicFieldInstanceValueFields}
${AcyclicFieldInstanceValuePartyFields}
${AcyclicFieldInstanceMultiValuePartyFields}
${AcyclicFieldInstanceMultiValueDocumentFields}
${DocumentBaseFields}
${TagFields}
${TagTypeFields}
${AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields}
${RegrelloObjectInstanceFields}
${AcyclicFieldInstanceMultiValueIntFields}
${AcyclicFieldInstanceMultiValueFloatFields}
${AcyclicFieldInstanceMultiValueTimeFields}
${FieldInstanceValuePartyFields}
${FieldInstanceMultiValuePartyFields}
${FieldInstanceMultiValueDocumentFields}
${DocumentFields}
${DocumentVersionFields}
${WorkflowPermissionsFields}
${FieldInstanceMultiValueRegrelloObjectInstanceFields}
${FieldInstanceMultiValueIntFields}
${FieldInstanceMultiValueIntBaseFields}
${FieldInstanceMultiValueFloatFields}
${FieldInstanceMultiValueFloatBaseFields}
${FieldInstanceMultiValueTimeFields}
${FieldInstanceMultiValueTimeBaseFields}
${ActionItemApprovalFields}
${ActionItemAuditHistoryFields}
${ActionItemAuditHistoryDeltaFields}
${ActionItemAuditHistoryDocumentRelatedRecordFields}
${ActionItemAuditHistoryFieldInstanceRelatedRecordFields}
${RootCommentFields}
${CommentFields}
${IntegrationAutomationInstanceFields}
${IntegrationAutomationSummaryFields}
${IntegrationAuthenticationFields}
${IntegrationAutomationInputFields}
${IntegrationAutomationOutputFields}
${ActionItemPermissionsFields}
${LineFields}
${WorkflowQueryChildActionItemTemplateFields}
${ActionItemTemplatePermissionsFields}
${ActionItemTemplateStartAfterActionItemTemplateChain}
${ActionItemTemplateStartAfterActionItemTemplateFields}
${RejectActionFields}
${NameTemplateFields}
${ConditionalExpressionGroupFields}
${FieldInstanceConditionalExpressionFields}
${FieldInstanceWithCrossWorkflowFields}
${FieldInstanceValueWithCrossWorkflowFields}
${FieldInstanceValueStringWithCrossWorkflowFields}
${FieldInstanceMultiValueStringWithCrossWorkflowFields}
${FieldInstanceValueIntWithCrossWorkflowFields}
${FieldInstanceValueFloatWithCrossWorkflowFields}
${FieldInstanceValueBooleanWithCrossWorkflowFields}
${FieldInstanceValueTimeWithCrossWorkflowFields}
${FieldInstanceValuePartyWithCrossWorkflowFields}
${FieldInstanceMultiValuePartyWithCrossWorkflowFields}
${FieldInstanceMultiValueDocumentWithCrossWorkflowFields}
${FieldInstanceMultiValueRegrelloObjectInstanceWithCrossWorkflowFields}
${FieldInstanceMultiValueIntWithCrossWorkflowFields}
${FieldInstanceMultiValueFloatWithCrossWorkflowFields}
${FieldInstanceMultiValueTimeWithCrossWorkflowFields}
${WorkflowCollaborationFields}
${PartyFields}
${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}
${StarWithoutEntityFields}
${WorkflowGraphFields}
${WorkflowGraphStageFields}
${GenericWorkflowStageFields}
${GenericWorkflowActionItemFields}
${WorkflowGraphActionItemFields}
${NotificationCardBaseFields}`;

/**
 * __useWorkflowQuery__
 *
 * To run a query within a React component, call `useWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowQuery({
 *   variables: {
 *      id: // value for 'id'
 *      referenceNumber: // value for 'referenceNumber'
 *   },
 * });
 */
export function useWorkflowQuery(baseOptions?: Apollo.QueryHookOptions<WorkflowQuery, WorkflowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkflowQuery, WorkflowQueryVariables>(WorkflowQueryDocument, options);
}
export function useWorkflowQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkflowQuery, WorkflowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkflowQuery, WorkflowQueryVariables>(WorkflowQueryDocument, options);
}
export function useWorkflowQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<WorkflowQuery, WorkflowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WorkflowQuery, WorkflowQueryVariables>(WorkflowQueryDocument, options);
}
export type WorkflowQueryHookResult = ReturnType<typeof useWorkflowQuery>;
export type WorkflowQueryLazyQueryHookResult = ReturnType<typeof useWorkflowQueryLazyQuery>;
export type WorkflowQuerySuspenseQueryHookResult = ReturnType<typeof useWorkflowQuerySuspenseQuery>;
export type WorkflowQueryResult = Apollo.QueryResult<WorkflowQuery, WorkflowQueryVariables>;
