import { t } from "@lingui/macro";
import { clsx } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  RegrelloControlChildContent,
  RegrelloControlWithLabel,
  RegrelloIcon,
  RegrelloRadioGroup,
  RegrelloRadioGroupItem,
  RegrelloSize,
  RegrelloTooltip,
  RegrelloTypography,
} from "@regrello/ui-core";
import { isSameDay, startOfDay } from "date-fns";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, type SetValueConfig, useFieldArray, type UseFormReturn, useWatch } from "react-hook-form";

import type { UserFormFields } from "./RegrelloUserForm";
import { ValidationRules } from "../../../../../constants/globalConstants";
import { FeatureFlagService } from "../../../../../services/FeatureFlagService";
import { RegrelloFormValidationReason } from "../../../../../utils/formUtils";
import type { PartyTypeUnion } from "../../../../../utils/parties/PartyTypeUnion";
import { useUser } from "../../../../app/authentication/userContextUtils";
import { RegrelloFormFieldLabelPlacement } from "../../../../molecules/formFields/_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldLayout } from "../../../../molecules/formFields/_internal/RegrelloFormFieldLayout";
import {
  RegrelloControlledFormFieldCheckbox,
  RegrelloControlledFormFieldDate,
  RegrelloControlledFormFieldPartySelect,
} from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";

export interface OutOfOfficeEventFormFields {
  startAt: Date | null;
  endAt: Date | null;
  delegationSettings: OutOfOfficeEventDelegationFormFields | undefined;
}

export interface OutOfOfficeEventDelegationFormFields {
  nonApprovalTaskDelegatesParties: PartyTypeUnion[];
  approvalTaskDelegatesParties: PartyTypeUnion[];
  shouldCcOnDelegatedTasks: boolean;
  roleDelegations: Array<{
    roleID: number;
    delegateParties: PartyTypeUnion[];
  }>;
}

export interface RegrelloOutOfOfficeEventFormProps {
  form: UseFormReturn<UserFormFields>;
  setTriedToSubmitWithoutDelegates: React.Dispatch<React.SetStateAction<boolean>>;
  triedToSubmitWithoutDelegates: boolean;
}

const DelegationSettingValues = {
  KEEP_TASKS_ASSIGNED_TO_ME: "keep-tasks-assigned-to-me",
  DELEGATE_ASSIGNED_TASKS: "delegate-assigned-tasks",
};

/**
 * Form for setting user out of office settings
 */
export const RegrelloOutOfOfficeEventForm = React.memo(function RegrelloOutOfOfficeEventFormFn({
  form,
  setTriedToSubmitWithoutDelegates,
  triedToSubmitWithoutDelegates,
}: RegrelloOutOfOfficeEventFormProps) {
  const { currentUser } = useUser();

  const delegationSettings = useWatch({ control: form.control, name: "outOfOffice.delegationSettings" });
  const startAt = useWatch({ control: form.control, name: "outOfOffice.startAt" });
  const endAt = useWatch({ control: form.control, name: "outOfOffice.endAt" });

  const isStartAtBeforeEndAt = dateBeforeOrEqual(startAt, endAt);
  const isRbacM6Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.RBAC_M6_2024_11);

  const currentUserRoles = useWatch({ control: form.control, name: "permissionsV2Roles" });
  const currentUserCustomRoles = useMemo(() => currentUserRoles?.filter((role) => !role.isSystem), [currentUserRoles]);
  const shouldShowRoleDelegations = isRbacM6Enabled && currentUserCustomRoles.length > 0;

  useEffect(() => {
    if (isStartAtBeforeEndAt) {
      // Triggers validation error when dates are wrong
    }

    void form.trigger("outOfOffice.endAt");
  }, [isStartAtBeforeEndAt, form]);

  const areDelegateFieldsInInvalidState = useMemo(() => {
    const allDelegatesLength = delegationSettings?.roleDelegations.reduce((acc: number, delegation) => {
      return acc + delegation.delegateParties.length;
    }, 0);
    return (
      delegationSettings != null &&
      delegationSettings.approvalTaskDelegatesParties.length === 0 &&
      delegationSettings.nonApprovalTaskDelegatesParties.length === 0 &&
      allDelegatesLength === 0
    );
  }, [delegationSettings]);

  const shouldShowDelegateFieldError = areDelegateFieldsInInvalidState && triedToSubmitWithoutDelegates;

  // (akager): Avoid errors of the form 'Type instantiation is excessively deep and possibly
  // infinite' by explicitly stating the keys instead of reling on the default Path type.
  // hard coded key since creating type for nested key is non trivial, and potentially cpu intensive
  const formSetDelegationValue = form.setValue as (
    name: "outOfOffice.delegationSettings",
    value: OutOfOfficeEventDelegationFormFields | undefined,
    options?: SetValueConfig,
  ) => void;

  const getIsDateDisabled = useCallback(
    (date?: Date | null) => {
      if (date == null) {
        return false;
      }
      if (startAt != null && isSameDay(date, startAt)) {
        return false;
      }
      if (date < startOfDay(new Date())) {
        return true;
      }
      return false;
    },
    [startAt],
  );

  const currentUserPartyIdSet = useMemo(() => new Set([currentUser.partyId]), [currentUser.partyId]);

  return (
    <div className={clsx("bg-backgroundSoft px-4 pb-4 pt-1")}>
      <header className="flex items-center gap-1 mt-4 mb-2">
        <RegrelloTypography variant="h6">{t`When I'll be out`}</RegrelloTypography>
        <RegrelloTooltip
          align="start"
          content={
            <div className="flex flex-col gap-3">
              <span>{t`When you'll be out of office`}</span>
              <ul className="ps-6 list-disc">
                <li>{t`Setting yourself to ‘Away’ without picking dates immediately turns on OOO. You must turn it off manually.`}</li>
                <li>{t`Setting a start date only will turn it on on that day. You must turn it off manually.`}</li>
                <li>{t`Setting start and end dates will show you as OOO only for those days and turn off automatically.`}</li>
                <li>{t`Setting an end date only will turn on OOO immediately and turn it off automatically.`}</li>
              </ul>
            </div>
          }
          side="top"
          variant="popover"
        >
          <div>
            <RegrelloIcon iconName="help-outline" intent="neutral" size={RegrelloSize.X_SMALL} />
          </div>
        </RegrelloTooltip>
      </header>

      <div className="flex">
        <RegrelloControlledFormFieldDate
          className="grow mr-6"
          controllerProps={{
            control: form.control,
            name: "outOfOffice.startAt",
            rules: {
              ...ValidationRules.NOT_REQUIRED,
            },
          }}
          dataTestId={DataTestIds.OUT_OF_OFFICE_START_DATE_INPUT}
          getIsDateDisabled={getIsDateDisabled}
          label={createWeightedLabel(t`Start date`)}
          labelPlacement={RegrelloFormFieldLabelPlacement.TOP}
        />
        <RegrelloControlledFormFieldDate
          className="grow"
          controllerProps={{
            control: form.control,
            name: "outOfOffice.endAt",
            rules: {
              ...ValidationRules.NOT_REQUIRED,
              validate: {
                [RegrelloFormValidationReason.PATTERN]: (endAtValue) =>
                  dateBeforeOrEqual(startAt, endAtValue) ? undefined : t`End date is before start date`,
              },
            },
          }}
          dataTestId={DataTestIds.OUT_OF_OFFICE_END_DATE_INPUT}
          label={createWeightedLabel(t`End date`)}
          labelPlacement={RegrelloFormFieldLabelPlacement.TOP}
          minDate={new Date()}
        />
      </div>

      <header className="flex items-center gap-1 mt-4 mb-2">
        <RegrelloTypography variant="h6">{t`While I'm away`}</RegrelloTypography>
        <RegrelloTooltip
          align="start"
          content={
            <div className="flex flex-col gap-3">
              <span>{t`Choose to keep your assigned tasks or delegate them to people and/or teams while you’re away.`}</span>
            </div>
          }
          side="top"
          variant="popover"
        >
          <div>
            <RegrelloIcon iconName="help-outline" intent="neutral" size={RegrelloSize.X_SMALL} />
          </div>
        </RegrelloTooltip>
      </header>
      <RegrelloFormFieldLayout>
        <RegrelloRadioGroup
          onChange={(nextValue) => {
            if (nextValue === DelegationSettingValues.KEEP_TASKS_ASSIGNED_TO_ME) {
              setTriedToSubmitWithoutDelegates(false);
              // eslint-disable-next-line lingui/no-unlocalized-strings
              formSetDelegationValue("outOfOffice.delegationSettings", undefined);
            } else if (nextValue === DelegationSettingValues.DELEGATE_ASSIGNED_TASKS) {
              // eslint-disable-next-line lingui/no-unlocalized-strings
              formSetDelegationValue("outOfOffice.delegationSettings", {
                nonApprovalTaskDelegatesParties: [],
                approvalTaskDelegatesParties: [],
                shouldCcOnDelegatedTasks: false,
                roleDelegations: currentUserCustomRoles.map((role) => {
                  return { roleID: role.id, delegateParties: [] };
                }),
              });
            }
          }}
          value={
            delegationSettings != null
              ? DelegationSettingValues.DELEGATE_ASSIGNED_TASKS
              : DelegationSettingValues.KEEP_TASKS_ASSIGNED_TO_ME
          }
        >
          <RegrelloControlWithLabel
            control={<RegrelloRadioGroupItem value={DelegationSettingValues.KEEP_TASKS_ASSIGNED_TO_ME} />}
            label={t`Keep tasks assigned to me`}
          />
          <RegrelloControlWithLabel
            control={<RegrelloRadioGroupItem value={DelegationSettingValues.DELEGATE_ASSIGNED_TASKS} />}
            label={t`Delegate assigned tasks`}
          />
        </RegrelloRadioGroup>
      </RegrelloFormFieldLayout>
      {delegationSettings != null && (
        <div>
          <RegrelloControlChildContent>
            {shouldShowRoleDelegations && (
              <RoleDelegationSection
                currentUserPartyIdSet={currentUserPartyIdSet}
                form={form}
                shouldShowDelegateFieldError={shouldShowDelegateFieldError}
              />
            )}
            <RegrelloControlledFormFieldPartySelect
              controllerProps={{
                control: form.control,
                name: "outOfOffice.delegationSettings.nonApprovalTaskDelegatesParties",
              }}
              dataTestId={DataTestIds.OUT_OF_OFFICE_NORMAL_DELEGATES_INPUT}
              hasErrorOverride={shouldShowDelegateFieldError}
              hiddenTabs={{ teams: false, users: false }}
              label={shouldShowRoleDelegations ? t`Tasks assigned directly to you` : t`Tasks`}
              labelPlacement={RegrelloFormFieldLabelPlacement.TOP}
              partyIdsToExclude={currentUserPartyIdSet}
              placeholder={t`Choose people and/or teams`}
            />
            <RegrelloControlledFormFieldPartySelect
              controllerProps={{
                control: form.control,
                name: "outOfOffice.delegationSettings.approvalTaskDelegatesParties",
              }}
              dataTestId={DataTestIds.OUT_OF_OFFICE_APPROVAL_DELEGATES_INPUT}
              hasErrorOverride={shouldShowDelegateFieldError}
              hiddenTabs={{ teams: false, users: false }}
              label={shouldShowRoleDelegations ? t`Approvals assigned directly to you` : t`Approvals`}
              labelPlacement={RegrelloFormFieldLabelPlacement.TOP}
              partyIdsToExclude={currentUserPartyIdSet}
              placeholder={t`Choose people and/or teams`}
            />
            {shouldShowDelegateFieldError && (
              <div className="flex items-center gap-1 mt-4 text-danger-textMuted">
                <RegrelloIcon iconName="alert" size="x-small" />
                <RegrelloTypography intent="danger" variant="body-xs">
                  {t`You must assign at least one delegate`}
                </RegrelloTypography>
              </div>
            )}
          </RegrelloControlChildContent>
          <header className="flex items-center gap-1 mt-4 mb-2">
            <RegrelloTypography variant="h6">{t`Task updates`}</RegrelloTypography>
            <RegrelloTooltip
              align="start"
              content={
                <div className="flex flex-col gap-3">
                  <span>{t`Choose to remain Cc’d on your delegated tasks while you’re away.`}</span>
                </div>
              }
              side="top"
              variant="popover"
            >
              <div>
                <RegrelloIcon iconName="help-outline" intent="neutral" size={RegrelloSize.X_SMALL} />
              </div>
            </RegrelloTooltip>
          </header>
          <RegrelloControlledFormFieldCheckbox
            controllerProps={{
              control: form.control,
              name: "outOfOffice.delegationSettings.shouldCcOnDelegatedTasks",
            }}
            label={t`Keep me Cc'd on delegated tasks`}
          />
        </div>
      )}
    </div>
  );
});

const dateBeforeOrEqual = (date1: Date | null, date2: Date | null) => {
  return date1 == null || date2 == null || isSameDay(date1, date2) || date1 <= date2;
};

const createWeightedLabel = (text: string) => <RegrelloTypography weight="normal">{text}</RegrelloTypography>;

/**
 * The section where a user delegates tasks per role. Pulled out into a separate component to avoid
 * an incomplete initialization of outOfOffice.delegationSettings due to useFieldArray.
 */
const RoleDelegationSection = React.memo(function RegrelloOutOfOfficeEventRoleDelegationSectionFn({
  currentUserPartyIdSet,
  form,
  shouldShowDelegateFieldError,
}: {
  currentUserPartyIdSet: Set<number>;
  form: UseFormReturn<UserFormFields>;
  shouldShowDelegateFieldError: boolean;
}) {
  const currentUserRoles = useWatch({ control: form.control, name: "permissionsV2Roles" });

  const roleNameById = useMemo(
    () =>
      currentUserRoles.reduce((acc: Record<number, string>, role) => {
        acc[role.id] = role.name;
        return acc;
      }, {}),
    [currentUserRoles],
  );

  const { fields: roleDelegationFields } = useFieldArray({
    control: form.control,
    name: "outOfOffice.delegationSettings.roleDelegations",
  });

  return (
    <>
      <header className="flex items-center gap-1 mt-4 mb-1">
        <RegrelloTypography variant="h6">{t`By Your Role(s)`}</RegrelloTypography>
      </header>
      <div className="pl-2">
        {roleDelegationFields.map((role, i) => (
          <div key={role.id}>
            {/*
             * (wsheehan): I added a hidden input field here so that the form submission data automatically
             * includes the roleID with its corresponding parties. The role ID shouldn't change (i.e., no user
             * input is needed), but I had to explicitly include a controlled form field input for it, in order
             * for it to be non-null in the payload. I'm not sure sure this is best practice, but it works for
             * this use case.
             */}
            <Controller
              control={form.control}
              name={`outOfOffice.delegationSettings.roleDelegations.${i}.roleID`}
              render={({ field }) => <input type="hidden" {...field} />}
            />
            <RegrelloControlledFormFieldPartySelect
              controllerProps={{
                control: form.control,
                name: `outOfOffice.delegationSettings.roleDelegations.${i}.delegateParties`,
              }}
              dataTestId={DataTestIds.OUT_OF_OFFICE_ROLE_DELEGATES_INPUT}
              hasErrorOverride={shouldShowDelegateFieldError}
              hiddenTabs={{ teams: true, users: false, roles: true }}
              label={
                <div className="flex flex-row gap-1">
                  <RegrelloIcon iconName="role" intent="neutral" size="small" />
                  <RegrelloTypography intent="neutral" noWrap={true}>
                    {roleNameById[role.roleID]}
                  </RegrelloTypography>
                </div>
              }
              labelPlacement={RegrelloFormFieldLabelPlacement.TOP}
              loadOptions={{ users: { limitToRoleIds: [role.roleID] } }}
              partyIdsToExclude={currentUserPartyIdSet}
              placeholder={t`Choose a person or people`}
            />
          </div>
        ))}
      </div>
    </>
  );
});
