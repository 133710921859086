import type { TagFields } from "@regrello/graphql-api";
import { RegrelloChip, type RegrelloIntent } from "@regrello/ui-core";
import React, { useCallback, useLayoutEffect, useRef } from "react";

interface TagsInlineListProps {
  onWidthMeasured?: (tagListWidthPx: number) => void;
  tagFields?: TagFields[];
}

export const TagInlineList = React.memo(function TagInlineListFn({ onWidthMeasured, tagFields }: TagsInlineListProps) {
  // TODO (clewis): Ideally we'd pass tags and rowId as explicitly typed props. Holding off to
  // minimize changes while I'm adding type safety to the whole codebase.
  const getIntent = useCallback((tagType: string | undefined): RegrelloIntent => {
    /* eslint-disable lingui/no-unlocalized-strings */
    switch (tagType) {
      case "Supplier":
        return "primary";
      case "Project":
        return "danger";
      case "Deliverable":
        return "success";
      case "Team":
        return "warning";
      case "Misc":
        return "neutral";
      default:
        return "neutral";
    }
    /* eslint-enable lingui/no-unlocalized-strings */
  }, []);

  const rootRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (tagFields) {
      // (clewis): Run this layout effect whenever the tags change, since that may affect the width.
    }

    if (onWidthMeasured == null) {
      return;
    }
    const measuredWidthPx = rootRef.current?.getBoundingClientRect().width;
    if (measuredWidthPx != null) {
      // (clewis): Include a little extra margin on the right for breathing room.
      onWidthMeasured?.(measuredWidthPx + 16);
    }
  }, [onWidthMeasured, tagFields]);

  return (
    <div ref={rootRef} className="flex">
      {tagFields?.map((tag) => {
        return (
          <RegrelloChip
            key={tag.id}
            className="mx-0.5"
            disableShrinking={true}
            intent={getIntent(tag.tagType.name)}
            size="small"
            visibleChipClassName="rounded-circular"
          >
            {tag.name}
          </RegrelloChip>
        );
      })}
    </div>
  );
});
