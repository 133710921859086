import type * as Types from "../../../generated/graphqlBaseTypes";

import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import { gql } from "@apollo/client";
export type IntegrationAutomationOutputFields = {
  id: number;
  name: string;
  displayName: string;
  valueType: Types.PropertyDataType;
  jsonExpression?: string | null;
  isHidden: boolean;
  createdAt: string;
  fieldInstance?: FieldInstanceFields | null;
};

export const IntegrationAutomationOutputFields = gql`
    fragment IntegrationAutomationOutputFields on IntegrationAutomationOutput {
  id
  name
  displayName
  valueType
  jsonExpression
  isHidden
  fieldInstance {
    ...FieldInstanceFields
  }
  createdAt
}
    `;
