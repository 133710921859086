import type { PureQueryOptions } from "@apollo/client";
import { plural, t } from "@lingui/macro";
import { EMPTY_ARRAY, EMPTY_STRING, getEmailDomain, isDefined, isValidEmail } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  AccessRoleUserScope,
  type Maybe,
  PartiesQueryDocument,
  useBulkCreateUsersMutation,
  useCreateUserMutation,
  useGiveAdminAccessToUserMutation,
  UserAccessLevel,
  type UserFields,
  type UserLanguage,
  useUpdateUserLanguage,
} from "@regrello/graphql-api";
import { RegrelloIcon } from "@regrello/ui-core";
import React, { useCallback, useMemo, useState } from "react";

import { FrontendUserAccessLevel, RegrelloUserForm, type UserFormFields } from "./RegrelloUserForm";
import { FeatureFlagService } from "../../../../../services/FeatureFlagService";
import { updateCacheOnUserCreated, updateCacheOnUsersCreated } from "../../../../../state/apolloCacheAccessors";
import type { SubmitHandler } from "../../../../../types/form";
import { getGraphQlErrorCode, RegrelloGraphQlErrorCode } from "../../../../../utils/errorUtils";
import { getBulkResponseStatus, getBulkResponseToastDetails } from "../../../../../utils/formUtils";
import { useErrorHandler } from "../../../../../utils/hooks/useErrorHandler";
import { RegrelloFormDialogName } from "../../../../../utils/sentryScopeUtils";
import { getUserTimeZoneName } from "../../../../../utils/timeZoneUtils";
import { useUser } from "../../../../app/authentication/userContextUtils";
import { RegrelloFormDialog } from "../../../../atoms/dialog/RegrelloFormDialog";
import { useToastMessageQueue } from "../../../../molecules/toast/useToastMessageQueue";

export interface AddUserDialogProps {
  /**
   * An initial value to show in the most appropriate form field. If a valid email, it will be
   * inserted into the email field; otherwise, it will be inserted into the First Name field.
   */
  defaultValue?: string;

  /** Whether the dialog should render as open. */
  isOpen: boolean;

  /** Callback invoked when the dialog closes. */
  onClose: () => void;

  /** Callback invoked when a user has been successfully created on the backend. */
  onUsersAdded?: (user: UserFields[]) => void;

  /** GraphQL queries to refetch after creating the user. */
  refetches?: PureQueryOptions[];
}

/**
 * A standalone dialog that allows the current user to invite a new user to Regrello.
 */
export const AddUserDialog = React.memo<AddUserDialogProps>(function AddUserDialogFn({
  defaultValue,
  isOpen,
  onClose,
  onUsersAdded,
  refetches,
}) {
  // (clewis): Sneaky: If the default value looks like an email address, put it in the email field
  // initially. Else put it in the name field.
  const isDefaultValueAValidEmail = defaultValue != null && isValidEmail(defaultValue);

  const defaultName: string = (isDefaultValueAValidEmail ? undefined : defaultValue) ?? EMPTY_STRING;
  const defaultEmail: string = (isDefaultValueAValidEmail ? defaultValue : undefined) ?? EMPTY_STRING;
  const isI18nPhaseOneEnabled = FeatureFlagService.isEnabled(FeatureFlagKey.I18N_PHASE_ONE_2024_10);
  const { currentUser } = useUser();
  const { showToast } = useToastMessageQueue();
  const { handleError } = useErrorHandler();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [createUserAsync] = useCreateUserMutation({
    onError: (error) => {
      const code = getGraphQlErrorCode(error);

      if (code === RegrelloGraphQlErrorCode.OBJECT_NAME_MUST_NOT_BE_EMPTY) {
        setErrorMessage(t`Name cannot be empty`);
      } else {
        // (clewis): Clear any errors from before.
        setErrorMessage(undefined);
        handleError(error, {
          toastMessage: t`Failed to create person. Please try again, or contact a Regrello admin if you continue to see this error.`,
        });
      }
    },
    update: (cache, mutationResult) => {
      // (clewis): Put the team in the cache so that other pages that the user may be currently
      // looking at can refresh if they need to. See: https://app.regrello.com/workflow/2422
      if (mutationResult.data?.createUser?.user != null) {
        updateCacheOnUserCreated(cache, mutationResult.data.createUser.user);
      }
    },
    // (clewis): Refetch all users so our new user will appear immediately on the users page.
    refetchQueries: [{ query: PartiesQueryDocument }],
  });
  const [bulkCreateUserAsync] = useBulkCreateUsersMutation({
    onError: (error) => {
      handleError(error, {
        toastMessage: t`Failed to create person. Please try again, or contact a Regrello admin if you continue to see this error.`,
      });
    },
    update: (cache, mutationResult) => {
      // (clewis): Put the team in the cache so that other pages that the user may be currently
      // looking at can refresh if they need to. See: https://app.regrello.com/workflow/2422
      if (mutationResult.data?.bulkCreateUsers != null) {
        updateCacheOnUsersCreated(cache, mutationResult.data.bulkCreateUsers.map(({ user }) => user).filter(isDefined));
      }
    },
    // (clewis): Refetch all users so our new user will appear immediately on the users page.
    refetchQueries: [{ query: PartiesQueryDocument }],
  });

  const [setUserLanguageAsync] = useUpdateUserLanguage();

  const handleBulkCreateUserResponse = useCallback(
    (
      createUserResponse: Array<{
        error?: Maybe<string> | undefined;
        user?: Maybe<UserFields> | undefined;
      }>,
    ) => {
      const filteredCreateUserResponse = createUserResponse.reduce<{
        createdUsers: UserFields[];
        numFailed: number;
      }>(
        (acc, { user, error }, i) => {
          if (error != null) {
            console.warn(`Failed to bulk-create user at index ${i}.`, error);
            acc.numFailed = acc.numFailed + 1;
          } else if (user != null) {
            acc.createdUsers.push(user);
          } else {
            console.warn(
              "Created a user successfully, but no user data was returned in the response. Ignoring this user in optimistic updates.",
            );
          }
          return acc;
        },
        { createdUsers: [], numFailed: 0 },
      );

      const numFailed = filteredCreateUserResponse.numFailed;
      const numSucceeded = filteredCreateUserResponse.createdUsers.length;

      const formattedCreatedUsers = filteredCreateUserResponse.createdUsers.map((user) => user.name).join(", ");

      const successMessage = plural(numSucceeded, {
        one: `Successfully created the following user: ${formattedCreatedUsers}.`,
        other: `Successfully created the following users: ${formattedCreatedUsers}.`,
      });
      const failureMessage = plural(numFailed, {
        one: "Failed to create # user.",
        other: "Failed to create # users.",
      });
      const failureMessageAfterSuccess = plural(numFailed, {
        one: "Failed to create other # user.",
        other: "Failed to create other # users.",
      });

      const toastDetails = getBulkResponseToastDetails(
        getBulkResponseStatus(numSucceeded, numFailed),
        successMessage,
        failureMessage,
        successMessage + " " + failureMessageAfterSuccess,
      );
      showToast(toastDetails);
      onUsersAdded?.(filteredCreateUserResponse.createdUsers);
    },
    [onUsersAdded, showToast],
  );

  const [giveAdminAccessToUserAsync] = useGiveAdminAccessToUserMutation({
    onError: (error) => handleError(error), // (clewis): If this failed, createUser probably failed too. Don't show duplicate toasts.
    // (clewis): Refetch all users so our updated user will appear immediately on the users page.
    refetchQueries: [{ query: PartiesQueryDocument }],
  });

  const handleSubmitInternal: SubmitHandler<UserFormFields> = useCallback(
    async (data) => {
      if (data.emailContext.type === "multiple") {
        const result = await bulkCreateUserAsync({
          variables: {
            input: {
              users: data.emailContext.emails.map((email) => {
                return {
                  name: email,
                  email: email,
                  timeZone: getUserTimeZoneName(),
                  accessLevel: FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)
                    ? data.accessLevelPermissionsV2
                    : data.accessRole?.userScope === AccessRoleUserScope.INTERNAL
                      ? UserAccessLevel.INTERNAL
                      : UserAccessLevel.EXTERNAL,
                  accessRoleId: data.accessRole?.id,
                  roles: data.permissionsV2Roles?.map((r) => r.id) ?? EMPTY_ARRAY,
                };
              }),
            },
          },
          refetchQueries: refetches,
        });
        if (result.errors != null || result.data?.bulkCreateUsers == null) {
          return false;
        }
        handleBulkCreateUserResponse(result.data?.bulkCreateUsers);
      } else {
        const result = await createUserAsync({
          variables: {
            input: {
              name: data.name,
              email: data.emailContext.email,
              timeZone: getUserTimeZoneName(),
              accessLevel:
                data.accessLevel === FrontendUserAccessLevel.NON_ADMIN_EXTERNAL
                  ? UserAccessLevel.EXTERNAL
                  : UserAccessLevel.INTERNAL,
              accessRoleId: data.accessRole?.id,
              roles: data.permissionsV2Roles?.map((r) => r.id) ?? EMPTY_ARRAY,
            },
          },
          refetchQueries: refetches,
        });

        if (result.errors != null) {
          return false;
        }

        if (result.data?.createUser?.user == null) {
          console.warn("Created a user successfully, but no user data was returned in the response.");
          return false;
        }

        if (data.accessLevel === FrontendUserAccessLevel.ADMIN) {
          const resultForGiveAdminAccessMutation = await giveAdminAccessToUserAsync({
            variables: {
              id: result.data.createUser.user.id,
              tenantId: currentUser.tenantId,
            },
          });

          if (result.errors != null) {
            // (clewis): Don't return because we technically did still create the user, and we want to
            // emit `onUserAdded` below.
            showToast({
              content: t`Failed to give admin access. Please try again, or contact a Regrello admin if you continue to see this error.`,
              intent: "danger",
            });
          }

          // (clewis): Don't fail on error, since this is just for Regrello auth admins at the moment.
          if (resultForGiveAdminAccessMutation.data?.giveAdminAccessToUser?.user == null) {
            console.warn(
              "Gave admin access to the new user successfully, but no user data was returned in the response.",
            );
          }
        }

        // i18n
        if (isI18nPhaseOneEnabled && data.language != null) {
          await setUserLanguageAsync({
            variables: {
              id: result.data.createUser.user.id,
              language: data.language as UserLanguage,
            },
          });
        }

        onUsersAdded?.([result.data.createUser.user]);
      }
      // (clewis): Close after adding so the previous view is already settled when we return to it.
      onClose();
      return true;
    },
    [
      bulkCreateUserAsync,
      createUserAsync,
      currentUser.tenantId,
      giveAdminAccessToUserAsync,
      handleBulkCreateUserResponse,
      isI18nPhaseOneEnabled,
      onClose,
      onUsersAdded,
      refetches,
      setUserLanguageAsync,
      showToast,
    ],
  );

  const isExternalAccessLevelRecommended =
    defaultEmail.length > 0 && getEmailDomain(defaultEmail) !== getEmailDomain(currentUser.email);

  const defaultValues: UserFormFields = useMemo(
    () => ({
      name: defaultName,
      emailContext: {
        type: "multiple",
        emails: defaultEmail.length > 0 ? [defaultEmail] : EMPTY_ARRAY,
      },
      permissionsV2Roles: EMPTY_ARRAY,
      accessLevel:
        isExternalAccessLevelRecommended || !currentUser.isAdmin
          ? FrontendUserAccessLevel.NON_ADMIN_EXTERNAL
          : FrontendUserAccessLevel.NON_ADMIN_INTERNAL,
      accessLevelPermissionsV2: currentUser.accessLevel,
      accessRole: undefined,
      outOfOffice: undefined,
    }),
    [currentUser.accessLevel, currentUser.isAdmin, defaultEmail, defaultName, isExternalAccessLevelRecommended],
  );

  return (
    <RegrelloFormDialog<UserFormFields>
      dataTestId={DataTestIds.ADD_USER_DIALOG}
      defaultValues={defaultValues}
      error={errorMessage}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmitInternal}
      scopeNameForSentry={RegrelloFormDialogName.ADD_USER}
      showRequiredInstruction={true}
      submitButtonText={t`Invite`}
      title={t`Invite people`}
      titleIcon={
        FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01) ? (
          <RegrelloIcon iconName="people" />
        ) : undefined
      }
    >
      {(form) => <RegrelloUserForm form={form} isEmailEditable={true} isOooSettingsHidden={true} />}
    </RegrelloFormDialog>
  );
});
