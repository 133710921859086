import type { UserFields } from "./UserFields.generated";
import { gql } from "@apollo/client";
export type AdminRoleFields = {
  id: number;
  name: string;
  isSystem: boolean;
  createdAt: string;
  deletedAt?: string | null;
  updatedAt: string;
  canInviteUsersToWorkspace: boolean;
  scimId?: string | null;
  createdByUser?: UserFields | null;
};

export const AdminRoleFields = gql`
    fragment AdminRoleFields on Role {
  id
  name
  isSystem
  createdAt
  deletedAt
  updatedAt
  canInviteUsersToWorkspace
  scimId
  createdByUser {
    ...UserFields
  }
}
    `;
