import type * as Types from "../../../generated/graphqlBaseTypes";

import type { GenericWorkflowActionItemFields } from "./GenericWorkflowActionItemFields.generated";
import { gql } from "@apollo/client";
export type GenericWorkflowStageFields = {
  id: number;
  name: string;
  startAt?: string | null;
  startedAt?: string | null;
  status: Types.WorkflowFrontendStageStatus;
  startAfterWorkflowStageID?: number | null;
  startAfterWorkflowStageIDs?: Array<number | null> | null;
  startOnWorkflowStart: boolean;
  actionItems: Array<GenericWorkflowActionItemFields>;
};

export const GenericWorkflowStageFields = gql`
    fragment GenericWorkflowStageFields on GenericWorkflowStage {
  id
  name
  startAt
  startedAt
  status
  startAfterWorkflowStageID
  startAfterWorkflowStageIDs
  startOnWorkflowStart
  actionItems {
    ...GenericWorkflowActionItemFields
  }
}
    `;
