import type * as Types from "../../../generated/graphqlBaseTypes";

import type { DocumentFields } from "./DocumentFields.generated";
import type { AcyclicFieldInstanceMultiValueDocumentFields } from "./AcyclicFieldInstanceMultiValueDocumentFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueDocumentFields = {
  __typename: "FieldInstanceMultiValueDocument";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  documentMultiValue: Array<DocumentFields>;
  sourceFieldInstanceMultiValueDocument?: AcyclicFieldInstanceMultiValueDocumentFields | null;
  sinksFieldInstanceMultiValueDocument: Array<AcyclicFieldInstanceMultiValueDocumentFields>;
};

export const FieldInstanceMultiValueDocumentFields = gql`
    fragment FieldInstanceMultiValueDocumentFields on FieldInstanceMultiValueDocument {
  __typename
  id
  inputType
  documentMultiValue {
    ...DocumentFields
  }
  sourceFieldInstanceMultiValueDocument {
    ...AcyclicFieldInstanceMultiValueDocumentFields
  }
  sinksFieldInstanceMultiValueDocument {
    ...AcyclicFieldInstanceMultiValueDocumentFields
  }
}
    `;
