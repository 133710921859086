import type * as Types from "../../../generated/graphqlBaseTypes";

import { TeamFields } from "../../fragments/generated/TeamFields.generated";
import { TeamMemberFields } from "../../fragments/generated/TeamMemberFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type TeamsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  searchQuery?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type TeamsQuery = { teams: Array<TeamFields> };

export const TeamsQueryDocument = gql`
    query TeamsQuery($id: ID, $searchQuery: String) {
  teams(id: $id, searchQuery: $searchQuery) {
    ...TeamFields
  }
}
    ${TeamFields}
${TeamMemberFields}
${UserBaseFields}`;

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useTeamsQuery(baseOptions?: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(TeamsQueryDocument, options);
}
export function useTeamsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(TeamsQueryDocument, options);
}
export function useTeamsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TeamsQuery, TeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TeamsQuery, TeamsQueryVariables>(TeamsQueryDocument, options);
}
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsQueryLazyQueryHookResult = ReturnType<typeof useTeamsQueryLazyQuery>;
export type TeamsQuerySuspenseQueryHookResult = ReturnType<typeof useTeamsQuerySuspenseQuery>;
export type TeamsQueryResult = Apollo.QueryResult<TeamsQuery, TeamsQueryVariables>;
