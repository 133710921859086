import type { UserFields } from "./UserFields.generated";
import type { TeamWithoutMembersFields } from "./TeamWithoutMembersFields.generated";
import { gql } from "@apollo/client";
export type PartyFields = {
  __typename: "Party";
  id: number;
  user?: UserFields | null;
  team?: TeamWithoutMembersFields | null;
};

export const PartyFields = gql`
    fragment PartyFields on Party {
  __typename
  id
  user {
    ...UserFields
  }
  team {
    ...TeamWithoutMembersFields
  }
}
    `;
