import type * as Types from "../../../generated/graphqlBaseTypes";

import type { FormInstanceSectionMetadataFields } from "./FormInstanceSectionMetadataFields.generated";
import { gql } from "@apollo/client";
export type FormSectionStatusFields = {
  formSectionID: number;
  status: Types.FormSectionStatusString;
  metadata: Array<FormInstanceSectionMetadataFields>;
};

export const FormSectionStatusFields = gql`
    fragment FormSectionStatusFields on FormSectionStatus {
  formSectionID
  status
  metadata {
    ...FormInstanceSectionMetadataFields
  }
}
    `;
