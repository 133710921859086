import type * as Types from "../../../generated/graphqlBaseTypes";

import { FormVersionFields } from "../../fragments/generated/FormVersionFields.generated";
import { FormMinimalFields } from "../../fragments/generated/FormMinimalFields.generated";
import { WorkflowPermissionsFields } from "../../fragments/generated/WorkflowPermissionsFields.generated";
import { WorkflowTemplatePermissionsFields } from "../../fragments/generated/WorkflowTemplatePermissionsFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { FormSectionFields } from "../../fragments/generated/FormSectionFields.generated";
import { FormSectionBaseFields } from "../../fragments/generated/FormSectionBaseFields.generated";
import { PartyFields } from "../../fragments/generated/PartyFields.generated";
import { UserFields } from "../../fragments/generated/UserFields.generated";
import { UserFieldsWithoutRoles } from "../../fragments/generated/UserFieldsWithoutRoles.generated";
import { AccessRoleFields } from "../../fragments/generated/AccessRoleFields.generated";
import { FormFieldFields } from "../../fragments/generated/FormFieldFields.generated";
import { FormFieldConstraintFields } from "../../fragments/generated/FormFieldConstraintFields.generated";
import { FormFieldConstraintRuleFields } from "../../fragments/generated/FormFieldConstraintRuleFields.generated";
import { FormConstraintConditionGroupFields } from "../../fragments/generated/FormConstraintConditionGroupFields.generated";
import { FormConstraintConditionGroupBaseFields } from "../../fragments/generated/FormConstraintConditionGroupBaseFields.generated";
import { FormConstraintConditionFields } from "../../fragments/generated/FormConstraintConditionFields.generated";
import { SpectrumFieldVersionFields } from "../../fragments/generated/SpectrumFieldVersionFields.generated";
import { SpectrumFieldFields } from "../../fragments/generated/SpectrumFieldFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { SpectrumFieldValidationTypeFields } from "../../fragments/generated/SpectrumFieldValidationTypeFields.generated";
import { SpectrumFieldConstraintFields } from "../../fragments/generated/SpectrumFieldConstraintFields.generated";
import { SpectrumValueConstraintFields } from "../../fragments/generated/SpectrumValueConstraintFields.generated";
import { SpectrumFieldAllowedValueFields } from "../../fragments/generated/SpectrumFieldAllowedValueFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { FormSectionConstraintFields } from "../../fragments/generated/FormSectionConstraintFields.generated";
import { FormSectionConstraintRuleFields } from "../../fragments/generated/FormSectionConstraintRuleFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateFormAndVersionMutationVariables = Types.Exact<{
  input: Types.CreateFormAndVersionInput;
}>;

export type CreateFormAndVersionMutation = { createFormAndVersion: FormVersionFields };

export const CreateFormAndVersionMutationDocument = gql`
    mutation CreateFormAndVersionMutation($input: CreateFormAndVersionInput!) {
  createFormAndVersion(input: $input) {
    ...FormVersionFields
  }
}
    ${FormVersionFields}
${FormMinimalFields}
${WorkflowPermissionsFields}
${WorkflowTemplatePermissionsFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${FormSectionFields}
${FormSectionBaseFields}
${PartyFields}
${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}
${FormFieldFields}
${FormFieldConstraintFields}
${FormFieldConstraintRuleFields}
${FormConstraintConditionGroupFields}
${FormConstraintConditionGroupBaseFields}
${FormConstraintConditionFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${PropertyTypeFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldUnitFields}
${FieldFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}
${FormSectionConstraintFields}
${FormSectionConstraintRuleFields}`;
export type CreateFormAndVersionMutationFn = Apollo.MutationFunction<
  CreateFormAndVersionMutation,
  CreateFormAndVersionMutationVariables
>;

/**
 * __useCreateFormAndVersionMutation__
 *
 * To run a mutation, you first call `useCreateFormAndVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormAndVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormAndVersionMutation, { data, loading, error }] = useCreateFormAndVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFormAndVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFormAndVersionMutation, CreateFormAndVersionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFormAndVersionMutation, CreateFormAndVersionMutationVariables>(
    CreateFormAndVersionMutationDocument,
    options,
  );
}
export type CreateFormAndVersionMutationHookResult = ReturnType<typeof useCreateFormAndVersionMutation>;
export type CreateFormAndVersionMutationResult = Apollo.MutationResult<CreateFormAndVersionMutation>;
export type CreateFormAndVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateFormAndVersionMutation,
  CreateFormAndVersionMutationVariables
>;
