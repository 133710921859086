import { t } from "@lingui/macro";
import {
  addHours,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  getHours,
  getMinutes,
  isWithinInterval,
  parseISO,
} from "date-fns";

import type { ValuesUnion } from "../types/ValuesUnion";
export const DateUnit = {
  DAY: "day" as const,
  WEEK: "week" as const,
  MONTH: "month" as const,
};

export const numOfDaysByUnit = {
  day: 1,
  week: 7,
  month: 30,
};

export const dateUnitOptions: Array<ValuesUnion<typeof DateUnit>> = [DateUnit.DAY, DateUnit.WEEK, DateUnit.MONTH];

export function getSecondsByUnitAndValue(value: number, unit: ValuesUnion<typeof DateUnit>) {
  return value * numOfDaysByUnit[unit] * 24 * 60 * 60;
}

/*
 * (krashanoff): Here, we take advantage of the `Date` type's built-in `setHours`. Previously,
 * we had used `date-fns`'s `endOfDay` function, but this produced inconsistent results since it
 * converts to and from the local timezone on a whim, even if using ISO strings.
 */
export function endOfDay(value: Date): Date {
  return timeOfDay(value, 23, 59);
}

/** Create a date object, by setting the HH:mm:ss:ms to 00:00:00.000. */
export function startOfDay(value: Date): Date {
  const result = new Date(value);
  result.setHours(0, 0, 0, 0);
  return result;
}

/** Create date object with HH and mm specified, and ss and ms defaulting to 59 and 999 . */
export function timeOfDay(value: Date, hours: number, minutes: number): Date {
  const result = new Date(value);
  result.setHours(hours, minutes, 59, 999);
  return result;
}

/** Returns true if the time in the date object passed is 11:59 PM. */
export function isEndOfDay(date: Date | string): boolean {
  const parsedDate = parseDate(date);
  return getHours(parsedDate) === 23 && getMinutes(parsedDate) === 59;
}

/** Returns true if the passed date is within the next num_hours, defaults to 24. */
export function isDateWithinNextHours(date: string | Date, num_hours = 24): boolean {
  const now = new Date();
  const endOfInterval = addHours(now, num_hours);
  const parsedDate = parseDate(date);
  return isWithinInterval(parsedDate, { start: now, end: endOfInterval });
}

/** Parses a given Date object or ISO string into a Date object. */
export function parseDate(date: Date | string): Date {
  if (date instanceof Date) {
    return parseISO(date.toISOString());
  }
  return parseISO(date);
}

/**
 * Returns the difference between date1 and date2.
 * E.g. getDifferenceInSeconds(3:45 PM, 3:46 PM) => -60 (seconds)
 * Note: Does not return the absolute difference.
 */
export function getDifferenceInSeconds(date1: Date | string, date2: Date | string): number {
  const date1Parsed = parseDate(date1);
  const date2Parsed = parseDate(date2);
  const differenceInMilliseconds = date1Parsed.getTime() - date2Parsed.getTime();
  return differenceInMilliseconds / 1000;
}

/**
 * Returns the relative difference between the given timestamp and the current date and time in a
 * human-readable format.

 * E.g. "1m" for 1 minute, "34m" for 34 minutes, "1h" for 1 hour, "5h" for 5 hours, "1d" for 1 day,
 * "55d" for 55 days, etc.
 */
export function getRelativeDifference(timestamp: string): string {
  const now = new Date();
  const parsedDate = parseISO(timestamp);

  const diffInMinutes = differenceInMinutes(now, parsedDate);
  if (diffInMinutes < 60) {
    return t({
      message: `${diffInMinutes}m`,
      comment: "Relative time distance in minutes with a single character as postfix to define the unit.",
    });
  }

  const diffInHours = differenceInHours(now, parsedDate);
  if (diffInHours < 24) {
    return t({
      message: `${diffInHours}h`,
      comment: "Relative time distance in hours with a single character as postfix to define the unit.",
    });
  }

  const diffInDays = differenceInDays(now, parsedDate);
  return t({
    message: `${diffInDays}d`,
    comment: "Relative time distance in days with a single character as postfix to define the unit.",
  });
}
