import { plural, t, Trans } from "@lingui/macro";
import {
  arrayRemoveAtIndex,
  clsx,
  EMPTY_STRING,
  mergeRefs,
  queueMacrotask,
  useSimpleDialog,
} from "@regrello/core-utils";
import {
  type DocumentFields,
  useCreateBlueprintImportFileMutation,
  useCreateDocumentMutation,
} from "@regrello/graphql-api";
import { RegrelloChipList, RegrelloField, RegrelloIcon, RegrelloTooltip } from "@regrello/ui-core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { type FileRejection, useDropzone } from "react-dropzone";
import { useMap } from "react-use";

import type { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldHelperText } from "./_internal/RegrelloFormFieldHelperText";
import { RegrelloFormFieldLayout } from "./_internal/RegrelloFormFieldLayout";
import { RegrelloPasteDocumentLinkDialog } from "./pasteDocumentLinkDialog/RegrelloPasteDocumentLinkDialog";
import { ResponseStatus } from "../../../constants/globalConstants";
import type { CreatedDocument } from "../../../types";
import { getDocumentFields, getFileExtension } from "../../../utils/documentUtil";
import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { RegrelloDocumentChip } from "../documentChip/RegrelloDocumentChip";
import { useToastMessageQueue } from "../toast/useToastMessageQueue";

export interface RegrelloFormFieldDocumentProps
  extends Pick<
    RegrelloFormFieldBaseProps<DocumentFields[]>,
    | "className"
    | "dataTestId"
    | "disabled"
    | "error"
    | "helperText"
    | "isDeleted"
    | "isRequiredAsteriskShown"
    | "infoTooltipText"
    | "infoTooltipIconName"
    | "infoTooltipVariant"
    | "labelPlacement"
    | "label"
    | "labelWidth"
    | "name"
    | "variant"
  > {
  allowMultipleValues?: boolean;
  allowPasteLink?: boolean;

  /**
   * List of allowed file extensions for selected files, prefixed with a ".".
   *
   * @example [".pdf", ".png"]
   */
  allowedFileTypes?: Array<`.${string}`>;

  /**
   * IDs of documents whose deletion should only occur locally and not be persisted to the server,
   * which happens by default. Persisting to the server needs to be bypassed in certain contexts to
   * avoid invalid document states, like when duplicating a task and the user decides to delete one
   * of the attached documents.
   */
  documentIdsToDeleteLocalOnly?: Set<number>;
  inputRef?: React.Ref<HTMLInputElement>;

  /**
   * List of document version IDs that require user-intervention to resolve. Documents can
   * enter this state if, for example, an upload did not complete successfully. If one of
   * the documents submitted to this field has its current version ID in `corruptVersionIds`,
   * the corresponding document chip will be highlighted in red, and prompt deletion by the
   * user to resolve its incorrect state.
   *
   * @default []
   */
  corruptVersionIds?: number[];

  isEmphasized?: boolean;
  isRequiredAsteriskShown?: boolean;
  maxDocumentsCount?: number;

  /** Callback invoked when the input loses focus. */
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;

  onChange: (newValue: DocumentFields[]) => void;
  onUploadStart: () => void;
  onUploadFinish: () => void;

  /**
   * Whether this document field is being used for blueprint import. If so, files will be uploaded to a different storage location.
   */
  isBlueprintImportField?: boolean;

  size?: "small" | "medium";
  value: DocumentFields[];
  hideValue?: boolean;
}

export const RegrelloFormFieldDocument = React.memo(function RegrelloFormFieldDocumentFn({
  allowMultipleValues = true,
  allowPasteLink = false,
  allowedFileTypes,
  className,
  dataTestId,
  disabled,
  documentIdsToDeleteLocalOnly,
  error,
  helperText,
  infoTooltipText,
  infoTooltipIconName,
  infoTooltipVariant,
  inputRef,
  corruptVersionIds = [],
  isBlueprintImportField = false,
  isDeleted,
  isEmphasized,
  isRequiredAsteriskShown,
  labelPlacement,
  label,
  labelWidth,
  maxDocumentsCount,
  name,
  onBlur,
  onChange,
  onUploadStart,
  onUploadFinish,
  size = "medium",
  value,
  variant,
  hideValue,
}: RegrelloFormFieldDocumentProps) {
  const { showToast } = useToastMessageQueue();
  const { handleError } = useErrorHandler();
  const [uploadsInProgress, { set: setUploadInProgress }] = useMap<Record<number, boolean>>({});
  const isDocumentLinkEnabled = allowPasteLink;
  const { isOpen, open: openAddLinkDialog, close: closeAddLinkDialog } = useSimpleDialog();
  const [localError, setLocalError] = useState<string>();

  const handleSubmitError = useCallback(() => {
    handleError(
      t`Failed to create document. Please try again, or contact a Regrello admin if you continue to see this error.`,
      {
        toastMessage: t`Failed to create document. Please try again, or contact a Regrello admin if you continue to see this error.`,
      },
    );
  }, [handleError]);

  const [createAttachmentAsync] = useCreateDocumentMutation({
    onError: handleSubmitError,
  });
  const [createBlueprintImportFileAsync] = useCreateBlueprintImportFileMutation({
    onError: handleSubmitError,
  });

  const [isDragging, setIsDragging] = useState(false);

  const uploadFileAsync = useCallback(
    async (file: File, document: DocumentFields, signedUrl: string) => {
      try {
        setUploadInProgress(document.id, true);

        const fetchResponse = await fetch(signedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: file,
        });
        if (fetchResponse.status !== ResponseStatus.SUCCESS) {
          handleError(fetchResponse.statusText, {
            toastMessage: t`Failed to create document. Please try again, or contact a Regrello admin if you continue to see this error.`,
          });
        }
      } finally {
        setUploadInProgress(document.id, false);
      }
    },
    [handleError, setUploadInProgress],
  );

  const isMaxDocumentsCountReached =
    maxDocumentsCount != null && maxDocumentsCount > 0 && value.length >= maxDocumentsCount;
  const isInputDisabled = disabled || isMaxDocumentsCountReached;

  const createAndUploadDocumentAsync = useCallback(
    async (files: FileList | File[]) => {
      onUploadStart();
      const appendedDocuments = [];
      const uploadPromises = [];

      if (files == null) {
        return;
      }

      for (const file of files) {
        if (isBlueprintImportField) {
          const { data: createImportFileData } = await createBlueprintImportFileAsync();
          const signedUrlForImport = createImportFileData?.createBlueprintImportFile.signedUrl;
          const fileUuid = createImportFileData?.createBlueprintImportFile.fileUuid;

          if (signedUrlForImport == null || fileUuid == null) {
            return;
          }

          const importFileAsDocument = getDocumentFields(file.name, fileUuid);
          appendedDocuments.push(importFileAsDocument);
          uploadPromises.push(uploadFileAsync(file, importFileAsDocument, signedUrlForImport));
          continue;
        }

        const response = await createAttachmentAsync({
          variables: {
            filename: file.name,
          },
        });

        if (response.data?.createDocument?.document == null || response.data.createDocument.signedUrl == null) {
          return;
        }
        const { document, signedUrl } = response.data.createDocument;

        appendedDocuments.push(document);
        uploadPromises.push(uploadFileAsync(file, document, signedUrl));
      }

      if (allowMultipleValues) {
        onChange([...value, ...appendedDocuments]);
      } else {
        onChange(appendedDocuments);
      }

      await Promise.all(uploadPromises);
      onUploadFinish();
      onBlur?.(
        new FocusEvent("blur", {
          relatedTarget: inputRefInternal.current,
        }) as unknown as React.FocusEvent<HTMLDivElement>,
      );
    },
    [
      allowMultipleValues,
      createAttachmentAsync,
      createBlueprintImportFileAsync,
      isBlueprintImportField,
      onBlur,
      onChange,
      onUploadFinish,
      onUploadStart,
      uploadFileAsync,
      value,
    ],
  );

  const maybeCreateAndUploadFiles = useCallback(
    async (files: File[]) => {
      setLocalError(undefined);

      if (allowMultipleValues) {
        if (maxDocumentsCount != null) {
          if (value.length >= maxDocumentsCount) {
            const fieldName = label?.toString() ?? t`This field`;
            showToast({
              content: plural(maxDocumentsCount, {
                one: `${fieldName} only accepts # document`,
                other: `${fieldName} only accepts # documents`,
              }),
              intent: "info",
            });
          } else if (value.length + files.length > maxDocumentsCount) {
            const fieldName = label?.toString() ?? t`This field`;
            showToast({
              content: plural(maxDocumentsCount, {
                one: `${fieldName} only accepts # document`,
                other: `${fieldName} only accepts # documents`,
              }),
              intent: "info",
            });
            await createAndUploadDocumentAsync(files.slice(0, maxDocumentsCount - value.length));
          } else {
            await createAndUploadDocumentAsync(files);
          }
        } else {
          await createAndUploadDocumentAsync(files);
        }
      } else {
        if (files.length > 1) {
          const fieldName = label?.toString() ?? t`This field`;
          showToast({
            content: t`${fieldName} only accepts one document`,
            intent: "info",
          });
        }
        await createAndUploadDocumentAsync([files[0]]);
      }
    },
    [createAndUploadDocumentAsync, allowMultipleValues, label, showToast, value.length, maxDocumentsCount],
  );

  const { getRootProps } = useDropzone({
    accept: allowedFileTypes?.join(", "),
    disabled: isInputDisabled,
    onDragEnter: () => {
      setIsDragging(true);
    },
    onDragLeave: () => {
      setIsDragging(false);
    },
    onDrop: async (files: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        setLocalError(fileRejections[0].errors[0].message);
      } else {
        await maybeCreateAndUploadFiles(files);
      }
      setIsDragging(false);
    },
  });

  const onUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = Array.from(event.target.files ?? []);

      if (fileList == null) {
        return;
      }

      const unsupportedFiles: File[] = [];

      // Prevent disallowed file types from being chosen by a user in explorer / finder window.
      if (allowedFileTypes != null && allowedFileTypes.length > 0) {
        for (const file of fileList) {
          const fileExtension = getFileExtension(file.name, { includePeriod: true });
          if (fileExtension == null) {
            unsupportedFiles.push(file);
          }

          const fileExtensionPrefixed = fileExtension as `.${string}`;

          if (fileExtension == null || !allowedFileTypes.includes(fileExtensionPrefixed)) {
            unsupportedFiles.push(file);
          }
        }

        if (unsupportedFiles.length > 0) {
          setLocalError(
            plural(allowedFileTypes.length, {
              one: `Only ${allowedFileTypes} is accepted.`,
              other: `Only ${allowedFileTypes} are accepted.`,
            }),
          );

          // FormErrorFileTypeInvalid(allowedFileTypes));
          event.target.files = null;

          // Important return statement to prevent the files from being uploaded if there are
          // unsupported file types.
          return;
        }
      }

      await maybeCreateAndUploadFiles(fileList);
    },
    [allowedFileTypes, maybeCreateAndUploadFiles],
  );

  const onDelete = useCallback(
    (documentToDelete: DocumentFields) => {
      if (inputRefInternal.current != null) {
        // (hchen): Reset the internal value of the native input element so that when a user attempt
        // to upload the same document again, the onChange event fires.
        inputRefInternal.current.value = EMPTY_STRING;
      }

      const indexToDelete = value.findIndex((document) => document.id === documentToDelete.id);
      if (indexToDelete !== -1) {
        onChange(arrayRemoveAtIndex(value, indexToDelete));
      }
      onBlur?.(
        new FocusEvent("blur", {
          relatedTarget: inputRefInternal.current,
        }) as unknown as React.FocusEvent<HTMLDivElement>,
      );
    },
    [onBlur, onChange, value],
  );

  const onPasteClick = useCallback(() => {
    if (isInputDisabled) {
      return;
    }

    openAddLinkDialog();
  }, [isInputDisabled, openAddLinkDialog]);

  const onAttachClick = useCallback(() => {
    if (isInputDisabled) {
      return;
    }

    inputRefInternal.current?.click();
  }, [isInputDisabled]);

  const handlePasteDocumentLinkDialogSubmit = useCallback(
    (createdDocument: CreatedDocument) => {
      if (createdDocument.document == null) {
        console.warn("Unexpected empty createdDocument.document in RegrelloPastLinkDocumentDialog onSubmit");
        return;
      }
      if (allowMultipleValues) {
        onChange([...value, createdDocument.document]);
      } else {
        onChange([createdDocument.document]);
      }
      onBlur?.(
        new FocusEvent("blur", {
          relatedTarget: inputRefInternal.current,
        }) as unknown as React.FocusEvent<HTMLDivElement>,
      );
    },
    [allowMultipleValues, onBlur, onChange, value],
  );

  const inputRefInternal = useRef<HTMLInputElement | null>(null);

  const handleClickableDropAreaKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      inputRefInternal.current?.click();
    }
  }, []);

  const dropzoneContent = useMemo(() => {
    switch (true) {
      case isMaxDocumentsCountReached: {
        const limit = maxDocumentsCount ?? 0;
        return (
          <span
            className={clsx({
              "flex items-center cursor-pointer text-primary-textMuted hover:underline": !isInputDisabled,
              "flex items-center text-textDisabled": isInputDisabled,
            })}
          >
            {t`${limit} / ${limit} documents attached`}
          </span>
        );
      }
      default:
        return (
          <>
            {!isDocumentLinkEnabled && <RegrelloIcon className="mr-1" iconName="attach-file" />}
            {isDragging ? (
              t`Drop files here`
            ) : isDocumentLinkEnabled ? (
              <Trans>
                Drop,{" "}
                <span
                  className={clsx({
                    "flex items-center cursor-pointer text-primary-textMuted hover:underline": !isInputDisabled,
                    "flex items-center text-textDisabled": isInputDisabled,
                  })}
                  onClick={onAttachClick}
                >
                  <RegrelloIcon className="m-0" iconName="attach-file" />
                  Attach
                </span>
                , or{" "}
                <span
                  className={clsx(
                    {
                      "flex items-center cursor-pointer text-primary-textMuted hover:underline": !isInputDisabled,
                      "flex items-center text-textDisabled": isInputDisabled,
                    },
                    "px-1",
                  )}
                  onClick={onPasteClick}
                >
                  <RegrelloIcon className="mr-1" iconName="copy-link" />
                  paste
                </span>{" "}
                a link
              </Trans>
            ) : (
              t`Click or drop to attach documents`
            )}
          </>
        );
    }
  }, [
    isDocumentLinkEnabled,
    isDragging,
    isInputDisabled,
    isMaxDocumentsCountReached,
    maxDocumentsCount,
    onAttachClick,
    onPasteClick,
  ]);

  const rootRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const maybeInputElement = inputRefInternal.current;

    const handleInputRefFocus = () => {
      // (clewis): When the form library auto-focuses the hidden <input> (e.g., when attempting to
      // submit an invalid form), move focus to the element that actually has focus styles defined.
      queueMacrotask(() => {
        rootRef.current?.focus();
      });
    };

    maybeInputElement?.addEventListener("focus", handleInputRefFocus);

    return () => {
      maybeInputElement?.removeEventListener("focus", handleInputRefFocus);
    };
  }, []);

  const documentFieldElement = useMemo(() => {
    return (
      <div
        {...getRootProps({
          tabIndex: undefined,
        })}
        ref={rootRef}
        className="relative flex flex-wrap items-center w-full focus:outline-0 group"
        onBlur={onBlur}
        onKeyDown={handleClickableDropAreaKeyDown}
        tabIndex={disabled ? undefined : 0}
      >
        {hideValue ? null : (
          <div
            className={clsx(
              "relative flex justify-center items-center w-full h-12 m-0",
              "border border-dashed rounded text-textPlaceholder bg-backgroundSoft",
              "group-focus:border-2 group-focus:border-primary-solid",
              {
                "border-0 text-textMuted": isInputDisabled,
                "hover:text-textMuted hover:border-neutral-borderInteractiveStrong active:bg-neutral-soft":
                  !isInputDisabled,
                "before:content-[''] before:absolute before:inset-y-0 before:left-0 before:w-1 before:z-1 before:rounded-l before:bg-warning-solid":
                  isEmphasized && !isDragging,
                "before:content-[''] before:absolute before:inset-y-0 before:left-0 before:w-1 before:z-1 before:rounded-l before:bg-danger-solid group-focus:border-danger-solid":
                  error != null || localError != null,
                "h-9 text-xs": size === "small",
                "border-2 border-primary-solid": isDragging,
              },
            )}
          >
            <input
              ref={mergeRefs(inputRefInternal, inputRef)}
              accept={allowedFileTypes?.join(", ")}
              className={clsx("opacity-0 absolute z-1 w-full h-full not-disabled:cursor-pointer", {
                "pointer-events-none": isDocumentLinkEnabled,
              })}
              disabled={isInputDisabled}
              multiple={allowMultipleValues}
              onChange={onUpload}
              // (clewis): Remove the input from the tab order since it's invisible. Instead, allow
              // focusing the parent.
              tabIndex={-1}
              type="file"
            />
            {dropzoneContent}
          </div>
        )}

        {
          // (clewis): For spectrum, we will have already rendered the helper text in RegrelloField.
          helperText != null && variant !== "spectrum" && (
            <RegrelloFormFieldHelperText>{helperText}</RegrelloFormFieldHelperText>
          )
        }

        {value.length > 0 && (
          <RegrelloChipList className="mt-2">
            {value.map((document) => {
              if (corruptVersionIds.includes(document.currentVersion.id)) {
                return (
                  <RegrelloTooltip
                    key={document.id}
                    content={t`The document supplied appears corrupt. Please remove this one and try uploading it again.`}
                  >
                    <RegrelloDocumentChip
                      key={document.id}
                      document={document}
                      intent="danger"
                      isClickable={false}
                      // (wbuchanan): Do not try to delete blueprint import files since they are stored differently than normal documents.
                      isDeletionLocalOnly={documentIdsToDeleteLocalOnly?.has(document.id) || isBlueprintImportField}
                      isUploading={uploadsInProgress[document.id]}
                      onDelete={!disabled ? onDelete : undefined}
                    />
                  </RegrelloTooltip>
                );
              }

              return (
                <RegrelloDocumentChip
                  key={document.id}
                  document={document}
                  // (wbuchanan): Do not try to delete blueprint import files since they are stored differently than normal documents.
                  isDeletionLocalOnly={documentIdsToDeleteLocalOnly?.has(document.id) || isBlueprintImportField}
                  isUploading={uploadsInProgress[document.id]}
                  onDelete={!disabled ? onDelete : undefined}
                />
              );
            })}
          </RegrelloChipList>
        )}
      </div>
    );
  }, [
    getRootProps,
    onBlur,
    handleClickableDropAreaKeyDown,
    disabled,
    hideValue,
    isInputDisabled,
    isEmphasized,
    isDragging,
    error,
    localError,
    size,
    inputRef,
    allowedFileTypes,
    isDocumentLinkEnabled,
    allowMultipleValues,
    onUpload,
    dropzoneContent,
    helperText,
    variant,
    value,
    corruptVersionIds,
    documentIdsToDeleteLocalOnly,
    isBlueprintImportField,
    onDelete,
    uploadsInProgress,
  ]);

  if (variant === "spectrum") {
    return (
      <>
        <RegrelloField
          dataTestId={dataTestId}
          deleted={isDeleted}
          description={infoTooltipText}
          errorMessage={localError ?? error}
          helperText={typeof helperText === "string" ? helperText : undefined}
          label={typeof label === "string" ? label : EMPTY_STRING}
          name={name ?? EMPTY_STRING}
          required={isRequiredAsteriskShown}
        >
          {() => {
            return documentFieldElement;
          }}
        </RegrelloField>
        <RegrelloPasteDocumentLinkDialog
          isOpen={isOpen}
          onClose={closeAddLinkDialog}
          onError={handleSubmitError}
          onSubmit={handlePasteDocumentLinkDialogSubmit}
        />
      </>
    );
  }

  return (
    <>
      <RegrelloFormFieldLayout
        className={className}
        dataTestId={dataTestId}
        error={localError ?? error}
        infoTooltipIconName={infoTooltipIconName}
        infoTooltipText={infoTooltipText}
        infoTooltipVariant={infoTooltipVariant}
        isDeleted={isDeleted}
        isRequiredAsteriskShown={isRequiredAsteriskShown}
        label={label}
        labelPlacement={labelPlacement}
        labelWidth={labelWidth}
        variant={variant}
      >
        {documentFieldElement}
      </RegrelloFormFieldLayout>

      <RegrelloPasteDocumentLinkDialog
        isOpen={isOpen}
        onClose={closeAddLinkDialog}
        onError={handleSubmitError}
        onSubmit={handlePasteDocumentLinkDialogSubmit}
      />
    </>
  );
});
