import type { FieldInstanceMultiValueFloatFields } from "./FieldInstanceMultiValueFloatFields.generated";
import type { AcyclicFieldInstanceMultiValueFloatFields } from "./AcyclicFieldInstanceMultiValueFloatFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueFloatWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceMultiValueFloat?: AcyclicFieldInstanceMultiValueFloatFields | null;
  crossWorkflowSinksFieldInstanceMultiValueFloat?: Array<AcyclicFieldInstanceMultiValueFloatFields> | null;
} & FieldInstanceMultiValueFloatFields;

export const FieldInstanceMultiValueFloatWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueFloatWithCrossWorkflowFields on FieldInstanceMultiValueFloat {
  ...FieldInstanceMultiValueFloatFields
  crossWorkflowSourceFieldInstanceMultiValueFloat {
    ...AcyclicFieldInstanceMultiValueFloatFields
  }
  crossWorkflowSinksFieldInstanceMultiValueFloat {
    ...AcyclicFieldInstanceMultiValueFloatFields
  }
}
    `;
