import type { FieldInstanceMultiValueDocumentFields } from "./FieldInstanceMultiValueDocumentFields.generated";
import type { AcyclicFieldInstanceMultiValueDocumentFields } from "./AcyclicFieldInstanceMultiValueDocumentFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueDocumentWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceMultiValueDocument?: AcyclicFieldInstanceMultiValueDocumentFields | null;
  crossWorkflowSinksFieldInstanceMultiValueDocument?: Array<AcyclicFieldInstanceMultiValueDocumentFields> | null;
} & FieldInstanceMultiValueDocumentFields;

export const FieldInstanceMultiValueDocumentWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueDocumentWithCrossWorkflowFields on FieldInstanceMultiValueDocument {
  ...FieldInstanceMultiValueDocumentFields
  crossWorkflowSourceFieldInstanceMultiValueDocument {
    ...AcyclicFieldInstanceMultiValueDocumentFields
  }
  crossWorkflowSinksFieldInstanceMultiValueDocument {
    ...AcyclicFieldInstanceMultiValueDocumentFields
  }
}
    `;
