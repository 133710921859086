import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type RegrelloObjectInstanceAuditHistoryDeltaFields = {
  id: number;
  fieldName: string;
  valueBefore?: string | null;
  valueAfter?: string | null;
  collectionAdd?: string | null;
  collectionRemove?: string | null;
  field?: {
    id: number;
    isMultiValued: boolean;
    name: string;
    fieldUnit?: { id: number; symbol: string } | null;
    allowedValues: Array<
      | { __typename: "FieldAllowedValueParty"; id: number; displayOrder: number; partyValue: PartyBaseFields }
      | { __typename: "FieldAllowedValueString"; id: number; displayOrder: number; stringValue: string }
    >;
  } | null;
  removedRecords: Array<{ id: number; name?: string | null }>;
  addedRecords: Array<{ id: number; name?: string | null }>;
};

export const RegrelloObjectInstanceAuditHistoryDeltaFields = gql`
    fragment RegrelloObjectInstanceAuditHistoryDeltaFields on RegrelloObjectInstanceAuditHistoryDelta {
  id
  fieldName
  field {
    id
    isMultiValued
    name
    fieldUnit {
      id
      symbol
    }
    allowedValues {
      ... on FieldAllowedValueString {
        __typename
        id
        displayOrder
        stringValue
      }
      ... on FieldAllowedValueParty {
        __typename
        id
        displayOrder
        partyValue {
          ...PartyBaseFields
        }
      }
    }
  }
  valueBefore
  valueAfter
  collectionAdd
  collectionRemove
  removedRecords {
    id
    name
  }
  addedRecords {
    id
    name
  }
}
    `;
