import type { AcyclicFieldInstanceValueStringFields } from "./AcyclicFieldInstanceValueStringFields.generated";
import type { AcyclicFieldInstanceMultiValueStringFields } from "./AcyclicFieldInstanceMultiValueStringFields.generated";
import type { AcyclicFieldInstanceValueIntFields } from "./AcyclicFieldInstanceValueIntFields.generated";
import type { AcyclicFieldInstanceValueFloatFields } from "./AcyclicFieldInstanceValueFloatFields.generated";
import type { AcyclicFieldInstanceValueBooleanFields } from "./AcyclicFieldInstanceValueBooleanFields.generated";
import type { AcyclicFieldInstanceValueTimeFields } from "./AcyclicFieldInstanceValueTimeFields.generated";
import type { AcyclicFieldInstanceValuePartyFields } from "./AcyclicFieldInstanceValuePartyFields.generated";
import type { AcyclicFieldInstanceMultiValuePartyFields } from "./AcyclicFieldInstanceMultiValuePartyFields.generated";
import type { AcyclicFieldInstanceMultiValueDocumentFields } from "./AcyclicFieldInstanceMultiValueDocumentFields.generated";
import type { AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields } from "./AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields.generated";
import type { AcyclicFieldInstanceMultiValueIntFields } from "./AcyclicFieldInstanceMultiValueIntFields.generated";
import type { AcyclicFieldInstanceMultiValueFloatFields } from "./AcyclicFieldInstanceMultiValueFloatFields.generated";
import type { AcyclicFieldInstanceMultiValueTimeFields } from "./AcyclicFieldInstanceMultiValueTimeFields.generated";
import { gql } from "@apollo/client";
export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument =
  AcyclicFieldInstanceMultiValueDocumentFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat = AcyclicFieldInstanceMultiValueFloatFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt = AcyclicFieldInstanceMultiValueIntFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty = AcyclicFieldInstanceMultiValuePartyFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance =
  AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString = AcyclicFieldInstanceMultiValueStringFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime = AcyclicFieldInstanceMultiValueTimeFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean = AcyclicFieldInstanceValueBooleanFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat = AcyclicFieldInstanceValueFloatFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueInt = AcyclicFieldInstanceValueIntFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueParty = AcyclicFieldInstanceValuePartyFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueString = AcyclicFieldInstanceValueStringFields;

export type AcyclicFieldInstanceValueFieldsFieldInstanceValueTime = AcyclicFieldInstanceValueTimeFields;

export type AcyclicFieldInstanceValueFields =
  | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueDocument
  | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueFloat
  | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueInt
  | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueParty
  | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance
  | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueString
  | AcyclicFieldInstanceValueFieldsFieldInstanceMultiValueTime
  | AcyclicFieldInstanceValueFieldsFieldInstanceValueBoolean
  | AcyclicFieldInstanceValueFieldsFieldInstanceValueFloat
  | AcyclicFieldInstanceValueFieldsFieldInstanceValueInt
  | AcyclicFieldInstanceValueFieldsFieldInstanceValueParty
  | AcyclicFieldInstanceValueFieldsFieldInstanceValueString
  | AcyclicFieldInstanceValueFieldsFieldInstanceValueTime;

export const AcyclicFieldInstanceValueFields = gql`
    fragment AcyclicFieldInstanceValueFields on FieldInstanceValue {
  ... on FieldInstanceValueString {
    ...AcyclicFieldInstanceValueStringFields
  }
  ... on FieldInstanceMultiValueString {
    ...AcyclicFieldInstanceMultiValueStringFields
  }
  ... on FieldInstanceValueInt {
    ...AcyclicFieldInstanceValueIntFields
  }
  ... on FieldInstanceValueFloat {
    ...AcyclicFieldInstanceValueFloatFields
  }
  ... on FieldInstanceValueBoolean {
    ...AcyclicFieldInstanceValueBooleanFields
  }
  ... on FieldInstanceValueTime {
    ...AcyclicFieldInstanceValueTimeFields
  }
  ... on FieldInstanceValueParty {
    ...AcyclicFieldInstanceValuePartyFields
  }
  ... on FieldInstanceMultiValueParty {
    ...AcyclicFieldInstanceMultiValuePartyFields
  }
  ... on FieldInstanceMultiValueDocument {
    ...AcyclicFieldInstanceMultiValueDocumentFields
  }
  ... on FieldInstanceMultiValueRegrelloObjectInstance {
    ...AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields
  }
  ... on FieldInstanceMultiValueInt {
    ...AcyclicFieldInstanceMultiValueIntFields
  }
  ... on FieldInstanceMultiValueFloat {
    ...AcyclicFieldInstanceMultiValueFloatFields
  }
  ... on FieldInstanceMultiValueTime {
    ...AcyclicFieldInstanceMultiValueTimeFields
  }
}
    `;
