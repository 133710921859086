import { t } from "@lingui/macro";
import {
  ComparatorResult,
  EMPTY_ARRAY,
  EMPTY_STRING,
  sortIgnoreCase,
  stringComparator,
  useSimpleDialog,
} from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  ConditionOperator,
  type CreateFieldInstanceValueInputs,
  type FieldFields,
  type FieldInstanceFields,
  type FieldInstanceFieldsWithBaseValues,
  type FieldInstanceValueInputType,
  type Maybe,
  PropertyDataType,
  type PropertyTypeFields,
  type RegrelloObjectFields,
  type RegrelloObjectInstanceFields,
  type UpdateFieldInstanceValueInputs,
  type UpdateStartingConditionsInputs,
  type ViewFilterFields,
} from "@regrello/graphql-api";
import {
  type RegrelloDataGridColumnDef,
  regrelloDataGridColumnHelper,
  RegrelloDataGridWithPopover,
  RegrelloIcon,
  RegrelloLinkify,
  RegrelloObjectChip,
} from "@regrello/ui-core";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";

import { CustomFieldPluginRegistrar } from "./registry/customFieldPluginRegistrar";
import type { ConditionOperatorConfig } from "./types/ConditionOperator";
import type { CustomFieldPlugin } from "./types/CustomFieldPlugin";
import { convertRegrelloObjectInstanceForDataGrid } from "./utils/convertRegrelloObjectInstanceForDataGrid";
import { createViewColumnsFromField } from "./utils/createViewColumnsFromField";
import { DEFAULT_INPUT_TYPE_IF_NO_VALUE, getConditionOperatorsByType } from "./utils/customFieldConstants";
import { extractAtMostOneValueOrThrow } from "./utils/extractAtMostOneValueOrThrow";
import {
  getIsFieldInstanceFields,
  getIsFieldInstanceValueWithCrossWorkflowFields,
} from "./utils/fieldInstanceTypeguards";
import { getUpdateStartingConditionsInputsForEmptyOperators } from "./utils/getUpdateStartingConditionsInputsForEmptyOperators";
import type { FieldInstanceBaseFields } from "../../../../types";
import { getFieldInstanceId } from "../../../../utils/customFields/getFieldInstanceId";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import { renderLinkifiedTextCell } from "../../../../utils/tableCellRenderUtils";
import { TableCellDefaultWidths } from "../../../../utils/tableCellWidthUtils";
import { RegrelloControlledFormFieldRegrelloObject } from "../../formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../formFields/controlled/RegrelloControlledFormFieldText";
import type { RegrelloObjectInstanceForDataGrid } from "../../formFields/regrelloObjectSearchDialog/RegrelloObjectSearchDialog";
import {
  getRegrelloDefaultFilterDefinitionStringValue,
  getRegrelloFilterDefinitionStringValue,
} from "../../tableFilterControlV2/_internal/core/regrelloFilterV2Constants";

const ERROR_INVALID_FIELD = "Provided 'regrelloObject' field is invalid";
const ERROR_INVALID_FORM_VALUE =
  "Provided 'regrelloObject'-field form value does not seeem to be a valid RegrelloObjectInstanceFields object";
const ERROR_INVALID_VALUE_COUNT = "Provided 'regrelloObject' field instance cannot have multiple values";
const ERROR_INVALID_VALUE_TYPE =
  "Provided 'regrelloObject' field instance value must have type 'FieldInstanceMultiValueRegrelloObjectInstance'";
const WARNING_UNDEFINED_REGRELLO_OBJECT = "Provided 'regrelloObject' should be defined";
const WARNING_UNEXPECTED_DEFINED_FORM_VALUE =
  "Provided 'regrelloObject' field form value must not be defined given the provided operator type";

function canProcessPropertyDataType(propertyDataType: PropertyDataType): boolean {
  return propertyDataType === PropertyDataType.REGRELLO_OBJECT_INSTANCE_ID;
}

function canProcessField(field: FieldFields): boolean {
  return field.allowedValues.length === 0 && canProcessPropertyDataType(field.propertyType.dataType);
}

type RegrelloObjectPluginFrontendValue = RegrelloObjectInstanceFields[] | undefined;
type RegrelloObjectFieldPluginType = CustomFieldPlugin<RegrelloObjectPluginFrontendValue>;

const renderDisplayValue: RegrelloObjectFieldPluginType["renderDisplayValue"] = (fieldInstance, options) => {
  const { value: regrelloObjectInstances } = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
  const regrelloObject = fieldInstance.field.regrelloObject;

  if (regrelloObject == null) {
    return undefined;
  }

  if (regrelloObjectInstances.length === 0 && options?.context === "tableV2") {
    return undefined;
  }

  if (regrelloObjectInstances.length === 0) {
    return <RegrelloObjectChip regrelloObject={regrelloObject} />;
  }

  const sortedProperties = sortIgnoreCase(
    [...(regrelloObject?.properties ?? [...EMPTY_ARRAY])],
    (property) => `${property.displayOrder}`,
  );

  const dataValues: Array<{
    displayOrder: number;
    displayName: string;
    dataObjectKey: string;
    value: string[];
  }> = sortedProperties.map(({ dataObjectKey, displayOrder, displayName }) => ({
    displayOrder,
    displayName: displayName ?? dataObjectKey,
    dataObjectKey: dataObjectKey,
    value: regrelloObjectInstances.map(
      ({ dataObjectCells }) => dataObjectCells.find(({ key }) => key === dataObjectKey)?.value.stringValue ?? "-",
    ),
  }));

  // If a specific `regrelloObjectProperty` was provided, extract the value for only it.
  if (options?.regrelloObjectProperty != null) {
    return (
      dataValues.find(({ dataObjectKey }) => dataObjectKey === options?.regrelloObjectProperty?.dataObjectKey)?.value ??
      undefined
    );
  }

  // When there are no values to show or if we're in the workflow templates header, render the field
  // count chip.
  if (options?.context === "inlineWrappable" || (regrelloObjectInstances.length === 0 && regrelloObject != null)) {
    return <RegrelloObjectChip regrelloObject={regrelloObject} />;
  }

  // Final render output option: Key-value pairs in a bordered box.
  return (
    <RegrelloObjectSelectedValues
      fieldInstance={fieldInstance}
      regrelloObject={regrelloObject}
      regrelloObjectInstances={regrelloObjectInstances}
    />
  );
};

const sortComparator: RegrelloObjectFieldPluginType["sortComparator"] = (
  fieldInstance1,
  fieldInstance2,
  direction,
  options,
): ComparatorResult => {
  if (options?.regrelloObjectProperty == null) {
    throw new Error("RegrelloObjects cannot be sorted without a specific property to sort on.");
  }

  if (direction === "desc") {
    return RegrelloObjectFieldPlugin.sortComparator(fieldInstance2, fieldInstance1, "asc", options);
  }

  if (fieldInstance1 == null) {
    return ComparatorResult.BEFORE;
  }

  if (fieldInstance2 == null) {
    return ComparatorResult.AFTER;
  }

  const fieldPlugin = CustomFieldPluginRegistrar.getPluginForField(
    fieldInstance1.field,
  ) as RegrelloObjectFieldPluginType;

  const value1 = (fieldPlugin.getValueForFrontend(fieldInstance1) || [])
    .map(
      ({ dataObjectCells }) =>
        dataObjectCells.find(({ key }) => key === options.regrelloObjectProperty?.dataObjectKey)?.value.stringValue ??
        EMPTY_STRING,
    )
    .join("-");
  const value2 = (fieldPlugin.getValueForFrontend(fieldInstance2) || [])
    ?.map(
      ({ dataObjectCells }) =>
        dataObjectCells.find(({ key }) => key === options.regrelloObjectProperty?.dataObjectKey)?.value.stringValue ??
        EMPTY_STRING,
    )
    .join("-");

  return stringComparator(value1, value2);
};

/**
 * Describes a custom field that holds a regrelloObject.
 */
// eslint-disable-next-line react-refresh/only-export-components
export const RegrelloObjectFieldPlugin: RegrelloObjectFieldPluginType = {
  uri: "com.regrello.customField.regrelloObject",
  version: "1.0.0",

  canProcessField: (field: FieldFields): boolean => {
    return canProcessField(field);
  },

  canProcessFieldInstance: (fieldInstance: FieldInstanceBaseFields): boolean => {
    return canProcessField(fieldInstance.field);
  },

  canProcessPropertyDataType,

  findPropertyTypeIdFromLoadedPropertyTypeIds: (propertyTypes: PropertyTypeFields[]): number | undefined => {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.REGRELLO_OBJECT_INSTANCE_ID)
      ?.id;
  },

  getColumnsForTable: createViewColumnsFromField,

  getConditionOperators: (): ConditionOperatorConfig[] => {
    return getConditionOperatorsByType("REGRELLO_OBJECT");
  },

  getCreateFieldInstanceValueInputsFromFormValue: (
    field: FieldFields,
    inputType: FieldInstanceValueInputType,
    value: unknown,
    displayOrder?: number,
    spectrumFieldVersionId?: number,
    isMultiValued?: boolean,
    projection?: {
      selectedRegrelloObjectPropertyIds: number[];
    },
  ): CreateFieldInstanceValueInputs => {
    if (!isValueValid(value)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FORM_VALUE, { field, inputType, value }));
    }

    return {
      fieldId: field.id,
      regrelloObjectInstanceV2IdMultiValue: value?.map((val) => val.id),
      inputType,
      isMultiValued,
      selectedRegrelloObjectPropertyIds: projection?.selectedRegrelloObjectPropertyIds,
      displayOrder,
      spectrumFieldVersionId,
    };
  },

  getCrossWorkflowSinksFieldInstanceIds: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number[] => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSinksFieldInstanceIds;
  },

  getCrossWorkflowSourceFieldInstanceIdFromValue: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSourceFieldInstanceId;
  },

  getFieldDisplayName: (): string => {
    return t`Synced object`;
  },

  getFilterDefinition: (_field: FieldFields) => {
    return getRegrelloDefaultFilterDefinitionStringValue();
  },

  getFilterDefinitionWithValues: (_field: FieldFields, filter: ViewFilterFields) => {
    const value = filter.value ?? EMPTY_STRING;
    return getRegrelloFilterDefinitionStringValue(filter.operator, value);
  },

  getEmptyValueForFrontend: (): RegrelloObjectInstanceFields[] | undefined => {
    return undefined;
  },

  getIconName: () => {
    return "object-outline";
  },

  getNameTemplateDisplayValueFromFormValue: (value, options) => {
    if (!isValueValid(value) || value == null || value.length === 0) {
      return undefined;
    }
    return value
      .map(
        ({ dataObjectCells }) =>
          dataObjectCells.find(({ key }) => key === options?.regrelloObjectProperty?.dataObjectKey)?.value
            .stringValue ?? EMPTY_STRING,
      )
      .join(", ");
  },

  getPreferredHomeTableColumnWidth: () => {
    return TableCellDefaultWidths.TEXT_CELL;
  },

  getSourceFieldInstance: (_fieldInstance: FieldInstanceFields): FieldInstanceFields | undefined => {
    return undefined;
  },

  getSourceFieldInstanceId: (fieldInstance: FieldInstanceFields): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstanceId;
  },

  getSourceFieldInstanceInputType: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): FieldInstanceValueInputType | undefined => {
    const sourceValue = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return sourceValue.sourceFieldInstanceInputType;
  },

  getFieldProperties: (field) => {
    return field.regrelloObject?.properties.map((prop) => ({
      id: prop.id,
      name: prop.displayName || String(prop.id),
    }));
  },

  getUpdateStartingConditionsInputsFromFormValues: (
    leftFieldInstance: FieldInstanceFields,
    value: unknown,
    operator: ConditionOperator,
    leftFieldInstancePropertyId?: Maybe<number>,
  ): UpdateStartingConditionsInputs | undefined => {
    if (operator === ConditionOperator.EMPTY || operator === ConditionOperator.NOT_EMPTY) {
      if (leftFieldInstance.field.regrelloObject == null) {
        console.warn(WARNING_UNDEFINED_REGRELLO_OBJECT, {
          leftFieldInstance,
        });
      }
      if (value != null) {
        console.warn(WARNING_UNEXPECTED_DEFINED_FORM_VALUE, {
          leftFieldInstance,
          value,
        });
      }
      return getUpdateStartingConditionsInputsForEmptyOperators(
        getFieldInstanceId(leftFieldInstance),
        operator,
        leftFieldInstancePropertyId,
      );
    }

    return {
      leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
      leftFieldInstancePropertyID: leftFieldInstancePropertyId,
      operatorV2: operator,
      rightStringValue: String(value),
      rightFloatMultiValue: EMPTY_ARRAY,
      rightIntMultiValue: EMPTY_ARRAY,
      rightPartyIDMultiValue: EMPTY_ARRAY,
      rightStringMultiValue: EMPTY_ARRAY,
      rightTimeMultiValue: EMPTY_ARRAY,
    };
  },
  // (hchen): Currently this is only used for handling the edge case when flipping the field
  // instance input type from OPTIONAL to REQUESTED (i.e. required) when the a previously OPTIONAL
  // field is set as a native field on a future task. It's not necessary to implement for field
  // types other than date and user, since we only support those as native field now.
  getUpdateFieldInstanceValueInputsFromFieldInstance: (
    _fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): UpdateFieldInstanceValueInputs[] => {
    throw new Error("not implmented yet");
  },

  getValueForFrontend: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): RegrelloObjectInstanceFields[] | undefined => {
    const regrelloObjectInstance = extractAtMostOneValueOrThrow({
      fieldInstance,

      fieldInstanceValueTypeName: "FieldInstanceMultiValueRegrelloObjectInstance",
      errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
      errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
      getterIfNoValue: () => undefined,
      getterIfValue: (fieldInstanceValue) => {
        // (dosipiuk): HACK - since regrello objects have their own id's for each value, we loose
        // the instanceId. Until we get better way to sole that we add it on top of exposed types.
        if (fieldInstanceValue.regrelloObjectInstanceMultiValue != null) {
          return fieldInstanceValue.regrelloObjectInstanceMultiValue.map((value) => ({
            ...value,
            fieldInstanceId: fieldInstanceValue.id,
          }));
        }
        return undefined;
      },
    });
    return regrelloObjectInstance ?? undefined;
  },

  isCreateAndEditAllowed: false,

  isFeatureFlagEnabled: (): boolean => {
    return true;
  },

  isFieldInstanceEmpty: (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.value.length === 0;
  },

  isFieldInstanceValueUnchanged: (fieldInstance, proposedChange) => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    if (frontendFieldInstance.sourceFieldInstanceId !== proposedChange.sourceFieldInstanceValueId) {
      return false;
    }

    // If new value ends up as null-ish, then it's definitely been changed.
    if (proposedChange.regrelloObjectInstanceV2IdMultiValue == null) {
      return false;
    }

    if (frontendFieldInstance.inputType !== proposedChange.inputType) {
      return false;
    }

    // Only care about first value for the foreseeable future.
    if (
      !isEqual(
        frontendFieldInstance.value.map((value) => value.id),
        proposedChange.regrelloObjectInstanceV2IdMultiValue,
      )
    ) {
      return false;
    }

    if (fieldInstance.isMultiValued !== proposedChange.isMultiValued) {
      return false;
    }

    if (
      !isEqual(
        fieldInstance.projection?.selectedRegrelloObjectPropertyIds,
        proposedChange.selectedRegrelloObjectPropertyIds,
      )
    ) {
      return false;
    }

    return true;
  },

  isMultiValued: (): boolean => {
    return false;
  },

  renderDisplayValue: renderDisplayValue,

  renderFormField: (field, props, options) => {
    if (!canProcessField(field)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
    }
    const isDeleted = props.fieldInstance?.field.deletedAt != null;

    if (field.regrelloObject == null) {
      return undefined;
    }

    const { operator } = props;
    if (operator != null) {
      return (
        <RegrelloControlledFormFieldText
          {...props}
          key={props.controllerProps.name}
          dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
          infoTooltipText={props.description}
          isDeleted={isDeleted}
        />
      );
    }

    return (
      <RegrelloControlledFormFieldRegrelloObject
        {...props}
        key={props.controllerProps.name}
        context={options?.context}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        isDeleted={isDeleted}
        regrelloObject={field.regrelloObject}
      />
    );
  },

  renderIcon: (props) => {
    return <RegrelloIcon {...props} iconName="object-outline" />;
  },

  renderMultipleDisplayValuesForDataGrid: (fieldInstances, options) => {
    const fieldInstance = fieldInstances[0];
    const regrelloObject = fieldInstance?.field.regrelloObject;

    if (options?.regrelloObjectProperty == null || fieldInstance == null || regrelloObject == null) {
      return EMPTY_STRING;
    }

    // (dosipiuk): For now we only support displaying first occurence of the object in workflow for a given property
    const { value: regrelloObjectInstances } =
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    const dataObjectKey = options?.regrelloObjectProperty?.dataObjectKey;

    return (
      <RegrelloObjectDataGridBullets
        dataObjectKey={dataObjectKey}
        fieldInstance={fieldInstance}
        regrelloObject={regrelloObject}
        regrelloObjectInstances={regrelloObjectInstances}
      />
    );
  },

  sortComparator,
};

export function RegrelloObjectDataGridBullets({
  dataObjectKey,
  fieldInstance,
  regrelloObject,
  regrelloObjectInstances,
}: {
  dataObjectKey: string;
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields;
  regrelloObject: RegrelloObjectFields;
  regrelloObjectInstances: RegrelloObjectInstanceFields[];
}) {
  const { isOpen, open, close } = useSimpleDialog();

  const instanceValues = regrelloObjectInstances.map(
    ({ dataObjectCells }) => dataObjectCells.find(({ key }) => key === dataObjectKey)?.value.stringValue ?? "-",
  );

  if (instanceValues.length === 0) {
    return null;
  }

  const moreLength = instanceValues.length - 1;
  return (
    <>
      <ul className="m-0 cursor-pointer ps-4" onClick={open}>
        {instanceValues.length < 3 ? (
          instanceValues.map((value, index) => (
            <li key={index}>
              <RegrelloLinkify>{value}</RegrelloLinkify>
            </li>
          ))
        ) : (
          <>
            <li>
              <RegrelloLinkify>{instanceValues[0]}</RegrelloLinkify>
            </li>
            <li className="text-primary-textMuted list-none">{t`${moreLength} more`}</li>
          </>
        )}
      </ul>
      {isOpen ? (
        <RegrelloObjectSelectedValues
          fieldInstance={fieldInstance}
          onClose={close}
          regrelloObject={regrelloObject}
          regrelloObjectInstances={regrelloObjectInstances}
          withPopoverOnly={true}
        />
      ) : null}
    </>
  );
}

interface FrontendRegrelloObjectFieldInstance {
  name: string;
  inputType: FieldInstanceValueInputType;
  crossWorkflowSinksFieldInstanceIds: number[];
  crossWorkflowSourceFieldInstanceId: number | undefined;
  sinksFieldInstanceIds: number[];
  sourceFieldInstanceId: number | undefined;
  sourceFieldInstanceInputType: FieldInstanceValueInputType | undefined;
  value: RegrelloObjectInstanceFields[];
}

function translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
): FrontendRegrelloObjectFieldInstance {
  const { field } = fieldInstance;

  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  const isFieldInstanceFields = getIsFieldInstanceFields(fieldInstance);

  return extractAtMostOneValueOrThrow({
    fieldInstance,
    fieldInstanceValueTypeName: "FieldInstanceMultiValueRegrelloObjectInstance",
    errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
    errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
    getterIfNoValue: () => ({
      name: field.name,
      inputType: DEFAULT_INPUT_TYPE_IF_NO_VALUE,
      crossWorkflowSinksFieldInstanceIds: EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: undefined,
      sinksFieldInstanceIds: EMPTY_ARRAY,
      sourceFieldInstanceId: undefined,
      sourceFieldInstanceInputType: undefined,
      value: EMPTY_ARRAY,
    }),
    getterIfValue: (fieldInstanceValue) => ({
      name: field.name,
      inputType: fieldInstanceValue.inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue) &&
        fieldInstanceValue.crossWorkflowSinksFieldInstanceMultiValueRegrelloObjectInstance != null
          ? fieldInstanceValue.crossWorkflowSinksFieldInstanceMultiValueRegrelloObjectInstance.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue)
        ? fieldInstanceValue.crossWorkflowSourceFieldInstanceMultiValueRegrelloObjectInstance?.id
        : undefined,
      sinksFieldInstanceIds: isFieldInstanceFields
        ? fieldInstanceValue.sinksFieldInstanceMultiValueRegrelloObjectInstance?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstanceId: isFieldInstanceFields
        ? fieldInstanceValue.sourceFieldInstanceMultiValueRegrelloObjectInstance?.id
        : undefined,
      sourceFieldInstanceInputType: isFieldInstanceFields
        ? fieldInstanceValue.sourceFieldInstanceMultiValueRegrelloObjectInstance?.inputType
        : undefined,
      value:
        fieldInstanceValue.regrelloObjectInstanceMultiValue != null
          ? fieldInstanceValue.regrelloObjectInstanceMultiValue
          : EMPTY_ARRAY,
    }),
  });
}

function isValueValid(value: unknown): value is RegrelloObjectPluginFrontendValue {
  return (
    value == null ||
    (Array.isArray(value) && value.length === 0) ||
    (Array.isArray(value) && value.length > 0 && value.every((roField) => roField.dataObjectCells != null))
  );
}

export function RegrelloObjectSelectedValues({
  fieldInstance,
  regrelloObject,
  regrelloObjectInstances,
  withPopoverOnly,
  onClose,
}: {
  fieldInstance?: FieldInstanceFields | FieldInstanceBaseFields;
  regrelloObject: RegrelloObjectFields;
  regrelloObjectInstances: RegrelloObjectInstanceFields[];
  withPopoverOnly?: boolean;
  onClose?: () => void;
}) {
  const columns = useMemo(
    () =>
      regrelloObject.properties
        .filter((property) => {
          const projection = fieldInstance?.projection?.selectedRegrelloObjectPropertyIds;
          return projection ? projection.includes(property.id) : true;
        })
        .map(({ displayName, dataObjectKey }) => {
          const columnDef: RegrelloDataGridColumnDef<RegrelloObjectInstanceForDataGrid, string> = {
            columnId: `${dataObjectKey}`,
            displayName: displayName ?? dataObjectKey,
            renderCell: renderLinkifiedTextCell,
          };
          return regrelloDataGridColumnHelper(columnDef);
        }),
    [fieldInstance?.projection?.selectedRegrelloObjectPropertyIds, regrelloObject.properties],
  );

  const data: RegrelloObjectInstanceForDataGrid[] = useMemo(() => {
    return [
      ...regrelloObjectInstances.map((regrelloObjectInstance) =>
        convertRegrelloObjectInstanceForDataGrid(
          regrelloObject,
          regrelloObjectInstance as RegrelloObjectInstanceFields,
        ),
      ),
    ];
  }, [regrelloObject, regrelloObjectInstances]);

  return (
    <RegrelloDataGridWithPopover<RegrelloObjectInstanceForDataGrid>
      columns={columns}
      data={data}
      dataTestId={DataTestIds.SYNCED_OBJECTS_SELECTED_VALUES}
      onClose={onClose}
      withPopoverOnly={withPopoverOnly}
    />
  );
}
