/**
 * This file is responsible for generating optimistic responses in Apollo for perceived performance.
 *
 * Note 1: The functions here generate an object for `optimisticResponse`, where the object matches
 *         the shape of the mutation response we expect from the server. The `id` and `__typename`
 *         fields are required. When adding more optimistic responses, you might need to add
 *         `__typename` in *.graphql.
 * Note 2: We have to use `null` instead of `undefined` for field values that have no optimistic data.
 *         Apollo needs an object that matches the shape of the mutation response we expect.
 *         For example, if there is no data for a given field `user`, the real response we get is
 *         `user: null`. We need to mimic that response. If we pass in `undefined`, the shape will
 *         not match and the optimistic response will not be successful.
 * Note 3: If an optimistic response was not successful, it will yell at you in the console. However,
 *         the page will not crash and Apollo will wait for the real response.
 */

import { EMPTY_STRING } from "@regrello/core-utils";
import {
  type AccessRoleFields,
  AccessRoleName,
  AccessRoleScope,
  AccessRoleUserScope,
  type DeleteActionItemTemplateMutation,
  type PartyFields,
  type TagFields,
  type TenantFields,
  type UpdateWorkflowDescriptionMutation,
  type UpdateWorkflowNameMutation,
  type UpdateWorkflowTagsMutation,
  type UpdateWorkflowTemplateDescriptionMutation,
  type UpdateWorkflowTemplateNameMutation,
  type UpdateWorkflowTemplateTagsMutation,
  type UpdateWorkflowTemplateVersionNotesMutation,
  UserAccessLevel,
  type UserFields,
  UserType,
} from "@regrello/graphql-api";

export function getOptimisticResponseForWorkflowActionItemTemplateDeleted(): DeleteActionItemTemplateMutation {
  return {
    deleteActionItemTemplate: {
      __typename: "DeleteActionItemTemplateResponse",
      success: true,
      error: null,
    },
  };
}

export function getOptimisticResponseForWorkflowNameUpdated(name: string): UpdateWorkflowNameMutation {
  return {
    updateWorkflowName: {
      workflow: {
        name,
      },
    },
  };
}

export function getOptimisticResponseForWorkflowDescriptionUpdated(
  description: string,
): UpdateWorkflowDescriptionMutation {
  return {
    updateWorkflowDescription: {
      workflow: {
        description,
      },
    },
  };
}

export function getOptimisticResponseForWorkflowTemplateVersionNotesUpdated(
  versionNotes: string | undefined,
): UpdateWorkflowTemplateVersionNotesMutation {
  return {
    updateWorkflowTemplateVersionNotes: {
      workflowTemplate: {
        versionNotes,
      },
    },
  };
}

export function getOptimisticResponseForWorkflowTagsUpdated(tags: TagFields[]): UpdateWorkflowTagsMutation {
  return {
    updateWorkflowTags: {
      workflow: {
        tags,
      },
    },
  };
}

export function getOptimisticResponseForWorkflowTemplateNameUpdated(name: string): UpdateWorkflowTemplateNameMutation {
  return {
    updateWorkflowTemplateName: {
      workflowTemplate: {
        name,
      },
    },
  };
}

export function getOptimisticResponseForWorkflowTemplateDescriptionUpdated(
  description: string,
): UpdateWorkflowTemplateDescriptionMutation {
  return {
    updateWorkflowTemplateDescription: {
      workflowTemplate: {
        description,
      },
    },
  };
}

export function getOptimisticResponseForWorkflowTemplateTagsUpdated(
  tags: TagFields[],
): UpdateWorkflowTemplateTagsMutation {
  return {
    updateWorkflowTemplateTags: {
      workflowTemplate: {
        tags,
      },
    },
  };
}

// Private
// =======

export function getOptimisticUserFields(): Required<UserFields> {
  return {
    __typename: "User",
    id: getRandomId(),
    name: EMPTY_STRING,
    email: EMPTY_STRING,
    deletedAt: null,
    isAdmin: false,
    isAuthAdmin: false,
    isAuthWorkspaceCreator: false,
    isMuted: false,
    isPendingSignUp: false,
    isRegrelloEmployee: false,
    isVerified: false,
    accessLevel: UserAccessLevel.INTERNAL,
    accessRole: getOptimisticAccessRoleFields(),
    globallyUniqueMetricsId: EMPTY_STRING,
    domain: EMPTY_STRING,
    timeZone: EMPTY_STRING,
    isTimeZoneLocked: false,
    organization: null,
    tenant: getOptimisticTenantFields(),
    party: getOptimisticPartyFields(),
    createdAt: EMPTY_STRING,
    createdBy: null,
    defaultViewId: null,
    userType: UserType.DEFAULT,
    scimDetails: {
      managePermissions: false,
      isActivelyScimProvisioned: false,
      isAddedViaScim: false,
    },
    language: null,
    setupCompletedAt: null,
  };
}

export function getOptimisticAccessRoleFields(): Required<AccessRoleFields> {
  return {
    id: getRandomId(),
    name: AccessRoleName.EXTERNAL_CAN_VIEW_AND_COMMENT, // Least privilege
    displayName: EMPTY_STRING,
    scope: AccessRoleScope.TEAM,
    userScope: AccessRoleUserScope.EXTERNAL, // Least privilege
    level: 0,
  };
}

function getOptimisticPartyFields(): Required<PartyFields> {
  return {
    __typename: "Party",
    id: getRandomId(),
    user: null,
    team: null,
  };
}

function getOptimisticTenantFields(): Required<TenantFields> {
  return {
    __typename: "Tenant",
    id: getRandomId(),
    name: EMPTY_STRING,
    displayName: EMPTY_STRING,
    permissionAllowNonAdminUserToCreateUsers: false,
    permissionAllowNonAdminUsersToEditAssignees: false,
    permissionAllowNonAdminUserToInviteNonDomain: false,
    globallyUniqueMetricsId: EMPTY_STRING,
    scimUserId: null,
    uuidShort: EMPTY_STRING,
    uuid: EMPTY_STRING,
  };
}

/**
 * When creating an optimistic response with a new object, we need to provide a fake and random ID.
 * Once the real response comes back, it will replace this fake ID. Setting the fake ID to negative
 * to avoid collision with a real ID number.
 *
 * @returns number
 */
function getRandomId(): number {
  return Math.round(Math.random() * -1000000);
}
