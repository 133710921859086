import type { UserBaseFields } from "./UserBaseFields.generated";
import { gql } from "@apollo/client";
export type TeamMemberFields = { isAdmin: boolean; user: UserBaseFields };

export const TeamMemberFields = gql`
    fragment TeamMemberFields on TeamMember {
  isAdmin
  user {
    ...UserBaseFields
  }
}
    `;
