import type { PermissionedSidebarContentFlagsFields } from "./PermissionedSidebarContentFlagsFields.generated";
import type { PermissionedCreateMenuContentFlagsFields } from "./PermissionedCreateMenuContentFlagsFields.generated";
import { gql } from "@apollo/client";
export type UserPermissionsToWorkspaceFields = {
  canAssignRoles: boolean;
  canCreateRolesWithInvite: boolean;
  canCreateTags: boolean;
  permissionedNavigationFlags: {
    sidebarContentFlags: PermissionedSidebarContentFlagsFields;
    createMenuContentFlags: PermissionedCreateMenuContentFlagsFields;
  };
  customFieldPermissions: { canCreate: boolean; canEdit: boolean; canDelete: boolean };
  tagPermissions: { canEdit: boolean; canDelete: boolean };
};

export const UserPermissionsToWorkspaceFields = gql`
    fragment UserPermissionsToWorkspaceFields on UserPermissionsToWorkspace {
  permissionedNavigationFlags {
    sidebarContentFlags {
      ...PermissionedSidebarContentFlagsFields
    }
    createMenuContentFlags {
      ...PermissionedCreateMenuContentFlagsFields
    }
  }
  customFieldPermissions {
    canCreate
    canEdit
    canDelete
  }
  tagPermissions {
    canEdit
    canDelete
  }
  canAssignRoles
  canCreateRolesWithInvite
  canCreateTags
}
    `;
