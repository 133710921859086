import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import type { AutomationRequestFields } from "./AutomationRequestFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { ActionItemApprovalFields } from "./ActionItemApprovalFields.generated";
import type { IntegrationAutomationInstanceFields } from "./IntegrationAutomationInstanceFields.generated";
import type { ActionItemPermissionsFields } from "./ActionItemPermissionsFields.generated";
import type { LineFields } from "./LineFields.generated";
import { gql } from "@apollo/client";
export type WorkflowQueryActionItemFields = {
  __typename: "ActionItem";
  id: number;
  uuid: string;
  uuidLong: string;
  name: string;
  status: Types.ActionItemStatus;
  frozeForReason?: Types.ActionItemFrozeForReason | null;
  completedAt?: string | null;
  dueOn?: string | null;
  dueOnClass: Types.DueOnClass;
  hasDocuments: boolean;
  hasException: boolean;
  requiresAck: boolean;
  createdAt: string;
  actualStartAt?: string | null;
  updatedAt: string;
  numberOfUnreadComments: number;
  type: Types.ActionItemType;
  isLocked: boolean;
  originalTemplateAssigneePartyIDs?: Array<number> | null;
  description?: string | null;
  assignedBy: PartyBaseFields;
  createdBy: UserBaseFields;
  assignees: Array<PartyBaseFields>;
  cc: Array<PartyBaseFields>;
  reviewers: Array<PartyBaseFields>;
  completedBy?: PartyBaseFields | null;
  documents: Array<{ id: number }>;
  automationRequests: Array<AutomationRequestFields>;
  fieldInstancesControllingAssignees?: Array<FieldInstanceFields> | null;
  fieldInstancesControllingCc?: Array<FieldInstanceFields> | null;
  fieldInstanceControllingDueOn?: FieldInstanceFields | null;
  integrationAutomationInstance?: IntegrationAutomationInstanceFields | null;
  permissions?: ActionItemPermissionsFields | null;
  lines: Array<LineFields>;
  linkedWorkflowReference?: {
    id: number;
    name: string;
    percentComplete?: number | null;
    daysDelayed?: number | null;
    endDate?: string | null;
    completedAt?: string | null;
    deletedAt?: string | null;
    scheduleStatus?: Types.WorkflowScheduleStatus | null;
    permissions: { canRead: boolean };
  } | null;
} & ActionItemApprovalFields;

export const WorkflowQueryActionItemFields = gql`
    fragment WorkflowQueryActionItemFields on ActionItem {
  __typename
  id
  uuid
  uuidLong
  name
  status
  frozeForReason
  assignedBy {
    ...PartyBaseFields
  }
  createdBy {
    ...UserBaseFields
  }
  assignees {
    ...PartyBaseFields
  }
  cc {
    ...PartyBaseFields
  }
  reviewers {
    ...PartyBaseFields
  }
  completedAt
  completedBy {
    ...PartyBaseFields
  }
  dueOn
  dueOnClass
  hasDocuments
  hasException
  requiresAck
  createdAt
  actualStartAt
  updatedAt
  numberOfUnreadComments
  documents {
    id
  }
  type
  automationRequests {
    ...AutomationRequestFields
  }
  fieldInstancesControllingAssignees {
    ...FieldInstanceFields
  }
  fieldInstancesControllingCc {
    ...FieldInstanceFields
  }
  fieldInstanceControllingDueOn {
    ...FieldInstanceFields
  }
  ...ActionItemApprovalFields
  integrationAutomationInstance {
    ...IntegrationAutomationInstanceFields
  }
  permissions {
    ...ActionItemPermissionsFields
  }
  isLocked
  originalTemplateAssigneePartyIDs
  description
  lines {
    ...LineFields
  }
  dueOnClass
  linkedWorkflowReference {
    id
    name
    percentComplete
    daysDelayed
    endDate
    completedAt
    deletedAt
    scheduleStatus
    permissions {
      canRead
    }
  }
}
    `;
