import type { FieldInstanceMultiValueStringFields } from "./FieldInstanceMultiValueStringFields.generated";
import type { AcyclicFieldInstanceMultiValueStringFields } from "./AcyclicFieldInstanceMultiValueStringFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueStringWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceMultiValueString?: AcyclicFieldInstanceMultiValueStringFields | null;
  crossWorkflowSinksFieldInstanceMultiValueString?: Array<AcyclicFieldInstanceMultiValueStringFields> | null;
} & FieldInstanceMultiValueStringFields;

export const FieldInstanceMultiValueStringWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueStringWithCrossWorkflowFields on FieldInstanceMultiValueString {
  ...FieldInstanceMultiValueStringFields
  crossWorkflowSourceFieldInstanceMultiValueString {
    ...AcyclicFieldInstanceMultiValueStringFields
  }
  crossWorkflowSinksFieldInstanceMultiValueString {
    ...AcyclicFieldInstanceMultiValueStringFields
  }
}
    `;
