import type { AdminRoleFields } from "./AdminRoleFields.generated";
import { gql } from "@apollo/client";
export type AdminRoleFieldsWithCount = {
  usedInWorkflowTemplates?: number | null;
  memberCount?: number | null;
  role: AdminRoleFields;
};

export const AdminRoleFieldsWithCount = gql`
    fragment AdminRoleFieldsWithCount on RolesQueryRole {
  role {
    ...AdminRoleFields
  }
  usedInWorkflowTemplates
  memberCount
}
    `;
