import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type TeamWithoutMembersFields = {
  __typename: "Team";
  id: number;
  name: string;
  email: string;
  type: Types.TeamType;
  domain: string;
  createdAt: string;
  deletedAt?: string | null;
  party: { id: number };
};

export const TeamWithoutMembersFields = gql`
    fragment TeamWithoutMembersFields on Team {
  __typename
  id
  name
  email
  type
  domain
  party {
    id
  }
  createdAt
  deletedAt
}
    `;
