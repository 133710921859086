import type { UserFieldsWithoutRoles } from "./UserFieldsWithoutRoles.generated";
import { gql } from "@apollo/client";
export type UserFields = { party: { id: number } } & UserFieldsWithoutRoles;

export const UserFields = gql`
    fragment UserFields on User {
  ...UserFieldsWithoutRoles
  party {
    id
  }
}
    `;
