import type { IntegrationAutomationSummaryFields } from "./IntegrationAutomationSummaryFields.generated";
import type { IntegrationAuthenticationFields } from "./IntegrationAuthenticationFields.generated";
import type { IntegrationAutomationInputFields } from "./IntegrationAutomationInputFields.generated";
import type { IntegrationAutomationOutputFields } from "./IntegrationAutomationOutputFields.generated";
import { gql } from "@apollo/client";
export type IntegrationAutomationInstanceFields = {
  id: number;
  integrationAutomation: IntegrationAutomationSummaryFields;
  integrationAuthentication: IntegrationAuthenticationFields;
  inputs: Array<IntegrationAutomationInputFields>;
  groupedInputs: Array<{ inputGroupName: string; inputs: Array<IntegrationAutomationInputFields> }>;
  outputs: Array<IntegrationAutomationOutputFields>;
  referencingAutomationInstances?: Array<{ id: number }> | null;
};

export const IntegrationAutomationInstanceFields = gql`
    fragment IntegrationAutomationInstanceFields on IntegrationAutomationInstance {
  id
  integrationAutomation {
    ...IntegrationAutomationSummaryFields
  }
  integrationAuthentication {
    ...IntegrationAuthenticationFields
  }
  inputs {
    ...IntegrationAutomationInputFields
  }
  groupedInputs {
    inputGroupName
    inputs {
      ...IntegrationAutomationInputFields
    }
  }
  outputs {
    ...IntegrationAutomationOutputFields
  }
  referencingAutomationInstances {
    id
  }
}
    `;
