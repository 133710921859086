/** Returns a new set of all elements in `a` that are not in `b`. */
export function setDifference<T>(a: Set<T>, b: Set<T>): Set<T> {
  const result = new Set<T>();
  for (const item of a) {
    if (!b.has(item)) {
      result.add(item);
    }
  }
  return result;
}

/** Returns a new set of all elements that are in both `a` and `b`. */
export function setIntersection<T>(a: Set<T>, b: Set<T>): Set<T> {
  const result = new Set<T>();
  for (const item of a) {
    if (b.has(item)) {
      result.add(item);
    }
  }
  return result;
}

export function areSetsEqual<T>(setA: Set<T>, setB: Set<T>): boolean {
  // Check if sets have the same size
  if (setA.size !== setB.size) {
    return false;
  }

  // Check if every element in setA exists in setB
  for (const element of setA) {
    if (!setB.has(element)) {
      return false;
    }
  }

  return true;
}
