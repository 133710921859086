import type { FieldInstanceValueFloatBaseFields } from "./FieldInstanceValueFloatBaseFields.generated";
import type { AcyclicFieldInstanceValueFloatFields } from "./AcyclicFieldInstanceValueFloatFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueFloatFields = {
  sourceFieldInstanceValueFloat?: AcyclicFieldInstanceValueFloatFields | null;
  sinksFieldInstanceValueFloat: Array<AcyclicFieldInstanceValueFloatFields>;
} & FieldInstanceValueFloatBaseFields;

export const FieldInstanceValueFloatFields = gql`
    fragment FieldInstanceValueFloatFields on FieldInstanceValueFloat {
  ...FieldInstanceValueFloatBaseFields
  sourceFieldInstanceValueFloat {
    ...AcyclicFieldInstanceValueFloatFields
  }
  sinksFieldInstanceValueFloat {
    ...AcyclicFieldInstanceValueFloatFields
  }
}
    `;
