import type { SpectrumFieldVersionFields } from "./SpectrumFieldVersionFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceSpectrumFields = {
  formInstanceID?: number | null;
  formFieldID?: number | null;
  spectrumFieldVersion?: SpectrumFieldVersionFields | null;
  latestAvailableSpectrumFieldVersion?: SpectrumFieldVersionFields | null;
};

export const FieldInstanceSpectrumFields = gql`
    fragment FieldInstanceSpectrumFields on FieldInstance {
  formInstanceID
  formFieldID
  spectrumFieldVersion {
    ...SpectrumFieldVersionFields
  }
  latestAvailableSpectrumFieldVersion {
    ...SpectrumFieldVersionFields
  }
}
    `;
