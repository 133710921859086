import type { UserFields } from "./UserFields.generated";
import type { TeamWithoutMembersFields } from "./TeamWithoutMembersFields.generated";
import { gql } from "@apollo/client";
export type UserFieldsWithTeams = { teams: Array<TeamWithoutMembersFields> } & UserFields;

export const UserFieldsWithTeams = gql`
    fragment UserFieldsWithTeams on User {
  ...UserFields
  teams {
    ...TeamWithoutMembersFields
  }
}
    `;
