import type * as Types from "../../../generated/graphqlBaseTypes";

import { PartyFields } from "../../fragments/generated/PartyFields.generated";
import { UserFields } from "../../fragments/generated/UserFields.generated";
import { UserFieldsWithoutRoles } from "../../fragments/generated/UserFieldsWithoutRoles.generated";
import { AccessRoleFields } from "../../fragments/generated/AccessRoleFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type PartiesQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars["ID"]["input"]>>;
  search?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type PartiesQuery = { parties: Array<PartyFields> };

export const PartiesQueryDocument = gql`
    query PartiesQuery($ids: [ID!], $search: String) {
  parties(ids: $ids, search: $search) {
    ...PartyFields
  }
}
    ${PartyFields}
${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}
${TeamWithoutMembersFields}`;

/**
 * __usePartiesQuery__
 *
 * To run a query within a React component, call `usePartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartiesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePartiesQuery(baseOptions?: Apollo.QueryHookOptions<PartiesQuery, PartiesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartiesQuery, PartiesQueryVariables>(PartiesQueryDocument, options);
}
export function usePartiesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartiesQuery, PartiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartiesQuery, PartiesQueryVariables>(PartiesQueryDocument, options);
}
export function usePartiesQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PartiesQuery, PartiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PartiesQuery, PartiesQueryVariables>(PartiesQueryDocument, options);
}
export type PartiesQueryHookResult = ReturnType<typeof usePartiesQuery>;
export type PartiesQueryLazyQueryHookResult = ReturnType<typeof usePartiesQueryLazyQuery>;
export type PartiesQuerySuspenseQueryHookResult = ReturnType<typeof usePartiesQuerySuspenseQuery>;
export type PartiesQueryResult = Apollo.QueryResult<PartiesQuery, PartiesQueryVariables>;
