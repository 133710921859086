import type { FieldInstanceValueIntFields } from "./FieldInstanceValueIntFields.generated";
import type { AcyclicFieldInstanceValueIntFields } from "./AcyclicFieldInstanceValueIntFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueIntWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceValueInt?: AcyclicFieldInstanceValueIntFields | null;
  crossWorkflowSinksFieldInstanceValueInt?: Array<AcyclicFieldInstanceValueIntFields> | null;
} & FieldInstanceValueIntFields;

export const FieldInstanceValueIntWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueIntWithCrossWorkflowFields on FieldInstanceValueInt {
  ...FieldInstanceValueIntFields
  crossWorkflowSourceFieldInstanceValueInt {
    ...AcyclicFieldInstanceValueIntFields
  }
  crossWorkflowSinksFieldInstanceValueInt {
    ...AcyclicFieldInstanceValueIntFields
  }
}
    `;
