import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type FormConstraintConditionFields = {
  name: string;
  operator: Types.FormConstraintConditionOperator;
  values: Array<string>;
  displayOrder: number;
};

export const FormConstraintConditionFields = gql`
    fragment FormConstraintConditionFields on FormConstraintCondition {
  name
  operator
  values
  displayOrder
}
    `;
