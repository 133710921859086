/* eslint-disable lingui/no-unlocalized-strings */
/* eslint-disable react-refresh/only-export-components */
import { RegrelloNonIdealStateWithRefresh } from "@regrello/ui-core";
import { createRoot } from "react-dom/client";

import { App } from "./App";
import { RegrelloErrorBoundary } from "./ui/molecules/errorBoundary/RegrelloErrorBoundary";
import { onBeforeSentryCaptureOnAppRoot } from "./utils/sentryScopeUtils";

import "@unocss/reset/tailwind.css";
import "virtual:uno.css";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

function RootComponent() {
  return (
    <RegrelloErrorBoundary
      beforeCapture={onBeforeSentryCaptureOnAppRoot}
      fallback={
        <RegrelloNonIdealStateWithRefresh
          // (swerner) Note: this is outside of the Lingui Provider for i18n and cannot be translated.
          description="Please refresh, or contact a Regrello admin if you continue to see this error."
          title="Something went wrong"
        />
      }
    >
      <App />
    </RegrelloErrorBoundary>
  );
}

root.render(<RootComponent />);
