import type { TagTypeFields } from "./TagTypeFields.generated";
import { gql } from "@apollo/client";
export type TagFields = { createdAt: string; id: number; name: string; tagType: TagTypeFields };

export const TagFields = gql`
    fragment TagFields on Tag {
  tagType {
    ...TagTypeFields
  }
  createdAt
  id
  name
}
    `;
