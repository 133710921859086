import type * as Types from "../../../generated/graphqlBaseTypes";

import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceConditionalExpressionFields = {
  id: number;
  leftFieldInstancePropertyID?: number | null;
  operatorV2: Types.ConditionOperator;
  left: FieldInstanceFields;
  right: Array<FieldInstanceFields>;
};

export const FieldInstanceConditionalExpressionFields = gql`
    fragment FieldInstanceConditionalExpressionFields on FieldInstanceConditionalExpression {
  id
  left {
    ...FieldInstanceFields
  }
  leftFieldInstancePropertyID
  right {
    ...FieldInstanceFields
  }
  operatorV2
}
    `;
