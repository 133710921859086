import type { FieldInstanceValuePartyFields } from "./FieldInstanceValuePartyFields.generated";
import type { AcyclicFieldInstanceValuePartyFields } from "./AcyclicFieldInstanceValuePartyFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValuePartyWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceValueParty?: AcyclicFieldInstanceValuePartyFields | null;
  crossWorkflowSinksFieldInstanceValueParty?: Array<AcyclicFieldInstanceValuePartyFields> | null;
} & FieldInstanceValuePartyFields;

export const FieldInstanceValuePartyWithCrossWorkflowFields = gql`
    fragment FieldInstanceValuePartyWithCrossWorkflowFields on FieldInstanceValueParty {
  ...FieldInstanceValuePartyFields
  crossWorkflowSourceFieldInstanceValueParty {
    ...AcyclicFieldInstanceValuePartyFields
  }
  crossWorkflowSinksFieldInstanceValueParty {
    ...AcyclicFieldInstanceValuePartyFields
  }
}
    `;
