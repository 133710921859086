import type { GenericWorkflowActionItemFields } from "./GenericWorkflowActionItemFields.generated";
import { gql } from "@apollo/client";
export type WorkflowGraphActionItemFields = {
  id: number;
  stageId: number;
  actionItem: GenericWorkflowActionItemFields;
  next: Array<{ stageId: number; actionItem: GenericWorkflowActionItemFields }>;
};

export const WorkflowGraphActionItemFields = gql`
    fragment WorkflowGraphActionItemFields on WorkflowGraphActionItem {
  id
  actionItem {
    ...GenericWorkflowActionItemFields
  }
  stageId
  next {
    actionItem {
      ...GenericWorkflowActionItemFields
    }
    stageId
  }
}
    `;
