import type { FieldInstanceValueIntBaseFields } from "./FieldInstanceValueIntBaseFields.generated";
import type { AcyclicFieldInstanceValueIntFields } from "./AcyclicFieldInstanceValueIntFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueIntFields = {
  sourceFieldInstanceValueInt?: AcyclicFieldInstanceValueIntFields | null;
  sinksFieldInstanceValueInt: Array<AcyclicFieldInstanceValueIntFields>;
} & FieldInstanceValueIntBaseFields;

export const FieldInstanceValueIntFields = gql`
    fragment FieldInstanceValueIntFields on FieldInstanceValueInt {
  ...FieldInstanceValueIntBaseFields
  sourceFieldInstanceValueInt {
    ...AcyclicFieldInstanceValueIntFields
  }
  sinksFieldInstanceValueInt {
    ...AcyclicFieldInstanceValueIntFields
  }
}
    `;
