import type * as Types from "../../../generated/graphqlBaseTypes";

import { AccessRoleFields } from "../../fragments/generated/AccessRoleFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AccessRolesQueryVariables = Types.Exact<{
  scope: Types.AccessRoleScope;
}>;

export type AccessRolesQuery = { accessRoles: Array<AccessRoleFields> };

export const AccessRolesQueryDocument = gql`
    query AccessRolesQuery($scope: AccessRoleScope!) {
  accessRoles(scope: $scope) {
    ...AccessRoleFields
  }
}
    ${AccessRoleFields}`;

/**
 * __useAccessRolesQuery__
 *
 * To run a query within a React component, call `useAccessRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessRolesQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useAccessRolesQuery(
  baseOptions: Apollo.QueryHookOptions<AccessRolesQuery, AccessRolesQueryVariables> &
    ({ variables: AccessRolesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccessRolesQuery, AccessRolesQueryVariables>(AccessRolesQueryDocument, options);
}
export function useAccessRolesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccessRolesQuery, AccessRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccessRolesQuery, AccessRolesQueryVariables>(AccessRolesQueryDocument, options);
}
export function useAccessRolesQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AccessRolesQuery, AccessRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccessRolesQuery, AccessRolesQueryVariables>(AccessRolesQueryDocument, options);
}
export type AccessRolesQueryHookResult = ReturnType<typeof useAccessRolesQuery>;
export type AccessRolesQueryLazyQueryHookResult = ReturnType<typeof useAccessRolesQueryLazyQuery>;
export type AccessRolesQuerySuspenseQueryHookResult = ReturnType<typeof useAccessRolesQuerySuspenseQuery>;
export type AccessRolesQueryResult = Apollo.QueryResult<AccessRolesQuery, AccessRolesQueryVariables>;
