import type * as Types from "../../../generated/graphqlBaseTypes";

import type { AcyclicFieldInstanceFields } from "./AcyclicFieldInstanceFields.generated";
import { gql } from "@apollo/client";
export type IntegrationAutomationInputFields = {
  id: number;
  name: string;
  displayName: string;
  isDynamic: boolean;
  valueType: Types.PropertyDataType;
  externalId?: string | null;
  isRequired: boolean;
  isHidden: boolean;
  inputGroupName?: string | null;
  createdAt: string;
  fieldInstance?: AcyclicFieldInstanceFields | null;
};

export const IntegrationAutomationInputFields = gql`
    fragment IntegrationAutomationInputFields on IntegrationAutomationInput {
  id
  name
  displayName
  isDynamic
  valueType
  externalId
  isRequired
  isHidden
  inputGroupName
  fieldInstance {
    ...AcyclicFieldInstanceFields
  }
  createdAt
}
    `;
