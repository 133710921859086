import type { SpectrumFieldVersionFields } from "./SpectrumFieldVersionFields.generated";
import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { RegrelloObjectPropertyFields } from "./RegrelloObjectPropertyFields.generated";
import type { RegrelloObjectInstanceFields } from "./RegrelloObjectInstanceFields.generated";
import type { ActionItemAuditHistoryDocumentRelatedRecordFields } from "./ActionItemAuditHistoryDocumentRelatedRecordFields.generated";
import type { ActionItemAuditHistoryFieldInstanceRelatedRecordFields } from "./ActionItemAuditHistoryFieldInstanceRelatedRecordFields.generated";
import { gql } from "@apollo/client";
export type ActionItemAuditHistoryDeltaFields = {
  id: number;
  fieldName: string;
  valueBefore?: string | null;
  valueAfter?: string | null;
  collectionAdd?: string | null;
  collectionRemove?: string | null;
  spectrumFieldVersion?: SpectrumFieldVersionFields | null;
  field?: {
    id: number;
    isMultiValued: boolean;
    name: string;
    allowedValues: Array<
      | { __typename: "FieldAllowedValueParty"; id: number; displayOrder: number; partyValue: PartyBaseFields }
      | { __typename: "FieldAllowedValueString"; id: number; displayOrder: number; stringValue: string }
    >;
    fieldUnit?: { id: number; symbol: string } | null;
    regrelloObject?: { id: number; properties: Array<RegrelloObjectPropertyFields> } | null;
    fieldRestriction?: { filterByRole?: { name: string } | null } | null;
  } | null;
  addedRecords: Array<{ id: number; name?: string | null }>;
  removedRecords: Array<{ id: number; name?: string | null }>;
  addedRecordsV2: Array<
    { __typename: "AuditHistoryRegrelloObjectInstance"; regrelloObjectInstance: RegrelloObjectInstanceFields } | {}
  >;
  removedRecordsV2: Array<
    { __typename: "AuditHistoryRegrelloObjectInstance"; regrelloObjectInstance: RegrelloObjectInstanceFields } | {}
  >;
  relatedRecords: Array<
    ActionItemAuditHistoryDocumentRelatedRecordFields | ActionItemAuditHistoryFieldInstanceRelatedRecordFields | {}
  >;
};

export const ActionItemAuditHistoryDeltaFields = gql`
    fragment ActionItemAuditHistoryDeltaFields on ActionItemAuditHistoryDelta {
  id
  fieldName
  spectrumFieldVersion {
    ...SpectrumFieldVersionFields
  }
  field {
    id
    isMultiValued
    allowedValues {
      ... on FieldAllowedValueString {
        __typename
        id
        displayOrder
        stringValue
      }
      ... on FieldAllowedValueParty {
        __typename
        id
        displayOrder
        partyValue {
          ...PartyBaseFields
        }
      }
    }
    name
    fieldUnit {
      id
      symbol
    }
    regrelloObject {
      id
      properties {
        ...RegrelloObjectPropertyFields
      }
    }
    fieldRestriction {
      filterByRole {
        name
      }
    }
  }
  valueBefore
  valueAfter
  collectionAdd
  collectionRemove
  addedRecords {
    id
    name
  }
  removedRecords {
    id
    name
  }
  addedRecordsV2 {
    ... on AuditHistoryRegrelloObjectInstance {
      __typename
      regrelloObjectInstance {
        ...RegrelloObjectInstanceFields
      }
    }
  }
  removedRecordsV2 {
    ... on AuditHistoryRegrelloObjectInstance {
      __typename
      regrelloObjectInstance {
        ...RegrelloObjectInstanceFields
      }
    }
  }
  relatedRecords {
    ...ActionItemAuditHistoryDocumentRelatedRecordFields
    ...ActionItemAuditHistoryFieldInstanceRelatedRecordFields
  }
}
    `;
