import type { ActionItemTemplateStartAfterActionItemTemplateFields } from "./ActionItemTemplateStartAfterActionItemTemplateFields.generated";
import { gql } from "@apollo/client";
export type ActionItemTemplateStartAfterActionItemTemplateChain = {
  startAfterActionItemTemplate?:
    | ({
        startAfterActionItemTemplate?:
          | ({
              startAfterActionItemTemplate?:
                | ({
                    startAfterActionItemTemplate?:
                      | ({
                          startAfterActionItemTemplate?:
                            | ({
                                startAfterActionItemTemplate?:
                                  | ({
                                      startAfterActionItemTemplate?:
                                        | ({
                                            startAfterActionItemTemplate?:
                                              | ({
                                                  startAfterActionItemTemplate?:
                                                    | ({
                                                        startAfterActionItemTemplate?:
                                                          | ({
                                                              startAfterActionItemTemplate?:
                                                                | ({
                                                                    startAfterActionItemTemplate?:
                                                                      | ({
                                                                          startAfterActionItemTemplate?:
                                                                            | ({
                                                                                startAfterActionItemTemplate?:
                                                                                  | ({
                                                                                      startAfterActionItemTemplate?:
                                                                                        | ({
                                                                                            startAfterActionItemTemplate?:
                                                                                              | ({
                                                                                                  startAfterActionItemTemplate?:
                                                                                                    | ({
                                                                                                        startAfterActionItemTemplate?:
                                                                                                          | ({
                                                                                                              startAfterActionItemTemplate?:
                                                                                                                | ({
                                                                                                                    startAfterActionItemTemplate?: ActionItemTemplateStartAfterActionItemTemplateFields | null;
                                                                                                                  } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                                                                                | null;
                                                                                                            } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                                                                          | null;
                                                                                                      } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                                                                    | null;
                                                                                                } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                                                              | null;
                                                                                          } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                                                        | null;
                                                                                    } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                                                  | null;
                                                                              } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                                            | null;
                                                                        } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                                      | null;
                                                                  } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                                | null;
                                                            } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                          | null;
                                                      } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                                    | null;
                                                } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                              | null;
                                          } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                        | null;
                                    } & ActionItemTemplateStartAfterActionItemTemplateFields)
                                  | null;
                              } & ActionItemTemplateStartAfterActionItemTemplateFields)
                            | null;
                        } & ActionItemTemplateStartAfterActionItemTemplateFields)
                      | null;
                  } & ActionItemTemplateStartAfterActionItemTemplateFields)
                | null;
            } & ActionItemTemplateStartAfterActionItemTemplateFields)
          | null;
      } & ActionItemTemplateStartAfterActionItemTemplateFields)
    | null;
};

export const ActionItemTemplateStartAfterActionItemTemplateChain = gql`
    fragment ActionItemTemplateStartAfterActionItemTemplateChain on ActionItemTemplate {
  startAfterActionItemTemplate {
    ...ActionItemTemplateStartAfterActionItemTemplateFields
    startAfterActionItemTemplate {
      ...ActionItemTemplateStartAfterActionItemTemplateFields
      startAfterActionItemTemplate {
        ...ActionItemTemplateStartAfterActionItemTemplateFields
        startAfterActionItemTemplate {
          ...ActionItemTemplateStartAfterActionItemTemplateFields
          startAfterActionItemTemplate {
            ...ActionItemTemplateStartAfterActionItemTemplateFields
            startAfterActionItemTemplate {
              ...ActionItemTemplateStartAfterActionItemTemplateFields
              startAfterActionItemTemplate {
                ...ActionItemTemplateStartAfterActionItemTemplateFields
                startAfterActionItemTemplate {
                  ...ActionItemTemplateStartAfterActionItemTemplateFields
                  startAfterActionItemTemplate {
                    ...ActionItemTemplateStartAfterActionItemTemplateFields
                    startAfterActionItemTemplate {
                      ...ActionItemTemplateStartAfterActionItemTemplateFields
                      startAfterActionItemTemplate {
                        ...ActionItemTemplateStartAfterActionItemTemplateFields
                        startAfterActionItemTemplate {
                          ...ActionItemTemplateStartAfterActionItemTemplateFields
                          startAfterActionItemTemplate {
                            ...ActionItemTemplateStartAfterActionItemTemplateFields
                            startAfterActionItemTemplate {
                              ...ActionItemTemplateStartAfterActionItemTemplateFields
                              startAfterActionItemTemplate {
                                ...ActionItemTemplateStartAfterActionItemTemplateFields
                                startAfterActionItemTemplate {
                                  ...ActionItemTemplateStartAfterActionItemTemplateFields
                                  startAfterActionItemTemplate {
                                    ...ActionItemTemplateStartAfterActionItemTemplateFields
                                    startAfterActionItemTemplate {
                                      ...ActionItemTemplateStartAfterActionItemTemplateFields
                                      startAfterActionItemTemplate {
                                        ...ActionItemTemplateStartAfterActionItemTemplateFields
                                        startAfterActionItemTemplate {
                                          ...ActionItemTemplateStartAfterActionItemTemplateFields
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
