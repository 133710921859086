import { t } from "@lingui/macro";
/* eslint-disable class-methods-use-this */
import { noop } from "@regrello/core-utils";
import {
  type DocumentFields,
  FormConstraintConditionOperator,
  PropertyDataType,
  type PropertyTypeFields,
  type SpectrumFieldValidationTypeFields,
  type SpectrumFieldVersionFields,
  type SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import type { ReactNode } from "react";
import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";

import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";
import { FrontendValueConstraintRuleName } from "./utils/spectrumFieldConstraintUtils";
import { AllAllowedFileTypes } from "../../../constants/globalConstants";
import type { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import type { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { RegrelloControlledFormFieldMultiSelect } from "../formFields/controlled/regrelloControlledFormFields";
import { RegrelloFormFieldDocument } from "../formFields/RegrelloFormFieldDocument";
import { RegrelloFormFieldSwitch } from "../formFields/RegrelloFormFieldSwitch";

type DocumentFieldPluginFrontendValue = DocumentFields[];

export class SpectrumDocumentFieldPluginDecorator extends SpectrumFieldPluginDecorator<DocumentFieldPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<DocumentFieldPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.document";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return [SpectrumFieldValidationType.DOCUMENT, SpectrumFieldValidationType.CERTIFICATE].includes(
      spectrumValidationType.validationType,
    );
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.DOCUMENT_ID);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find(
      (validationType) => validationType.validationType === SpectrumFieldValidationType.DOCUMENT,
    );
  }

  public findValueConstraintsFromLoadedValueConstraints(valueConstraints: SpectrumValueConstraintFields[]) {
    return valueConstraints.filter(
      ({ valueConstraintRule }) =>
        valueConstraintRule === FrontendValueConstraintRuleName.DOCUMENT_TYPE ||
        valueConstraintRule === FrontendValueConstraintRuleName.DOCUMENT_DISALLOW_LINK,
    );
  }

  public isDataFormatToggleVisible() {
    return true;
  }

  public isValueConstraintEnabled() {
    return true;
  }

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.IS_ZERO]: {
        label: t`Is empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_NOT_ZERO]: {
        label: t`Is not empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.ARRAY_COUNT_EQUALS]: {
        label: t`Is count equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onBlur" as const;
  }

  public renderPreviewFormField() {
    return (
      <RegrelloFormFieldDocument
        disabled={true}
        onChange={noop}
        onUploadFinish={noop}
        onUploadStart={noop}
        size="small"
        value={[]}
      />
    );
  }

  public renderValueConstraints(props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints", "id">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
    focusField: `valueConstraints.${number}.args.${number}`;
    updateConstraint: (index: number, value: ConfigureSpectrumFieldFormFormFields["valueConstraints"][number]) => void;
  }): ReactNode {
    const { constraints, disabled, form, focusField, updateConstraint } = props;

    if (constraints.length <= 0) {
      return null;
    }

    return (
      <>
        {constraints.map((constraint, index) => {
          if (constraint.constraint.valueConstraintRule === FrontendValueConstraintRuleName.DOCUMENT_TYPE) {
            return (
              <RegrelloControlledFormFieldMultiSelect
                key={constraint.id}
                autoFocus={focusField === `valueConstraints.${index}.args.0`}
                controllerProps={{
                  control: form.control,
                  name: `valueConstraints.${index}.args`,
                }}
                disabled={disabled}
                getSelectedItemProps={getSelectedItemProps}
                helperText={t`List of accepted file extensions for this field.`}
                itemPredicate={itemPredicate}
                items={AllAllowedFileTypes}
                label={t`File extensions`}
                renderItem={renderItem}
              />
            );
          }
          const fieldName = `valueConstraints.${index}.args.0` as const;
          return (
            <RegrelloFormFieldSwitch
              key={constraint.id}
              checked={form.getValues(fieldName) === "true"}
              disabled={disabled}
              label={t`Disallow external link`}
              onChange={(isChecked) => {
                const previousValue = form.getValues(`valueConstraints.${index}`);
                if (!isChecked) {
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  updateConstraint(index, { ...previousValue, args: ["false"] });
                } else {
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  updateConstraint(index, { ...previousValue, args: ["true"] });
                }
              }}
              variant="spectrum"
            />
          );
        })}
      </>
    );
  }
}

function getSelectedItemProps(item: string) {
  return {
    children: getItemDisplayValue(item),
  };
}

function itemPredicate(query: string, item: string) {
  return getItemDisplayValue(item).toLocaleLowerCase().includes(query.toLocaleLowerCase());
}

function renderItem(item: string) {
  return {
    key: item,
    text: getItemDisplayValue(item),
  };
}

function getItemDisplayValue(item: string) {
  return `.${item}`;
}
