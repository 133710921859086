import type { UserFieldsWithTeams } from "./UserFieldsWithTeams.generated";
import type { TeamFields } from "./TeamFields.generated";
import { gql } from "@apollo/client";
export type PartyWithActionItemCountFields = {
  numInProgress: number;
  numOnTrack: number;
  numException: number;
  numOverdue: number;
  numCompleted: number;
  numPaused: number;
  numEnded: number;
  numTotal: number;
  numOwnedWorkflows: number;
  party: {
    __typename: "Party";
    id: number;
    user?: ({ lastActive?: string | null } & UserFieldsWithTeams) | null;
    team?: TeamFields | null;
  };
  permissions?: { canViewTasks: boolean } | null;
};

export const PartyWithActionItemCountFields = gql`
    fragment PartyWithActionItemCountFields on PartyWithActionItemCount {
  party {
    __typename
    id
    user {
      ...UserFieldsWithTeams
      lastActive
    }
    team {
      ...TeamFields
    }
  }
  numInProgress
  numOnTrack
  numException
  numOverdue
  numCompleted
  numPaused
  numEnded
  numTotal
  numOwnedWorkflows
  permissions {
    canViewTasks
  }
}
    `;
