import type * as Types from "../../../generated/graphqlBaseTypes";

import { TagFields } from "../../fragments/generated/TagFields.generated";
import { TagTypeFields } from "../../fragments/generated/TagTypeFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type TagsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars["ID"]["input"]>>;
  search?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type TagsQuery = { tags: Array<TagFields> };

export const TagsQueryDocument = gql`
    query TagsQuery($ids: [ID!], $search: String) {
  tags(ids: $ids, search: $search) {
    ...TagFields
  }
}
    ${TagFields}
${TagTypeFields}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsQueryDocument, options);
}
export function useTagsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsQueryDocument, options);
}
export function useTagsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TagsQuery, TagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TagsQuery, TagsQueryVariables>(TagsQueryDocument, options);
}
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsQueryLazyQueryHookResult = ReturnType<typeof useTagsQueryLazyQuery>;
export type TagsQuerySuspenseQueryHookResult = ReturnType<typeof useTagsQuerySuspenseQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
