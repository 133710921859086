import type * as Types from "../../../generated/graphqlBaseTypes";

import type { RegrelloObjectInstanceFields } from "./RegrelloObjectInstanceFields.generated";
import { gql } from "@apollo/client";
export type AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields = {
  __typename: "FieldInstanceMultiValueRegrelloObjectInstance";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  regrelloObjectInstanceMultiValue: Array<RegrelloObjectInstanceFields>;
};

export const AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields = gql`
    fragment AcyclicFieldInstanceMultiValueRegrelloObjectInstanceFields on FieldInstanceMultiValueRegrelloObjectInstance {
  __typename
  id
  inputType
  regrelloObjectInstanceMultiValue {
    ...RegrelloObjectInstanceFields
  }
}
    `;
