import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyFields } from "./PartyFields.generated";
import type { FormFieldFields } from "./FormFieldFields.generated";
import type { FormSectionConstraintFields } from "./FormSectionConstraintFields.generated";
import { gql } from "@apollo/client";
export type FormSectionBaseFields = {
  id: number;
  kind: Types.FormSectionKind;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
  displayOrder: number;
  instructionOnly: boolean;
  updatedBy: PartyFields;
  formVersion: { id: number; uuid: string };
  parentFormSection?: { uuid: string } | null;
  formFields: Array<FormFieldFields>;
  formSectionConstraint: Array<FormSectionConstraintFields>;
};

export const FormSectionBaseFields = gql`
    fragment FormSectionBaseFields on FormSection {
  id
  kind
  uuid
  createdAt
  updatedAt
  updatedBy {
    ...PartyFields
  }
  formVersion {
    id
    uuid
  }
  title
  description
  displayOrder
  instructionOnly
  parentFormSection {
    uuid
  }
  formFields {
    ...FormFieldFields
  }
  formSectionConstraint {
    ...FormSectionConstraintFields
  }
}
    `;
