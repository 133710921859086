import type { CommentFields } from "./CommentFields.generated";
import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type RootCommentFields = { createdBy: PartyBaseFields } & CommentFields;

export const RootCommentFields = gql`
    fragment RootCommentFields on Comment {
  ...CommentFields
  createdBy {
    ...PartyBaseFields
  }
}
    `;
