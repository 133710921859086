import type * as Types from "../../../generated/graphqlBaseTypes";

import type { DocumentFields } from "./DocumentFields.generated";
import { gql } from "@apollo/client";
export type CommentFields = {
  id: number;
  text: string;
  createdAt: string;
  createdByUnknownEmail?: string | null;
  hasCurrentUserRead: boolean;
  visibilityType: Types.CommentVisibilityType;
  mentioningActionItemDeepLink?: string | null;
  mentioningActionItemName?: string | null;
  createdBy: { id: number };
  documents: Array<DocumentFields>;
};

export const CommentFields = gql`
    fragment CommentFields on Comment {
  id
  text
  createdAt
  createdBy {
    id
  }
  createdByUnknownEmail
  documents {
    ...DocumentFields
  }
  hasCurrentUserRead
  visibilityType
  mentioningActionItemDeepLink
  mentioningActionItemName
}
    `;
