import { isDefined } from "@regrello/core-utils";
import type { FieldInstanceFields, FieldInstanceValueStringFields } from "@regrello/graphql-api";

import { extractAllowedValuesForFrontend } from "./extractAllowedValuesForFrontend";
import type { FieldInstanceBaseFields } from "../../../../../types";
import { getErrorMessageWithPayload } from "../../../../../utils/getErrorMessageWithPayload";
import {
  type CustomFieldFrontendSelectableOption,
  INACTIVE_SELECTABLE_OPTION_ID,
} from "../types/CustomFieldFrontendSelectableOption";

/**
 * Extract a frontend-compatible selected value from the provided 'Select' field instance.
 *
 * @throws if any selected values do not appear in `field.allowedValues`
 * @throws if any selected values do not have type `"FieldInstanceValueString"`
 * @throws if more than 1 value is selected
 */
export function extractSelectedValueOrThrow<TReturnValue>(params: {
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields;
  errorMessageIfMultipleValues: string;
  errorMessageIfWrongValueType: string;
  getterIfNoValue: (allowedValues: CustomFieldFrontendSelectableOption[]) => TReturnValue;
  getterIfValue: (
    fieldInstanceValues: FieldInstanceValueStringFields[],
    frontendSelectedOptions: CustomFieldFrontendSelectableOption[],
    allowedValues: CustomFieldFrontendSelectableOption[],
  ) => TReturnValue;
  options?: {
    includeInactiveOptions: boolean;
  };
}): TReturnValue {
  const {
    fieldInstance,
    errorMessageIfMultipleValues,
    errorMessageIfWrongValueType,
    getterIfNoValue,
    getterIfValue,
    options,
  } = params;

  const { field, values } = fieldInstance;

  const frontendOptions = extractAllowedValuesForFrontend(field);

  if (values.length === 0) {
    return getterIfNoValue(frontendOptions);
  }

  if (values.length > 1) {
    throw new Error(getErrorMessageWithPayload(errorMessageIfMultipleValues, { fieldInstance }));
  }

  const representativeSingleValue = values[0];

  const isSomeValueHasInvalidType = values.some((value) => value.__typename !== "FieldInstanceValueString");

  if (representativeSingleValue.__typename !== "FieldInstanceValueString" || isSomeValueHasInvalidType) {
    throw new Error(getErrorMessageWithPayload(errorMessageIfWrongValueType, { fieldInstance }));
  }

  // (clewis): We've just asserted above that this array contains string values only.
  const valuesTyped = values as FieldInstanceValueStringFields[];

  const frontendOptionIdsByValues = new Map(frontendOptions.map((option) => [option.value, option.id]));
  const frontendSelectedOptionsWithWrongIds = valuesTyped
    // (clewis): We eventually want the allowedValue.id, not the value.id, else it'll be
    // possible to select duplicate values.
    .map((value) => (value.stringValue != null ? { id: value.id, value: value.stringValue } : undefined))
    .filter(isDefined);

  const frontendSelectedOptionsWithCorrectIds = frontendSelectedOptionsWithWrongIds.map((option) => {
    const optionId = frontendOptionIdsByValues.get(option.value);
    if (optionId == null && !options?.includeInactiveOptions) {
      return undefined;
    }
    // (clewis, zstanik): Replace with the original allowedValue ID. The allowed value may have been
    // deleted since the original data was submitted so set the ID to a default ID to indicate the
    // allowed value was deleted.
    return { id: optionId ?? INACTIVE_SELECTABLE_OPTION_ID, value: option.value };
  });

  // (clewis): Silently omit invalid values rather than throwing an error.
  return getterIfValue(valuesTyped, frontendSelectedOptionsWithCorrectIds.filter(isDefined), frontendOptions);
}
