import { gql } from "@apollo/client";
export type FormFieldConstraintRuleFields = { id: number; uuid: string; formFieldConstraintRule: string };

export const FormFieldConstraintRuleFields = gql`
    fragment FormFieldConstraintRuleFields on FormFieldConstraintRule {
  id
  uuid
  formFieldConstraintRule
}
    `;
