import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { FormMinimalFields } from "./FormMinimalFields.generated";
import { gql } from "@apollo/client";
export type FormsQueryFormFields = {
  id: number;
  uuid: string;
  publishedFromFormID?: number | null;
  deletedAt?: string | null;
  createdBy: PartyBaseFields;
  latestFormVersion: {
    id: number;
    uuid: string;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    numberOfFields: number;
    createdBy: PartyBaseFields;
    form: FormMinimalFields;
    occupiedSpectrumFieldNamesById: Array<{ spectrumFieldId: number; spectrumFieldVersionName: string }>;
  };
};

export const FormsQueryFormFields = gql`
    fragment FormsQueryFormFields on Form {
  id
  uuid
  publishedFromFormID
  deletedAt
  createdBy {
    ...PartyBaseFields
  }
  latestFormVersion {
    id
    uuid
    name
    description
    createdAt
    updatedAt
    createdBy {
      ...PartyBaseFields
    }
    form {
      ...FormMinimalFields
    }
    numberOfFields
    occupiedSpectrumFieldNamesById {
      spectrumFieldId
      spectrumFieldVersionName
    }
  }
}
    `;
