import type * as Types from "../../../generated/graphqlBaseTypes";

import { DocumentFields } from "../../fragments/generated/DocumentFields.generated";
import { DocumentVersionFields } from "../../fragments/generated/DocumentVersionFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { TagFields } from "../../fragments/generated/TagFields.generated";
import { TagTypeFields } from "../../fragments/generated/TagTypeFields.generated";
import { WorkflowPermissionsFields } from "../../fragments/generated/WorkflowPermissionsFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateDocumentMutationVariables = Types.Exact<{
  filename: Types.Scalars["String"]["input"];
  externalLink?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type CreateDocumentMutation = {
  createDocument?: { error?: string | null; signedUrl?: string | null; document?: DocumentFields | null } | null;
};

export const CreateDocumentMutationDocument = gql`
    mutation CreateDocumentMutation($filename: String!, $externalLink: String) {
  createDocument(input: {name: $filename, externalLink: $externalLink}) {
    error
    document {
      ...DocumentFields
    }
    signedUrl
  }
}
    ${DocumentFields}
${DocumentVersionFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${TagFields}
${TagTypeFields}
${WorkflowPermissionsFields}`;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      externalLink: // value for 'externalLink'
 *   },
 * });
 */
export function useCreateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(
    CreateDocumentMutationDocument,
    options,
  );
}
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>;
