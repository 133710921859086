import type { FormSectionConstraintRuleFields } from "./FormSectionConstraintRuleFields.generated";
import type { FormConstraintConditionGroupFields } from "./FormConstraintConditionGroupFields.generated";
import { gql } from "@apollo/client";
export type FormSectionConstraintFields = {
  id: number;
  uuid: string;
  formSectionConstraintRule: FormSectionConstraintRuleFields;
  condition: FormConstraintConditionGroupFields;
};

export const FormSectionConstraintFields = gql`
    fragment FormSectionConstraintFields on FormSectionConstraint {
  id
  uuid
  formSectionConstraintRule {
    ...FormSectionConstraintRuleFields
  }
  condition {
    ...FormConstraintConditionGroupFields
  }
}
    `;
