import { t } from "@lingui/macro";
import { ComparatorResult, EMPTY_ARRAY, endOfDay, formatDateString } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  ConditionOperator,
  type CreateFieldInstanceValueInputs,
  type FieldFields,
  type FieldInstanceFields,
  type FieldInstanceFieldsWithBaseValues,
  type FieldInstanceValueInputType,
  PropertyDataType,
  type PropertyTypeFields,
  type UpdateFieldInstanceValueInputs,
  type UpdateStartingConditionsInputs,
  type ViewFilterFields,
} from "@regrello/graphql-api";
import { RegrelloIcon } from "@regrello/ui-core";
import { isValid, parseISO } from "date-fns";
import type { FieldPath, FieldValues } from "react-hook-form";

import { CustomFieldPluginRegistrar } from "./registry/customFieldPluginRegistrar";
import type { CustomFieldPlugin, CustomFieldPluginV2RenderFormFieldProps } from "./types/CustomFieldPlugin";
import { createViewColumnsFromField } from "./utils/createViewColumnsFromField";
import { DEFAULT_INPUT_TYPE_IF_NO_VALUE, getConditionOperatorsByType } from "./utils/customFieldConstants";
import { extractAtMostOneValueOrThrow } from "./utils/extractAtMostOneValueOrThrow";
import {
  getIsFieldInstanceFields,
  getIsFieldInstanceValueWithCrossWorkflowFields,
} from "./utils/fieldInstanceTypeguards";
import { getFieldInstanceFieldsFromAcyclicFieldInstanceFields } from "./utils/getFieldInstanceFieldsFromAcyclicFieldInstanceFields";
import { getUpdateStartingConditionsInputsForEmptyOperators } from "./utils/getUpdateStartingConditionsInputsForEmptyOperators";
import type { FieldInstanceBaseFields } from "../../../../types";
import { numberComparator } from "../../../../utils/comparators/numberComparator";
import { getFieldInstanceId } from "../../../../utils/customFields/getFieldInstanceId";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import { TableCellDefaultWidths } from "../../../../utils/tableCellWidthUtils";
import {
  getRegrelloDefaultFilterDefinitionDateValue,
  getRegrelloFilterDefinitionDateValue,
} from "../../../molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Constants";
import { RegrelloControlledFormFieldDate } from "../../formFields/controlled/regrelloControlledFormFields";
import { RegrelloCustomFieldMultiValuePopover } from "../components/RegrelloCustomFieldMultiValuePopover";

// TODO Misc: Turn the following into warnings and handle gracefully so the app doesn't crash in
// case of field misconfiguration.
const ERROR_INVALID_FIELD = "Provided 'date' field is invalid";
const ERROR_INVALID_FORM_VALUE = "Provided 'date'-field form value is not a valid date string";
const ERROR_INVALID_VALUE_COUNT = "Provided 'date' field instance cannot have multiple values";
const WARNING_INVALID_VALUE_TYPE = "Provided 'date' field instance value must have type 'FieldInstanceValueTime'";
const WARNING_INVALID_OPERATOR_TYPE = "Provided operator type is invalid for 'date' fields";
const WARNING_UNEXPECTED_EMPTY_FORM_VALUE =
  "Provided 'date' field form value must not be empty given the provided operator type";
const WARNING_UNEXPECTED_DEFINED_FORM_VALUE =
  "Provided 'date' field form value must not be defined given the provided operator type";

function canProcessPropertyDataType(propertyDataType: PropertyDataType): boolean {
  return propertyDataType === PropertyDataType.TIME;
}

function canProcessField(field: FieldFields): boolean {
  return (
    !field.isMultiValued && field.allowedValues.length === 0 && canProcessPropertyDataType(field.propertyType.dataType)
  );
}

type DateFieldPluginFrontendValue = Date | null;
type DateFieldPluginType = CustomFieldPlugin<DateFieldPluginFrontendValue>;

const getConditionOperators: DateFieldPluginType["getConditionOperators"] = () => getConditionOperatorsByType("DATE");

const renderDisplayValue: DateFieldPluginType["renderDisplayValue"] = (fieldInstance) => {
  const { value } = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
  const output = value != null ? formatDateString(value) : undefined;

  return output;
};

const sortComparator: DateFieldPluginType["sortComparator"] = (
  fieldInstance1,
  fieldInstance2,
  direction = "asc",
): ComparatorResult => {
  if (direction === "desc") {
    return DateFieldPlugin.sortComparator(fieldInstance2, fieldInstance1, "asc");
  }

  if (fieldInstance1 == null) {
    return ComparatorResult.BEFORE;
  }

  if (fieldInstance2 == null) {
    return ComparatorResult.AFTER;
  }

  const fieldPlugin = CustomFieldPluginRegistrar.getPluginForField(fieldInstance1.field);

  const value1 = (fieldPlugin.getValueForFrontend(fieldInstance1) as Date).getTime();
  const value2 = (fieldPlugin.getValueForFrontend(fieldInstance2) as Date).getTime();

  return numberComparator(value1, value2);
};

/**
 * Describes a custom field that holds a date value, in the form of an ISO-8601 string.
 */
export const DateFieldPlugin: DateFieldPluginType = {
  uri: "com.regrello.customField.date",
  version: "1.0.0",

  canProcessField: (field: FieldFields): boolean => {
    return canProcessField(field);
  },

  canProcessFieldInstance: (fieldInstance: FieldInstanceBaseFields): boolean => {
    try {
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
      return true;
    } catch (_error) {
      return false;
    }
  },

  canProcessPropertyDataType,

  findPropertyTypeIdFromLoadedPropertyTypeIds: (propertyTypes: PropertyTypeFields[]): number | undefined => {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.TIME)?.id;
  },

  getColumnsForTable: createViewColumnsFromField,

  getConditionOperators,

  getCreateFieldInstanceValueInputsFromFormValue: (
    field: FieldFields,
    inputType: FieldInstanceValueInputType,
    value: unknown,
    displayOrder?: number,
    spectrumFieldVersionId?: number,
  ): CreateFieldInstanceValueInputs => {
    if (!isValueValid(value)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FORM_VALUE, { field, inputType, value }));
    }

    return {
      fieldId: field.id,
      timeValue: value != null ? endOfDay(value).toISOString() : undefined,
      inputType,
      displayOrder,
      spectrumFieldVersionId,
    };
  },

  getCrossWorkflowSinksFieldInstanceIds: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number[] => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSinksFieldInstanceIds;
  },

  getCrossWorkflowSourceFieldInstanceIdFromValue: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSourceFieldInstanceId;
  },

  getNameTemplateDisplayValueFromFormValue: (value) => {
    if (!isValueValid(value) || value == null) {
      return undefined;
    }
    return formatDateString(value);
  },

  getFieldDisplayName: (): string => {
    return t`Date`;
  },

  getFilterDefinition: (_field: FieldFields) => {
    return getRegrelloDefaultFilterDefinitionDateValue();
  },

  getFilterDefinitionWithValues: (_field: FieldFields, filter: ViewFilterFields) => {
    return getRegrelloFilterDefinitionDateValue(filter.operator, filter.value, filter.values);
  },

  getEmptyValueForFrontend: (_, defaultValue): Date | null => {
    return defaultValue ? new Date(Date.parse(defaultValue)) : null;
  },

  getIconName: () => {
    return "date-field";
  },

  getPreferredHomeTableColumnWidth: () => {
    return TableCellDefaultWidths.DATE_CELL;
  },

  getSourceFieldInstance: (fieldInstance: FieldInstanceFields): FieldInstanceFields | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstance;
  },

  getSourceFieldInstanceId: (fieldInstance: FieldInstanceFields): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstanceId;
  },

  getSourceFieldInstanceInputType: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): FieldInstanceValueInputType | undefined => {
    const sourceValue = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return sourceValue.sourceFieldInstanceInputType;
  },

  getUpdateStartingConditionsInputsFromFormValues: (
    leftFieldInstance: FieldInstanceFields,
    value: unknown,
    operator: ConditionOperator,
  ): UpdateStartingConditionsInputs | undefined => {
    if (!isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        leftFieldInstance,
        operator,
      });
      return undefined;
    }

    if (operator === ConditionOperator.EMPTY || operator === ConditionOperator.NOT_EMPTY) {
      if (value != null) {
        console.warn(WARNING_UNEXPECTED_DEFINED_FORM_VALUE, {
          leftFieldInstance,
          value,
        });
      }
      return getUpdateStartingConditionsInputsForEmptyOperators(getFieldInstanceId(leftFieldInstance), operator);
    }

    if (value == null || (Array.isArray(value) && value.length === 0)) {
      console.warn(WARNING_UNEXPECTED_EMPTY_FORM_VALUE, { leftFieldInstance, value });
      return undefined;
    }

    if (operator === ConditionOperator.BETWEEN) {
      // (zstanik): For the `BETWEEN` operator, the value is expected to be an array of 2
      // Date objects: a lower bound and upper bound.
      if (
        !Array.isArray(value) ||
        value.length !== 2 ||
        value.some((date) => !(date instanceof Date) || !isValid(date))
      ) {
        console.warn(WARNING_INVALID_VALUE_TYPE, { leftFieldInstance, value });
        return undefined;
      }

      return {
        leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
        operatorV2: operator,
        rightTimeMultiValue: value.map((date) => date.toISOString()),
        rightFloatMultiValue: EMPTY_ARRAY,
        rightIntMultiValue: EMPTY_ARRAY,
        rightPartyIDMultiValue: EMPTY_ARRAY,
        rightStringMultiValue: EMPTY_ARRAY,
      };
    }

    // (zstanik): For all other operators, the value is expected to be a Date object.
    if (!(value instanceof Date) || !isValid(value)) {
      console.warn(WARNING_INVALID_VALUE_TYPE, { leftFieldInstance, value });
      return undefined;
    }

    return {
      leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
      operatorV2: operator,
      rightTimeValue: value.toISOString(),
      rightTimeMultiValue: EMPTY_ARRAY,
      rightFloatMultiValue: EMPTY_ARRAY,
      rightIntMultiValue: EMPTY_ARRAY,
      rightPartyIDMultiValue: EMPTY_ARRAY,
      rightStringMultiValue: EMPTY_ARRAY,
    };
  },

  getUpdateFieldInstanceValueInputsFromFieldInstance: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): UpdateFieldInstanceValueInputs[] => {
    const value = extractAtMostOneValueOrThrow({
      fieldInstance,
      fieldInstanceValueTypeName: "FieldInstanceValueTime",
      errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
      errorMessageIfWrongValueType: WARNING_INVALID_VALUE_TYPE,
      getterIfNoValue: () => null,
      getterIfValue: (fieldInstanceValue) => {
        if (fieldInstanceValue.timeValue == null) {
          return null;
        }
        const parsedDate = parseISO(fieldInstanceValue.timeValue);
        if (!isValid(parsedDate)) {
          return null;
        }
        return parsedDate;
      },
    });
    return [
      {
        timeValue: value != null ? value.toISOString() : undefined,
        inputType: fieldInstance.values[0].inputType,
        id: fieldInstance.values[0].id,
      },
    ];
  },

  getValueForFrontend: (fieldInstance: FieldInstanceFields | FieldInstanceBaseFields): Date | null => {
    return extractAtMostOneValueOrThrow({
      fieldInstance,
      fieldInstanceValueTypeName: "FieldInstanceValueTime",
      errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
      errorMessageIfWrongValueType: WARNING_INVALID_VALUE_TYPE,
      getterIfNoValue: () => null,
      getterIfValue: (fieldInstanceValue) => {
        if (fieldInstanceValue.timeValue == null) {
          return null;
        }
        const parsedDate = parseISO(fieldInstanceValue.timeValue);
        if (!isValid(parsedDate)) {
          return null;
        }
        return parsedDate;
      },
    });
  },

  isCreateAndEditAllowed: true,

  isFeatureFlagEnabled: (): boolean => {
    return true;
  },

  isFieldInstanceEmpty: (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.value == null || !isValid(frontendFieldInstance.value);
  },

  isFieldInstanceValueUnchanged: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    proposedChange: CreateFieldInstanceValueInputs,
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    if (frontendFieldInstance.sourceFieldInstanceId !== proposedChange.sourceFieldInstanceValueId) {
      return false;
    }

    if (frontendFieldInstance.inputType !== proposedChange.inputType) {
      return false;
    }

    return (
      (frontendFieldInstance.value == null && proposedChange.timeValue == null) ||
      (frontendFieldInstance.value != null &&
        proposedChange.timeValue != null &&
        frontendFieldInstance.value.toISOString() === new Date(proposedChange.timeValue).toISOString())
    );
  },

  renderDisplayValue: renderDisplayValue,

  renderFormField: <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    field: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
  ): React.ReactNode => {
    if (!canProcessField(field)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
    }
    const isDeleted = props.fieldInstance?.field.deletedAt != null;
    const { operator } = props;
    if (operator != null && !isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        field,
        operator,
      });
      return undefined;
    }

    return (
      <RegrelloControlledFormFieldDate
        {...props}
        key={props.controllerProps.name}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        infoTooltipText={props.description}
        isDeleted={isDeleted}
      />
    );
  },

  renderIcon: (props) => {
    return <RegrelloIcon {...props} iconName="date-field" />;
  },

  renderMultipleDisplayValuesForDataGrid: (fieldInstances, options) => {
    if (fieldInstances.length === 0) {
      return null;
    }
    if (fieldInstances.length === 1) {
      return renderDisplayValue(fieldInstances[0], { context: options?.context ?? "table" });
    }

    const instancesWithSource = fieldInstances.map((fieldInstance) => {
      return {
        content: renderDisplayValue(fieldInstance, { context: options?.context ?? "table" }),
        workflowName: fieldInstance.workflow?.name,
        stageName: fieldInstance.actionItem?.workflowReference?.stageName,
        taskName: fieldInstance.actionItem?.name,
      };
    });

    return <RegrelloCustomFieldMultiValuePopover instancesWithSource={instancesWithSource} />;
  },

  sortComparator,
};

interface FrontendDateFieldInstance {
  name: string;
  inputType: FieldInstanceValueInputType;
  crossWorkflowSinksFieldInstanceIds: number[];
  crossWorkflowSourceFieldInstanceId: number | undefined;
  sinksFieldInstanceIds: number[];
  sourceFieldInstanceId: number | undefined;
  sourceFieldInstance: FieldInstanceFields | undefined;
  sourceFieldInstanceInputType: FieldInstanceValueInputType | undefined;
  value: Date | null;
}

function translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
): FrontendDateFieldInstance {
  const { field } = fieldInstance;

  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  const isFieldInstanceFields = getIsFieldInstanceFields(fieldInstance);

  return extractAtMostOneValueOrThrow({
    fieldInstance,
    fieldInstanceValueTypeName: "FieldInstanceValueTime",
    errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
    errorMessageIfWrongValueType: WARNING_INVALID_VALUE_TYPE,
    getterIfNoValue: () => ({
      name: field.name,
      inputType: DEFAULT_INPUT_TYPE_IF_NO_VALUE,
      crossWorkflowSinksFieldInstanceIds: EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: undefined,
      sinksFieldInstanceIds: EMPTY_ARRAY,
      sourceFieldInstanceId: undefined,
      sourceFieldInstance: undefined,
      sourceFieldInstanceInputType: undefined,
      value: null,
    }),
    getterIfValue: (fieldInstanceValue) => ({
      name: field.name,
      inputType: fieldInstanceValue.inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue) &&
        fieldInstanceValue.crossWorkflowSinksFieldInstanceValueTime != null
          ? fieldInstanceValue.crossWorkflowSinksFieldInstanceValueTime.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue)
        ? fieldInstanceValue.crossWorkflowSourceFieldInstanceValueTime?.id
        : undefined,
      sinksFieldInstanceIds: isFieldInstanceFields
        ? fieldInstanceValue.sinksFieldInstanceValueTime?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstance:
        isFieldInstanceFields && fieldInstanceValue.sourceFieldInstanceValueTimeV2 != null
          ? getFieldInstanceFieldsFromAcyclicFieldInstanceFields(fieldInstanceValue.sourceFieldInstanceValueTimeV2)
          : undefined,
      sourceFieldInstanceId: isFieldInstanceFields ? fieldInstanceValue.sourceFieldInstanceValueTime?.id : undefined,
      sourceFieldInstanceInputType: isFieldInstanceFields
        ? fieldInstanceValue.sourceFieldInstanceValueTime?.inputType
        : undefined,
      value: fieldInstanceValue.timeValue != null ? parseISO(fieldInstanceValue.timeValue) : null,
    }),
  });
}

function isOperatorValid(
  operator: ConditionOperator,
): operator is
  | ConditionOperator.EMPTY
  | ConditionOperator.NOT_EMPTY
  | ConditionOperator.GREATER_THAN_OR_EQUALS
  | ConditionOperator.LESS_THAN_OR_EQUALS
  | ConditionOperator.EQUALS
  | ConditionOperator.BETWEEN {
  return getConditionOperators().find((stageStartOperator) => stageStartOperator.operator === operator) != null;
}

function isValueValid(value: unknown): value is DateFieldPluginFrontendValue {
  return value == null || (value instanceof Date && isValid(value));
}
