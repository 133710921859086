import { t } from "@lingui/macro";
import { clsx, getScrollParentElement, queueMacrotask, type WithChildren } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { RegrelloButton, RegrelloTooltip, type RegrelloTooltipProps } from "@regrello/ui-core";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";

export interface RegrelloClampedTextProps extends WithChildren {
  /** Whether the text field is collapsed when the component is first rendered. */
  initialState?: "collapsed" | "expanded";

  /**
   * The maximum number of lines to display before clamping the text. If the text is clamped, a
   * `Show More` button will be displayed. Pass in `-1` to disable clamping.
   * @default 2
   */
  maxLines?: -1 | 1 | 2 | 3 | 4 | 5;

  /**
   * The behavior when the text is clampped. Under `button` mode, a button will be displayed to
   * expaned the clampped content. Under `tooltip` mode, a tooltip will be displayed to show the
   * full content.
   */
  mode?: "button" | "tooltip";

  /** Optional props applied to the tooltip if mode = "tooltip". */
  tooltipProps?: Omit<RegrelloTooltipProps, "children" | "content">;
}

/** Renders text clamped, or truncated, at 5 lines with a button for toggling text visibility. */
export const RegrelloClampedText = React.memo<RegrelloClampedTextProps>(function RegrelloClampedTextFn({
  children,
  // eslint-disable-next-line lingui/no-unlocalized-strings
  initialState = "collapsed",
  maxLines = 2,
  mode = "button",
  tooltipProps,
}) {
  const [isExpanded, setIsExpanded] = useState(initialState === "expanded");
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const updateIsOverflowing = useCallback(() => {
    if (textRef.current != null) {
      const { scrollHeight, clientHeight } = textRef.current;
      setIsOverflowing(scrollHeight > clientHeight);
    }
  }, []);

  useLayoutEffect(() => {
    if (children || maxLines) {
      // If these props change, we need to rerun measurements, but should be handled by ResizeObserver i guess
    }

    updateIsOverflowing();

    const resizeObserver = new ResizeObserver(() => {
      updateIsOverflowing();
    });

    const textElement = textRef.current;

    if (textElement != null) {
      resizeObserver.observe(textElement);
    }

    return () => {
      if (textElement != null) {
        resizeObserver.unobserve(textElement);
      }
    };
  }, [children, maxLines, updateIsOverflowing]);

  const handleToggleButtonClick = useCallback(() => {
    setIsExpanded((currIsExpanded) => {
      if (currIsExpanded) {
        // (clewis): Per BUG-1280, when collapsing, scroll the top of the text fully into view so
        // that the user doesn't lose track of it. Animate to clarify what's going on.
        queueMacrotask(() => {
          textRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        });
      } else {
        // (clewis): When expanding, ensure the top of the text stays put if it's fully visible, or
        // if not, scroll to the top of the text so the user can read from the beginning.
        queueMacrotask(() => {
          const textElement = textRef.current;
          if (textElement == null) {
            return;
          }
          const scrollParent = getScrollParentElement(textElement);
          if (scrollParent == null) {
            return;
          }
          if (textElement.offsetTop < scrollParent.scrollTop) {
            textRef.current?.scrollIntoView({ behavior: "instant", block: "start" });
          }
        });
      }
      return !currIsExpanded;
    });
  }, []);

  return (
    <>
      <RegrelloTooltip {...tooltipProps} content={mode === "tooltip" && isOverflowing ? children : undefined}>
        <span
          ref={textRef}
          // (zstanik) TODO: Turns out supporting dynamic variables in Tailwind CSS (in this case a
          // max line number defined by a prop) is difficult and not recommended. The immediate need
          // for this new component is to clamp the text at 5 lines, but this should be more
          // generalized. Instead of supporting any arbitrary number of max lines, we could establish
          // an enum with defined, supported numbers once those numbers become clear with use/designs.
          className={clsx("whitespace-pre-wrap", {
            "line-clamp-1": !isExpanded && maxLines === 1,
            "line-clamp-2": !isExpanded && maxLines === 2,
            "line-clamp-3": !isExpanded && maxLines === 3,
            "line-clamp-4": !isExpanded && maxLines === 4,
            "line-clamp-5": !isExpanded && maxLines === 5,
          })}
        >
          {children}
        </span>
      </RegrelloTooltip>
      {mode === "button" && (isOverflowing || isExpanded) && (
        <RegrelloButton
          // (zstanik) HACK: apply some negative margin to make the button text line up with the
          // text's leftmost edge. Otherwise it looks noticeably unaligned.
          className="block ml--1.5"
          dataTestId={DataTestIds.TOGGLE_TEXT_CLAMP_BUTTON}
          intent="primary"
          onClick={handleToggleButtonClick}
          size="x-small"
          variant="ghost"
        >
          {isExpanded ? t`Show less` : t`Show more`}
        </RegrelloButton>
      )}
    </>
  );
});
