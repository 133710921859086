import { i18n } from "@lingui/core";
import { Plural, t, Trans } from "@lingui/macro";
import { assertNever, clsx, EMPTY_STRING, formatDateString, formatDateTimeString } from "@regrello/core-utils";
import {
  type ActionItem,
  type ActionItemDetailFields,
  ActionItemFrozeForReason,
  ActionItemStatus,
  type ActionItemTemplate,
  type ActionItemTemplateActionItemFields,
  ActionItemType,
  DueOnClass,
  type FieldInstanceFields,
  IntegrationAutomationType,
  type WorkflowQueryActionItemFields,
  type WorkflowQueryActionItemTemplateFields,
} from "@regrello/graphql-api";
import { WorkflowFrontendTaskStatus } from "@regrello/ui-app-molecules";
import { RegrelloIcon, RegrelloSize, RegrelloTooltip, RegrelloTypography } from "@regrello/ui-core";

import { PartyTypeUnion } from "./parties/PartyTypeUnion";
import { getBasePartyTypeUnions, isPartyDeleted } from "./parties/partyUtils";
import {
  getWorkflowFrontendApprovalChildTaskStatus,
  getWorkflowFrontendLinkedWorkflowTaskStatus,
  getWorkflowFrontendStandaloneApprovalTaskStatus,
  getWorkflowFrontendTaskStatus,
} from "./workflows/getWorkflowFrontendTaskStatus";
import { ACTION_ITEM_UUID_EXPECTED_FORMAT } from "../constants/globalConstants";
import { CustomFieldPluginRegistrar } from "../ui/molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { AuditHistoryFileError, FrontendAuditHistoryFieldName } from "../ui/molecules/messageThread/auditHistoryConsts";
import { getReasonForLinkedWorkflowProblem } from "../ui/views/modals/actionItemView/_internal/getReasonForLinkedWorkflowProblemUtils";

export const DETAIL_DIALOG_FORM_FIELD_WIDTH_PX = 96;
export const WIDE_DETAIL_DIALOG_FORM_FIELD_WIDTH_PX = 128;

export function isAutomated(
  actionItem: Pick<
    ActionItem | ActionItemTemplate | ActionItemTemplateActionItemFields,
    "automationRequests" | "integrationAutomationInstance"
  >,
): boolean {
  return actionItem.automationRequests.length > 0 || actionItem.integrationAutomationInstance != null;
}

export function isCreateDraftFromTemplateAutomation(
  actionItem: Pick<
    ActionItem | ActionItemTemplate | ActionItemTemplateActionItemFields,
    "automationRequests" | "integrationAutomationInstance"
  >,
): boolean {
  return (
    actionItem.integrationAutomationInstance?.integrationAutomation.integrationAutomationType ===
    IntegrationAutomationType.DOCUSIGN_CREATE_DRAFT_FROM_TEMPLATE
  );
}

export function isDocusignAutomation(
  actionItem: Pick<
    ActionItem | ActionItemTemplate | ActionItemTemplateActionItemFields,
    "automationRequests" | "integrationAutomationInstance"
  >,
): boolean {
  return [
    IntegrationAutomationType.DOCUSIGN_CREATE_DRAFT_FROM_TEMPLATE,
    IntegrationAutomationType.DOCUSIGN_CREATE_AND_SEND_FOR_SIGNATURE_AND_SAVE_ON_COMPLETED,
  ].some(
    (integrationAutomationType) =>
      integrationAutomationType ===
      actionItem.integrationAutomationInstance?.integrationAutomation.integrationAutomationType,
  );
}

/**
 * Returns whether the automation can be resolved without restarting the operation.
 */
export function isResolvableAutomation(
  actionItem: Pick<
    ActionItem | ActionItemTemplate | ActionItemTemplateActionItemFields,
    "automationRequests" | "integrationAutomationInstance"
  >,
): boolean {
  // (krashanoff): This is a bit of a hack for information that should be supplied by the backend in the
  // form of a resolver. For the time being, this (and the other `isXAutomation` functions) will suffice.
  return (
    actionItem.integrationAutomationInstance?.integrationAutomation.integrationAutomationType ===
    IntegrationAutomationType.DOCUSIGN_CREATE_AND_SEND_FOR_SIGNATURE_AND_SAVE_ON_COMPLETED
  );
}

/**
 * Checks the `auditHistory` of the provided action item for the most recent change to the `status`
 * field to determine if it is currently reopened. Returns `true` if so.
 */
export function isReopened(actionItemDetailFields: Pick<ActionItemDetailFields, "auditHistory">): boolean {
  const latestStatusChange = actionItemDetailFields.auditHistory.find(
    ({ fields }) => fields[0]?.fieldName === "Status",
  );
  const firstFieldOfStatusChange = latestStatusChange?.fields[0];

  return (
    firstFieldOfStatusChange?.valueBefore === ActionItemStatus.COMPLETED &&
    firstFieldOfStatusChange?.valueAfter === ActionItemStatus.PENDING_SUBMISSION
  );
}

/**
 * Verifies if the provided task uuid is the correct format and valid.
 */
export function isUuid(uuidToVerify: string): boolean {
  const resultInternal = ACTION_ITEM_UUID_EXPECTED_FORMAT.exec(uuidToVerify);
  const isOldStyle = resultInternal !== null && resultInternal[0] === uuidToVerify;

  if (!isOldStyle) {
    if (uuidToVerify.length === 11) {
      return true;
    }
  }

  return isOldStyle;
}

export function hasUnassignedApprovals(actionItem: {
  approvalActionItemTemplates: Array<
    Pick<WorkflowQueryActionItemFields, "assignees" | "fieldInstancesControllingAssignees">
  >;
}): boolean {
  return actionItem.approvalActionItemTemplates.some((template) =>
    isUnassigned(getBasePartyTypeUnions(template.assignees)),
  );
}

export function isUnassigned(assignees: PartyTypeUnion[]): boolean {
  /**
   * If assignee empty => unassigned
   * If assignee non empty:
   *  If assigned party is inactive (deleted)
   *  If has fields: unassigned if any fields are empty
   */

  return (
    assignees.length === 0 ||
    assignees.some(
      (assignee) =>
        isPartyDeleted(assignee) ||
        (PartyTypeUnion.isFieldInstance(assignee) &&
          CustomFieldPluginRegistrar.getPluginForFieldInstance(assignee.fieldInstance).isFieldInstanceEmpty(
            assignee.fieldInstance,
          )),
    )
  );
}

export function isAllUnassigned(assignees: PartyTypeUnion[]): boolean {
  /**
   * If assignee empty => unassigned
   * If assignee non empty:
   *  If assigned party is inactive (deleted)
   *  If has fields: unassigned if any fields are empty
   */
  return (
    assignees.length === 0 ||
    assignees.every(
      (assignee) =>
        isPartyDeleted(assignee) ||
        (PartyTypeUnion.isFieldInstance(assignee) &&
          CustomFieldPluginRegistrar.getPluginForFieldInstance(assignee.fieldInstance).isFieldInstanceEmpty(
            assignee.fieldInstance,
          )),
    )
  );
}

export function getStatusIcon(
  status: ActionItemStatus,
  isApprovalTask: boolean,
  dueOnClass: DueOnClass,
  hasException: boolean,
): React.ReactElement {
  if (status === ActionItemStatus.COMPLETED) {
    return isApprovalTask ? (
      <RegrelloIcon iconName="approved" intent="primary" size={RegrelloSize.MEDIUM} />
    ) : (
      <RegrelloIcon iconName="complete" intent="primary" size={RegrelloSize.MEDIUM} />
    );
  }
  if (hasException) {
    return <RegrelloIcon iconName="exception" intent="danger" size={RegrelloSize.MEDIUM} />;
  }
  if (status === ActionItemStatus.PENDING_REVIEW) {
    return <RegrelloIcon iconName="approval-not-started" intent="success" size={RegrelloSize.SMALL} />;
  }
  if (dueOnClass === DueOnClass.OVERDUE) {
    return <RegrelloIcon iconName="DEPRECATED-in-progress-overdue" intent="warning" size={RegrelloSize.MEDIUM} />;
  }
  return <RegrelloIcon iconName="in-progress" intent="success" size={RegrelloSize.MEDIUM} />;
}

export function getStringTypeFromEnumType(type: ActionItemType): "default" | "linkedWorkflow" | "standaloneApproval" {
  switch (type) {
    case ActionItemType.LINKED_WORKFLOW:
      return "linkedWorkflow";
    case ActionItemType.APPROVAL:
      return "standaloneApproval";
    default:
      return "default";
  }
}

export function getEnumTypeFromStringType(type: "default" | "linkedWorkflow" | "standaloneApproval"): ActionItemType {
  switch (type) {
    case "linkedWorkflow":
      return ActionItemType.LINKED_WORKFLOW;
    case "standaloneApproval":
      return ActionItemType.APPROVAL;
    default:
      return ActionItemType.DEFAULT;
  }
}

export function getTaskBannerStatus(
  type: "childApproval" | "default" | "standaloneApproval" | "linkedWorkflow",
  actionItem: Pick<
    ActionItemTemplateActionItemFields,
    "approvalStatus" | "dueOnClass" | "frozeForReason" | "hasException" | "status"
  >,
): WorkflowFrontendTaskStatus {
  switch (type) {
    case "childApproval":
      return getWorkflowFrontendApprovalChildTaskStatus(actionItem);
    case "linkedWorkflow":
      return getWorkflowFrontendLinkedWorkflowTaskStatus(actionItem);
    case "default":
      return getWorkflowFrontendTaskStatus(actionItem);
    case "standaloneApproval":
      return getWorkflowFrontendStandaloneApprovalTaskStatus(actionItem);
    default:
      assertNever(type);
  }
}

/**
 * Check if the action item is a sharepoint automated task that is in an exception state because setup sharepoint folder
 * does not exist.
 */
export function isSharepointFolderNotFoundError(
  actionItem: Pick<ActionItemDetailFields, "hasException" | "type"> & {
    auditHistory: Array<
      Pick<ActionItemDetailFields["auditHistory"][0], "createdAt"> & {
        fields: Array<{
          fieldName: string;
          valueAfter?: string | null;
        }>;
      }
    >;
  },
): boolean {
  return isAutomationErrorByAuditHistoryErrorCode(actionItem, AuditHistoryFileError.UPLOAD_FAILED_FOLDER_NOT_FOUND);
}

/**
 * Check if the action item is an automated task that is in an exception state because a connection error.
 */
export function isConnectionError(
  actionItem: Pick<ActionItemDetailFields, "hasException" | "type"> & {
    auditHistory: Array<
      Pick<ActionItemDetailFields["auditHistory"][0], "createdAt"> & {
        fields: Array<{
          fieldName: string;
          valueAfter?: string | null;
        }>;
      }
    >;
    integrationAutomationInstance?: {
      integrationAuthentication?: {
        isActive: boolean;
      } | null;
    } | null;
  },
): boolean {
  const isConnectionActive = actionItem?.integrationAutomationInstance?.integrationAuthentication?.isActive;

  return (
    isConnectionActive === false ||
    isAutomationErrorByAuditHistoryErrorCode(actionItem, AuditHistoryFileError.UPLOAD_FAILED_UNAUTHORIZED)
  );
}

/**
 * Check if the action item is an automated task that is in an exception state because of a specific error.
 */
function isAutomationErrorByAuditHistoryErrorCode(
  actionItem: Pick<ActionItemDetailFields, "hasException" | "type"> & {
    auditHistory: Array<
      Pick<ActionItemDetailFields["auditHistory"][0], "createdAt"> & {
        fields: Array<{
          fieldName: string;
          valueAfter?: string | null;
        }>;
      }
    >;
  },
  errorType: string,
): boolean {
  // Ensure the actionItem is of type AUTOMATION
  if (actionItem.type !== ActionItemType.AUTOMATION || !actionItem.hasException) {
    return false;
  }

  // Find the first audit history with the specific field name
  const automationErrorCode = actionItem.auditHistory.find((audit) =>
    audit.fields.some(
      (field) => field.fieldName === FrontendAuditHistoryFieldName.AUTOMATION_DOCUMENT_UPLOAD_FAILED_ERROR_CODE,
    ),
  );

  if (!automationErrorCode) {
    // Return false if the specific error is not found
    return false;
  }

  // If found, check if the error is errorType
  return automationErrorCode.fields.some(
    (field) =>
      field.fieldName === FrontendAuditHistoryFieldName.AUTOMATION_DOCUMENT_UPLOAD_FAILED_ERROR_CODE &&
      field.valueAfter === errorType,
  );
}

function getTaskExceptionText(
  actionItem: Pick<ActionItemDetailFields, "hasException" | "type"> & {
    auditHistory: Array<Pick<ActionItemDetailFields["auditHistory"][0], "fields" | "createdAt">>;
  },
) {
  return (
    <div className="flex flex-row gap-1 items-center">
      <RegrelloTypography variant="body">{t`Pending resolution`}</RegrelloTypography>
      {isSharepointFolderNotFoundError(actionItem) ? (
        <RegrelloTypography muted={true} variant="body">
          ({t`Folder not found`})
        </RegrelloTypography>
      ) : isConnectionError(actionItem) ? (
        <RegrelloTypography muted={true} variant="body">
          <RegrelloTooltip
            content={<span>{t`This integration requires attention from an administrator.`}</span>}
            side="top"
          >
            <span>({t`Connection issue`})</span>
          </RegrelloTooltip>
        </RegrelloTypography>
      ) : null}
    </div>
  );
}

export function getTaskType(task: Pick<ActionItemDetailFields, "approvalForActionItem" | "type"> | undefined) {
  /* eslint-disable lingui/no-unlocalized-strings */
  return task?.type === ActionItemType.APPROVAL
    ? "standaloneApproval"
    : task?.type === ActionItemType.LINKED_WORKFLOW
      ? "linkedWorkflow"
      : task?.approvalForActionItem != null
        ? "childApproval"
        : "default";
  /* eslint-enable lingui/no-unlocalized-strings */
}

/**
 * Returns the information to display in the task banner, this is used for the spectrum task detail
 * dialog. In the spectrum task detail dialog, the status is displayed as a chip with a short
 * phrase. A short sentence is displayed beside the chip when applicable to explain the status.
 * Secondary text is useful when we want to further customize the view. For example, problematic
 * linked workflow will show the percentage complete besides the chip and show the reason for the
 * problem in the footer with the `secondaryText`.
 */
export function getTaskStatusInfo({
  actionItem,
  completedOn,
  status,
  renderDate,
}: {
  actionItem: Pick<ActionItemDetailFields, "fieldInstances" | "hasException" | "dueOn" | "type"> & {
    auditHistory: Array<Pick<ActionItemDetailFields["auditHistory"][0], "fields" | "createdAt">>;
    linkedWorkflowReference?:
      | (Pick<
          NonNullable<WorkflowQueryActionItemTemplateFields["actionItems"][0]["linkedWorkflowReference"]>,
          "completedAt" | "daysDelayed" | "endDate" | "id" | "percentComplete" | "permissions"
        > & {
          fieldInstances: FieldInstanceFields[];
          updatedAt?: string;
        })
      | null;
  };
  completedOn: string | undefined;
  status: WorkflowFrontendTaskStatus;
  renderDate: React.ReactElement;
  variant?: "default" | "spectrum";
}): {
  /**
   * Detailed explanation of the status of the task. For example: when did the status transition
   * happen, why does the task ended up in this state.
   */
  text: React.ReactNode;

  /**
   * Secondary text that provides additional information about the status of the task. This is
   * useful for linked workflow tasks where we want to display the primary text at the bottom of the
   * dialog and the text explaning the progress at the top.
   */
  secondaryText: React.ReactNode;

  /**
   * Text that will be displayed in the tooltip when the user hovers over the status chip. This is
   * useful when the explanation for the status gets too long and doesn't fit in the dialog itself.
   */
  tooltipText: string;
} {
  const lastFrozenActivity = actionItem.auditHistory.find(
    (auditHistoryItem) =>
      auditHistoryItem.fields.length > 0 &&
      auditHistoryItem.fields[0].fieldName === FrontendAuditHistoryFieldName.FROZE_FOR_REASON,
  );

  switch (status) {
    case WorkflowFrontendTaskStatus.NOT_STARTED_DELAYED:
    case WorkflowFrontendTaskStatus.NOT_STARTED:
    case WorkflowFrontendTaskStatus.APPROVAL_NOT_STARTED:
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_NOT_STARTED:
    case WorkflowFrontendTaskStatus.APPROVED:
    case WorkflowFrontendTaskStatus.REJECTED:
    case WorkflowFrontendTaskStatus.SKIPPED:
    case WorkflowFrontendTaskStatus.IN_PROGRESS_ON_TRACK:
      return { text: EMPTY_STRING, secondaryText: EMPTY_STRING, tooltipText: EMPTY_STRING };
    case WorkflowFrontendTaskStatus.COMPLETED:
    case WorkflowFrontendTaskStatus.COMPLETED_LATE: {
      const formattedTime = completedOn && formatDateTimeString(completedOn);
      return {
        text: completedOn != null ? t`Completed on ${formattedTime}` : t`Completed`,
        secondaryText: EMPTY_STRING,
        tooltipText: EMPTY_STRING,
      };
    }
    case WorkflowFrontendTaskStatus.IN_PROGRESS_OVERDUE:
      return {
        text: actionItem.dueOn != null ? <Trans>Requires action by {renderDate}</Trans> : t`Requires action`,
        secondaryText: EMPTY_STRING,
        tooltipText: EMPTY_STRING,
      };
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_DELAYED:
      return {
        text:
          actionItem.linkedWorkflowReference?.daysDelayed != null &&
          actionItem.linkedWorkflowReference.daysDelayed > 0 ? (
            <Trans>
              Delayed by
              <span className="text-warning-textMuted font-semibold">
                <Plural one="# day" other="# days" value={actionItem.linkedWorkflowReference.daysDelayed} />
              </span>
            </Trans>
          ) : (
            t`Delayed`
          ),
        secondaryText: EMPTY_STRING,
        tooltipText: EMPTY_STRING,
      };
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_ENDED: {
      const updatedAt = actionItem.linkedWorkflowReference?.updatedAt;
      const formattedDate = updatedAt && formatDateString(updatedAt);
      return {
        text: formattedDate ? (
          <Trans>
            Ended on <strong>{formattedDate}</strong>
          </Trans>
        ) : (
          t`Workflow ended`
        ),
        secondaryText: EMPTY_STRING,
        tooltipText: EMPTY_STRING,
      };
    }
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_IN_PROGRESS:
      return {
        text: getLinkedWorkflowProgressText(actionItem, status),
        secondaryText: EMPTY_STRING,
        tooltipText: EMPTY_STRING,
      };
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_PAUSED: {
      const updatedAt = actionItem.linkedWorkflowReference?.updatedAt;
      const formattedDate = updatedAt && formatDateString(updatedAt);
      return {
        text: formattedDate ? t`Workflow paused - ${formattedDate}` : t`Workflow paused`,
        secondaryText: EMPTY_STRING,
        tooltipText: EMPTY_STRING,
      };
    }
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_PROBLEM:
      return {
        text: getReasonForLinkedWorkflowProblem(actionItem).text,
        secondaryText: getReasonForLinkedWorkflowProblem(actionItem).text,
        tooltipText: getReasonForLinkedWorkflowProblem(actionItem).tooltipText,
      };
    case WorkflowFrontendTaskStatus.PAUSED: {
      if (lastFrozenActivity == null) {
        console.warn("Unable to find an audit record indiciating when the task is paused.");
        return { text: EMPTY_STRING, secondaryText: EMPTY_STRING, tooltipText: EMPTY_STRING };
      }
      const createdDate = lastFrozenActivity.createdAt;
      const formattedDate = createdDate && formatDateString(createdDate);

      return {
        text: (
          <Trans>
            Paused on <strong>{formattedDate}</strong>
          </Trans>
        ),
        secondaryText: EMPTY_STRING,
        tooltipText: EMPTY_STRING,
      };
    }
    case WorkflowFrontendTaskStatus.ENDED: {
      if (lastFrozenActivity == null) {
        console.warn("Unable to find an audit record indiciating when the task is ended.");
        return { text: EMPTY_STRING, secondaryText: EMPTY_STRING, tooltipText: EMPTY_STRING };
      }

      const createdDate = lastFrozenActivity.createdAt;
      const formattedDate = createdDate && formatDateString(createdDate);
      return {
        text: (
          <Trans>
            Ended on <strong>{formattedDate}</strong>
          </Trans>
        ),
        secondaryText: EMPTY_STRING,
        tooltipText: EMPTY_STRING,
      };
    }
    case WorkflowFrontendTaskStatus.EXCEPTION:
      return {
        text: getTaskExceptionText(actionItem),
        secondaryText: EMPTY_STRING,
        tooltipText: EMPTY_STRING,
      };
    default:
      assertNever(status);
  }
}

/**
 * Returns a short phrase indicating the percentage completion of the linked workflow and a helper
 * text.
 */
function getLinkedWorkflowProgressText(
  actionItem: {
    linkedWorkflowReference?: {
      percentComplete?: number | null;
    } | null;
  },
  status: WorkflowFrontendTaskStatus,
) {
  const complete = i18n.number(actionItem.linkedWorkflowReference?.percentComplete ?? 0, { style: "percent" });
  return (
    <div className="flex gap-1 items-baseline">
      <RegrelloTypography
        className={clsx({
          "text-success-textMuted": status === WorkflowFrontendTaskStatus.LINKED_WORKFLOW_IN_PROGRESS,
          "text-danger-textMuted": status === WorkflowFrontendTaskStatus.LINKED_WORKFLOW_PROBLEM,
        })}
        weight="semi-bold"
      >
        {t`${complete} complete`}
      </RegrelloTypography>
      <RegrelloTypography muted={true} variant="body-xs">
        ({t`This task will complete once the linked workflow completes`})
      </RegrelloTypography>
    </div>
  );
}

/**
 * Returns the display string for each action item status. This is an older implementation that only
 * accounts for regular tasks. This is currently only used when setting the browser page title.
 * @deprecated
 */
export function getActionItemStatusDisplayString({
  actionItemStatus,
  hasException,
  frozeForReason,
  dueDate,
  isInWorkflow,
}: {
  actionItemStatus: ActionItemStatus;
  hasException: boolean;
  frozeForReason: ActionItemFrozeForReason | undefined;
  dueDate?: string | undefined;
  isInWorkflow?: boolean;
}): string {
  if (hasException) {
    return t`Pending resolution`;
  }

  if (frozeForReason === ActionItemFrozeForReason.ENDED) {
    return t`Ended`;
  }
  if (frozeForReason === ActionItemFrozeForReason.PAUSED) {
    return t`Paused`;
  }
  switch (actionItemStatus) {
    case ActionItemStatus.PENDING_ACKNOWLEDGEMENT:
      return t`Pending acknowledgement`;
    case ActionItemStatus.PENDING_REVIEW:
      return t`Pending approval`;
    case ActionItemStatus.PENDING_SUBMISSION:
      if (isInWorkflow) {
        const formattedDate = dueDate && formatDateString(dueDate);
        return formattedDate ? t`Requires action` : t`Requires action by ${formattedDate}`;
      }
      return t`Pending submission`;
    case ActionItemStatus.REJECTED:
      return t`Rejected`;
    case ActionItemStatus.COMPLETED:
      return t`Completed`;
    case ActionItemStatus.INACTIVE:
      return t`Not started`;
    default:
      assertNever(actionItemStatus);
  }
}

/**
 * Return the display string for each action item status depending on the calulated frontend status.
 * It takes into account all possible cases including various types of tasks. The return value of
 * this conforms to the spectrum task detail view design
 */
export function getActionItemStatusDisplayStringV2(status: WorkflowFrontendTaskStatus) {
  switch (status) {
    case WorkflowFrontendTaskStatus.APPROVAL_NOT_STARTED:
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_NOT_STARTED:
    case WorkflowFrontendTaskStatus.NOT_STARTED:
    case WorkflowFrontendTaskStatus.NOT_STARTED_DELAYED:
      return t`Not started`;
    case WorkflowFrontendTaskStatus.APPROVED:
    case WorkflowFrontendTaskStatus.COMPLETED_LATE:
    case WorkflowFrontendTaskStatus.COMPLETED:
      return t`Completed`;
    case WorkflowFrontendTaskStatus.ENDED:
      return t`Ended`;
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_ENDED:
      return t`Workflow ended`;
    case WorkflowFrontendTaskStatus.EXCEPTION:
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_PROBLEM:
      return t`Problem`;
    case WorkflowFrontendTaskStatus.IN_PROGRESS_ON_TRACK:
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_IN_PROGRESS:
      return t`In progress`;
    case WorkflowFrontendTaskStatus.IN_PROGRESS_OVERDUE:
      return t`Overdue`;
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_DELAYED:
      return t`Delayed`;
    case WorkflowFrontendTaskStatus.LINKED_WORKFLOW_PAUSED:
      return t`Workflow paused`;
    case WorkflowFrontendTaskStatus.PAUSED:
      return t`Paused`;
    case WorkflowFrontendTaskStatus.REJECTED:
      return t`Rejected`;
    case WorkflowFrontendTaskStatus.SKIPPED:
      return t`Skipped`;
    default:
      assertNever(status);
  }
}

/**
 * Determines whether the provided value is considered empty. This value usually come from the
 * useHookForm data, which is sometimes untyped.
 */
export function isFrontendFieldValueEmpty(value: unknown) {
  return value == null || value === EMPTY_STRING || (Array.isArray(value) && value.length === 0);
}

/** Returns the most recent date on which the provided task was completed. */
export function getTaskCompletedOnDate(task: {
  auditHistory: Array<Pick<ActionItemDetailFields["auditHistory"][0], "fields" | "createdAt">>;
}): string | undefined {
  const latestCompleteEvent = task.auditHistory.reduce((latestEvent, auditHistoryEvent) => {
    const { fields, createdAt } = auditHistoryEvent;
    if (fields.length !== 1) {
      if (fields.length === 2) {
        // (surya): For AADD, we send two audit history events for the completion to
        // record the planned completion.
        // The first event is still the update event for the status.
        // The second one packs the planned completion date.
        if (
          fields[0].fieldName === FrontendAuditHistoryFieldName.STATUS &&
          fields[0].valueAfter === ActionItemStatus.COMPLETED &&
          (latestEvent == null || new Date(createdAt) > new Date(latestEvent.createdAt))
        ) {
          return auditHistoryEvent;
        }
      }
      return latestEvent;
    }
    if (
      fields[0].fieldName === FrontendAuditHistoryFieldName.STATUS &&
      fields[0].valueAfter === ActionItemStatus.COMPLETED &&
      (latestEvent == null || new Date(createdAt) > new Date(latestEvent.createdAt))
    ) {
      return auditHistoryEvent;
    }
    return latestEvent;
  });
  return latestCompleteEvent?.createdAt;
}

/** Returns the most recent date on which the provided task was prematurely ended. */
export function getTaskEndedOnDate(task: ActionItemDetailFields): string | undefined {
  const status = getTaskBannerStatus(getTaskType(task), task);
  if (status !== WorkflowFrontendTaskStatus.ENDED) {
    return undefined;
  }

  const lastFrozenEvent = [...task.auditHistory]
    .reverse()
    .find(
      (auditHistoryEvent) =>
        auditHistoryEvent.fields.length > 0 &&
        auditHistoryEvent.fields[0].fieldName === FrontendAuditHistoryFieldName.FROZE_FOR_REASON,
    );

  return lastFrozenEvent?.createdAt;
}
