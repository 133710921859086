import type { PropertyTypeFields } from "./PropertyTypeFields.generated";
import { gql } from "@apollo/client";
export type RegrelloObjectPropertyFields = {
  id: number;
  displayName?: string | null;
  displayOrder: number;
  dataObjectKey: string;
  isPrimaryKey?: boolean | null;
  propertyType?: PropertyTypeFields | null;
};

export const RegrelloObjectPropertyFields = gql`
    fragment RegrelloObjectPropertyFields on RegrelloObjectProperty {
  id
  displayName
  displayOrder
  dataObjectKey
  isPrimaryKey
  propertyType {
    ...PropertyTypeFields
  }
}
    `;
