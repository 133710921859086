import type * as Types from "../../../generated/graphqlBaseTypes";

import { TagTypeFields } from "../../fragments/generated/TagTypeFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateTagTypeMutationVariables = Types.Exact<{
  input: Types.CreateTagTypeInputs;
}>;

export type CreateTagTypeMutation = { createTagType?: { tagType?: TagTypeFields | null } | null };

export const CreateTagTypeMutationDocument = gql`
    mutation CreateTagTypeMutation($input: CreateTagTypeInputs!) {
  createTagType(input: $input) {
    tagType {
      ...TagTypeFields
    }
  }
}
    ${TagTypeFields}`;
export type CreateTagTypeMutationFn = Apollo.MutationFunction<CreateTagTypeMutation, CreateTagTypeMutationVariables>;

/**
 * __useCreateTagTypeMutation__
 *
 * To run a mutation, you first call `useCreateTagTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagTypeMutation, { data, loading, error }] = useCreateTagTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTagTypeMutation, CreateTagTypeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagTypeMutation, CreateTagTypeMutationVariables>(
    CreateTagTypeMutationDocument,
    options,
  );
}
export type CreateTagTypeMutationHookResult = ReturnType<typeof useCreateTagTypeMutation>;
export type CreateTagTypeMutationResult = Apollo.MutationResult<CreateTagTypeMutation>;
export type CreateTagTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateTagTypeMutation,
  CreateTagTypeMutationVariables
>;
