import { t } from "@lingui/macro";
import { ComparatorResult, EMPTY_ARRAY } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  type ConditionOperator,
  type CreateFieldInstanceValueInputs,
  type FieldFields,
  type FieldInstanceFields,
  type FieldInstanceFieldsWithBaseValues,
  type FieldInstanceValueInputType,
  type FieldInstanceWithCrossWorkflowFields,
  PropertyDataType,
  type PropertyTypeFields,
  type UpdateFieldInstanceValueInputs,
  type UpdateStartingConditionsInputs,
  type ViewFilterFields,
} from "@regrello/graphql-api";
import { RegrelloIcon } from "@regrello/ui-core";
import type { FieldPath, FieldValues } from "react-hook-form";

import { CustomFieldPluginRegistrar } from "./registry/customFieldPluginRegistrar";
import type { CustomFieldPlugin, CustomFieldPluginV2RenderFormFieldProps } from "./types/CustomFieldPlugin";
import { createViewColumnsFromField } from "./utils/createViewColumnsFromField";
import { DEFAULT_INPUT_TYPE_IF_NO_VALUE, getConditionOperatorsByType } from "./utils/customFieldConstants";
import { extractAtMostOneValueOrThrow } from "./utils/extractAtMostOneValueOrThrow";
import {
  getIsFieldInstanceFields,
  getIsFieldInstanceValueWithCrossWorkflowFields,
} from "./utils/fieldInstanceTypeguards";
import type { FieldInstanceBaseFields } from "../../../../types";
import { booleanComparator } from "../../../../utils/comparators/booleanComparator";
import { getFieldInstanceId } from "../../../../utils/customFields/getFieldInstanceId";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import { TableCellDefaultWidths } from "../../../../utils/tableCellWidthUtils";
import {
  getRegrelloDefaultFilterDefinitionBooleanValue,
  getRegrelloFilterDefinitionBooleanValue,
} from "../../../molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Constants";
import { RegrelloControlledFormFieldCheckbox } from "../../formFields/controlled/regrelloControlledFormFields";
import { RegrelloCustomFieldMultiValuePopover } from "../components/RegrelloCustomFieldMultiValuePopover";

// TODO Misc: Turn the following into warnings and handle gracefully so the app doesn't crash in
// case of field misconfiguration.
const ERROR_INVALID_FIELD = "Provided 'checkbox' field is invalid";
const ERROR_INVALID_FORM_VALUE = "Provided 'checkbox'-field form value is not a boolean";
const ERROR_INVALID_VALUE_COUNT = "Provided 'checkbox' field instance cannot have multiple values";
const ERROR_INVALID_VALUE_TYPE = "Provided 'checkbox' field instance value must have type 'FieldInstanceValueBoolean'";
const WARNING_INVALID_OPERATOR_TYPE = "Provided operator type is invalid for 'checkbox' fields";

function canProcessPropertyDataType(propertyDataType: PropertyDataType): boolean {
  return propertyDataType === PropertyDataType.BOOLEAN;
}

function canProcessField(field: FieldFields): boolean {
  return (
    !field.isMultiValued && field.allowedValues.length === 0 && canProcessPropertyDataType(field.propertyType.dataType)
  );
}

type CheckboxFieldPluginType = CustomFieldPlugin<boolean>;

const getConditionOperators: CheckboxFieldPluginType["getConditionOperators"] = () =>
  getConditionOperatorsByType("CHECKBOX");

const renderDisplayValue: CheckboxFieldPluginType["renderDisplayValue"] = (fieldInstance) => {
  const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
  const isChecked = Boolean(frontendFieldInstance.value); // (clewis): Don't bother with indeterminate states for now.

  const checkboxIcon = isChecked ? (
    // We're using this icon to communicate form field value, so we need to add an aria-label for
    // assistive technologies.
    <RegrelloIcon aria-label={t`Checked`} iconName="selected" />
  ) : (
    <RegrelloIcon aria-label={t`Unchecked`} iconName="blank" />
  );

  return checkboxIcon;
};

const sortComparator: CheckboxFieldPluginType["sortComparator"] = (
  fieldInstance1,
  fieldInstance2,
  direction = "asc",
): ComparatorResult => {
  if (direction === "desc") {
    return CheckboxFieldPlugin.sortComparator(fieldInstance2, fieldInstance1, "asc");
  }

  if (fieldInstance1 == null) {
    return ComparatorResult.BEFORE;
  }

  if (fieldInstance2 == null) {
    return ComparatorResult.AFTER;
  }

  const fieldPlugin = CustomFieldPluginRegistrar.getPluginForField(fieldInstance1.field);

  const value1 = Boolean(fieldPlugin.getValueForFrontend(fieldInstance1));
  const value2 = Boolean(fieldPlugin.getValueForFrontend(fieldInstance2));

  return booleanComparator(value1, value2);
};

/**
 * Describes a custom field that holds a boolean value.
 */
export const CheckboxFieldPlugin: CheckboxFieldPluginType = {
  uri: "com.regrello.customField.checkbox",
  version: "1.0.0",

  canProcessField: (field: FieldFields): boolean => {
    return canProcessField(field);
  },

  canProcessFieldInstance: (fieldInstance: FieldInstanceBaseFields): boolean => {
    try {
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
      return true;
    } catch (_error) {
      return false;
    }
  },

  canProcessPropertyDataType,

  findPropertyTypeIdFromLoadedPropertyTypeIds: (propertyTypes: PropertyTypeFields[]): number | undefined => {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.BOOLEAN)?.id;
  },

  getColumnsForTable: createViewColumnsFromField,

  getConditionOperators,

  getCreateFieldInstanceValueInputsFromFormValue: (
    field: FieldFields,
    inputType: FieldInstanceValueInputType,
    value: unknown,
    displayOrder?: number,
    spectrumFieldVersionId?: number,
  ): CreateFieldInstanceValueInputs => {
    if (value != null && typeof value !== "boolean") {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FORM_VALUE, { field, inputType, value }));
    }
    return {
      spectrumFieldVersionId,
      fieldId: field.id,
      booleanValue: value ?? undefined,
      inputType,
      displayOrder,
    };
  },

  getCrossWorkflowSinksFieldInstanceIds: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number[] => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSinksFieldInstanceIds;
  },

  getCrossWorkflowSourceFieldInstanceIdFromValue: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSourceFieldInstanceId;
  },

  getFieldDisplayName: (): string => {
    return t`Checkbox`;
  },

  getFilterDefinition: (_field: FieldFields) => {
    return getRegrelloDefaultFilterDefinitionBooleanValue();
  },

  getFilterDefinitionWithValues: (_field: FieldFields, filter: ViewFilterFields) => {
    const value = filter.value === "true";
    return getRegrelloFilterDefinitionBooleanValue(value);
  },

  getEmptyValueForFrontend: (): boolean => {
    return false;
  },

  getIconName: () => {
    return "checkbox-field";
  },

  // (zstanik): Checkbox fields are the only ones not supported in name templates.
  getNameTemplateDisplayValueFromFormValue: () => {
    return undefined;
  },

  getPreferredHomeTableColumnWidth: () => {
    return TableCellDefaultWidths.CHECKBOX_CELL;
  },

  getSourceFieldInstance: (_fieldInstance: FieldInstanceFields): FieldInstanceFields | undefined => {
    return undefined;
  },

  getSourceFieldInstanceId: (fieldInstance: FieldInstanceFields): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstanceId;
  },

  getUpdateStartingConditionsInputsFromFormValues: (
    leftFieldInstance: FieldInstanceFields,
    _value: unknown,
    operator: ConditionOperator,
  ): UpdateStartingConditionsInputs | undefined => {
    if (!isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        leftFieldInstance,
        operator,
      });
      return undefined;
    }

    // (zstanik): Both of the supported operator types for checkbox fields don't render form fields,
    // so the 'value' passed in will always be null and ignored here. I also don't see this logic
    // ever changing because a checkbox is only ever checked or not.
    return {
      leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
      operatorV2: operator,
      rightBooleanValue: true,
      rightFloatMultiValue: EMPTY_ARRAY,
      rightIntMultiValue: EMPTY_ARRAY,
      rightPartyIDMultiValue: EMPTY_ARRAY,
      rightStringMultiValue: EMPTY_ARRAY,
      rightTimeMultiValue: EMPTY_ARRAY,
    };
  },

  // (hchen): Currently this is only used for handling the edge case when flipping the field
  // instance input type from OPTIONAL to REQUESTED (i.e. required) when the a previously OPTIONAL
  // field is set as a native field on a future task. It's not necessary to implement for field
  // types other than date and user, since we only support those as native field now.
  getUpdateFieldInstanceValueInputsFromFieldInstance: (
    _fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): UpdateFieldInstanceValueInputs[] => {
    throw new Error("not implmented yet");
  },

  getValueForFrontend: (fieldInstance: FieldInstanceFields | FieldInstanceBaseFields): boolean => {
    return extractAtMostOneValueOrThrow({
      fieldInstance,
      fieldInstanceValueTypeName: "FieldInstanceValueBoolean",
      errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
      errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
      getterIfNoValue: () => false,
      getterIfValue: (fieldInstanceValue) => fieldInstanceValue.booleanValue ?? false,
    });
  },

  getSourceFieldInstanceInputType: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): FieldInstanceValueInputType | undefined => {
    const sourceValue = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return sourceValue.sourceFieldInstanceInputType;
  },

  isCreateAndEditAllowed: true,

  isFeatureFlagEnabled: (): boolean => {
    return true;
  },

  isFieldInstanceEmpty: (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.value == null;
  },

  isFieldInstanceValueUnchanged: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    proposedChange: CreateFieldInstanceValueInputs,
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    if (frontendFieldInstance.sourceFieldInstanceId !== proposedChange.sourceFieldInstanceValueId) {
      return false;
    }

    if (frontendFieldInstance.inputType !== proposedChange.inputType) {
      return false;
    }

    return (
      (frontendFieldInstance.value == null && proposedChange.booleanValue == null) ||
      frontendFieldInstance.value === proposedChange.booleanValue
    );
  },

  isFormFieldValueRequired: () => {
    // (clewis): Checkbox fields can never be required: the unchecked state is the same as
    // the untouched state. It's possible we'll eventually want to support certain checkboxes that
    // *must* be checked (e.g., "I agree to these terms and conditions"), but we haven't seen that
    // use case yet.
    return false;
  },

  renderDisplayValue: renderDisplayValue,

  renderFormField: <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    field: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
  ): React.ReactNode => {
    const { operator } = props;
    const isDeleted = props.fieldInstance?.field.deletedAt != null;
    if (operator != null && !isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        field,
        operator,
      });
      return undefined;
    }

    return (
      <RegrelloControlledFormFieldCheckbox
        {...props}
        key={props.controllerProps.name}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        infoTooltipText={props.description}
        isDeleted={isDeleted}
      />
    );
  },

  renderIcon: (props) => {
    return <RegrelloIcon {...props} iconName="checkbox-field" />;
  },

  renderMultipleDisplayValuesForDataGrid: (fieldInstances, options) => {
    if (fieldInstances.length === 0) {
      return null;
    }
    if (fieldInstances.length === 1) {
      return renderDisplayValue(fieldInstances[0], { context: options?.context ?? "table" });
    }

    const instancesWithSource = fieldInstances.map((fieldInstance) => {
      return {
        content: renderDisplayValue(fieldInstance, { context: options?.context ?? "table" }),
        workflowName: fieldInstance.workflow?.name,
        stageName: fieldInstance.actionItem?.workflowReference?.stageName || "",
        taskName: fieldInstance.actionItem?.name,
      };
    });

    return <RegrelloCustomFieldMultiValuePopover instancesWithSource={instancesWithSource} />;
  },

  sortComparator,
};

interface FrontendCheckboxFieldInstance {
  name: string;
  inputType: FieldInstanceValueInputType;
  crossWorkflowSinksFieldInstanceIds: number[];
  crossWorkflowSourceFieldInstanceId: number | undefined;
  sinksFieldInstanceIds: number[];
  sourceFieldInstanceId: number | undefined;
  sourceFieldInstanceInputType: FieldInstanceValueInputType | undefined;
  value: boolean | undefined;
}

function translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields | FieldInstanceWithCrossWorkflowFields,
): FrontendCheckboxFieldInstance {
  const { field } = fieldInstance;

  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  const isFieldInstanceFields = getIsFieldInstanceFields(fieldInstance);

  return extractAtMostOneValueOrThrow({
    fieldInstance,
    fieldInstanceValueTypeName: "FieldInstanceValueBoolean",
    errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
    errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
    getterIfNoValue: () => ({
      name: field.name,
      inputType: DEFAULT_INPUT_TYPE_IF_NO_VALUE,
      crossWorkflowSinksFieldInstanceIds: EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: undefined,
      sinksFieldInstanceIds: EMPTY_ARRAY,
      sourceFieldInstanceId: undefined,
      sourceFieldInstanceInputType: undefined,
      value: undefined,
    }),
    getterIfValue: (fieldInstanceValue) => ({
      name: field.name,
      inputType: fieldInstanceValue.inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue) &&
        fieldInstanceValue.crossWorkflowSinksFieldInstanceValueBoolean != null
          ? fieldInstanceValue.crossWorkflowSinksFieldInstanceValueBoolean.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue)
        ? fieldInstanceValue.crossWorkflowSourceFieldInstanceValueBoolean?.id
        : undefined,
      sinksFieldInstanceIds: isFieldInstanceFields
        ? fieldInstanceValue.sinksFieldInstanceValueBoolean?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstanceId: isFieldInstanceFields ? fieldInstanceValue.sourceFieldInstanceValueBoolean?.id : undefined,
      sourceFieldInstanceInputType: isFieldInstanceFields
        ? fieldInstanceValue.sourceFieldInstanceValueBoolean?.inputType
        : undefined,
      value: fieldInstanceValue.booleanValue ?? undefined,
    }),
  });
}

function isOperatorValid(
  operator: ConditionOperator,
): operator is ConditionOperator.EQUALS | ConditionOperator.NOT_EQUALS {
  return getConditionOperators().find((stageStartOperator) => stageStartOperator.operator === operator) != null;
}
