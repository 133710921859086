import type * as Types from "../../../generated/graphqlBaseTypes";

import { TeamFields } from "../../fragments/generated/TeamFields.generated";
import { TeamMemberFields } from "../../fragments/generated/TeamMemberFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateTeamMutationVariables = Types.Exact<{
  input: Types.CreateTeamInput;
}>;

export type CreateTeamMutation = { createTeam?: { team: TeamFields } | null };

export const CreateTeamMutationDocument = gql`
    mutation CreateTeamMutation($input: CreateTeamInput!) {
  createTeam(input: $input) {
    team {
      ...TeamFields
    }
  }
}
    ${TeamFields}
${TeamMemberFields}
${UserBaseFields}`;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamMutationDocument, options);
}
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
