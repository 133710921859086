import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type AcyclicFieldInstanceValueIntFields = {
  __typename: "FieldInstanceValueInt";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  intValue?: number | null;
};

export const AcyclicFieldInstanceValueIntFields = gql`
    fragment AcyclicFieldInstanceValueIntFields on FieldInstanceValueInt {
  __typename
  id
  inputType
  intValue
}
    `;
