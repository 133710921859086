import type * as Types from "../../../generated/graphqlBaseTypes";

import { RoleFields } from "../../fragments/generated/RoleFields.generated";
import { SpectrumFieldVersionFields } from "../../fragments/generated/SpectrumFieldVersionFields.generated";
import { SpectrumFieldFields } from "../../fragments/generated/SpectrumFieldFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { SpectrumFieldValidationTypeFields } from "../../fragments/generated/SpectrumFieldValidationTypeFields.generated";
import { SpectrumFieldConstraintFields } from "../../fragments/generated/SpectrumFieldConstraintFields.generated";
import { SpectrumValueConstraintFields } from "../../fragments/generated/SpectrumValueConstraintFields.generated";
import { SpectrumFieldAllowedValueFields } from "../../fragments/generated/SpectrumFieldAllowedValueFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CreateRoleMutationVariables = Types.Exact<{
  input: Types.CreateRoleInput;
}>;

export type CreateRoleMutation = {
  createRole: { role: { spectrumFieldVersion?: SpectrumFieldVersionFields | null } & RoleFields };
};

export const CreateRoleMutationDocument = gql`
    mutation CreateRoleMutation($input: CreateRoleInput!) {
  createRole(input: $input) {
    role {
      ...RoleFields
      spectrumFieldVersion {
        ...SpectrumFieldVersionFields
      }
    }
  }
}
    ${RoleFields}
${SpectrumFieldVersionFields}
${SpectrumFieldFields}
${PropertyTypeFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldUnitFields}
${FieldFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleMutationDocument, options);
}
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
