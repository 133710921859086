import type { FieldInstanceMultiValueStringBaseFields } from "./FieldInstanceMultiValueStringBaseFields.generated";
import type { AcyclicFieldInstanceMultiValueStringFields } from "./AcyclicFieldInstanceMultiValueStringFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueStringFields = {
  sourceFieldInstanceMultiValueString?: AcyclicFieldInstanceMultiValueStringFields | null;
  sinksFieldInstanceMultiValueString: Array<AcyclicFieldInstanceMultiValueStringFields>;
} & FieldInstanceMultiValueStringBaseFields;

export const FieldInstanceMultiValueStringFields = gql`
    fragment FieldInstanceMultiValueStringFields on FieldInstanceMultiValueString {
  ...FieldInstanceMultiValueStringBaseFields
  sourceFieldInstanceMultiValueString {
    ...AcyclicFieldInstanceMultiValueStringFields
  }
  sinksFieldInstanceMultiValueString {
    ...AcyclicFieldInstanceMultiValueStringFields
  }
}
    `;
