import type { FieldFields } from "./FieldFields.generated";
import { gql } from "@apollo/client";
export type CustomFieldTableV2Field = {
  workflowTemplateCounts: number;
  scimAttributeName?: string | null;
} & FieldFields;

export const CustomFieldTableV2Field = gql`
    fragment CustomFieldTableV2Field on Field {
  ...FieldFields
  workflowTemplateCounts
  scimAttributeName
}
    `;
