import type * as Types from "../../../generated/graphqlBaseTypes";

import type { ActionItemTemplateActionItemFields } from "./ActionItemTemplateActionItemFields.generated";
import { gql } from "@apollo/client";
export type ActionItemTemplateActionItemWithLinkedWorkflowFields = {
  hasDocuments: boolean;
  linkedWorkflowReference?: {
    id: number;
    name: string;
    percentComplete?: number | null;
    daysDelayed?: number | null;
    endDate?: string | null;
    completedAt?: string | null;
    startedAt?: string | null;
    endedAt?: string | null;
    deletedAt?: string | null;
    scheduleStatus?: Types.WorkflowScheduleStatus | null;
    permissions: { canRead: boolean };
  } | null;
} & ActionItemTemplateActionItemFields;

export const ActionItemTemplateActionItemWithLinkedWorkflowFields = gql`
    fragment ActionItemTemplateActionItemWithLinkedWorkflowFields on ActionItem {
  ...ActionItemTemplateActionItemFields
  hasDocuments
  linkedWorkflowReference {
    id
    name
    percentComplete
    daysDelayed
    endDate
    completedAt
    startedAt
    endedAt
    deletedAt
    scheduleStatus
    permissions {
      canRead
    }
  }
}
    `;
