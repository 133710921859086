import { EMPTY_STRING, formatDateString, formatDateTimeString, formatTimeString } from "@regrello/core-utils";
import type { TagFields } from "@regrello/graphql-api";
import { RegrelloPartyAvatar, RegrelloPartyAvatars } from "@regrello/ui-app-molecules";
import { RegrelloChip, RegrelloLinkify, RegrelloTooltip, RegrelloTypography } from "@regrello/ui-core";

import type { PartyTypeUnion } from "./parties/PartyTypeUnion";
import { getPartyCreator, ScimPartyUtils } from "./parties/partyUtils";
import { toFlatParty, toFlatPartyArray } from "./parties/toFlatParty";
import { TagInlineList } from "../ui/molecules/tables/_internal/TagInlineList";

export const EMPTY_TABLE_CELL_VALUE = "-";

/** Renders a date string for display in a table cell. */
export function renderDateCell(
  value: string | undefined,
  typographyVariant: "body" | "body-xs" = "body-xs",
): React.ReactNode {
  return (
    <RegrelloTypography noWrap={true} variant={typographyVariant}>
      {value == null ? EMPTY_TABLE_CELL_VALUE : formatDateString(value)}
    </RegrelloTypography>
  );
}

/** Renders a date string for display in a table cell with date+time on hover. */
export function renderDateCellWithDateAndTimeOnHover(value: string | undefined): React.ReactNode {
  if (value == null) {
    return (
      <RegrelloTypography noWrap={true} variant="body-xs">
        {EMPTY_TABLE_CELL_VALUE}
      </RegrelloTypography>
    );
  }
  return (
    <RegrelloTooltip content={formatDateTimeString(value)} variant="popover">
      <div className="truncate">
        <RegrelloTypography noWrap={true} variant="body-xs">
          {formatDateString(value)}
        </RegrelloTypography>
      </div>
    </RegrelloTooltip>
  );
}

/** Renders a date+time string for display in a table cell with date+time on hover. */
export function renderDateAndTimeCellWithDateAndTimeOnHover(value: string | undefined): React.ReactNode {
  if (value == null) {
    return (
      <RegrelloTypography noWrap={true} variant="body-xs">
        {EMPTY_TABLE_CELL_VALUE}
      </RegrelloTypography>
    );
  }
  return (
    <RegrelloTooltip content={formatDateTimeString(value)} variant="popover">
      <div className="truncate">
        <RegrelloTypography noWrap={true} variant="body-xs">
          {formatDateTimeString(value)}
        </RegrelloTypography>
      </div>
    </RegrelloTooltip>
  );
}

/** Renders a date and time string for display in a table cell. */
export function renderDateAndTimeCell(value: string | undefined): React.ReactNode {
  return (
    <div>
      <RegrelloTypography className="text-textDefault" component="p" noWrap={true} variant="body-xs">
        {value == null ? EMPTY_TABLE_CELL_VALUE : formatDateString(value)}
      </RegrelloTypography>
      <RegrelloTypography component="p" muted={true} noWrap={true} variant="body-xs">
        {value == null ? EMPTY_TABLE_CELL_VALUE : formatTimeString(value)}
      </RegrelloTypography>
    </div>
  );
}

/** Renders a number for display in a table cell. */
export function renderNumberCell(value: number | undefined) {
  return (
    <RegrelloTypography noWrap={true} variant="body-xs">
      {value == null || value === 0 ? EMPTY_TABLE_CELL_VALUE : value}
    </RegrelloTypography>
  );
}

/** Renders a single party for display in a table cell. */
export function renderPartyCell(party: PartyTypeUnion | undefined): React.ReactNode {
  return party == null ? (
    EMPTY_TABLE_CELL_VALUE
  ) : (
    <RegrelloTypography component="div" noWrap={true}>
      <RegrelloPartyAvatars parties={toFlatPartyArray([party])} />
    </RegrelloTypography>
  );
}

/** Renders a list of parties for display in a table cell. */
export function renderPartiesCell(value: PartyTypeUnion[] | undefined): React.ReactNode {
  return value == null ? (
    EMPTY_TABLE_CELL_VALUE
  ) : (
    <RegrelloTypography component="div" noWrap={true} variant="body-xs">
      <RegrelloPartyAvatars parties={toFlatPartyArray(value)} />
    </RegrelloTypography>
  );
}

/**
 * Renders the creator of the party cell. If the user was SCIM provisioned at any point, a
 * description Via SCIM will be displayed.
 */
export function renderCreatedByPartyCell(value: PartyTypeUnion[] | undefined): React.ReactNode {
  if (value?.[0] == null) {
    return EMPTY_TABLE_CELL_VALUE;
  }
  const creator = getPartyCreator(value[0]);

  if (creator == null) {
    return EMPTY_TABLE_CELL_VALUE;
  }

  return (
    <RegrelloTypography component="div" noWrap={true}>
      <RegrelloPartyAvatar
        isActivelyScimProvisioned={ScimPartyUtils.isPartyActivelyScimProvisioned(value[0])}
        party={toFlatParty(creator)}
        showViaScimCaption={ScimPartyUtils.isPartyAddedViaScim(value[0])}
        size="x-small"
      />
    </RegrelloTypography>
  );
}

/** Renders a list of tags for display in a table cell. */
export function renderTagsCell(value: TagFields[]): React.ReactNode {
  return (
    <div style={{ overflow: "hidden" }}>
      <TagInlineList tagFields={value} />
    </div>
  );
}

/** Renders a name inside a tag chip. */
export function renderTagChipCell(value: string): React.ReactNode {
  return value == null || value === "" ? (
    EMPTY_TABLE_CELL_VALUE
  ) : (
    <RegrelloChip icon={{ type: "iconName", iconName: "tag" }}>{value}</RegrelloChip>
  );
}

export function renderTagTypeChipCell(value: string): React.ReactNode {
  return value == null || value === "" ? EMPTY_TABLE_CELL_VALUE : <RegrelloChip>{value}</RegrelloChip>;
}

/** Renders text for display in a table cell. */
export function renderTextCell(value: string | undefined): React.ReactNode {
  return value == null ? (
    EMPTY_TABLE_CELL_VALUE
  ) : (
    <RegrelloTypography noWrap={true} variant="body-xs">
      {value}
    </RegrelloTypography>
  );
}

/** Renders alternate emails for display in a table cell. */
export function renderAlternateEmails(value: string[] | undefined) {
  if (value == null) {
    return "-";
  }
  return (
    <div className="truncate">
      <ul>
        {value.map((email) => {
          return <div key={email}>{email}</div>;
        })}
      </ul>
    </div>
  );
}

/** Renders text for display in a table cell with tooltip. */
export function renderTextCellWithTooltip(value: string | undefined) {
  return value == null ? (
    EMPTY_TABLE_CELL_VALUE
  ) : (
    <RegrelloTooltip content={value}>
      <RegrelloTypography noWrap={true} variant="body-xs">
        {value}
      </RegrelloTypography>
    </RegrelloTooltip>
  );
}

/** Renders text for display in a table cell and try to linkify it. */
export function renderLinkifiedTextCell(value: string | undefined) {
  return value == null ? (
    EMPTY_TABLE_CELL_VALUE
  ) : (
    <RegrelloTypography noWrap={true} variant="body-xs">
      <RegrelloLinkify>{value}</RegrelloLinkify>
    </RegrelloTypography>
  );
}

export function renderTextCellWithHighlights(highlights: Array<string | undefined>) {
  const insensitiveHighlights = highlights
    .filter((highlight): highlight is string => highlight != null && highlight !== EMPTY_STRING)
    .map((highlight) => highlight.toLowerCase());

  return function renderCell(value: string | undefined) {
    if (value == null) {
      return EMPTY_TABLE_CELL_VALUE;
    }

    // Split on highlight term and include term into parts, ignore case
    const parts = value.split(new RegExp(`(${insensitiveHighlights.join("|")})`, "gi"));

    return (
      <RegrelloTooltip content={value}>
        <RegrelloTypography noWrap={true} variant="body-xs">
          {parts.map((part, i) => {
            if (insensitiveHighlights.includes(part.toLowerCase())) {
              return (
                <mark key={i} style={{ padding: 0, backgroundColor: "#FAECCD" }}>
                  {part}
                </mark>
              );
            }
            return part;
          })}{" "}
        </RegrelloTypography>
      </RegrelloTooltip>
    );
  };
}
