import type * as Types from "../../../generated/graphqlBaseTypes";

import { SpectrumFieldVersionWithWorkflowTemplateCountsFields } from "../../fragments/generated/SpectrumFieldVersionWithWorkflowTemplateCountsFields.generated";
import { SpectrumFieldFields } from "../../fragments/generated/SpectrumFieldFields.generated";
import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { SpectrumFieldValidationTypeFields } from "../../fragments/generated/SpectrumFieldValidationTypeFields.generated";
import { SpectrumFieldConstraintFields } from "../../fragments/generated/SpectrumFieldConstraintFields.generated";
import { SpectrumValueConstraintFields } from "../../fragments/generated/SpectrumValueConstraintFields.generated";
import { SpectrumFieldAllowedValueFields } from "../../fragments/generated/SpectrumFieldAllowedValueFields.generated";
import { FieldUnitFields } from "../../fragments/generated/FieldUnitFields.generated";
import { FieldFields } from "../../fragments/generated/FieldFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { RegrelloObjectFields } from "../../fragments/generated/RegrelloObjectFields.generated";
import { AutomationOAuth2ConfigFields } from "../../fragments/generated/AutomationOAuth2ConfigFields.generated";
import { RegrelloObjectPropertyFields } from "../../fragments/generated/RegrelloObjectPropertyFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  search?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  sortBy?: Types.InputMaybe<Types.CustomFieldDefaultColumnOption>;
  sortOrder?: Types.InputMaybe<Types.SortOrder>;
  params?: Types.InputMaybe<Types.LatestSpectrumFieldVersionsV2Params>;
  filters?: Types.InputMaybe<Types.LatestSpectrumFieldVersionsV2ColumnFilters>;
}>;

export type LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery = {
  latestSpectrumFieldVersionsV2: {
    totalCount: number;
    fields: Array<SpectrumFieldVersionWithWorkflowTemplateCountsFields>;
  };
};

export const LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryDocument = gql`
    query LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery($limit: Int, $offset: Int, $search: String, $sortBy: CustomFieldDefaultColumnOption, $sortOrder: SortOrder, $params: LatestSpectrumFieldVersionsV2Params, $filters: LatestSpectrumFieldVersionsV2ColumnFilters) {
  latestSpectrumFieldVersionsV2(
    limit: $limit
    offset: $offset
    search: $search
    sortBy: $sortBy
    sortOrder: $sortOrder
    params: $params
    filters: $filters
  ) {
    fields {
      ...SpectrumFieldVersionWithWorkflowTemplateCountsFields
    }
    totalCount
  }
}
    ${SpectrumFieldVersionWithWorkflowTemplateCountsFields}
${SpectrumFieldFields}
${PropertyTypeFields}
${SpectrumFieldValidationTypeFields}
${SpectrumFieldConstraintFields}
${SpectrumValueConstraintFields}
${SpectrumFieldAllowedValueFields}
${FieldUnitFields}
${FieldFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${RegrelloObjectFields}
${AutomationOAuth2ConfigFields}
${RegrelloObjectPropertyFields}`;

/**
 * __useLatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery__
 *
 * To run a query within a React component, call `useLatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      params: // value for 'params'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useLatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery,
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery,
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryVariables
  >(LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryDocument, options);
}
export function useLatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery,
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery,
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryVariables
  >(LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryDocument, options);
}
export function useLatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery,
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery,
    LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryVariables
  >(LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryDocument, options);
}
export type LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryHookResult = ReturnType<
  typeof useLatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery
>;
export type LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryLazyQueryHookResult = ReturnType<
  typeof useLatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryLazyQuery
>;
export type LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuerySuspenseQueryHookResult = ReturnType<
  typeof useLatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuerySuspenseQuery
>;
export type LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryResult = Apollo.QueryResult<
  LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQuery,
  LatestSpectrumFieldVersionsV2WithWorkflowTemplateCountsQueryVariables
>;
