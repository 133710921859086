import { gql } from "@apollo/client";
export type WorkflowPermissionsFields = {
  canAddAutomatedTask: boolean;
  canAddLinkedWorkflowTask: boolean;
  canRead: boolean;
  canEdit: boolean;
  canEditExistingAccess: boolean;
  canEditExistingFields: boolean;
  canEditData: boolean;
  canReorderStages: boolean;
  canStart: boolean;
  canDelete: boolean;
  permissionsToWorkflowOwner: { canEdit: boolean; isLocked: boolean };
};

export const WorkflowPermissionsFields = gql`
    fragment WorkflowPermissionsFields on UserPermissionsToWorkflow {
  canAddAutomatedTask
  canAddLinkedWorkflowTask
  canRead
  canEdit
  canEditExistingAccess
  canEditExistingFields
  permissionsToWorkflowOwner {
    canEdit
    isLocked
  }
  canEditData
  canReorderStages
  canStart
  canDelete
  canEditExistingAccess
}
    `;
