import { clsx, type WithClassName } from "@regrello/core-utils";
import React from "react";

import type { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import { RegrelloButton, type RegrelloButtonProps } from "../button/RegrelloButton";
import { RegrelloIcon, type RegrelloIconName } from "../icons/RegrelloIcon";
import { RegrelloTypography } from "../typography/RegrelloTypography";

export interface RegrelloCalloutProps extends WithClassName {
  /**
   * An optional list of buttons to show below the {@link text}. Will be shown in order from left to
   * right. By default, will use `primary` intent unless {@link intent} is `danger`.
   */
  actions?: Array<Omit<RegrelloButtonProps, "size">>;

  /** An icon to display in the callout. */
  icon?: RegrelloIconName;

  /**
   * A semantic intent to apply to the callout.
   * @default "neutral"
   */
  intent?: RegrelloIntent;

  /**
   * The size of the callout's padding and text.
   * @default "default"
   */
  size?: "small" | "default";

  /** The main text to display. */
  text: React.ReactNode;

  /** An optional title to display above the `text`. Will have bold styling by default. */
  title?: React.ReactNode;
}

/** A callout is a visually emphasized message used to convey a concise notice or warning inline. */
export const RegrelloCallout = React.memo<RegrelloCalloutProps>(function RegrelloCalloutFn({
  actions,
  className,
  icon,
  intent = "neutral",
  size = "medium",
  text,
  title,
}) {
  return (
    <div
      // TODO (clewis): Why are bg-backgroundSoft and friends not valid?

      className={clsx([
        `
        flex
        items-start
        gap-2

        p-3

        rounded
        border
        `,
        {
          "py-1 px-1.5 gap-1": size === "small",
          "bg-neutral-soft border-neutral-border": intent === "neutral",
          "bg-primary-soft border-primary-border": intent === "primary",
          "bg-success-soft border-success-border": intent === "success",
          "bg-warning-soft border-warning-border": intent === "warning",
          "bg-danger-soft border-danger-border": intent === "danger",
          "bg-secondary-soft border-secondary-border": intent === "secondary",
        },
        className,
      ])}
    >
      {icon != null &&
        (typeof icon === "string" ? (
          <RegrelloIcon
            className={clsx({
              "text-textMuted": intent === "neutral",
              "text-primary-textMuted": intent === "primary",
              "text-success-textMuted": intent === "success",
              "text-warning-textMuted": intent === "warning",
              "text-danger-textMuted": intent === "danger",
              "text-secondary-textMuted": intent === "secondary",
            })}
            iconName={icon}
            size={size === "small" ? "x-small" : "small"}
          />
        ) : (
          icon
        ))}
      <div className={clsx("flex flex-col gap-0.5", { "gap-0 mt-px": size === "small" })}>
        {title != null && (
          <RegrelloTypography
            className={clsx("font-semibold", {
              "text-textDefault": intent === "neutral",
              "text-primary-textMuted": intent === "primary",
              "text-success-textMuted": intent === "success",
              "text-warning-textMuted": intent === "warning",
              "text-danger-textMuted": intent === "danger",
              "text-secondary-textMuted": intent === "secondary",
            })}
            component="div"
            variant={size === "small" ? "body-xs" : "body"}
          >
            {title}
          </RegrelloTypography>
        )}
        <RegrelloTypography className="text-textDefault" variant={size === "small" ? "body-xs" : "body"}>
          {text}
        </RegrelloTypography>
        {actions != null && (
          <div className="flex justify-end gap-2 mt-2">
            {actions.map((buttonProps, index) => (
              <RegrelloButton
                key={index}
                intent={
                  // Show primary buttons in a neutral callout.
                  intent === "danger" ? "danger" : "primary"
                }
                {...buttonProps}
                size="small"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
});
