import type { PartyFields } from "./PartyFields.generated";
import { gql } from "@apollo/client";
export type DelegationFields = {
  id: number;
  createdAt: string;
  outOfOfficeEvent: { startAt: string; endAt?: string | null; party: PartyFields };
  delegateValues?: { isCcdOnDelegatedTask: boolean; delegates: Array<PartyFields> } | null;
};

export const DelegationFields = gql`
    fragment DelegationFields on OutOfOfficeDelegationEvent {
  id
  createdAt
  outOfOfficeEvent {
    startAt
    endAt
    party {
      ...PartyFields
    }
  }
  delegateValues {
    isCcdOnDelegatedTask
    delegates {
      ...PartyFields
    }
  }
}
    `;
