import { t } from "@lingui/macro";
/* eslint-disable class-methods-use-this */
import { EMPTY_ARRAY, noop } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  type FieldFields,
  FormConstraintConditionOperator,
  PropertyDataType,
  type PropertyTypeFields,
  type SpectrumFieldValidationTypeFields,
  type SpectrumFieldVersionFields,
  type SpectrumValueConstraintFields,
} from "@regrello/graphql-api";
import { type RegrelloIconName, RegrelloPhoneInput } from "@regrello/ui-core";
import { lazy, type ReactNode, Suspense } from "react";
import type { FieldArrayWithId, FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

import { SpectrumFieldPluginDecorator } from "./types/SpectrumFieldPluginDecorator";
import { SpectrumFieldValidationType } from "./types/SpectrumFieldValidationType";
import type { ConfigureSpectrumFieldFormFormFields } from "../../views/modals/formDialogs/spectrumFields/_internal/ConfigureSpectrumFieldForm";
import type {
  CustomFieldPlugin,
  CustomFieldPluginV2RenderFormFieldProps,
} from "../customFields/plugins/types/CustomFieldPlugin";

// (dosipiuk): We need to code-split phone validation library.
// eslint-disable-next-line react-refresh/only-export-components
const RegrelloControlledFormFieldPhone = lazy(async () => {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const page = await import("../formFields/controlled/RegrelloControlledFormFieldPhone");
  return { default: page.RegrelloControlledFormFieldPhone };
});

type PhoneFieldPluginFrontendValue = string | null;

export class SpectrumPhoneFieldPluginDecorator extends SpectrumFieldPluginDecorator<PhoneFieldPluginFrontendValue> {
  constructor(plugin: CustomFieldPlugin<PhoneFieldPluginFrontendValue>) {
    super(plugin);
    this.uri = "com.regrello.spectrumField.phone";
  }

  public canProcessValidationType(spectrumValidationType: SpectrumFieldValidationTypeFields) {
    return spectrumValidationType.validationType === SpectrumFieldValidationType.PHONE;
  }

  public canProcessSpectrumField(field: SpectrumFieldVersionFields) {
    return (
      this.canProcessPropertyDataType(field.propertyType.dataType) &&
      this.canProcessValidationType(field.validationType)
    );
  }

  public findPropertyTypeFromLoadedPropertyTypes(propertyTypes: PropertyTypeFields[]) {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.STRING);
  }

  public findValidationTypeFromLoadedValidationTypes(validationTypes: SpectrumFieldValidationTypeFields[]) {
    return validationTypes.find(
      (validationType) => validationType.validationType === SpectrumFieldValidationType.PHONE,
    );
  }

  public findValueConstraintsFromLoadedValueConstraints(_valueConstraints: SpectrumValueConstraintFields[]) {
    return EMPTY_ARRAY;
  }

  public isDataFormatToggleVisible() {
    return false;
  }

  public isValueConstraintEnabled() {
    return false;
  }

  public override getFieldDisplayName = () => {
    return t`Phone`;
  };

  public override getIconName = (): RegrelloIconName => {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    return "phone";
  };

  public getConstraintConditionOperators() {
    return {
      [FormConstraintConditionOperator.IS_ZERO]: {
        label: t`Is empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.IS_NOT_ZERO]: {
        label: t`Is not empty`,
        inputCount: 0,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.EQ]: {
        label: t`Is equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.NOT]: {
        label: t`Is not equal to`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_STARTS_WITH]: {
        label: t`Starts with`,
        inputCount: 1,
        isMultiselect: false,
      },
      [FormConstraintConditionOperator.STRING_ENDS_WITH]: {
        label: t`Ends with`,
        inputCount: 1,
        isMultiselect: false,
      },
    };
  }

  public getSpectrumFormAutosaveMode() {
    return "onBlur" as const;
  }

  public renderPreviewFormField() {
    return <RegrelloPhoneInput disabled={true} onChange={noop} size="medium" value="" />;
  }

  public renderValueConstraints(_props: {
    constraints: Array<FieldArrayWithId<ConfigureSpectrumFieldFormFormFields, "valueConstraints", "id">>;
    disabled: boolean;
    form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;
    focusField: `valueConstraints.${number}.args.${number}`;
  }): ReactNode {
    return null;
  }

  public override renderSpectrumFormField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    _field: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
  ): React.ReactNode => {
    const isDeleted = props.fieldInstance?.field.deletedAt != null;
    return (
      <Suspense fallback="">
        {/* @ts-expect-error Lazy component props mismatch */}
        <RegrelloControlledFormFieldPhone
          {...props}
          key={props.controllerProps.name}
          dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
          infoTooltipText={props.description ?? undefined}
          isDeleted={isDeleted}
        />
      </Suspense>
    );
  };
}
