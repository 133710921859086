import type * as Types from "../../../generated/graphqlBaseTypes";

import { AdminRoleFieldsWithCount } from "../../fragments/generated/AdminRoleFieldsWithCount.generated";
import { AdminRoleFields } from "../../fragments/generated/AdminRoleFields.generated";
import { UserFields } from "../../fragments/generated/UserFields.generated";
import { UserFieldsWithoutRoles } from "../../fragments/generated/UserFieldsWithoutRoles.generated";
import { AccessRoleFields } from "../../fragments/generated/AccessRoleFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type AdminRolesQueryVariables = Types.Exact<{
  input: Types.RolesQueryInput;
}>;

export type AdminRolesQuery = { roles: { totalCount: number; roles: Array<AdminRoleFieldsWithCount> } };

export const AdminRolesQueryDocument = gql`
    query AdminRolesQuery($input: RolesQueryInput!) {
  roles(input: $input) {
    roles {
      ...AdminRoleFieldsWithCount
    }
    totalCount
  }
}
    ${AdminRoleFieldsWithCount}
${AdminRoleFields}
${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}`;

/**
 * __useAdminRolesQuery__
 *
 * To run a query within a React component, call `useAdminRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminRolesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminRolesQuery(
  baseOptions: Apollo.QueryHookOptions<AdminRolesQuery, AdminRolesQueryVariables> &
    ({ variables: AdminRolesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminRolesQuery, AdminRolesQueryVariables>(AdminRolesQueryDocument, options);
}
export function useAdminRolesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminRolesQuery, AdminRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminRolesQuery, AdminRolesQueryVariables>(AdminRolesQueryDocument, options);
}
export function useAdminRolesQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AdminRolesQuery, AdminRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminRolesQuery, AdminRolesQueryVariables>(AdminRolesQueryDocument, options);
}
export type AdminRolesQueryHookResult = ReturnType<typeof useAdminRolesQuery>;
export type AdminRolesQueryLazyQueryHookResult = ReturnType<typeof useAdminRolesQueryLazyQuery>;
export type AdminRolesQuerySuspenseQueryHookResult = ReturnType<typeof useAdminRolesQuerySuspenseQuery>;
export type AdminRolesQueryResult = Apollo.QueryResult<AdminRolesQuery, AdminRolesQueryVariables>;
