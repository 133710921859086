import { gql } from "@apollo/client";
export type StartAfterWorkflowStageTemplateFields = {
  id: number;
  name: string;
  startAfterWorkflowStageTemplate?: { id: number; name: string } | null;
};

export const StartAfterWorkflowStageTemplateFields = gql`
    fragment StartAfterWorkflowStageTemplateFields on WorkflowStageTemplate {
  id
  name
  startAfterWorkflowStageTemplate {
    id
    name
  }
}
    `;
