import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { RegrelloObjectInstanceAuditHistoryFields } from "./RegrelloObjectInstanceAuditHistoryFields.generated";
import { gql } from "@apollo/client";
export type RegrelloObjectInstancesAuditHistoryFields = {
  id: number;
  createdAt: string;
  event: Types.AuditHistoryEvent;
  createdBy: PartyBaseFields;
  fields: Array<RegrelloObjectInstanceAuditHistoryFields>;
};

export const RegrelloObjectInstancesAuditHistoryFields = gql`
    fragment RegrelloObjectInstancesAuditHistoryFields on RegrelloObjectInstancesAuditHistory {
  id
  createdAt
  createdBy {
    ...PartyBaseFields
  }
  event
  fields {
    ...RegrelloObjectInstanceAuditHistoryFields
  }
}
    `;
