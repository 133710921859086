import type * as Types from "../../../generated/graphqlBaseTypes";

import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { NameTemplateFields } from "./NameTemplateFields.generated";
import { gql } from "@apollo/client";
export type WorkflowTemplateForSelectFieldFields = {
  id: number;
  name: string;
  type: Types.WorkflowTemplateType;
  fieldInstances: Array<FieldInstanceFields>;
  nameTemplate?: NameTemplateFields | null;
  workflowOwnerParty?: PartyBaseFields | null;
};

export const WorkflowTemplateForSelectFieldFields = gql`
    fragment WorkflowTemplateForSelectFieldFields on WorkflowTemplate {
  id
  name
  fieldInstances {
    ...FieldInstanceFields
  }
  nameTemplate {
    ...NameTemplateFields
  }
  workflowOwnerParty {
    ...PartyBaseFields
  }
  type
}
    `;
