import type * as Types from "../../../generated/graphqlBaseTypes";

import type { UserBaseFields } from "./UserBaseFields.generated";
import type { WorkflowQueryActionItemTemplateFields } from "./WorkflowQueryActionItemTemplateFields.generated";
import type { ConditionalExpressionGroupFields } from "./ConditionalExpressionGroupFields.generated";
import { gql } from "@apollo/client";
export type WorkflowStageFields = {
  id: number;
  name: string;
  description?: string | null;
  status: Types.WorkflowStageStatus;
  createdAt: string;
  required: boolean;
  executionOrder: number;
  hasException: boolean;
  frozeForReason?: Types.WorkflowStageFrozeForReason | null;
  startAt?: string | null;
  startedAt?: string | null;
  startOnWorkflowStart: boolean;
  isLocked: boolean;
  createdBy: UserBaseFields;
  actionItemTemplates: Array<WorkflowQueryActionItemTemplateFields>;
  startAfterWorkflowStage?: { id: number; name: string; status: Types.WorkflowStageStatus } | null;
  startAfterWorkflowStages?: Array<{ id: number; name: string; status: Types.WorkflowStageStatus }> | null;
  startingConditions?: ConditionalExpressionGroupFields | null;
  permissions: { canStart: boolean; canDelete: boolean; canEdit: boolean };
};

export const WorkflowStageFields = gql`
    fragment WorkflowStageFields on WorkflowStage {
  id
  name
  description
  status
  createdBy {
    ...UserBaseFields
  }
  createdAt
  actionItemTemplates {
    ...WorkflowQueryActionItemTemplateFields
  }
  required
  executionOrder
  hasException
  frozeForReason
  startAt
  startedAt
  startAfterWorkflowStage {
    id
    name
    status
  }
  startAfterWorkflowStages {
    id
    name
    status
  }
  startingConditions {
    ...ConditionalExpressionGroupFields
  }
  startOnWorkflowStart
  permissions {
    canStart
    canDelete
    canEdit
  }
  isLocked
}
    `;
