import { gql } from "@apollo/client";
export type RoleFields = {
  id: number;
  name: string;
  isSystem: boolean;
  scimId?: string | null;
  deletedAt?: string | null;
};

export const RoleFields = gql`
    fragment RoleFields on Role {
  id
  name
  isSystem
  scimId
  deletedAt
}
    `;
