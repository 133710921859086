// (clewis): Ideally, this would be served in our GraphQL schema, but I'm adding it only on the
// frontend for now.
export const FrontendAuditHistoryFieldName = {
  ACTION_ITEM_ADJUSTED_DUE_DATE: "ActionItemAdjustedDueDate",
  ACTION_ITEM_ADJUSTED_DUE_DATE_TIMEZOME: "ActionItemAdjustedDueDateTimezone",
  ACTION_ITEM_PLANNED_START_DATE: "ActionItemPlannedStartDate",
  ACTION_ITEM_COMPLETED: "ActionItemCompleted",
  APPROVAL_ACTION_ITEM: "ApprovalActionItem",
  APPROVAL_STATUS: "ApprovalStatus",
  ASSIGNED_BY_PARTY_ID: "AssignedByPartyID",
  ASSIGNEES: "Assignees",
  AUTOMATION_DOCUMENT_UPLOAD_FAILED_ERROR_CODE: "FailedToUploadDocumentErrorCode",
  AUTOMATION_DOCUMENT_UPLOAD_FAILED_ERROR_MESSAGE: "FailedToUploadDocumentErrorMessage",
  AUTOMATION_REQUEST_URL: "AutomationRequestUrl",
  AUTOMATION_REQUEST_FORM_DATA: "AutomationRequestFormData",
  AUTOMATION_REQUEST_JSON_DATA: "AutomationRequestJsonData",
  AUTOMATION_REQUEST_HEADERS: "AutomationRequestHeaders",
  AUTOMATION_REQUEST_METHOD: "AutomationRequestMethod",
  AUTOMATION_RESPONSE_BODY: "AutomationResponseBody",
  AUTOMATION_RESPONSE_STATUS: "AutomationResponseStatus",
  AUTOMATION_EXTRACT_VALUE_FROM_JSON_RESPONSE_ERROR: "AutomationExtractValueFromJsonResponseError",
  AUTOMATION_WORKATO_REQUEST_URL: "WorkatoApiRequestUrl",
  AUTOMATION_WORKATO_REQUEST_METHOD: "WorkatoApiRequestMethod",
  AUTOMATION_WORKATO_RESPONSE_STATUS: "WorkatoApiResponseStatus",
  AUTOMATION_WORKATO_RESPONSE_BODY: "WorkatoApiResponseBody",
  AUTOMATION_REQUEST_RETRY_TIMED_OUT: "AutomationRequestRetryTimedOut",
  AWAITING_AUTOMATION_RESPONSE: "AwaitingAutomationResponse",
  CC: "Cc",
  CUSTOM_FIELD_BOOLEAN_VALUE: "Custom Field Boolean Value",
  CUSTOM_FIELD_DOCUMENT_ID_MULTI_VALUE: "Custom Field Document Id Multi Value",
  CUSTOM_FIELD_DOCUMENT_ID_VALUE: "Custom Field Document Id Value",
  CUSTOM_FIELD_FLOAT_VALUE: "Custom Field Float Value",
  CUSTOM_FIELD_INT_VALUE: "Custom Field Int Value",
  CUSTOM_FIELD_PARTY_ID_MULTI_VALUE: "Custom Field Party Id Multi Value",
  CUSTOM_FIELD_PARTY_ID_VALUE: "Custom Field Party Id Value",
  CUSTOM_FIELD_REGRELLO_OBJECT_INSTANCE_MULTI_VALUE: "Custom Field Regrello Object Instance Id Multi Value",
  CUSTOM_FIELD_STRING_VALUE: "Custom Field String Value",
  CUSTOM_FIELD_STRING_ALLOWED_VALUE_ID_MULTI_VALUE: "Custom Field String Allowed Value Id Multi Value",
  CUSTOM_FIELD_TIME_VALUE: "Custom Field Time Value",
  CUSTOM_FIELD_DRAFT_BOOLEAN_VALUE: "Custom Field Boolean Value (Draft)",
  CUSTOM_FIELD_DRAFT_DOCUMENT_ID_MULTI_VALUE: "Custom Field Document Id Multi Value (Draft)",
  CUSTOM_FIELD_DRAFT_DOCUMENT_ID_VALUE: "Custom Field Document Id Value (Draft)",
  CUSTOM_FIELD_DRAFT_FLOAT_VALUE: "Custom Field Float Value (Draft)",
  CUSTOM_FIELD_DRAFT_INT_VALUE: "Custom Field Int Value (Draft)",
  CUSTOM_FIELD_DRAFT_PARTY_ID_MULTI_VALUE: "Custom Field Party Id Multi Value (Draft)",
  CUSTOM_FIELD_DRAFT_PARTY_ID_VALUE: "Custom Field Party Id Value (Draft)",
  CUSTOM_FIELD_DRAFT_REGRELLO_OBJECT_INSTANCE_MULTI_VALUE:
    "Custom Field Regrello Object Instance Id Multi Value (Draft)",
  CUSTOM_FIELD_DRAFT_STRING_VALUE: "Custom Field String Value (Draft)",
  CUSTOM_FIELD_DRAFT_STRING_ALLOWED_VALUE_ID_MULTI_VALUE: "Custom Field String Allowed Value Id Multi Value (Draft)",
  CUSTOM_FIELD_DRAFT_TIME_VALUE: "Custom Field Time Value (Draft)",
  DESCRIPTION: "Description",
  DOCUMENTS: "Documents",
  DOCUSIGN_ENVELOPE_STATUS: "DocusignEnvelopeStatus",
  DOCUSIGN_ENVELOPE_STATUS_CHANGED_BY_PARTY: "DocusignEnvelopeStatusChangedByParty",
  DUE_ON: "DueOn",
  EXCEPTION_REPORTED: "ExceptionReported",
  EXCEPTION_RESOLVED: "ExceptionResolved",
  FROZE_FOR_REASON: "FrozeForReason",
  HAS_EXCEPTION: "HasException",
  NAME: "Name",
  REQUIRES_ACK: "RequiresAck",
  REQUIRES_REVIEW: "RequiresReview",
  REVIEWERS: "Reviewers",
  SAVED_FIELDS: "SavedFields",
  STATUS: "Status",
  SUBMITTED_FOR_APPROVAL_TO_PARTIES: "SubmittedForApprovalToParties",
  TAGS: "Tags",
  WORKFLOW_STATUS: "WorkflowStatus", // (hchen): Workflow status change recorded by children tasks
  WORKFLOW_STAGE_STATUS: "WorkflowStageStatus", // (hchen): Workflow stage status change recorded by children tasks
  LINKED_WORKFLOW_STATUS: "LinkedWorkflowStatus", // (akager): Linked workflow status change recorded on linked workflow task
  LINKED_WORKFLOW_ACTION_ITEM_FIELD_OWNER_INVALID: "LinkedWorklfowActionItemFieldOwnerInvalid",
};

export const AuditHistoryFileError = {
  DOWNLOAD_FAILED_FILE_NOT_FOUND: "DOWNLOAD_FAILED_FILE_NOT_FOUND",
  DOWNLOAD_FAILED_UNAUTHORIZED: "DOWNLOAD_FAILED_UNAUTHORIZED",
  DOWNLOAD_FAILED_PERMISSION_DENIED: "DOWNLOAD_FAILED_PERMISSION_DENIED",
  DOWNLOAD_FAILED: "DOWNLOAD_FAILED",
  UPLOAD_FAILED_FOLDER_NOT_FOUND: "UPLOAD_FAILED_FOLDER_NOT_FOUND",
  UPLOAD_FAILED_UNAUTHORIZED: "UPLOAD_FAILED_UNAUTHORIZED",
  UPLOAD_FAILED_PERMISSION_DENIED: "UPLOAD_FAILED_PERMISSION_DENIED",
  UPLOAD_FAILED: "UPLOAD_FAILED",
  CREATE_SHARED_LINK_FAILED: "CREATE_SHARED_LINK_FAILED",
  SHAREPOINT_INVALID_FILENAME: "SHAREPOINT_INVALID_FILENAME",
} as const;

export const DocusignEnvelopeStatus = {
  APPROVED: "ENVELOPE_APPROVED",
  CONNECTION_ERROR: "ENVELOPE_CONNECTION_ERROR",
  DECLINED: "ENVELOPE_DECLINED",
  REASSIGNED: "ENVELOPE_REASSIGNED",
  RESET: "ENVELOPE_RESET",
  SENT_FOR_SIGNATURE: "ENVELOPE_SENT_FOR_SIGNATURE",
  SIGNED: "ENVELOPE_SIGNED",
  VOIDED: "ENVELOPE_VOID",
} as const;
