import type * as Types from "../../../generated/graphqlBaseTypes";

import { UserFields } from "../../fragments/generated/UserFields.generated";
import { UserFieldsWithoutRoles } from "../../fragments/generated/UserFieldsWithoutRoles.generated";
import { AccessRoleFields } from "../../fragments/generated/AccessRoleFields.generated";
import { OutOfOfficeEventFields } from "../../fragments/generated/OutOfOfficeEventFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { UserPermissionsToWorkspaceFields } from "../../fragments/generated/UserPermissionsToWorkspaceFields.generated";
import { PermissionedSidebarContentFlagsFields } from "../../fragments/generated/PermissionedSidebarContentFlagsFields.generated";
import { PermissionedCreateMenuContentFlagsFields } from "../../fragments/generated/PermissionedCreateMenuContentFlagsFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  currentUser?:
    | ({
        accessRole?: AccessRoleFields | null;
        currentOutOfOfficeEvent?: OutOfOfficeEventFields | null;
        permissions: UserPermissionsToWorkspaceFields;
      } & UserFields)
    | null;
};

export const CurrentUserQueryDocument = gql`
    query CurrentUserQuery {
  currentUser {
    ...UserFields
    accessRole {
      ...AccessRoleFields
    }
    currentOutOfOfficeEvent {
      ...OutOfOfficeEventFields
    }
    permissions {
      ...UserPermissionsToWorkspaceFields
    }
  }
}
    ${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}
${OutOfOfficeEventFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${UserPermissionsToWorkspaceFields}
${PermissionedSidebarContentFlagsFields}
${PermissionedCreateMenuContentFlagsFields}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserQueryDocument, options);
}
export function useCurrentUserQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserQueryDocument, options);
}
export function useCurrentUserQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserQueryDocument, options);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserQueryLazyQueryHookResult = ReturnType<typeof useCurrentUserQueryLazyQuery>;
export type CurrentUserQuerySuspenseQueryHookResult = ReturnType<typeof useCurrentUserQuerySuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
