import type { FieldInstanceValueTimeFields } from "./FieldInstanceValueTimeFields.generated";
import type { AcyclicFieldInstanceValueTimeFields } from "./AcyclicFieldInstanceValueTimeFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueTimeWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceValueTime?: AcyclicFieldInstanceValueTimeFields | null;
  crossWorkflowSinksFieldInstanceValueTime?: Array<AcyclicFieldInstanceValueTimeFields> | null;
} & FieldInstanceValueTimeFields;

export const FieldInstanceValueTimeWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueTimeWithCrossWorkflowFields on FieldInstanceValueTime {
  ...FieldInstanceValueTimeFields
  crossWorkflowSourceFieldInstanceValueTime {
    ...AcyclicFieldInstanceValueTimeFields
  }
  crossWorkflowSinksFieldInstanceValueTime {
    ...AcyclicFieldInstanceValueTimeFields
  }
}
    `;
