import type * as Types from "../../../generated/graphqlBaseTypes";

import { PropertyTypeFields } from "../../fragments/generated/PropertyTypeFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type PropertyTypesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PropertyTypesQuery = { propertyTypes: Array<PropertyTypeFields> };

export const PropertyTypesQueryDocument = gql`
    query PropertyTypesQuery {
  propertyTypes {
    ...PropertyTypeFields
  }
}
    ${PropertyTypeFields}`;

/**
 * __usePropertyTypesQuery__
 *
 * To run a query within a React component, call `usePropertyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePropertyTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<PropertyTypesQuery, PropertyTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PropertyTypesQuery, PropertyTypesQueryVariables>(PropertyTypesQueryDocument, options);
}
export function usePropertyTypesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PropertyTypesQuery, PropertyTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PropertyTypesQuery, PropertyTypesQueryVariables>(PropertyTypesQueryDocument, options);
}
export function usePropertyTypesQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PropertyTypesQuery, PropertyTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PropertyTypesQuery, PropertyTypesQueryVariables>(PropertyTypesQueryDocument, options);
}
export type PropertyTypesQueryHookResult = ReturnType<typeof usePropertyTypesQuery>;
export type PropertyTypesQueryLazyQueryHookResult = ReturnType<typeof usePropertyTypesQueryLazyQuery>;
export type PropertyTypesQuerySuspenseQueryHookResult = ReturnType<typeof usePropertyTypesQuerySuspenseQuery>;
export type PropertyTypesQueryResult = Apollo.QueryResult<PropertyTypesQuery, PropertyTypesQueryVariables>;
