import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { TagFields } from "./TagFields.generated";
import type { DocumentFields } from "./DocumentFields.generated";
import type { WorkflowQueryActionItemFields } from "./WorkflowQueryActionItemFields.generated";
import type { AutomationRequestFields } from "./AutomationRequestFields.generated";
import type { ActionItemTemplatePermissionsFields } from "./ActionItemTemplatePermissionsFields.generated";
import type { ActionItemTemplateStartAfterActionItemTemplateChain } from "./ActionItemTemplateStartAfterActionItemTemplateChain.generated";
import { gql } from "@apollo/client";
export type WorkflowQueryChildActionItemTemplateFields = {
  __typename: "ActionItemTemplate";
  uuid: string;
  name: string;
  displayOrder: number;
  frozeForReason?: Types.ActionItemTemplateFrozeForReason | null;
  type: Types.ActionItemType;
  isLocked: boolean;
  id: number;
  dueOn?: string | null;
  plannedStartAt?: string | null;
  plannedDueOn?: string | null;
  conditionalStartAt?: string | null;
  conditionalDueOn?: string | null;
  dueOnIntervalSeconds?: number | null;
  startAt?: string | null;
  cc: Array<PartyBaseFields>;
  fieldInstances: Array<FieldInstanceFields>;
  actionItems: Array<
    {
      auditHistory: Array<{
        __typename: "ActionItemAuditHistory";
        id: number;
        createdAt: string;
        event: Types.AuditHistoryEvent;
        reason?: Types.TaskStatusChangeReason | null;
        createdBy: PartyBaseFields;
        fields: Array<{
          __typename: "ActionItemAuditHistoryDelta";
          id: number;
          fieldName: string;
          valueBefore?: string | null;
          valueAfter?: string | null;
          collectionAdd?: string | null;
          collectionRemove?: string | null;
        }>;
      }>;
    } & WorkflowQueryActionItemFields
  >;
  assignees: Array<PartyBaseFields>;
  fieldInstancesControllingAssignees?: Array<FieldInstanceFields> | null;
  assignedBy: PartyBaseFields;
  reviewers: Array<PartyBaseFields>;
  tags: Array<TagFields>;
  documents: Array<DocumentFields>;
  automationRequests: Array<AutomationRequestFields>;
  permissions: ActionItemTemplatePermissionsFields;
} & ActionItemTemplateStartAfterActionItemTemplateChain;

export const WorkflowQueryChildActionItemTemplateFields = gql`
    fragment WorkflowQueryChildActionItemTemplateFields on ActionItemTemplate {
  __typename
  uuid
  name
  displayOrder
  frozeForReason
  type
  cc {
    ...PartyBaseFields
  }
  fieldInstances {
    ...FieldInstanceFields
  }
  isLocked
  actionItems {
    ...WorkflowQueryActionItemFields
    auditHistory {
      __typename
      id
      createdAt
      createdBy {
        ...PartyBaseFields
      }
      event
      fields {
        id
        __typename
        fieldName
        valueBefore
        valueAfter
        collectionAdd
        collectionRemove
      }
      reason
    }
  }
  id
  assignees {
    ...PartyBaseFields
  }
  dueOn
  plannedStartAt
  plannedDueOn
  conditionalStartAt
  conditionalDueOn
  dueOnIntervalSeconds
  fieldInstancesControllingAssignees {
    ...FieldInstanceFields
  }
  assignedBy {
    ...PartyBaseFields
  }
  reviewers {
    ...PartyBaseFields
  }
  tags {
    ...TagFields
  }
  documents {
    ...DocumentFields
  }
  automationRequests {
    ...AutomationRequestFields
  }
  permissions {
    ...ActionItemTemplatePermissionsFields
  }
  startAt
  ...ActionItemTemplateStartAfterActionItemTemplateChain
}
    `;
