import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import type { ActionItemAuditHistoryFields } from "./ActionItemAuditHistoryFields.generated";
import type { RootCommentFields } from "./RootCommentFields.generated";
import { gql } from "@apollo/client";
export type ActionItemApprovalFields = {
  approvalStatus?: Types.ApprovalStatus | null;
  approvalActionItemTemplates: Array<{
    id: number;
    uuid: string;
    assignees: Array<PartyBaseFields>;
    actionItems: Array<{
      id: number;
      uuid: string;
      assignedBy: PartyBaseFields;
      createdBy: UserBaseFields;
      assignees: Array<PartyBaseFields>;
      cc: Array<PartyBaseFields>;
      auditHistory: Array<ActionItemAuditHistoryFields>;
      comments: Array<RootCommentFields>;
    }>;
  }>;
  approvalForActionItem?: {
    id: number;
    uuid: string;
    assignedBy: PartyBaseFields;
    createdBy: UserBaseFields;
    assignees: Array<PartyBaseFields>;
    cc: Array<PartyBaseFields>;
    approvalActionItemTemplates: Array<{
      id: number;
      uuid: string;
      assignees: Array<PartyBaseFields>;
      actionItems: Array<{
        id: number;
        uuid: string;
        assignedBy: PartyBaseFields;
        createdBy: UserBaseFields;
        assignees: Array<PartyBaseFields>;
        cc: Array<PartyBaseFields>;
        auditHistory: Array<ActionItemAuditHistoryFields>;
        comments: Array<RootCommentFields>;
      }>;
    }>;
  } | null;
};

export const ActionItemApprovalFields = gql`
    fragment ActionItemApprovalFields on ActionItem {
  approvalActionItemTemplates {
    id
    uuid
    assignees {
      ...PartyBaseFields
    }
    actionItems {
      id
      uuid
      assignedBy {
        ...PartyBaseFields
      }
      createdBy {
        ...UserBaseFields
      }
      assignees {
        ...PartyBaseFields
      }
      cc {
        ...PartyBaseFields
      }
      auditHistory {
        ...ActionItemAuditHistoryFields
      }
      comments {
        ...RootCommentFields
      }
    }
  }
  approvalStatus
  approvalForActionItem {
    id
    uuid
    assignedBy {
      ...PartyBaseFields
    }
    createdBy {
      ...UserBaseFields
    }
    assignees {
      ...PartyBaseFields
    }
    cc {
      ...PartyBaseFields
    }
    approvalActionItemTemplates {
      id
      uuid
      assignees {
        ...PartyBaseFields
      }
      actionItems {
        id
        uuid
        assignedBy {
          ...PartyBaseFields
        }
        createdBy {
          ...UserBaseFields
        }
        assignees {
          ...PartyBaseFields
        }
        cc {
          ...PartyBaseFields
        }
        auditHistory {
          ...ActionItemAuditHistoryFields
        }
        comments {
          ...RootCommentFields
        }
      }
    }
  }
}
    `;
