import type { RegrelloObjectInstanceAuditHistoryDeltaFields } from "./RegrelloObjectInstanceAuditHistoryDeltaFields.generated";
import { gql } from "@apollo/client";
export type RegrelloObjectInstanceAuditHistoryFields = {
  line: { regrelloObjectInstanceId: number; lineNumber: number };
  fields: Array<RegrelloObjectInstanceAuditHistoryDeltaFields>;
};

export const RegrelloObjectInstanceAuditHistoryFields = gql`
    fragment RegrelloObjectInstanceAuditHistoryFields on RegrelloObjectInstanceAuditHistory {
  line {
    regrelloObjectInstanceId
    lineNumber
  }
  fields {
    ...RegrelloObjectInstanceAuditHistoryDeltaFields
  }
}
    `;
