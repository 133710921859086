import type { DocumentFields } from "./DocumentFields.generated";
import { gql } from "@apollo/client";
export type ActionItemAuditHistoryDocumentRelatedRecordFields = { document: DocumentFields };

export const ActionItemAuditHistoryDocumentRelatedRecordFields = gql`
    fragment ActionItemAuditHistoryDocumentRelatedRecordFields on AuditHistoryDocument {
  document {
    ...DocumentFields
  }
}
    `;
