import type { FormMinimalFields } from "./FormMinimalFields.generated";
import type { FormVersionFields } from "./FormVersionFields.generated";
import type { FormSectionStatusFields } from "./FormSectionStatusFields.generated";
import { gql } from "@apollo/client";
export type FormInstanceFields = {
  id: number;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  form: FormMinimalFields;
  formVersion: FormVersionFields;
  formSectionStatuses: Array<FormSectionStatusFields>;
};

export const FormInstanceFields = gql`
    fragment FormInstanceFields on FormInstance {
  id
  uuid
  createdAt
  updatedAt
  form {
    ...FormMinimalFields
  }
  formVersion {
    ...FormVersionFields
  }
  formSectionStatuses {
    ...FormSectionStatusFields
  }
}
    `;
