import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { PropertyTypeFields } from "./PropertyTypeFields.generated";
import type { FieldUnitFields } from "./FieldUnitFields.generated";
import type { RegrelloObjectFields } from "./RegrelloObjectFields.generated";
import { gql } from "@apollo/client";
export type FieldFields = {
  id: number;
  name: string;
  description?: string | null;
  isMultiValued: boolean;
  fieldType: Types.FieldType;
  updatedAt?: string | null;
  deletedAt?: string | null;
  allowedValues: Array<
    | { __typename: "FieldAllowedValueParty"; id: number; displayOrder: number; partyValue: PartyBaseFields }
    | { __typename: "FieldAllowedValueString"; id: number; displayOrder: number; stringValue: string }
  >;
  propertyType: PropertyTypeFields;
  fieldUnit?: FieldUnitFields | null;
  regrelloObject?: RegrelloObjectFields | null;
  fieldRestriction?: { filterByRole?: { id: number } | null } | null;
};

export const FieldFields = gql`
    fragment FieldFields on Field {
  id
  name
  description
  isMultiValued
  fieldType
  allowedValues {
    ... on FieldAllowedValueString {
      __typename
      id
      displayOrder
      stringValue
    }
    ... on FieldAllowedValueParty {
      __typename
      id
      displayOrder
      partyValue {
        ...PartyBaseFields
      }
    }
  }
  propertyType {
    ...PropertyTypeFields
  }
  fieldUnit {
    ...FieldUnitFields
  }
  regrelloObject {
    ...RegrelloObjectFields
  }
  fieldRestriction {
    filterByRole {
      id
    }
  }
  updatedAt
  deletedAt
}
    `;
