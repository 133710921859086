import type { PartyFields } from "./PartyFields.generated";
import type { AccessRoleFields } from "./AccessRoleFields.generated";
import { gql } from "@apollo/client";
export type WorkflowTemplateCollaborationFields = { party: PartyFields; accessRole: AccessRoleFields };

export const WorkflowTemplateCollaborationFields = gql`
    fragment WorkflowTemplateCollaborationFields on WorkflowTemplateCollaboration {
  party {
    ...PartyFields
  }
  accessRole {
    ...AccessRoleFields
  }
}
    `;
