import { t, Trans } from "@lingui/macro";
import { RegrelloLinkV2, RegrelloTypography } from "@regrello/ui-core";

import { REGRELLO_PRIVACY_POLICY_URL } from "../../../../../constants/globalConstants";
import { useQueryMap } from "../../../../../utils/hooks/useQueryStrings";
import { RegrelloCopyright } from "../../../../atoms/copyright/RegrelloCopyright";

export interface UnsubscribePageProps {
  isLoggedIn?: boolean;
}

/**
 * Page component that does not require authentication. Backend redirects user to this page whenever
 * user clicks `unsubscribe` button in received email. Depending on type of unsubscribe button
 * clicked, different message is presented to the user.
 */
export function UnsubscribePage({ isLoggedIn = false }: UnsubscribePageProps) {
  const { task, daily, weekly } = useQueryMap();

  return (
    <div className="flex justify-center w-full h-full">
      <div className="flex flex-col px-12 pb-4">
        <div className="flex flex-col justify-center flex-auto">
          <RegrelloTypography className="mb-10" variant="h1">
            {getMessage(task, daily, weekly)}
          </RegrelloTypography>

          <RegrelloTypography>
            <Trans>You can turn notifications back on at any time.</Trans>
          </RegrelloTypography>

          {isLoggedIn ? null : (
            <div className="mb-10 mt-4">
              <RegrelloLinkV2 className="font-normal" to="/">
                <Trans>Sign in to manage notifications</Trans>
              </RegrelloLinkV2>
            </div>
          )}
        </div>
        {isLoggedIn ? null : (
          <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
            <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
              {t`Privacy Policy`}
            </RegrelloLinkV2>
            <RegrelloCopyright />
          </RegrelloTypography>
        )}
      </div>
    </div>
  );
}

function getMessage(task: string | undefined, daily: string | undefined, weekly: string | undefined) {
  if (task != null) {
    return t`Notifications for this task have been turned off.`;
  }
  if (daily != null) {
    return t`Daily task alerts have been turned off.`;
  }
  if (weekly != null) {
    return t`Weekly Digests have been turned off.`;
  }
  return t`Notifications have been turned off.`;
}
