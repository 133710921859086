import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type AcyclicFieldInstanceValueBooleanFields = {
  __typename: "FieldInstanceValueBoolean";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  booleanValue?: boolean | null;
};

export const AcyclicFieldInstanceValueBooleanFields = gql`
    fragment AcyclicFieldInstanceValueBooleanFields on FieldInstanceValueBoolean {
  __typename
  id
  inputType
  booleanValue
}
    `;
