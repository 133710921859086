import { gql } from "@apollo/client";
export type SpectrumFieldValidationTypeFields = { id: number; uuid: string; validationType: string };

export const SpectrumFieldValidationTypeFields = gql`
    fragment SpectrumFieldValidationTypeFields on SpectrumFieldValidationType {
  id
  uuid
  validationType
}
    `;
