import type * as Types from "../../../generated/graphqlBaseTypes";

import { FormFieldConstraintRuleFields } from "../../fragments/generated/FormFieldConstraintRuleFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FormFieldConstraintRulesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FormFieldConstraintRulesQuery = { formFieldConstraintRules: Array<FormFieldConstraintRuleFields> };

export const FormFieldConstraintRulesQueryDocument = gql`
    query FormFieldConstraintRulesQuery {
  formFieldConstraintRules {
    ...FormFieldConstraintRuleFields
  }
}
    ${FormFieldConstraintRuleFields}`;

/**
 * __useFormFieldConstraintRulesQuery__
 *
 * To run a query within a React component, call `useFormFieldConstraintRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormFieldConstraintRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormFieldConstraintRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormFieldConstraintRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<FormFieldConstraintRulesQuery, FormFieldConstraintRulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormFieldConstraintRulesQuery, FormFieldConstraintRulesQueryVariables>(
    FormFieldConstraintRulesQueryDocument,
    options,
  );
}
export function useFormFieldConstraintRulesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FormFieldConstraintRulesQuery, FormFieldConstraintRulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormFieldConstraintRulesQuery, FormFieldConstraintRulesQueryVariables>(
    FormFieldConstraintRulesQueryDocument,
    options,
  );
}
export function useFormFieldConstraintRulesQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FormFieldConstraintRulesQuery, FormFieldConstraintRulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FormFieldConstraintRulesQuery, FormFieldConstraintRulesQueryVariables>(
    FormFieldConstraintRulesQueryDocument,
    options,
  );
}
export type FormFieldConstraintRulesQueryHookResult = ReturnType<typeof useFormFieldConstraintRulesQuery>;
export type FormFieldConstraintRulesQueryLazyQueryHookResult = ReturnType<
  typeof useFormFieldConstraintRulesQueryLazyQuery
>;
export type FormFieldConstraintRulesQuerySuspenseQueryHookResult = ReturnType<
  typeof useFormFieldConstraintRulesQuerySuspenseQuery
>;
export type FormFieldConstraintRulesQueryResult = Apollo.QueryResult<
  FormFieldConstraintRulesQuery,
  FormFieldConstraintRulesQueryVariables
>;
