import type { StartAfterWorkflowStageTemplateFields } from "./StartAfterWorkflowStageTemplateFields.generated";
import type { WorkflowStageTemplateActionItemTemplateFields } from "./WorkflowStageTemplateActionItemTemplateFields.generated";
import type { ConditionalExpressionGroupFields } from "./ConditionalExpressionGroupFields.generated";
import { gql } from "@apollo/client";
export type WorkflowStageTemplateFields = {
  id: number;
  name: string;
  description?: string | null;
  executionOrder: number;
  required: boolean;
  startAt?: string | null;
  startOnWorkflowStart: boolean;
  startAfterWorkflowStageTemplate?: StartAfterWorkflowStageTemplateFields | null;
  startAfterWorkflowStageTemplates?: Array<StartAfterWorkflowStageTemplateFields> | null;
  actionItemTemplates: Array<WorkflowStageTemplateActionItemTemplateFields>;
  startingConditions?: ConditionalExpressionGroupFields | null;
};

export const WorkflowStageTemplateFields = gql`
    fragment WorkflowStageTemplateFields on WorkflowStageTemplate {
  id
  name
  description
  executionOrder
  required
  startAt
  startAfterWorkflowStageTemplate {
    ...StartAfterWorkflowStageTemplateFields
  }
  startAfterWorkflowStageTemplates {
    ...StartAfterWorkflowStageTemplateFields
  }
  actionItemTemplates {
    ...WorkflowStageTemplateActionItemTemplateFields
  }
  startingConditions {
    ...ConditionalExpressionGroupFields
  }
  startOnWorkflowStart
}
    `;
