import env from "@env";
import { t, Trans } from "@lingui/macro";
import { clsx, EMPTY_STRING, getEmailDomain, KeyNames } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  RegrelloBrandIcon,
  RegrelloButton,
  RegrelloFullColorLogoMarkIcon,
  RegrelloFullColorReceiveInviteIcon,
  RegrelloIcon,
  RegrelloIconStyler,
  RegrelloLinkV2,
  RegrelloTypography,
} from "@regrello/ui-core";
import throttle from "lodash/throttle";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { RegrelloRedirectingSpinner } from "./_internal/RegrelloRedirectingSpinner";
import { AuthenticationConnectionName } from "../../../../../constants/auth";
import { REGRELLO_PRIVACY_POLICY_URL, ValidationRules } from "../../../../../constants/globalConstants";
import { PendoMetricName } from "../../../../../constants/PendoMetricName";
import { RegrelloRestApiService } from "../../../../../services/RegrelloRestApiService";
import { isDevEnvironment } from "../../../../../utils/environmentUtils";
import { RegrelloSessionStorageKey, sessionStorageSetTyped } from "../../../../../utils/getFromSessionStorage";
import { useQueryMap } from "../../../../../utils/hooks/useQueryStrings";
import { useRegrelloHistory } from "../../../../../utils/hooks/useRegrelloHistory";
import { useRegrelloAuthV2 } from "../../../../app/authentication/userContextUtils";
import { RoutePaths, RouteQueryStringKeys } from "../../../../app/routes/consts";
import { RegrelloCopyright } from "../../../../atoms/copyright/RegrelloCopyright";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { RegrelloFormFieldCheckbox } from "../../../../molecules/formFields/RegrelloFormFieldCheckbox";

const ARTIFICIAL_DELAY = 1500;
const SUBMIT_THROTTLE_IN_MILLISECONDS = 1000;
const DOMAIN_PREFIX_MATCH_MIN_LENGTH = 4;
const TIMES_TRIED_BEFORE_REDIRECT_THRESHOLD = 10;

export interface UnauthenticatedSignInPageProps {
  isLoginSpoofingEnabled: boolean;
  onLoginSpoofingChange: () => void;
}

export interface UnauthenticatedSignInPageFormFields {
  email: string;
  password: string;
}

export const UnauthenticatedSignInPageV2 = React.memo(function UnauthenticatedSignInPageFn({
  isLoginSpoofingEnabled,
  onLoginSpoofingChange,
}: UnauthenticatedSignInPageProps) {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isPasswordFieldShown, setIsPasswordFieldShown] = useState(false);
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const [whichLoginButtonPressed, setWhichLoginButtonPressed] = useState<"default" | "google">("default");
  const [ssoConnectionName, setSsoConnectionName] = useState<string | null>(null);
  const [timesTried, setTimesTried] = useState<Record<string, number>>({});
  const passwordInputRef = React.useRef<HTMLInputElement>(null);

  const isPendoEnabled = env.IsPendoEnabled;

  const { getQueryParam, push } = useRegrelloHistory();
  const { login, authError, loading: isLoading, clearError } = useRegrelloAuthV2();
  const { supplier: supplierQueryValue } = useQueryMap();

  const isSupplierMode = supplierQueryValue != null;
  const shouldUseSso = ssoConnectionName != null;

  const emailFromQueryParam = getQueryParam(RouteQueryStringKeys.EMAIL) ?? EMPTY_STRING;
  const userVerificationUUID = getQueryParam(RouteQueryStringKeys.USER_VERIFICATION_UUID);
  if (userVerificationUUID != null) {
    sessionStorageSetTyped(RegrelloSessionStorageKey.USER_VERIFICATION_UUID, userVerificationUUID);
  }

  // Pulls the error message as returned from Auth0, and makes it presentable.
  const { [RouteQueryStringKeys.AUTH0_ERROR_DESCRIPTION]: errorDescriptionUnformatted } = useQueryMap();
  const errorDescription = useMemo(() => {
    if (errorDescriptionUnformatted == null || errorDescriptionUnformatted.length === 0) {
      return null;
    }
    if (errorDescriptionUnformatted.length < 2) {
      return errorDescriptionUnformatted;
    }
    return (
      errorDescriptionUnformatted.charAt(0).toUpperCase() +
      errorDescriptionUnformatted.slice(1) +
      (errorDescriptionUnformatted.charAt(errorDescriptionUnformatted.length - 1) !== "." && ".")
    );
  }, [errorDescriptionUnformatted]);

  // Clear any existing errors when the component mounts
  useEffect(() => {
    clearError();
  }, [clearError]);

  const defaultValues = useMemo<UnauthenticatedSignInPageFormFields>(
    () => ({
      email: emailFromQueryParam,
      password: EMPTY_STRING,
    }),
    [emailFromQueryParam],
  );

  const form = useForm<UnauthenticatedSignInPageFormFields>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    // (clewis): Our own defaultValues prop requires a default value for each field, whereas this
    // hook expects a partial. A full object is simply a superset of a partial, but the types are
    // still complaining that we're not providing a partial-typed object. Hence, we cast as any.
    //
    // biome-ignore lint/suspicious/noExplicitAny: Above ^
    defaultValues: defaultValues as any,
  });

  // Throttled function to handle Google login
  const throttledOnContinueWithGoogle = useMemo(
    () =>
      throttle(async () => {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        setWhichLoginButtonPressed("google");
        if (isPendoEnabled) {
          window.pendo?.track(PendoMetricName.SIGN_IN_WITH_GOOGLE);
        }
        await login({
          connection: AuthenticationConnectionName.GOOGLE,
          email: form.getValues().email,
          password: form.getValues().password,
        });
      }, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [login, form, isPendoEnabled],
  );

  const throttledOnContinueWithSso = useMemo(
    () =>
      throttle(async (connectionName?: string) => {
        let currConnectionName = connectionName;
        // Check for forced SSO provider first
        if (env.ForceProviderName != null && env.ForceProviderName !== "") {
          currConnectionName = env.ForceProviderName;
        }
        if (currConnectionName != null) {
          await login(
            {
              connection: currConnectionName,
              email: form.getValues().email,
              password: form.getValues().password,
            },
            isSupplierMode ? ARTIFICIAL_DELAY : undefined,
          );
        } else {
          await login({ connection: AuthenticationConnectionName.SSO });
        }
      }, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [login, form, isSupplierMode],
  );

  const checkSso = useCallback(
    async (emailToCheck: string): Promise<string | null> => {
      const domain = getEmailDomain(emailToCheck);
      // Don't start searching on keystroke until it's a sufficiently long (4) domain.
      if (domain.length < DOMAIN_PREFIX_MATCH_MIN_LENGTH) {
        return null;
      }
      const result = await RegrelloRestApiService.isSso(domain);
      if (result.status === 200 && result.json.isSso && !result.json.isSsoOptional) {
        setSsoConnectionName(result.json.connectionName);
        if (isSupplierMode && isPendoEnabled) {
          window.pendo?.track(PendoMetricName.SUPPLIER_AUTO_SIGN_IN, { connectionName: result.json.connectionName });
        }
        return result.json.connectionName;
      }
      return null;
    },
    [isSupplierMode, isPendoEnabled],
  );

  const onEmailChange = useCallback(
    async (newEmail?: string): Promise<void> => {
      clearError();
      const emailToCheck = newEmail ?? form.getValues().email;
      const connectionName = await checkSso(emailToCheck);
      setSsoConnectionName(connectionName);

      // If SSO is detected, hide password field and show the continue button instead of the sign in button
      if (connectionName != null) {
        setIsPasswordFieldShown(false);
        form.setValue("password", "");
      }
    },
    [form, checkSso, clearError],
  );

  useEffect(() => {
    if (emailFromQueryParam != null) {
      void (async () => {
        const connectionName = await checkSso(emailFromQueryParam);
        setSsoConnectionName(connectionName);
        if (connectionName != null) {
          await login({
            connection: connectionName,
            email: emailFromQueryParam,
            password: EMPTY_STRING,
          });
        }
      })();
    }
  }, [emailFromQueryParam, checkSso, login]);

  const throttledOnSubmit = useMemo(
    () =>
      throttle(async (data: UnauthenticatedSignInPageFormFields) => {
        // (krashanoff): Catch too many attempts with same email
        if (timesTried[data.email] && timesTried[data.email] >= TIMES_TRIED_BEFORE_REDIRECT_THRESHOLD) {
          setTimesTried((oldTimesTried) => ({ ...oldTimesTried, [data.email]: 0 }));
          push(RoutePaths.CREDENTIALS_ERROR, { [RouteQueryStringKeys.REDIRECT]: RoutePaths.LOGIN });
          return;
        }

        clearError();
        await login({ connection: AuthenticationConnectionName.DEFAULT, email: data.email, password: data.password });
        setTimesTried((oldTimesTried) => ({ ...oldTimesTried, [data.email]: (oldTimesTried[data.email] ?? 0) + 1 }));
      }, SUBMIT_THROTTLE_IN_MILLISECONDS),
    [timesTried, clearError, login, push],
  );

  const submit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      // (hchen): Prevent refreshing.
      event.preventDefault();
      // (clewis): Be sure to include an extra '()', since formHandleSubmit(...) returns a function.
      void form.handleSubmit(throttledOnSubmit)();
    },
    [form, throttledOnSubmit],
  );

  const onClickReceiveAnInvite = useCallback(() => {
    clearError();
    const currentEmail = form.getValues().email;
    push(RoutePaths.INVITE_REQUEST, {
      ...(isSupplierMode ? { [RouteQueryStringKeys.SUPPLIER]: "1" } : undefined),
      ...(currentEmail != null ? { [RouteQueryStringKeys.EMAIL]: encodeURIComponent(currentEmail) } : undefined),
    });
  }, [clearError, push, isSupplierMode, form]);

  const onClickForgotPassword = useCallback(() => {
    const emailValue = form.getValues().email;
    clearError();
    if (isPendoEnabled) {
      window.pendo?.track(PendoMetricName.FORGOT_PASSWORD);
    }
    push(RoutePaths.PASSWORD_RESET_REQUEST, {
      ...(isSupplierMode ? { [RouteQueryStringKeys.SUPPLIER]: "1" } : undefined),
      ...(emailValue != null ? { [RouteQueryStringKeys.EMAIL]: encodeURIComponent(emailValue) } : undefined),
    });
  }, [clearError, isPendoEnabled, isSupplierMode, form, push]);

  const onCheckboxChange = useCallback((isChecked: boolean) => {
    setIsPasswordShown(isChecked);
  }, []);

  const handleContinue = useCallback(async () => {
    const emailValue = form.getValues().email;
    if (!emailValue) {
      return;
    }
    // Check for SSO before showing password
    if (ssoConnectionName == null) {
      setIsPasswordFieldShown(true);
      // Focus on password field after it becomes visible
      setTimeout(() => passwordInputRef.current?.focus(), 0);
    } else {
      await throttledOnContinueWithSso(ssoConnectionName);
    }
  }, [form, ssoConnectionName, throttledOnContinueWithSso]);

  const renderPill = useCallback((text: string) => {
    return (
      <div className="relative w-full py-2 mb-3">
        <div className="absolute inset-x-0 top-1/2 -translate-y-1/2 border-t -z-1" />
        <div className="bg-background px-4 m-auto w-min">
          <RegrelloTypography
            className={`
                bg-background

                border
                rounded-circular

                w-min
                whitespace-nowrap

                px-1
                m-auto
              `}
            uppercase={true}
            variant="body-xs"
          >
            {text}
          </RegrelloTypography>
        </div>
      </div>
    );
  }, []);

  const newUsersBanner = renderPill(isSupplierMode ? t`New Users`.toUpperCase() : t`New Suppliers`.toUpperCase());

  // Clicks the continue button when the user presses enter while inside the email field.
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === KeyNames.ENTER && !isPasswordFieldShown) {
        event.preventDefault();
        void handleContinue();
      }
    },
    [handleContinue, isPasswordFieldShown],
  );

  const emailField = useMemo(
    () => (
      <RegrelloControlledFormFieldText
        autoFocus={true}
        className="w-full mb-2"
        controllerProps={{
          control: form.control,
          name: "email",
          rules: {
            onChange: async (e) => {
              await onEmailChange(e?.target?.value);
            },
          },
        }}
        dataTestId={DataTestIds.SIGN_IN_EMAIL_INPUT}
        disabled={isLoading}
        isDefaultMarginsOmitted={true}
        onKeyDown={handleKeyDown}
        placeholder={t`Email`}
      />
    ),
    [form.control, isLoading, onEmailChange, handleKeyDown],
  );

  const passwordField = useMemo(
    () => (
      <RegrelloControlledFormFieldText
        className={clsx("w-full mb-2", !isPasswordFieldShown)}
        controllerProps={{
          control: form.control,
          name: "password",
          rules: { ...ValidationRules.REQUIRED },
        }}
        dataTestId={DataTestIds.SIGN_IN_PASSWORD_INPUT}
        disabled={isLoading || shouldUseSso}
        inputRef={passwordInputRef}
        isDefaultMarginsOmitted={true}
        placeholder={t`Password`}
        type={isPasswordShown ? "text" : "password"}
      />
    ),
    [form.control, isLoading, isPasswordFieldShown, isPasswordShown, shouldUseSso],
  );

  const continueButton = useMemo(
    () => (
      <RegrelloButton
        className="w-full mb-6"
        dataTestId={DataTestIds.SIGN_IN_CONTINUE_BUTTON}
        disabled={isLoading}
        intent="primary"
        onClick={handleContinue}
        size="large"
      >
        {t`Continue`}
      </RegrelloButton>
    ),
    [handleContinue, isLoading],
  );

  // Toggle controlling login spoofing in dev environments.
  const loginSpoofingCheckbox = useMemo(
    () => (
      <div className="w-full">
        <RegrelloFormFieldCheckbox
          dataTestId={DataTestIds.ENABLE_LOGIN_SPOOFING_TOGGLE}
          disabled={isLoading}
          label={t`Enable login spoofing`}
          onChange={onLoginSpoofingChange}
          value={isLoginSpoofingEnabled}
        />
      </div>
    ),
    [isLoading, isLoginSpoofingEnabled, onLoginSpoofingChange],
  );

  const showPasswordCheckbox = useMemo(
    () => (
      <RegrelloFormFieldCheckbox
        dataTestId={DataTestIds.SIGN_IN_SHOW_PASSWORD_CHECKBOX}
        disabled={isLoading || shouldUseSso}
        isDefaultMarginsOmitted={true}
        label={t`Show password`}
        onChange={onCheckboxChange}
        value={isPasswordShown}
      />
    ),
    [isLoading, isPasswordShown, onCheckboxChange, shouldUseSso],
  );

  const forgotPasswordLink = useMemo(
    () => (
      <RegrelloLinkV2
        dataTestId={DataTestIds.FORGOT_PASSWORD_LINK}
        onClick={(e) => {
          // Prevent default redirection and call onClickForgotPassword handler
          e.preventDefault();
          onClickForgotPassword();
        }}
        // Using "#" as a placeholder URL since RegrelloLinkV2 requires a "to" prop,
        // And we want to handle navigation programmatically in onClickForgotPassword
        to="#"
      >
        {t`Forgot password`}
      </RegrelloLinkV2>
    ),
    [onClickForgotPassword],
  );

  const signInButton = useMemo(
    () => (
      <RegrelloButton
        className="w-full mb-6"
        dataTestId={DataTestIds.SIGN_IN_BUTTON}
        disabled={shouldUseSso || isLoading}
        intent="primary"
        loading={isLoading && whichLoginButtonPressed === "default"}
        size="large"
        type="submit"
      >
        {t`Sign in`}
      </RegrelloButton>
    ),
    [isLoading, shouldUseSso, whichLoginButtonPressed],
  );

  const loginInfoFields = useMemo(
    () => (
      <>
        {emailField}
        {isPasswordFieldShown ? passwordField : continueButton}

        {/* Password related fields */}
        {isPasswordFieldShown && (
          <>
            {isDevEnvironment() && loginSpoofingCheckbox}

            <div className={clsx("w-full flex items-center justify-between mb-4")}>
              {showPasswordCheckbox}
              {forgotPasswordLink}
            </div>

            {signInButton}
          </>
        )}

        {(authError != null || errorDescription != null) && (
          <RegrelloTypography className="flex justify-center items-center mb-8 text-danger-textMuted w-[200%] max-w-[100vw]">
            <RegrelloIcon className="text-danger-icon pr-1" iconName="alert" />
            {authError || errorDescription}
          </RegrelloTypography>
        )}
      </>
    ),
    [
      emailField,
      isPasswordFieldShown,
      passwordField,
      continueButton,
      loginSpoofingCheckbox,
      showPasswordCheckbox,
      forgotPasswordLink,
      signInButton,
      authError,
      errorDescription,
    ],
  );

  const receiveAnEmailInvite = (
    <>
      {newUsersBanner}

      <RegrelloTypography className="text-center mb-3" variant="h5">
        {t`Regrello is invite only`}
      </RegrelloTypography>
      <RegrelloTypography className="text-center mb-4">
        {isSupplierMode ? (
          <Trans>
            Been assigned tasks in Regrello? If you would like to sign in to complete your work, please click send email
            invite. You must click/tap <strong>Accept invite</strong> in your email invite in order to create your
            account password.
          </Trans>
        ) : (
          <Trans>
            You must click/tap <strong>Accept invite</strong> in your email invite in order to set your password.
          </Trans>
        )}
      </RegrelloTypography>

      <RegrelloButton
        className="w-full"
        dataTestId={DataTestIds.RESEND_EMAIL_INVITE_BUTTON}
        intent="primary"
        onClick={onClickReceiveAnInvite}
        startIcon={<RegrelloFullColorReceiveInviteIcon />}
        variant="outline"
      >
        {isSupplierMode ? t`Send email invite` : t`Resend email invite`}
      </RegrelloButton>
    </>
  );

  const googleButton = useMemo(
    () => (
      <RegrelloButton
        className="w-full mb-6"
        dataTestId={DataTestIds.CONTINUE_WITH_GOOGLE_BUTTON}
        disabled={shouldUseSso || isLoading}
        loading={isLoading && whichLoginButtonPressed === "google"}
        onClick={throttledOnContinueWithGoogle}
        startIcon={<RegrelloBrandIcon brand="google" size="x-small" />}
        variant="outline"
      >
        {t`Continue with Google`}
      </RegrelloButton>
    ),
    [shouldUseSso, isLoading, whichLoginButtonPressed, throttledOnContinueWithGoogle],
  );

  return (
    <div className="flex justify-center w-full h-full">
      <div className="flex flex-col w-97.5 px-12 pb-4">
        <form className="flex flex-col flex-auto items-center justify-center" onSubmit={submit}>
          <div className="mb-8">
            <RegrelloIconStyler size="x-large">
              <RegrelloFullColorLogoMarkIcon />
            </RegrelloIconStyler>
          </div>
          <RegrelloTypography className="mb-8" variant="h2">
            {isSupplierMode ? t`Supplier sign in` : t`Welcome to Regrello`}
          </RegrelloTypography>

          {isSupplierMode && shouldUseSso && isLoading && (
            <RegrelloRedirectingSpinner message={t`Redirecting to your company's sign in page`} />
          )}

          {isSupplierMode ? (
            <>
              {loginInfoFields}
              {receiveAnEmailInvite}
            </>
          ) : (
            <>
              {loginInfoFields}
              {googleButton}
              {receiveAnEmailInvite}
            </>
          )}
        </form>
        <RegrelloTypography className="text-center flex justify-center justify-self-end" variant="body-xs">
          <RegrelloLinkV2 className="text-textDefault font-normal text-xs mr-6" to={REGRELLO_PRIVACY_POLICY_URL}>
            {t`Privacy Policy`}
          </RegrelloLinkV2>
          <RegrelloCopyright />
        </RegrelloTypography>
      </div>
    </div>
  );
});
