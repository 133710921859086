import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import type { NameTemplateFields } from "./NameTemplateFields.generated";
import type { WorkflowStageTemplateFields } from "./WorkflowStageTemplateFields.generated";
import type { TagFields } from "./TagFields.generated";
import type { ConditionalExpressionGroupFields } from "./ConditionalExpressionGroupFields.generated";
import type { FieldInstanceFieldsWithBaseValues } from "./FieldInstanceFieldsWithBaseValues.generated";
import type { WorkflowTemplatePermissionsFields } from "./WorkflowTemplatePermissionsFields.generated";
import type { WorkflowTemplateCollaborationFields } from "./WorkflowTemplateCollaborationFields.generated";
import type { StarWithoutEntityFields } from "./StarWithoutEntityFields.generated";
import type { WorkflowGraphFields } from "./WorkflowGraphFields.generated";
import { gql } from "@apollo/client";
export type WorkflowTemplateFields = {
  id: number;
  blueprintUuid: string;
  name: string;
  createdAt: string;
  deletedAt?: string | null;
  updatedAt: string;
  description?: string | null;
  isValidToUse: boolean;
  isEditingWorkflowApprovalsRestricted: boolean;
  isEditingWorkflowsRestricted: boolean;
  type: Types.WorkflowTemplateType;
  referenceNumberPrefix?: string | null;
  referenceNumberStartingValue?: number | null;
  isCreateViaEmailEnabled: boolean;
  createViaEmailContactEmail?: string | null;
  autoAdjustDueOn?: boolean | null;
  versionNotes?: string | null;
  versionNumber?: number | null;
  isVariantCreationEnabled: boolean;
  isVariantCreationEnabledOnPublishedVersion: boolean;
  onlineParties: Array<{ status: Types.OnlinePartyStatus; party: PartyBaseFields }>;
  nameTemplate?: NameTemplateFields | null;
  createdBy: UserBaseFields;
  publishedBy?: PartyBaseFields | null;
  updatedBy: PartyBaseFields;
  stageTemplates: Array<WorkflowStageTemplateFields>;
  tags: Array<TagFields>;
  fieldInstances: Array<FieldInstanceFieldsWithBaseValues>;
  collaborators: Array<PartyBaseFields>;
  permissions?: WorkflowTemplatePermissionsFields | null;
  collaborations: Array<WorkflowTemplateCollaborationFields>;
  currentUserStar?: StarWithoutEntityFields | null;
  workflowOwnerParty?: PartyBaseFields | null;
  graph: WorkflowGraphFields;
  draftVersionWorkflowTemplate?: { id: number; permissions: { canRead: boolean } } | null;
  variantData?: {
    isCompliant: boolean;
    standardBlueprint: {
      id: number;
      name: string;
      permissions: { canRead: boolean };
      fieldInstances: Array<FieldInstanceFieldsWithBaseValues>;
    };
    conditionGroup?: ConditionalExpressionGroupFields | null;
  } | null;
};

export const WorkflowTemplateFields = gql`
    fragment WorkflowTemplateFields on WorkflowTemplate {
  id
  blueprintUuid
  onlineParties {
    party {
      ...PartyBaseFields
    }
    status
  }
  name
  nameTemplate {
    ...NameTemplateFields
  }
  createdBy {
    ...UserBaseFields
  }
  createdAt
  publishedBy {
    ...PartyBaseFields
  }
  deletedAt
  updatedAt
  updatedBy {
    ...PartyBaseFields
  }
  description
  isValidToUse
  isEditingWorkflowApprovalsRestricted
  isEditingWorkflowsRestricted
  type
  stageTemplates {
    ...WorkflowStageTemplateFields
  }
  tags {
    ...TagFields
  }
  fieldInstances {
    ...FieldInstanceFieldsWithBaseValues
  }
  collaborators {
    ...PartyBaseFields
  }
  permissions {
    ...WorkflowTemplatePermissionsFields
  }
  collaborations {
    ...WorkflowTemplateCollaborationFields
  }
  currentUserStar {
    ...StarWithoutEntityFields
  }
  workflowOwnerParty {
    ...PartyBaseFields
  }
  referenceNumberPrefix
  referenceNumberStartingValue
  graph {
    ...WorkflowGraphFields
  }
  isCreateViaEmailEnabled
  createViaEmailContactEmail
  autoAdjustDueOn
  draftVersionWorkflowTemplate {
    id
    permissions {
      canRead
    }
  }
  versionNotes
  versionNumber
  variantData {
    standardBlueprint {
      id
      name
      permissions {
        canRead
      }
      fieldInstances {
        ...FieldInstanceFieldsWithBaseValues
      }
    }
    conditionGroup {
      ...ConditionalExpressionGroupFields
    }
    isCompliant
  }
  isVariantCreationEnabled
  isVariantCreationEnabledOnPublishedVersion
}
    `;
