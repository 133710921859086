import type { FieldInstanceValueTimeBaseFields } from "./FieldInstanceValueTimeBaseFields.generated";
import type { AcyclicFieldInstanceValueTimeFields } from "./AcyclicFieldInstanceValueTimeFields.generated";
import type { AcyclicFieldInstanceFields } from "./AcyclicFieldInstanceFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueTimeFields = {
  sourceFieldInstanceValueTime?: AcyclicFieldInstanceValueTimeFields | null;
  sinksFieldInstanceValueTime: Array<AcyclicFieldInstanceValueTimeFields>;
  sourceFieldInstanceValueTimeV2?: AcyclicFieldInstanceFields | null;
} & FieldInstanceValueTimeBaseFields;

export const FieldInstanceValueTimeFields = gql`
    fragment FieldInstanceValueTimeFields on FieldInstanceValueTime {
  ...FieldInstanceValueTimeBaseFields
  sourceFieldInstanceValueTime {
    ...AcyclicFieldInstanceValueTimeFields
  }
  sinksFieldInstanceValueTime {
    ...AcyclicFieldInstanceValueTimeFields
  }
  sourceFieldInstanceValueTimeV2 {
    ...AcyclicFieldInstanceFields
  }
}
    `;
