import type { FieldInstanceValueStringBaseFields } from "./FieldInstanceValueStringBaseFields.generated";
import type { AcyclicFieldInstanceValueStringFields } from "./AcyclicFieldInstanceValueStringFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueStringFields = {
  sourceFieldInstanceValueString?: AcyclicFieldInstanceValueStringFields | null;
  sinksFieldInstanceValueString: Array<AcyclicFieldInstanceValueStringFields>;
} & FieldInstanceValueStringBaseFields;

export const FieldInstanceValueStringFields = gql`
    fragment FieldInstanceValueStringFields on FieldInstanceValueString {
  ...FieldInstanceValueStringBaseFields
  sourceFieldInstanceValueString {
    ...AcyclicFieldInstanceValueStringFields
  }
  sinksFieldInstanceValueString {
    ...AcyclicFieldInstanceValueStringFields
  }
}
    `;
