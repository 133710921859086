import type * as Types from "../../../generated/graphqlBaseTypes";

import { FormsQueryFormFields } from "../../fragments/generated/FormsQueryFormFields.generated";
import { PartyBaseFields } from "../../fragments/generated/PartyBaseFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { FormMinimalFields } from "../../fragments/generated/FormMinimalFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type FormSelectFormsForWorkflowsQueryVariables = Types.Exact<{
  workflowID: Types.Scalars["ID"]["input"];
}>;

export type FormSelectFormsForWorkflowsQuery = { formsForWorkflow: Array<FormsQueryFormFields> };

export const FormSelectFormsForWorkflowsQueryDocument = gql`
    query FormSelectFormsForWorkflowsQuery($workflowID: ID!) {
  formsForWorkflow(workflowID: $workflowID) {
    ...FormsQueryFormFields
  }
}
    ${FormsQueryFormFields}
${PartyBaseFields}
${UserBaseFields}
${TeamWithoutMembersFields}
${FormMinimalFields}`;

/**
 * __useFormSelectFormsForWorkflowsQuery__
 *
 * To run a query within a React component, call `useFormSelectFormsForWorkflowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSelectFormsForWorkflowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSelectFormsForWorkflowsQuery({
 *   variables: {
 *      workflowID: // value for 'workflowID'
 *   },
 * });
 */
export function useFormSelectFormsForWorkflowsQuery(
  baseOptions: Apollo.QueryHookOptions<FormSelectFormsForWorkflowsQuery, FormSelectFormsForWorkflowsQueryVariables> &
    ({ variables: FormSelectFormsForWorkflowsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FormSelectFormsForWorkflowsQuery, FormSelectFormsForWorkflowsQueryVariables>(
    FormSelectFormsForWorkflowsQueryDocument,
    options,
  );
}
export function useFormSelectFormsForWorkflowsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FormSelectFormsForWorkflowsQuery,
    FormSelectFormsForWorkflowsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FormSelectFormsForWorkflowsQuery, FormSelectFormsForWorkflowsQueryVariables>(
    FormSelectFormsForWorkflowsQueryDocument,
    options,
  );
}
export function useFormSelectFormsForWorkflowsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FormSelectFormsForWorkflowsQuery,
    FormSelectFormsForWorkflowsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FormSelectFormsForWorkflowsQuery, FormSelectFormsForWorkflowsQueryVariables>(
    FormSelectFormsForWorkflowsQueryDocument,
    options,
  );
}
export type FormSelectFormsForWorkflowsQueryHookResult = ReturnType<typeof useFormSelectFormsForWorkflowsQuery>;
export type FormSelectFormsForWorkflowsQueryLazyQueryHookResult = ReturnType<
  typeof useFormSelectFormsForWorkflowsQueryLazyQuery
>;
export type FormSelectFormsForWorkflowsQuerySuspenseQueryHookResult = ReturnType<
  typeof useFormSelectFormsForWorkflowsQuerySuspenseQuery
>;
export type FormSelectFormsForWorkflowsQueryResult = Apollo.QueryResult<
  FormSelectFormsForWorkflowsQuery,
  FormSelectFormsForWorkflowsQueryVariables
>;
