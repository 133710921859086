import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type AcyclicFieldInstanceMultiValueStringFields = {
  __typename: "FieldInstanceMultiValueString";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  stringMultiValue: Array<string>;
};

export const AcyclicFieldInstanceMultiValueStringFields = gql`
    fragment AcyclicFieldInstanceMultiValueStringFields on FieldInstanceMultiValueString {
  __typename
  id
  inputType
  stringMultiValue
}
    `;
