import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type AcyclicFieldInstanceMultiValueFloatFields = {
  __typename: "FieldInstanceMultiValueFloat";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  floatMultiValue: Array<number>;
};

export const AcyclicFieldInstanceMultiValueFloatFields = gql`
    fragment AcyclicFieldInstanceMultiValueFloatFields on FieldInstanceMultiValueFloat {
  __typename
  id
  inputType
  floatMultiValue
}
    `;
