import type * as Types from "../../../generated/graphqlBaseTypes";

import { TenantFields } from "../../fragments/generated/TenantFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type CurrentTenantQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CurrentTenantQuery = { currentTenant?: ({ emailDomain: string } & TenantFields) | null };

export const CurrentTenantQueryDocument = gql`
    query CurrentTenantQuery {
  currentTenant {
    ...TenantFields
    emailDomain
  }
}
    ${TenantFields}`;

/**
 * __useCurrentTenantQuery__
 *
 * To run a query within a React component, call `useCurrentTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentTenantQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentTenantQuery, CurrentTenantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentTenantQuery, CurrentTenantQueryVariables>(CurrentTenantQueryDocument, options);
}
export function useCurrentTenantQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentTenantQuery, CurrentTenantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentTenantQuery, CurrentTenantQueryVariables>(CurrentTenantQueryDocument, options);
}
export function useCurrentTenantQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentTenantQuery, CurrentTenantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CurrentTenantQuery, CurrentTenantQueryVariables>(CurrentTenantQueryDocument, options);
}
export type CurrentTenantQueryHookResult = ReturnType<typeof useCurrentTenantQuery>;
export type CurrentTenantQueryLazyQueryHookResult = ReturnType<typeof useCurrentTenantQueryLazyQuery>;
export type CurrentTenantQuerySuspenseQueryHookResult = ReturnType<typeof useCurrentTenantQuerySuspenseQuery>;
export type CurrentTenantQueryResult = Apollo.QueryResult<CurrentTenantQuery, CurrentTenantQueryVariables>;
