import { t } from "@lingui/macro";
import { EMPTY_ARRAY } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React, { useCallback } from "react";
import { type UseFormReturn, useWatch } from "react-hook-form";

import { RegrelloConfigureConditionsRecursive } from "./RegrelloConfigureConditionsRecursive";
import {
  type RegrelloScheduleStartAfterDependency,
  type RegrelloScheduleTimeFormSectionBase,
  ScheduleTimeKeys,
  ScheduleTimeValues,
} from "./RegrelloScheduleTimeFormSectionBase";
import { SCHEDULE_TIME_FORM_LABEL_WIDTH } from "./scheduleTimeConstants";
import { type ConditionGroup, makeEmptyConditionGroup } from "./utils";
import { ValidationRules } from "../../../../../constants/globalConstants";
import type { CustomFieldPlugin } from "../../../../molecules/customFields/plugins/types/CustomFieldPlugin";
import { RegrelloFormFieldLayout } from "../../../../molecules/formFields/_internal/RegrelloFormFieldLayout";
import { RegrelloControlledFormFieldMultiSelect } from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";

export interface RegrelloConfigureConditionsFormSectionProps {
  /**
   * The field plugins allowed in the custom field instance select; i.e., the only field instances
   * that can be selected are those that can be processed by one of the field plugins in this array.
   */
  allowedFieldPlugins: Array<CustomFieldPlugin<unknown>>;

  /**
   * The form for configuring the schedule time of the stage or action item. Should have `mode`
   * set to 'all' for form validation to work properly.
   */
  form: UseFormReturn<RegrelloScheduleTimeFormSectionBase.Fields>;

  /**
   * Whether the entire schedule time section is disabled.
   */
  isDisabled: boolean;

  isRecursiveGroupsEnabled: boolean;
  /**
   * Callback invoked when the condition start after stage dependency changes.
   */
  onConditionStageDependencyChange?: (name: string, newValue: RegrelloScheduleStartAfterDependency[]) => void;

  /**
   * The options to render for the user to select as a start after dependency.
   */
  startAfterDependencies?: RegrelloScheduleStartAfterDependency[];

  /**
   * The start condition schedule time option selected by the user. Only 2 options should render
   * this component, hence the stricter union type. If not defined, it's assumed this component is
   * being rendered as a standalone form section.
   */
  startConditionOption?: ScheduleTimeValues.START_CONDITION_STAGE | ScheduleTimeValues.START_CONDITION_WORKFLOW;

  /**
   * Current workflow or workflow template context for the stage. Used to determine which field
   * instances to show users when selecting fields for start conditions.
   */
  workflowContext:
    | {
        type: "workflowTemplate";
        workflowTemplateId: number;
        workflowTemplateIsCreateViaEmailEnabled: boolean;
      }
    | {
        type: "workflow";
        workflowId: number;
      };
}

/** A form section for configuring conditions involving field instances. */
export const RegrelloConfigureConditionsFormSection = React.memo<RegrelloConfigureConditionsFormSectionProps>(
  function RegrelloConfigureConditionsFormSectionFn(props: RegrelloConfigureConditionsFormSectionProps) {
    const { form, isDisabled, onConditionStageDependencyChange, startAfterDependencies, startConditionOption } = props;

    // currentDependencies is the list of actually configured dependencies.
    // startAfterDependencies is all possible dependencies.
    const currentDependencies = useWatch({
      control: form.control,
      name: ScheduleTimeKeys.START_CONDITION_DEPENDENCY,
    });

    const groups =
      useWatch({
        control: form.control,
        name: ScheduleTimeKeys.CONDITIONS,
      }) ?? makeEmptyConditionGroup(0);
    const changeGroup = useCallback(
      (newGroup: ConditionGroup) => {
        form.setValue(ScheduleTimeKeys.CONDITIONS, newGroup);
      },
      [form],
    );

    return (
      <>
        {startConditionOption === ScheduleTimeValues.START_CONDITION_STAGE && (
          <RegrelloFormFieldLayout
            isDefaultMarginsOmitted={true}
            label={t`After`}
            labelAlignment="right"
            labelWidth={SCHEDULE_TIME_FORM_LABEL_WIDTH}
          >
            <div className="grow min-w-0">
              <RegrelloControlledFormFieldMultiSelect
                controllerProps={{
                  control: form.control,
                  name: ScheduleTimeKeys.START_CONDITION_DEPENDENCY,
                  rules: ValidationRules.REQUIRED,
                }}
                dataTestId={DataTestIds.START_DEPENDENCY_START_AFTER_STAGE_SELECT}
                disabled={isDisabled}
                getSelectedItemProps={(option) => ({ children: option.name })}
                isItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
                isRequiredAsteriskShown={true}
                itemPredicate={itemPredicate}
                items={startAfterDependencies ?? EMPTY_ARRAY}
                onValueChange={onConditionStageDependencyChange}
                placeholder={t`Select stages`}
                renderItem={(item) => ({ text: item.name, key: item.id })}
              />
            </div>
          </RegrelloFormFieldLayout>
        )}

        {/* (clewis): Indent the conditions form to left-align with the "After" input. */}
        <div className="ml-13.25">
          <RegrelloConfigureConditionsRecursive
            {...props}
            currentGroup={groups}
            dependingOnStageIds={currentDependencies?.map((s) => s.id) ?? EMPTY_ARRAY}
            form={form}
            showHeader={false}
            updateGroup={changeGroup}
          />
        </div>
      </>
    );
  },
);

function itemPredicate(query: string, item: RegrelloScheduleStartAfterDependency) {
  return item.name.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}
