import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type FieldInstanceValueBooleanBaseFields = {
  __typename: "FieldInstanceValueBoolean";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  booleanValue?: boolean | null;
};

export const FieldInstanceValueBooleanBaseFields = gql`
    fragment FieldInstanceValueBooleanBaseFields on FieldInstanceValueBoolean {
  __typename
  id
  inputType
  booleanValue
}
    `;
