import type { TagFields } from "./TagFields.generated";
import { gql } from "@apollo/client";
export type DocumentBaseFields = {
  id: number;
  name: string;
  currentVersion: {
    id: number;
    uid: string;
    createdAt: string;
    deletedAt?: string | null;
    externalLink?: string | null;
    createdBy: { __typename: "Party"; id: number };
  };
  documentType: { id: number; name: string; icon: string };
  tags: Array<TagFields>;
  permissions: { canDelete: boolean };
};

export const DocumentBaseFields = gql`
    fragment DocumentBaseFields on Document {
  id
  name
  currentVersion {
    id
    uid
    createdAt
    createdBy {
      __typename
      id
    }
    deletedAt
    externalLink
  }
  documentType {
    id
    name
    icon
  }
  tags {
    ...TagFields
  }
  permissions {
    canDelete
  }
}
    `;
