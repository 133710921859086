import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import type { TagFields } from "./TagFields.generated";
import type { DocumentFields } from "./DocumentFields.generated";
import type { WorkflowPermissionsFields } from "./WorkflowPermissionsFields.generated";
import type { AutomationRequestFields } from "./AutomationRequestFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { ActionItemApprovalFields } from "./ActionItemApprovalFields.generated";
import type { IntegrationAutomationInstanceFields } from "./IntegrationAutomationInstanceFields.generated";
import type { ActionItemPermissionsFields } from "./ActionItemPermissionsFields.generated";
import type { StarWithoutEntityFields } from "./StarWithoutEntityFields.generated";
import { gql } from "@apollo/client";
export type ActionItemFields = {
  id: number;
  uuid: string;
  uuidLong: string;
  name: string;
  status: Types.ActionItemStatus;
  dueOn?: string | null;
  dueOnClass: Types.DueOnClass;
  hasException: boolean;
  frozeForReason?: Types.ActionItemFrozeForReason | null;
  requiresAck: boolean;
  createdAt: string;
  updatedAt: string;
  numberOfUnreadComments: number;
  type: Types.ActionItemType;
  assignedBy: PartyBaseFields;
  createdBy: UserBaseFields;
  assignees: Array<PartyBaseFields>;
  cc: Array<PartyBaseFields>;
  reviewers: Array<PartyBaseFields>;
  tags: Array<TagFields>;
  documents: Array<DocumentFields>;
  workflowReference?: {
    workflowId: number;
    workflowName: string;
    stageId: number;
    stageName: string;
    workflowPermissions: WorkflowPermissionsFields;
  } | null;
  automationRequests: Array<AutomationRequestFields>;
  fieldInstancesControllingAssignees?: Array<FieldInstanceFields> | null;
  fieldInstancesControllingCc?: Array<FieldInstanceFields> | null;
  fieldInstanceControllingDueOn?: FieldInstanceFields | null;
  integrationAutomationInstance?: IntegrationAutomationInstanceFields | null;
  permissions?: ActionItemPermissionsFields | null;
  currentUserStar?: StarWithoutEntityFields | null;
} & ActionItemApprovalFields;

export const ActionItemFields = gql`
    fragment ActionItemFields on ActionItem {
  id
  uuid
  uuidLong
  name
  status
  assignedBy {
    ...PartyBaseFields
  }
  createdBy {
    ...UserBaseFields
  }
  assignees {
    ...PartyBaseFields
  }
  cc {
    ...PartyBaseFields
  }
  reviewers {
    ...PartyBaseFields
  }
  dueOn
  dueOnClass
  hasException
  frozeForReason
  requiresAck
  tags {
    ...TagFields
  }
  documents {
    ...DocumentFields
  }
  createdAt
  updatedAt
  numberOfUnreadComments
  type
  workflowReference {
    workflowId
    workflowName
    stageId
    stageName
    workflowPermissions {
      ...WorkflowPermissionsFields
    }
  }
  automationRequests {
    ...AutomationRequestFields
  }
  fieldInstancesControllingAssignees {
    ...FieldInstanceFields
  }
  fieldInstancesControllingCc {
    ...FieldInstanceFields
  }
  fieldInstanceControllingDueOn {
    ...FieldInstanceFields
  }
  ...ActionItemApprovalFields
  integrationAutomationInstance {
    ...IntegrationAutomationInstanceFields
  }
  permissions {
    ...ActionItemPermissionsFields
  }
  currentUserStar {
    ...StarWithoutEntityFields
  }
}
    `;
