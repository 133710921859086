import type * as Types from "../../../generated/graphqlBaseTypes";

import { UserFieldsWithRoles } from "../../fragments/generated/UserFieldsWithRoles.generated";
import { UserFieldsWithoutRoles } from "../../fragments/generated/UserFieldsWithoutRoles.generated";
import { AccessRoleFields } from "../../fragments/generated/AccessRoleFields.generated";
import { RoleFields } from "../../fragments/generated/RoleFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UserWithRolesSelectorQueryResultsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.UserSelectorQueryResultsInput>;
}>;

export type UserWithRolesSelectorQueryResultsQuery = {
  userSelectorQueryResults?: {
    isRoleFilteringApplied: boolean;
    users: Array<UserFieldsWithRoles>;
    filteringByRoles?: Array<{ id: number; name: string }> | null;
  } | null;
};

export const UserWithRolesSelectorQueryResultsQueryDocument = gql`
    query UserWithRolesSelectorQueryResultsQuery($input: UserSelectorQueryResultsInput) {
  userSelectorQueryResults(input: $input) {
    users {
      ...UserFieldsWithRoles
    }
    isRoleFilteringApplied
    filteringByRoles {
      id
      name
    }
  }
}
    ${UserFieldsWithRoles}
${UserFieldsWithoutRoles}
${AccessRoleFields}
${RoleFields}`;

/**
 * __useUserWithRolesSelectorQueryResultsQuery__
 *
 * To run a query within a React component, call `useUserWithRolesSelectorQueryResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithRolesSelectorQueryResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithRolesSelectorQueryResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserWithRolesSelectorQueryResultsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserWithRolesSelectorQueryResultsQuery,
    UserWithRolesSelectorQueryResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserWithRolesSelectorQueryResultsQuery, UserWithRolesSelectorQueryResultsQueryVariables>(
    UserWithRolesSelectorQueryResultsQueryDocument,
    options,
  );
}
export function useUserWithRolesSelectorQueryResultsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserWithRolesSelectorQueryResultsQuery,
    UserWithRolesSelectorQueryResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserWithRolesSelectorQueryResultsQuery, UserWithRolesSelectorQueryResultsQueryVariables>(
    UserWithRolesSelectorQueryResultsQueryDocument,
    options,
  );
}
export function useUserWithRolesSelectorQueryResultsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UserWithRolesSelectorQueryResultsQuery,
    UserWithRolesSelectorQueryResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserWithRolesSelectorQueryResultsQuery,
    UserWithRolesSelectorQueryResultsQueryVariables
  >(UserWithRolesSelectorQueryResultsQueryDocument, options);
}
export type UserWithRolesSelectorQueryResultsQueryHookResult = ReturnType<
  typeof useUserWithRolesSelectorQueryResultsQuery
>;
export type UserWithRolesSelectorQueryResultsQueryLazyQueryHookResult = ReturnType<
  typeof useUserWithRolesSelectorQueryResultsQueryLazyQuery
>;
export type UserWithRolesSelectorQueryResultsQuerySuspenseQueryHookResult = ReturnType<
  typeof useUserWithRolesSelectorQueryResultsQuerySuspenseQuery
>;
export type UserWithRolesSelectorQueryResultsQueryResult = Apollo.QueryResult<
  UserWithRolesSelectorQueryResultsQuery,
  UserWithRolesSelectorQueryResultsQueryVariables
>;
