import { gql } from "@apollo/client";
export type PermissionedSidebarContentFlagsFields = {
  homePage: boolean;
  assignedTasksPage: boolean;
  ccdTasksPage: boolean;
  documentsPage: boolean;
  peopleAndTeamsPage: boolean;
  starredPage: boolean;
  starredSection: boolean;
  adminPage: boolean;
  designDocs: boolean;
  templatesSection: {
    blueprints: boolean;
    myTemplatesPage: boolean;
    companyTemplatesPage: boolean;
    draftBlueprintsPage: boolean;
    sharedBlueprintsPage: boolean;
    fieldsPage: boolean;
    formsPage: boolean;
    tagsPage: boolean;
  };
};

export const PermissionedSidebarContentFlagsFields = gql`
    fragment PermissionedSidebarContentFlagsFields on PermissionedSidebarContentFlags {
  homePage
  assignedTasksPage
  ccdTasksPage
  documentsPage
  peopleAndTeamsPage
  starredPage
  starredSection
  templatesSection {
    blueprints
    myTemplatesPage
    companyTemplatesPage
    draftBlueprintsPage
    sharedBlueprintsPage
    fieldsPage
    formsPage
    tagsPage
  }
  adminPage
  designDocs
}
    `;
