import type * as Types from "../../../generated/graphqlBaseTypes";

import { RoleFields } from "../../fragments/generated/RoleFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type RoleSelectorQueryResultsQueryVariables = Types.Exact<{
  query: Types.Scalars["String"]["input"];
  roleIdsToPrioritize?: Types.InputMaybe<Array<Types.Scalars["Int"]["input"]>>;
}>;

export type RoleSelectorQueryResultsQuery = { roleSelectorQueryResults: { roles: Array<RoleFields> } };

export const RoleSelectorQueryResultsQueryDocument = gql`
    query RoleSelectorQueryResultsQuery($query: String!, $roleIdsToPrioritize: [Int!]) {
  roleSelectorQueryResults(
    input: {query: $query, roleIdsToPrioritize: $roleIdsToPrioritize}
  ) {
    roles {
      ...RoleFields
    }
  }
}
    ${RoleFields}`;

/**
 * __useRoleSelectorQueryResultsQuery__
 *
 * To run a query within a React component, call `useRoleSelectorQueryResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleSelectorQueryResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleSelectorQueryResultsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      roleIdsToPrioritize: // value for 'roleIdsToPrioritize'
 *   },
 * });
 */
export function useRoleSelectorQueryResultsQuery(
  baseOptions: Apollo.QueryHookOptions<RoleSelectorQueryResultsQuery, RoleSelectorQueryResultsQueryVariables> &
    ({ variables: RoleSelectorQueryResultsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RoleSelectorQueryResultsQuery, RoleSelectorQueryResultsQueryVariables>(
    RoleSelectorQueryResultsQueryDocument,
    options,
  );
}
export function useRoleSelectorQueryResultsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoleSelectorQueryResultsQuery, RoleSelectorQueryResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RoleSelectorQueryResultsQuery, RoleSelectorQueryResultsQueryVariables>(
    RoleSelectorQueryResultsQueryDocument,
    options,
  );
}
export function useRoleSelectorQueryResultsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RoleSelectorQueryResultsQuery, RoleSelectorQueryResultsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RoleSelectorQueryResultsQuery, RoleSelectorQueryResultsQueryVariables>(
    RoleSelectorQueryResultsQueryDocument,
    options,
  );
}
export type RoleSelectorQueryResultsQueryHookResult = ReturnType<typeof useRoleSelectorQueryResultsQuery>;
export type RoleSelectorQueryResultsQueryLazyQueryHookResult = ReturnType<
  typeof useRoleSelectorQueryResultsQueryLazyQuery
>;
export type RoleSelectorQueryResultsQuerySuspenseQueryHookResult = ReturnType<
  typeof useRoleSelectorQueryResultsQuerySuspenseQuery
>;
export type RoleSelectorQueryResultsQueryResult = Apollo.QueryResult<
  RoleSelectorQueryResultsQuery,
  RoleSelectorQueryResultsQueryVariables
>;
