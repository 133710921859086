import type * as Types from "../../../generated/graphqlBaseTypes";

import { TenantFields } from "../../fragments/generated/TenantFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type TenantsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TenantsQuery = { tenants: Array<TenantFields> };

export const TenantsQueryDocument = gql`
    query TenantsQuery {
  tenants {
    ...TenantFields
  }
}
    ${TenantFields}`;

/**
 * __useTenantsQuery__
 *
 * To run a query within a React component, call `useTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantsQuery(baseOptions?: Apollo.QueryHookOptions<TenantsQuery, TenantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TenantsQuery, TenantsQueryVariables>(TenantsQueryDocument, options);
}
export function useTenantsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TenantsQuery, TenantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TenantsQuery, TenantsQueryVariables>(TenantsQueryDocument, options);
}
export function useTenantsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TenantsQuery, TenantsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TenantsQuery, TenantsQueryVariables>(TenantsQueryDocument, options);
}
export type TenantsQueryHookResult = ReturnType<typeof useTenantsQuery>;
export type TenantsQueryLazyQueryHookResult = ReturnType<typeof useTenantsQueryLazyQuery>;
export type TenantsQuerySuspenseQueryHookResult = ReturnType<typeof useTenantsQuerySuspenseQuery>;
export type TenantsQueryResult = Apollo.QueryResult<TenantsQuery, TenantsQueryVariables>;
