import type { FieldInstanceMultiValueTimeFields } from "./FieldInstanceMultiValueTimeFields.generated";
import type { AcyclicFieldInstanceMultiValueTimeFields } from "./AcyclicFieldInstanceMultiValueTimeFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValueTimeWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceMultiValueTime?: AcyclicFieldInstanceMultiValueTimeFields | null;
  crossWorkflowSinksFieldInstanceMultiValueTime?: Array<AcyclicFieldInstanceMultiValueTimeFields> | null;
} & FieldInstanceMultiValueTimeFields;

export const FieldInstanceMultiValueTimeWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValueTimeWithCrossWorkflowFields on FieldInstanceMultiValueTime {
  ...FieldInstanceMultiValueTimeFields
  crossWorkflowSourceFieldInstanceMultiValueTime {
    ...AcyclicFieldInstanceMultiValueTimeFields
  }
  crossWorkflowSinksFieldInstanceMultiValueTime {
    ...AcyclicFieldInstanceMultiValueTimeFields
  }
}
    `;
