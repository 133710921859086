import type { SpectrumValueConstraintFields } from "./SpectrumValueConstraintFields.generated";
import { gql } from "@apollo/client";
export type SpectrumFieldConstraintFields = {
  id: number;
  uuid: string;
  createdAt: string;
  constraintArgs: Array<string>;
  spectrumValueConstraint: SpectrumValueConstraintFields;
};

export const SpectrumFieldConstraintFields = gql`
    fragment SpectrumFieldConstraintFields on SpectrumFieldConstraint {
  id
  uuid
  createdAt
  spectrumValueConstraint {
    ...SpectrumValueConstraintFields
  }
  constraintArgs
}
    `;
