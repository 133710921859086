import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type FieldInstanceValueIntBaseFields = {
  __typename: "FieldInstanceValueInt";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  intValue?: number | null;
};

export const FieldInstanceValueIntBaseFields = gql`
    fragment FieldInstanceValueIntBaseFields on FieldInstanceValueInt {
  __typename
  id
  inputType
  intValue
}
    `;
