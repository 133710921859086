import { gql } from "@apollo/client";
export type FormSectionConstraintRuleFields = { id: number; uuid: string; formSectionConstraintRule: string };

export const FormSectionConstraintRuleFields = gql`
    fragment FormSectionConstraintRuleFields on FormSectionConstraintRule {
  id
  uuid
  formSectionConstraintRule
}
    `;
