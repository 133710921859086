import type { WorkflowGraphStageFields } from "./WorkflowGraphStageFields.generated";
import type { WorkflowGraphActionItemFields } from "./WorkflowGraphActionItemFields.generated";
import { gql } from "@apollo/client";
export type WorkflowGraphFields = {
  workflow: { id: number; startedAt?: string | null };
  rootStages: Array<WorkflowGraphStageFields>;
  headlessStages: Array<WorkflowGraphStageFields>;
  allStages: Array<WorkflowGraphStageFields>;
  allActionItems: Array<WorkflowGraphActionItemFields>;
};

export const WorkflowGraphFields = gql`
    fragment WorkflowGraphFields on WorkflowGraph {
  workflow {
    id
    startedAt
  }
  rootStages {
    ...WorkflowGraphStageFields
  }
  headlessStages {
    ...WorkflowGraphStageFields
  }
  allStages {
    ...WorkflowGraphStageFields
  }
  allActionItems {
    ...WorkflowGraphActionItemFields
  }
}
    `;
