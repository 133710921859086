import type * as Types from "../../../generated/graphqlBaseTypes";

import { PartyWithActionItemCountFields } from "../../fragments/generated/PartyWithActionItemCountFields.generated";
import { UserFieldsWithTeams } from "../../fragments/generated/UserFieldsWithTeams.generated";
import { UserFields } from "../../fragments/generated/UserFields.generated";
import { UserFieldsWithoutRoles } from "../../fragments/generated/UserFieldsWithoutRoles.generated";
import { AccessRoleFields } from "../../fragments/generated/AccessRoleFields.generated";
import { TeamWithoutMembersFields } from "../../fragments/generated/TeamWithoutMembersFields.generated";
import { TeamFields } from "../../fragments/generated/TeamFields.generated";
import { TeamMemberFields } from "../../fragments/generated/TeamMemberFields.generated";
import { UserBaseFields } from "../../fragments/generated/UserBaseFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type NonAdminPeopleAndTeamsTableQueryVariables = Types.Exact<{
  isTeamsQuery: Types.Scalars["Boolean"]["input"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  sortBy: Types.NonAdminPeopleAndTeamsDefaultColumnOption;
  sortOrder: Types.SortOrder;
  teamId?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
  userIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars["ID"]["input"]>>>;
  searchQuery?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type NonAdminPeopleAndTeamsTableQuery = {
  nonAdminPeopleAndTeamsTable: { totalCount: number; parties: Array<PartyWithActionItemCountFields> };
};

export const NonAdminPeopleAndTeamsTableQueryDocument = gql`
    query NonAdminPeopleAndTeamsTableQuery($isTeamsQuery: Boolean!, $limit: Int, $offset: Int, $sortBy: NonAdminPeopleAndTeamsDefaultColumnOption!, $sortOrder: SortOrder!, $teamId: ID, $userIds: [ID], $searchQuery: String) {
  nonAdminPeopleAndTeamsTable(
    isTeamsQuery: $isTeamsQuery
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortOrder: $sortOrder
    teamId: $teamId
    userIds: $userIds
    searchQuery: $searchQuery
  ) {
    totalCount
    parties {
      ...PartyWithActionItemCountFields
    }
  }
}
    ${PartyWithActionItemCountFields}
${UserFieldsWithTeams}
${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}
${TeamWithoutMembersFields}
${TeamFields}
${TeamMemberFields}
${UserBaseFields}`;

/**
 * __useNonAdminPeopleAndTeamsTableQuery__
 *
 * To run a query within a React component, call `useNonAdminPeopleAndTeamsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonAdminPeopleAndTeamsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonAdminPeopleAndTeamsTableQuery({
 *   variables: {
 *      isTeamsQuery: // value for 'isTeamsQuery'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      teamId: // value for 'teamId'
 *      userIds: // value for 'userIds'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useNonAdminPeopleAndTeamsTableQuery(
  baseOptions: Apollo.QueryHookOptions<NonAdminPeopleAndTeamsTableQuery, NonAdminPeopleAndTeamsTableQueryVariables> &
    ({ variables: NonAdminPeopleAndTeamsTableQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NonAdminPeopleAndTeamsTableQuery, NonAdminPeopleAndTeamsTableQueryVariables>(
    NonAdminPeopleAndTeamsTableQueryDocument,
    options,
  );
}
export function useNonAdminPeopleAndTeamsTableQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonAdminPeopleAndTeamsTableQuery,
    NonAdminPeopleAndTeamsTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NonAdminPeopleAndTeamsTableQuery, NonAdminPeopleAndTeamsTableQueryVariables>(
    NonAdminPeopleAndTeamsTableQueryDocument,
    options,
  );
}
export function useNonAdminPeopleAndTeamsTableQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NonAdminPeopleAndTeamsTableQuery,
    NonAdminPeopleAndTeamsTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NonAdminPeopleAndTeamsTableQuery, NonAdminPeopleAndTeamsTableQueryVariables>(
    NonAdminPeopleAndTeamsTableQueryDocument,
    options,
  );
}
export type NonAdminPeopleAndTeamsTableQueryHookResult = ReturnType<typeof useNonAdminPeopleAndTeamsTableQuery>;
export type NonAdminPeopleAndTeamsTableQueryLazyQueryHookResult = ReturnType<
  typeof useNonAdminPeopleAndTeamsTableQueryLazyQuery
>;
export type NonAdminPeopleAndTeamsTableQuerySuspenseQueryHookResult = ReturnType<
  typeof useNonAdminPeopleAndTeamsTableQuerySuspenseQuery
>;
export type NonAdminPeopleAndTeamsTableQueryResult = Apollo.QueryResult<
  NonAdminPeopleAndTeamsTableQuery,
  NonAdminPeopleAndTeamsTableQueryVariables
>;
