import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type AutomationOAuth2ConfigFields = {
  id: number;
  accessTokenUrl: string;
  clientId: string;
  clientSecret: string;
  grantType: Types.OAuthGrantType;
  location: Types.OAuthCredentialsLocation;
  scope?: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
};

export const AutomationOAuth2ConfigFields = gql`
    fragment AutomationOAuth2ConfigFields on AutomationOAuth2Config {
  id
  accessTokenUrl
  clientId
  clientSecret
  grantType
  location
  scope
  createdAt
  updatedAt
  deletedAt
}
    `;
