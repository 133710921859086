import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type OutOfOfficeEventFields = {
  id: number;
  startAt: string;
  endAt?: string | null;
  isActive: boolean;
  delegationSettings?: {
    shouldCcOnDelegatedTasks: boolean;
    nonApprovalTaskDelegates: Array<PartyBaseFields>;
    approvalTaskDelegates: Array<PartyBaseFields>;
    roleDelegations: Array<{ role: { id: number; name: string }; delegateParties: Array<PartyBaseFields> }>;
  } | null;
};

export const OutOfOfficeEventFields = gql`
    fragment OutOfOfficeEventFields on OutOfOfficeEvent {
  id
  startAt
  endAt
  isActive
  delegationSettings {
    nonApprovalTaskDelegates {
      ...PartyBaseFields
    }
    approvalTaskDelegates {
      ...PartyBaseFields
    }
    shouldCcOnDelegatedTasks
    roleDelegations {
      role {
        id
        name
      }
      delegateParties {
        ...PartyBaseFields
      }
    }
  }
}
    `;
