import { t } from "@lingui/macro";
import { getInitials } from "@regrello/core-utils";
import {
  RegrelloAvatar,
  type RegrelloAvatarProps,
  RegrelloIcon,
  RegrelloShape,
  RegrelloTooltip,
  RegrelloTypography,
} from "@regrello/ui-core";
import React, { useMemo } from "react";

import type { FlatParty } from "./utils";

export interface RegrelloPartyAvatarProps
  extends Omit<RegrelloAvatarProps, "indicator" | "scope" | "shape" | "text" | "visual"> {
  hideText?: boolean;

  isActivelyScimProvisioned?: boolean;

  /** Whether to visually emphasize and show an error icon for deleted parties. */
  isHighlightedIfDeleted?: boolean;

  party: FlatParty;

  /** Whether to show the party's email address as secondary text. */
  showEmail?: boolean;

  /**
   * Whether this party is idle, indicating they are online but dormant. This can happen if they
   * have the Regrello tab open but haven't done anything in it in some time—or if they may have
   * even unfocused the page to do something else.
   *
   * @default false
   */
  idle?: boolean;

  /** Whether to show the party's muted indicator. We typically want to enable this
   * where the user's mute status is important, such as when assigning or CCing them
   * else it gets super noisy to see the icon everywhere.
   */
  showIsMutedIndicator?: boolean;

  /** Show Via SCIM description under user avatar */
  showViaScimCaption?: boolean;
}

/** An avatar that represents a party in Regrello. */
export const RegrelloPartyAvatar = React.memo<RegrelloPartyAvatarProps>(function RegrelloPartyAvatarFn({
  hideText,
  isActivelyScimProvisioned = false,
  isHighlightedIfDeleted = false,
  idle: isIdle = false,
  party,
  showEmail,
  showIsMutedIndicator,
  showViaScimCaption = false,
  ...avatarProps
}) {
  const visual: RegrelloAvatarProps["visual"] = useMemo(() => {
    switch (true) {
      case party.type === "fieldInstance" && party.fieldInstanceSubtype !== "role":
        return {
          type: "icon",
          iconName: "person-field",
          isFullSize: true,
          isVisuallyUnwrapped: true,
        };
      case party.type === "role" || party.fieldInstanceSubtype === "role":
        return {
          type: "icon",
          iconName: "role",
          isFullSize: true,
          isVisuallyUnwrapped: true,
        };
      case party.userType === "SERVICE_ACCOUNT":
      case party.userType === "SCIM_SERVICE_ACCOUNT":
        return {
          type: "icon",
          iconName: "settings",
          intent: "primary",
          isFullSize: false,
        };
      case party.userType === "AI_AGENT_ACCOUNT":
      case party.userType === "REGRELLO_INTERNAL_SERVICE_ACCOUNT":
        return {
          type: "icon",
          iconName: "integration-simple-solid",
          isFullSize: false,
        };
      default:
        return {
          type: "initials",
          text: getInitials(party.fullName),
        };
    }
  }, [party.fullName, party.fieldInstanceSubtype, party.type, party.userType]);

  return (
    <>
      <RegrelloAvatar
        {...avatarProps}
        idle={isIdle}
        indicator={party.isInactive ? "inactive" : showIsMutedIndicator && party.isMuted ? "muted" : undefined}
        isHighlightedWhenInactive={isHighlightedIfDeleted}
        scope={party.isInternal ? "internal" : "external"}
        secondaryText={showEmail ? party.email : undefined}
        shape={party.type === "team" ? RegrelloShape.RECTANGLE : RegrelloShape.CIRCLE}
        showBetaChip={party.userType === "AI_AGENT_ACCOUNT"}
        text={hideText ? undefined : showViaScimCaption ? t`User AD` : party.fullName}
        visual={visual}
      />
      {showViaScimCaption && (
        <div className="inline-flex">
          <RegrelloTypography className="flex items-center mt-auto" muted={true} variant="body-xs">
            {t`via SCIM`}
          </RegrelloTypography>
          {!isActivelyScimProvisioned && (
            <RegrelloTooltip
              content={t`This user was initially added via SCIM but is not currently being provisioned.`}
            >
              <span>
                <RegrelloIcon className="pl-2" iconName="data-sync-error" intent="neutral" variant="muted" />
              </span>
            </RegrelloTooltip>
          )}
        </div>
      )}
    </>
  );
});
