import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type ActionItemRegrelloObjectAuditHistoryFields = {
  createdAt: string;
  createdBy?: PartyBaseFields | null;
  changes: Array<{
    regrelloObjectId: number;
    regrelloObjectInstanceId: number;
    event?: Types.AuditHistoryEvent | null;
    valueAfter?: Array<{ key: string; value: { stringValue?: string | null } }> | null;
    valueBefore?: Array<{ key: string; value: { stringValue?: string | null } }> | null;
  }>;
};

export const ActionItemRegrelloObjectAuditHistoryFields = gql`
    fragment ActionItemRegrelloObjectAuditHistoryFields on RegrelloObjectChangeEvent {
  createdAt
  createdBy {
    ...PartyBaseFields
  }
  changes {
    regrelloObjectId
    regrelloObjectInstanceId
    event
    valueAfter {
      key
      value {
        ... on DataObjectCellValueString {
          stringValue
        }
      }
    }
    valueBefore {
      key
      value {
        ... on DataObjectCellValueString {
          stringValue
        }
      }
    }
  }
}
    `;
