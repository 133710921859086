import type * as Types from "../../../generated/graphqlBaseTypes";

import { UserFields } from "../../fragments/generated/UserFields.generated";
import { UserFieldsWithoutRoles } from "../../fragments/generated/UserFieldsWithoutRoles.generated";
import { AccessRoleFields } from "../../fragments/generated/AccessRoleFields.generated";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UserQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars["ID"]["input"]>;
}>;

export type UserQuery = { users: Array<UserFields> };

export const UserQueryDocument = gql`
    query UserQuery($id: ID) {
  users(id: $id) {
    ...UserFields
  }
}
    ${UserFields}
${UserFieldsWithoutRoles}
${AccessRoleFields}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserQueryDocument, options);
}
export function useUserQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserQueryDocument, options);
}
export function useUserQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserQueryDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserQueryLazyQueryHookResult = ReturnType<typeof useUserQueryLazyQuery>;
export type UserQuerySuspenseQueryHookResult = ReturnType<typeof useUserQuerySuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
