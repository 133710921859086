import { ConditionConnective } from "@regrello/graphql-api";

export const SCHEDULE_TIME_FORM_LABEL_WIDTH = 45;

export const SPLITTER_FIELD_SELECT_LABEL_WIDTH = 305;

export const START_CONDITION_LABEL_WIDTH = 90;
export const START_CONDITION_BETWEEN_LABEL_WIDTH = 32;

export const START_CONDITION_CONNECTIVES = [ConditionConnective.AND, ConditionConnective.OR];

/**
 * The CSS classes for the initial label in a condition row (i.e., the "If", the connetive
 * selector, and the "and"/"or" label).
 */
export const LEFTMOST_LABEL_CLASSES = "w-18 flex-none";
