import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import type { TagFields } from "./TagFields.generated";
import type { DocumentFields } from "./DocumentFields.generated";
import type { AutomationRequestFields } from "./AutomationRequestFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { ActionItemApprovalFields } from "./ActionItemApprovalFields.generated";
import type { RootCommentFields } from "./RootCommentFields.generated";
import type { IntegrationAutomationInstanceFields } from "./IntegrationAutomationInstanceFields.generated";
import type { ActionItemPermissionsFields } from "./ActionItemPermissionsFields.generated";
import type { LineFields } from "./LineFields.generated";
import { gql } from "@apollo/client";
export type ActionItemTemplateActionItemFields = {
  __typename: "ActionItem";
  id: number;
  uuid: string;
  uuidLong: string;
  name: string;
  status: Types.ActionItemStatus;
  dueOn?: string | null;
  dueOnClass: Types.DueOnClass;
  hasException: boolean;
  frozeForReason?: Types.ActionItemFrozeForReason | null;
  requiresAck: boolean;
  createdAt: string;
  updatedAt: string;
  numberOfUnreadComments: number;
  type: Types.ActionItemType;
  isLocked: boolean;
  description?: string | null;
  assignedBy: PartyBaseFields;
  createdBy: UserBaseFields;
  assignees: Array<PartyBaseFields>;
  cc: Array<PartyBaseFields>;
  reviewers: Array<PartyBaseFields>;
  tags: Array<TagFields>;
  documents: Array<DocumentFields>;
  automationRequests: Array<AutomationRequestFields>;
  fieldInstancesControllingAssignees?: Array<FieldInstanceFields> | null;
  fieldInstancesControllingCc?: Array<FieldInstanceFields> | null;
  fieldInstanceControllingDueOn?: FieldInstanceFields | null;
  integrationAutomationInstance?: IntegrationAutomationInstanceFields | null;
  permissions?: ActionItemPermissionsFields | null;
  comments: Array<RootCommentFields>;
  fieldInstances: Array<FieldInstanceFields>;
  lines: Array<LineFields>;
  auditHistory: Array<{
    __typename: "ActionItemAuditHistory";
    id: number;
    createdAt: string;
    event: Types.AuditHistoryEvent;
    createdBy: PartyBaseFields;
    fields: Array<{
      __typename: "ActionItemAuditHistoryDelta";
      id: number;
      fieldName: string;
      valueBefore?: string | null;
      valueAfter?: string | null;
      collectionAdd?: string | null;
      collectionRemove?: string | null;
    }>;
  }>;
} & ActionItemApprovalFields;

export const ActionItemTemplateActionItemFields = gql`
    fragment ActionItemTemplateActionItemFields on ActionItem {
  __typename
  id
  uuid
  uuidLong
  name
  status
  assignedBy {
    ...PartyBaseFields
  }
  createdBy {
    ...UserBaseFields
  }
  assignees {
    ...PartyBaseFields
  }
  cc {
    ...PartyBaseFields
  }
  reviewers {
    ...PartyBaseFields
  }
  dueOn
  dueOnClass
  hasException
  frozeForReason
  requiresAck
  tags {
    ...TagFields
  }
  documents {
    ...DocumentFields
  }
  createdAt
  updatedAt
  numberOfUnreadComments
  type
  automationRequests {
    ...AutomationRequestFields
  }
  fieldInstancesControllingAssignees {
    ...FieldInstanceFields
  }
  fieldInstancesControllingCc {
    ...FieldInstanceFields
  }
  fieldInstanceControllingDueOn {
    ...FieldInstanceFields
  }
  ...ActionItemApprovalFields
  integrationAutomationInstance {
    ...IntegrationAutomationInstanceFields
  }
  permissions {
    ...ActionItemPermissionsFields
  }
  isLocked
  comments {
    ...RootCommentFields
  }
  description
  fieldInstances {
    ...FieldInstanceFields
  }
  lines {
    ...LineFields
  }
  dueOnClass
  auditHistory {
    id
    __typename
    createdAt
    createdBy {
      ...PartyBaseFields
    }
    event
    fields {
      id
      __typename
      fieldName
      valueBefore
      valueAfter
      collectionAdd
      collectionRemove
    }
  }
}
    `;
