import type { FieldInstanceMultiValuePartyFields } from "./FieldInstanceMultiValuePartyFields.generated";
import type { AcyclicFieldInstanceMultiValuePartyFields } from "./AcyclicFieldInstanceMultiValuePartyFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceMultiValuePartyWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceMultiValueParty?: AcyclicFieldInstanceMultiValuePartyFields | null;
  crossWorkflowSinksFieldInstanceMultiValueParty?: Array<AcyclicFieldInstanceMultiValuePartyFields> | null;
} & FieldInstanceMultiValuePartyFields;

export const FieldInstanceMultiValuePartyWithCrossWorkflowFields = gql`
    fragment FieldInstanceMultiValuePartyWithCrossWorkflowFields on FieldInstanceMultiValueParty {
  ...FieldInstanceMultiValuePartyFields
  crossWorkflowSourceFieldInstanceMultiValueParty {
    ...AcyclicFieldInstanceMultiValuePartyFields
  }
  crossWorkflowSinksFieldInstanceMultiValueParty {
    ...AcyclicFieldInstanceMultiValuePartyFields
  }
}
    `;
