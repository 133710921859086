import { gql } from "@apollo/client";
export type NameTemplateWithSuffixFields = { stringTemplate: string; fieldIds: Array<number>; suffix?: string | null };

export const NameTemplateWithSuffixFields = gql`
    fragment NameTemplateWithSuffixFields on NameTemplateWithSuffix {
  stringTemplate
  fieldIds
  suffix
}
    `;
