import type * as Types from "../../../generated/graphqlBaseTypes";

import type { UserBaseFields } from "./UserBaseFields.generated";
import type { WorkflowTemplatePermissionsFields } from "./WorkflowTemplatePermissionsFields.generated";
import type { ConditionalExpressionGroupFields } from "./ConditionalExpressionGroupFields.generated";
import { gql } from "@apollo/client";
export type WorkflowTemplatePrimitiveFields = {
  id: number;
  blueprintUuid: string;
  name: string;
  createdAt: string;
  deletedAt?: string | null;
  publishedAt?: string | null;
  updatedAt: string;
  description?: string | null;
  isValidToUse: boolean;
  type: Types.WorkflowTemplateType;
  predictedDurationDays: number;
  numberOfStages: number;
  isCreateViaEmailEnabled: boolean;
  createViaEmailContactEmail?: string | null;
  versionNotes?: string | null;
  versionNumber?: number | null;
  isVariantCreationEnabled: boolean;
  createdBy: UserBaseFields;
  permissions?: WorkflowTemplatePermissionsFields | null;
  draftVersionWorkflowTemplate?: { id: number; permissions: { canRead: boolean } } | null;
  variantData?: {
    isCompliant: boolean;
    standardBlueprint: { name: string };
    conditionGroup?: ConditionalExpressionGroupFields | null;
  } | null;
};

export const WorkflowTemplatePrimitiveFields = gql`
    fragment WorkflowTemplatePrimitiveFields on WorkflowTemplate {
  id
  blueprintUuid
  name
  createdBy {
    ...UserBaseFields
  }
  createdAt
  deletedAt
  publishedAt
  updatedAt
  description
  isValidToUse
  type
  predictedDurationDays
  numberOfStages
  permissions {
    ...WorkflowTemplatePermissionsFields
  }
  isCreateViaEmailEnabled
  createViaEmailContactEmail
  draftVersionWorkflowTemplate {
    id
    permissions {
      canRead
    }
  }
  versionNotes
  versionNumber
  isVariantCreationEnabled
  variantData {
    isCompliant
    standardBlueprint {
      name
    }
    conditionGroup {
      ...ConditionalExpressionGroupFields
    }
  }
}
    `;
