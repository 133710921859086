import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { ActionItemAuditHistoryDeltaFields } from "./ActionItemAuditHistoryDeltaFields.generated";
import { gql } from "@apollo/client";
export type ActionItemAuditHistoryFields = {
  id: number;
  createdAt: string;
  createdByUnknownEmail?: string | null;
  event: Types.AuditHistoryEvent;
  reason?: Types.TaskStatusChangeReason | null;
  createdBy: PartyBaseFields;
  fields: Array<ActionItemAuditHistoryDeltaFields>;
};

export const ActionItemAuditHistoryFields = gql`
    fragment ActionItemAuditHistoryFields on ActionItemAuditHistory {
  id
  createdAt
  createdBy {
    ...PartyBaseFields
  }
  createdByUnknownEmail
  event
  fields {
    ...ActionItemAuditHistoryDeltaFields
  }
  reason
}
    `;
