import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import { gql } from "@apollo/client";
export type ActionItemTemplateStartAfterActionItemTemplateFields = {
  id: number;
  name: string;
  dueOn?: string | null;
  dueOnIntervalSeconds?: number | null;
  actionItems: Array<{ id: number; status: Types.ActionItemStatus }>;
  approvalActionItemTemplates: Array<{
    id: number;
    dueOn?: string | null;
    dueOnIntervalSeconds?: number | null;
    actionItems: Array<{ id: number }>;
    assignees: Array<PartyBaseFields>;
    fieldInstancesControllingAssignees?: Array<FieldInstanceFields> | null;
  }>;
};

export const ActionItemTemplateStartAfterActionItemTemplateFields = gql`
    fragment ActionItemTemplateStartAfterActionItemTemplateFields on ActionItemTemplate {
  id
  actionItems {
    id
    status
  }
  name
  dueOn
  dueOnIntervalSeconds
  approvalActionItemTemplates {
    id
    actionItems {
      id
    }
    assignees {
      ...PartyBaseFields
    }
    dueOn
    dueOnIntervalSeconds
    fieldInstancesControllingAssignees {
      ...FieldInstanceFields
    }
  }
}
    `;
