import type { UserFieldsWithoutRoles } from "./UserFieldsWithoutRoles.generated";
import type { RoleFields } from "./RoleFields.generated";
import { gql } from "@apollo/client";
export type UserFieldsWithRoles = { party: { id: number; roles: Array<RoleFields> } } & UserFieldsWithoutRoles;

export const UserFieldsWithRoles = gql`
    fragment UserFieldsWithRoles on User {
  ...UserFieldsWithoutRoles
  party {
    id
    roles {
      ...RoleFields
    }
  }
}
    `;
