/**
 * Name of the connection that will be used for SSO (e.g. google-oauth2). The names needs to be
 * consistent with Auth0 settings.
 */

export enum AuthenticationConnectionName {
  DEFAULT = "Username-Password-Authentication",
  GOOGLE = "google-oauth2",
  SSO = "sso",
}
