import type * as Types from "../../../generated/graphqlBaseTypes";

import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValuePartyBaseFields = {
  __typename: "FieldInstanceValueParty";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  partyValue?: PartyBaseFields | null;
};

export const FieldInstanceValuePartyBaseFields = gql`
    fragment FieldInstanceValuePartyBaseFields on FieldInstanceValueParty {
  __typename
  id
  inputType
  partyValue {
    ...PartyBaseFields
  }
}
    `;
