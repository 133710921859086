import type { FieldInstanceValueStringFields } from "./FieldInstanceValueStringFields.generated";
import type { AcyclicFieldInstanceValueStringFields } from "./AcyclicFieldInstanceValueStringFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueStringWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceValueString?: AcyclicFieldInstanceValueStringFields | null;
  crossWorkflowSinksFieldInstanceValueString?: Array<AcyclicFieldInstanceValueStringFields> | null;
} & FieldInstanceValueStringFields;

export const FieldInstanceValueStringWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueStringWithCrossWorkflowFields on FieldInstanceValueString {
  ...FieldInstanceValueStringFields
  crossWorkflowSourceFieldInstanceValueString {
    ...AcyclicFieldInstanceValueStringFields
  }
  crossWorkflowSinksFieldInstanceValueString {
    ...AcyclicFieldInstanceValueStringFields
  }
}
    `;
