import type * as Types from "../../../generated/graphqlBaseTypes";

import type { FieldInstanceConditionalExpressionFields } from "./FieldInstanceConditionalExpressionFields.generated";
import { gql } from "@apollo/client";
export type ConditionalExpressionGroupFields = {
  id: number;
  connective: Types.ConditionConnective;
  conditions: Array<FieldInstanceConditionalExpressionFields>;
  conditionGroups: Array<{
    id: number;
    connective: Types.ConditionConnective;
    conditions: Array<FieldInstanceConditionalExpressionFields>;
    conditionGroups: Array<{
      id: number;
      connective: Types.ConditionConnective;
      conditions: Array<FieldInstanceConditionalExpressionFields>;
      conditionGroups: Array<{
        id: number;
        connective: Types.ConditionConnective;
        conditions: Array<FieldInstanceConditionalExpressionFields>;
      }>;
    }>;
  }>;
};

export const ConditionalExpressionGroupFields = gql`
    fragment ConditionalExpressionGroupFields on ConditionalExpressionGroup {
  id
  conditions {
    ...FieldInstanceConditionalExpressionFields
  }
  connective
  conditionGroups {
    id
    conditions {
      ...FieldInstanceConditionalExpressionFields
    }
    connective
    conditionGroups {
      id
      conditions {
        ...FieldInstanceConditionalExpressionFields
      }
      connective
      conditionGroups {
        id
        conditions {
          ...FieldInstanceConditionalExpressionFields
        }
        connective
      }
    }
  }
}
    `;
