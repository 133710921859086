import type { PartyFields } from "./PartyFields.generated";
import type { AccessRoleFields } from "./AccessRoleFields.generated";
import { gql } from "@apollo/client";
export type WorkflowCollaborationFields = {
  isFromWorkflowTemplate: boolean;
  party: PartyFields;
  accessRole: AccessRoleFields;
};

export const WorkflowCollaborationFields = gql`
    fragment WorkflowCollaborationFields on WorkflowCollaboration {
  party {
    ...PartyFields
  }
  accessRole {
    ...AccessRoleFields
  }
  isFromWorkflowTemplate
}
    `;
