import { gql } from "@apollo/client";
export type PermissionedCreateMenuContentFlagsFields = {
  showCreateActionItemButton: boolean;
  showCreateFieldButton: boolean;
  showCreatePeopleButton: boolean;
  showCreateTagButton: boolean;
  showCreateTeamButton: boolean;
  showCreateTemplateButton: boolean;
  showCreateWorkflowButton: boolean;
  showCreateWorkflowFromScratch: boolean;
  showCreateFormButton: boolean;
  showCreateRoleButton: boolean;
};

export const PermissionedCreateMenuContentFlagsFields = gql`
    fragment PermissionedCreateMenuContentFlagsFields on PermissionedCreateMenuContentFlags {
  showCreateActionItemButton
  showCreateFieldButton
  showCreatePeopleButton
  showCreateTagButton
  showCreateTeamButton
  showCreateTemplateButton
  showCreateWorkflowButton
  showCreateWorkflowFromScratch
  showCreateFormButton
  showCreateRoleButton
}
    `;
