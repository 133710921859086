import { gql } from "@apollo/client";
export type TenantFields = {
  __typename: "Tenant";
  id: number;
  name: string;
  displayName?: string | null;
  permissionAllowNonAdminUserToCreateUsers: boolean;
  permissionAllowNonAdminUsersToEditAssignees: boolean;
  permissionAllowNonAdminUserToInviteNonDomain: boolean;
  globallyUniqueMetricsId: string;
  scimUserId?: number | null;
  uuidShort: string;
  uuid: string;
};

export const TenantFields = gql`
    fragment TenantFields on Tenant {
  __typename
  id
  name
  displayName
  permissionAllowNonAdminUserToCreateUsers
  permissionAllowNonAdminUsersToEditAssignees
  permissionAllowNonAdminUserToInviteNonDomain
  globallyUniqueMetricsId
  scimUserId
  uuidShort
  uuid
}
    `;
