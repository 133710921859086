import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type AcyclicFieldInstanceMultiValueIntFields = {
  __typename: "FieldInstanceMultiValueInt";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  intMultiValue: Array<number>;
};

export const AcyclicFieldInstanceMultiValueIntFields = gql`
    fragment AcyclicFieldInstanceMultiValueIntFields on FieldInstanceMultiValueInt {
  __typename
  id
  inputType
  intMultiValue
}
    `;
