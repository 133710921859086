import type { TagTypeFields } from "./TagTypeFields.generated";
import type { PartyBaseFields } from "./PartyBaseFields.generated";
import { gql } from "@apollo/client";
export type TagTableTagFields = {
  createdAt: string;
  id: number;
  name: string;
  tagType: TagTypeFields;
  createdBy: { party: PartyBaseFields };
};

export const TagTableTagFields = gql`
    fragment TagTableTagFields on Tag {
  tagType {
    ...TagTypeFields
  }
  createdAt
  createdBy {
    party {
      ...PartyBaseFields
    }
  }
  id
  name
}
    `;
