import type { FormMinimalFields } from "./FormMinimalFields.generated";
import type { WorkflowPermissionsFields } from "./WorkflowPermissionsFields.generated";
import type { WorkflowTemplatePermissionsFields } from "./WorkflowTemplatePermissionsFields.generated";
import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { FormSectionFields } from "./FormSectionFields.generated";
import { gql } from "@apollo/client";
export type FormVersionFields = {
  id: number;
  uuid: string;
  createdAt: string;
  name: string;
  description: string;
  updatedAt: string;
  editingLocked: boolean;
  form: {
    usedBy: Array<{ id: number; isLocked: boolean; actionItems: Array<{ id: number; isLocked: boolean }> }>;
    workflow?: { id: number; name: string; permissions: WorkflowPermissionsFields } | null;
    workflowTemplate?: {
      id: number;
      name: string;
      publishedAt?: string | null;
      permissions?: WorkflowTemplatePermissionsFields | null;
    } | null;
  } & FormMinimalFields;
  createdBy: PartyBaseFields;
  defaultFormSection: FormSectionFields;
  occupiedSpectrumFieldNamesById: Array<{ spectrumFieldId: number; spectrumFieldVersionName: string }>;
};

export const FormVersionFields = gql`
    fragment FormVersionFields on FormVersion {
  id
  uuid
  createdAt
  name
  description
  createdAt
  updatedAt
  form {
    ...FormMinimalFields
    usedBy {
      id
      isLocked
      actionItems {
        id
        isLocked
      }
    }
    workflow {
      id
      name
      permissions {
        ...WorkflowPermissionsFields
      }
    }
    workflowTemplate {
      id
      name
      publishedAt
      permissions {
        ...WorkflowTemplatePermissionsFields
      }
    }
  }
  createdBy {
    ...PartyBaseFields
  }
  defaultFormSection {
    ...FormSectionFields
  }
  editingLocked
  occupiedSpectrumFieldNamesById {
    spectrumFieldId
    spectrumFieldVersionName
  }
}
    `;
