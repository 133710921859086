import { t, Trans } from "@lingui/macro";
import { EMPTY_STRING, useSimpleDialog } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  type FieldInstanceFields,
  type FieldInstanceFieldsWithBaseValues,
  type RegrelloObjectFields,
  type RegrelloObjectInstanceFields,
  SortOrder,
} from "@regrello/graphql-api";
import {
  REGRELLO_DATA_GRID_BULK_EDIT_COLUMN_ID,
  RegrelloButton,
  RegrelloDataGrid,
  type RegrelloDataGridColumnDef,
  regrelloDataGridColumnHelper,
  type RegrelloDialogAction,
  RegrelloDialogV2,
  type RegrelloDialogV2Props,
  RegrelloIcon,
  RegrelloNonIdealState,
  RegrelloSpinner,
  RegrelloTextButton,
} from "@regrello/ui-core";
import type { ColumnFiltersState, Row, RowSelectionState, SortingState, Updater } from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useThrottle } from "react-use";

import { RegrelloObjectSearchColumnFilter } from "./_internal/RegrelloObjectSearchColumnFilter";
import { useInstancesQuery } from "./_internal/useInstancesQuery";
import { usePagination } from "./_internal/usePagination";
import { getFieldInstanceId } from "../../../../utils/customFields/getFieldInstanceId";
import { renderTextCellWithHighlights } from "../../../../utils/tableCellRenderUtils";
import { convertRegrelloObjectInstanceForDataGrid } from "../../customFields/plugins/utils/convertRegrelloObjectInstanceForDataGrid";
import { RegrelloControlledFormFieldText } from "../controlled/RegrelloControlledFormFieldText";

const QUERY_LIMIT = 25;
const SEARCH_DEBOUNCE_MS = 300;

/**
 * All customer-provided `dataObjectKey`s mapped to their values. `dataObjectKey` is used as the
 * `columnId` for the data grid.
 */
export type RegrelloObjectInstanceForDataGrid = {
  [dataObjectKey: string]: string;

  /**
   * `rowId` for the DataGrid so we can address particular rows.
   */
  rowId: string;
};

export interface RegrelloObjectSearchDialogProps
  extends Omit<RegrelloDialogV2Props, "onChange" | "onClose" | "children"> {
  fieldInstance?: FieldInstanceFields | FieldInstanceFieldsWithBaseValues;
  onChange: (newValue: RegrelloObjectInstanceFields[] | undefined) => void;
  onClose: () => void;
  isReadonly?: boolean;
  regrelloObject: RegrelloObjectFields;
  value?: RegrelloObjectInstanceFields[];
}

/** A form dialog that allows the user to paste a document link, give it a name, and upload it. */
export const RegrelloObjectSearchDialog = React.memo<RegrelloObjectSearchDialogProps>(
  function RegrelloObjectSearchDialogFn({
    fieldInstance,
    onChange,
    onClose,
    isReadonly,
    regrelloObject,
    value = [],
    ...regrelloDialogProps
  }) {
    const isMultiselectEnabled = fieldInstance?.isMultiValued || false;

    const defaultRowSelector: RowSelectionState = useMemo(() => {
      const rows: Record<number, boolean> = {};
      value?.forEach((val) => {
        if (val?.id != null) {
          rows[`${val.id}`] = true;
        }
      });

      return rows;
    }, [value]);

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [rowSelection, setSelection] = useState<RowSelectionState>(defaultRowSelector);
    const [sortState, setSortState] = useState<SortingState>([]);
    const [hideUnselected, setHideUnselected] = useState(false);
    // Keep selected objects data since we partially fetch
    const [objectsSelection, setObjectsSelection] = useState(value);
    const { close, isOpen, open } = useSimpleDialog();

    const searchForm = useForm({ defaultValues: { searchQuery: EMPTY_STRING } });
    const searchFormValues = useWatch({ control: searchForm.control });

    const isFiltered = Boolean(searchFormValues.searchQuery) || columnFilters.length > 0;

    const {
      hasMorePages,
      instances,
      isLoading,
      runInstancesQuery,
      totalCount,
      runTotalCountQuery,
      filterTotalCount,
      filterTotalCountLoading,
    } = useInstancesQuery();

    // Prepare columns based on object projection
    const projectedColumns = useMemo(
      () =>
        regrelloObject.properties.filter((property) => {
          const projection = fieldInstance?.projection?.selectedRegrelloObjectPropertyIds;
          return projection ? projection.includes(property.id) : true;
        }),
      [fieldInstance?.projection?.selectedRegrelloObjectPropertyIds, regrelloObject.properties],
    );
    // Additional columns visibility filter on top of projections
    const [visibleColumns, setVisibleColumns] = useState<string[]>(
      projectedColumns.map((column) => column.dataObjectKey),
    );

    // (dosipiuk): We need custom state setter, due to partial fetching of object instances.
    // Otherwise when selecting multiple items and providing filter, we would loose selected items
    // that are not in the currently fetched ones
    const setRowSelection = useCallback(
      (updaterOrValue: Updater<RowSelectionState>) => {
        if (isReadonly) {
          return;
        }

        let newSelection: RowSelectionState;

        if (typeof updaterOrValue === "function") {
          newSelection = updaterOrValue(rowSelection);
        } else {
          newSelection = updaterOrValue;
        }

        const newObjectSelection = Object.keys(newSelection).map((selection) => {
          const foundInPreviousSelection = objectsSelection.find((object) => object.id === Number(selection));
          if (foundInPreviousSelection) {
            return foundInPreviousSelection;
          }
          return instances.find((object) => object.id === Number(selection)) as RegrelloObjectInstanceFields;
        });

        setSelection(newSelection);
        setObjectsSelection(newObjectSelection);
      },
      [isReadonly, rowSelection, objectsSelection, instances],
    );

    const onMultiSelectRowClick = useCallback(
      (row: Row<RegrelloObjectInstanceForDataGrid>) => {
        if (isReadonly) {
          return;
        }

        const newSelection = { ...rowSelection };

        if (newSelection[row.id]) {
          delete newSelection[row.id];
        } else {
          newSelection[row.id] = true;
        }

        setRowSelection(newSelection);
      },
      [isReadonly, rowSelection, setRowSelection],
    );

    const data: RegrelloObjectInstanceForDataGrid[] = useMemo(() => {
      // Show selected rows with filters applied to bypass pagination
      if (objectsSelection.length > 0 && hideUnselected) {
        const globalFilter = searchFormValues.searchQuery?.trim() || undefined;
        const hasColumnFilters = columnFilters.length;

        return objectsSelection
          .filter((object) => {
            // Skip if no filters
            if (!globalFilter && !hasColumnFilters) {
              return true;
            }
            // Filter selected items
            return object.dataObjectCells.some((cell) => {
              const columnFilter = columnFilters.find((col) => col.id === cell.key)?.value as string | undefined;
              const cellValue = cell.value.stringValue || "";
              const includesGlobalFilter = globalFilter ? cellValue.includes(globalFilter) : false;
              const includesColumnFilter = columnFilter ? cellValue.includes(columnFilter) : false;
              return includesGlobalFilter || includesColumnFilter;
            });
          })
          .map((regrelloObjectInstance) =>
            convertRegrelloObjectInstanceForDataGrid(regrelloObject, regrelloObjectInstance),
          );
      }

      return instances.map((regrelloObjectInstance) =>
        convertRegrelloObjectInstanceForDataGrid(regrelloObject, regrelloObjectInstance),
      );
    }, [objectsSelection, hideUnselected, instances, searchFormValues.searchQuery, columnFilters, regrelloObject]);

    const columns = useMemo(() => {
      const newColumns = projectedColumns.map(({ displayName, dataObjectKey }) => {
        const filter = columnFilters.find((columnFilter) => columnFilter.id === dataObjectKey);
        const columnDef: RegrelloDataGridColumnDef<RegrelloObjectInstanceForDataGrid, string> = {
          columnId: `${dataObjectKey}`,
          displayName: displayName ?? dataObjectKey,
          renderCell: renderTextCellWithHighlights([searchFormValues.searchQuery, filter?.value as string]),
        };
        return regrelloDataGridColumnHelper(columnDef);
      });
      newColumns.push({
        id: REGRELLO_DATA_GRID_BULK_EDIT_COLUMN_ID,
        accessorFn: () => "",
        enableSorting: false,
        enableColumnFilter: false,
      });
      return newColumns;
    }, [columnFilters, projectedColumns, searchFormValues.searchQuery]);

    const handleOnClose = useCallback(() => {
      searchForm.reset();
      setColumnFilters([]);
      setObjectsSelection(value);
      setSelection(defaultRowSelector);
      onClose();
    }, [defaultRowSelector, onClose, searchForm, value]);

    const handleDoneButtonClick = useCallback(() => {
      onChange(objectsSelection);
      searchForm.reset();
      setColumnFilters([]);
      onClose();
    }, [objectsSelection, onChange, onClose, searchForm]);

    const dialogActions = useMemo(() => {
      const contructiveButton = {
        buttonProps: {
          // (hchen): Don't disable this button,because we need to support leaving this as empty
          // when being used as an optional field. There is no way to tell if this is optional
          // because it doesn't have an `inputType` attribute.
          intent: "primary",
          dataTestId: DataTestIds.SYNCED_OBJECTS_SELECT_DIALOG_DONE_BUTTON,
          type: "submit",
          onClick: handleDoneButtonClick,
        },
        text: isReadonly ? t`Close` : t`Done`,
      } satisfies RegrelloDialogAction;

      if (isReadonly) {
        return [contructiveButton];
      }

      return [
        {
          buttonProps: {
            variant: "outline",
            onClick: handleOnClose,
          },
          text: t`Cancel`,
        },
        contructiveButton,
      ] satisfies RegrelloDialogAction[];
    }, [handleDoneButtonClick, handleOnClose, isReadonly]);

    const isMaximizable = columns.length > 5 || data.length > 9;
    const selectedRows = Object.keys(rowSelection).length;

    const pagination = usePagination({
      dataLength: data.length,
      filterTotalCount,
      hasMorePages,
      hideUnselected,
      isFiltered,
      pageSize: QUERY_LIMIT,
      totalCount,
    });

    useEffect(() => {
      if (columnFilters || searchFormValues.searchQuery) {
        // (dosipiuk): this is intentional to reset pagination on filters change
      }
      pagination.resetOffset();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilters, pagination.resetOffset, searchFormValues.searchQuery]);

    const regrelloObjectPropertyDataObjectKeyToPropertyIdMap = useMemo(() => {
      const output: Record<string, number> = {};
      for (const property of regrelloObject.properties) {
        output[property.dataObjectKey] = property.id;
      }
      return output;
    }, [regrelloObject.properties]);

    const fetchRegrelloObjectInstances = useCallback(() => {
      const filters = columnFilters
        .filter((columnFilter) => columnFilter.id !== REGRELLO_DATA_GRID_BULK_EDIT_COLUMN_ID)
        .map((columnFilter) => {
          return {
            regrelloObjectPropertyId: regrelloObjectPropertyDataObjectKeyToPropertyIdMap[columnFilter.id],
            stringValue: (columnFilter.value as string).trim(),
          };
        });

      const sortByPropertyId =
        sortState.length > 0 ? regrelloObjectPropertyDataObjectKeyToPropertyIdMap[sortState[0].id] : undefined;
      const sortOrder = sortState.length > 0 ? (sortState[0].desc ? SortOrder.DESC : SortOrder.ASC) : undefined;

      const fieldInstanceId =
        fieldInstance?.values && fieldInstance.values.length > 0 ? getFieldInstanceId(fieldInstance) : undefined;

      void runInstancesQuery({
        variables: {
          fieldInstanceId,
          filters,
          limit: QUERY_LIMIT,
          offset: pagination.offset,
          regrelloObjectId: regrelloObject.id,
          searchTerm: searchFormValues.searchQuery?.trim() || undefined,
          sortByPropertyId,
          sortOrder,
        },
      });

      if (isFiltered) {
        void runTotalCountQuery({
          variables: {
            fieldInstanceId,
            filters,
            limit: QUERY_LIMIT,
            offset: pagination.offset,
            regrelloObjectId: regrelloObject.id,
            searchTerm: searchFormValues.searchQuery?.trim() || undefined,
            sortByPropertyId,
            sortOrder,
            totalCountOnly: true,
          },
        });
      }
    }, [
      columnFilters,
      sortState,
      regrelloObjectPropertyDataObjectKeyToPropertyIdMap,
      runInstancesQuery,
      fieldInstance,
      pagination.offset,
      regrelloObject.id,
      searchFormValues.searchQuery,
      isFiltered,
      runTotalCountQuery,
    ]);

    // Execute `fetchRegrelloObjectInstances` when search queries and filter values change. Debounced
    // to avoid over-calling the back-end.
    useThrottle(fetchRegrelloObjectInstances, SEARCH_DEBOUNCE_MS);

    return (
      <>
        <RegrelloDialogV2
          {...regrelloDialogProps}
          actions={dialogActions}
          contentClassName="min-w-100 max-w-200 min-h-145 max-h-145 data-[maximized='true']:max-w-full data-[maximized='true']:max-h-full"
          contentWrapperClassName="flex flex-col overflow-hidden"
          disableDefaultPadding={true}
          onClose={handleOnClose}
          showCloseButton={true}
          showMaximizeButton={isMaximizable}
        >
          <div className="relative max-h-full flex flex-col grow overflow-hidden p-4">
            <div className="flex gap-2 py-1">
              <RegrelloControlledFormFieldText
                autoFocus={true}
                className="grow"
                controllerProps={{
                  control: searchForm.control,
                  name: "searchQuery",
                }}
                endElement={
                  isLoading
                    ? {
                        type: "nonInteractive",
                        element: <RegrelloSpinner className="text-neutral-iconMuted" size="small" />,
                      }
                    : undefined
                }
                placeholder={t`Search`}
                startElement={{
                  type: "icon",
                  iconName: "search",
                }}
              />
              {regrelloObject.properties.length > 1 ? (
                <RegrelloButton className="mb-2" onClick={open} startIcon="columns" variant="ghost">
                  {t`Columns`}
                </RegrelloButton>
              ) : null}
            </div>

            <RegrelloDataGrid<RegrelloObjectInstanceForDataGrid>
              className="grow regrelloObjectPickerTable"
              columnFilters={columnFilters}
              columns={columns}
              data={data}
              emphasizedRows={rowSelection}
              filterable={true}
              isManuallySorted={true}
              onColumnFiltersChange={setColumnFilters}
              onRowClick={isMultiselectEnabled ? onMultiSelectRowClick : (row) => setRowSelection({ [row.id]: true })}
              onRowSelectionChange={isMultiselectEnabled ? setRowSelection : undefined}
              onSortChange={setSortState}
              reorderable={false}
              rowSelection={rowSelection}
              sortable={true}
              sortState={sortState}
              visibleColumns={visibleColumns}
            />

            {data.length === 0 ? (
              <div className="absolute pointer-events-none inset-x-4 bottom-4 top-38">
                <RegrelloNonIdealState title={isLoading ? t`Loading...` : t`No results`} />
              </div>
            ) : null}
          </div>
          <div className="flex justify-between p-4 border-t">
            {selectedRows > 0 ? (
              <div className="flex items-center gap-4">
                <span>
                  {selectedRows} {t`selected`}
                </span>
                <RegrelloTextButton
                  onClick={() => {
                    setHideUnselected(!hideUnselected);
                  }}
                >
                  {hideUnselected ? t`Show unselected` : t`Hide unselected`}
                </RegrelloTextButton>
              </div>
            ) : (
              <div />
            )}
            <div className="flex items-center gap-1">
              <RegrelloButton
                disabled={!pagination.hasPrevPage}
                iconOnly={true}
                onClick={pagination.setPrevPage}
                size="x-small"
                startIcon="arrow-back"
                variant="ghost"
              />
              {isFiltered ? <RegrelloIcon iconName="filter-outline" size="x-small" /> : null}
              <span>
                {(() => {
                  const { startIndex, endIndex, totalRows } = pagination;
                  const loadingSpinner =
                    isFiltered && filterTotalCountLoading ? (
                      <div className="absolute inset-0 flex justify-center backdrop-blur-[1px] bg-background/40 min-w-4">
                        <RegrelloSpinner size="small" />
                      </div>
                    ) : undefined;

                  return (
                    <Trans>
                      {startIndex}-{endIndex} of{" "}
                      <span className="relative">
                        {totalRows} {loadingSpinner}
                      </span>
                    </Trans>
                  );
                })()}
              </span>
              <RegrelloButton
                disabled={!pagination.hasNextPage}
                iconOnly={true}
                onClick={pagination.setNextPage}
                size="x-small"
                startIcon="arrow-forward"
                variant="ghost"
              />
            </div>
          </div>
        </RegrelloDialogV2>
        <RegrelloObjectSearchColumnFilter
          defaultColumns={projectedColumns.map((column) => ({
            id: column.dataObjectKey,
            name: column.displayName || "",
            disabled: column.isPrimaryKey === true,
          }))}
          isOpen={isOpen}
          onClose={close}
          onSubmit={(visibility) => {
            setVisibleColumns(visibility);
          }}
        />
      </>
    );
  },
);
