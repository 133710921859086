import type { FormConstraintConditionGroupBaseFields } from "./FormConstraintConditionGroupBaseFields.generated";
import { gql } from "@apollo/client";
export type FormConstraintConditionGroupFields = {
  groups: Array<
    {
      groups: Array<{ groups: Array<FormConstraintConditionGroupBaseFields> } & FormConstraintConditionGroupBaseFields>;
    } & FormConstraintConditionGroupBaseFields
  >;
} & FormConstraintConditionGroupBaseFields;

export const FormConstraintConditionGroupFields = gql`
    fragment FormConstraintConditionGroupFields on FormConstraintConditionGroup {
  ...FormConstraintConditionGroupBaseFields
  groups {
    ...FormConstraintConditionGroupBaseFields
    groups {
      ...FormConstraintConditionGroupBaseFields
      groups {
        ...FormConstraintConditionGroupBaseFields
      }
    }
  }
}
    `;
