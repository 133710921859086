import type { FieldInstanceBaseFields } from "./FieldInstanceBaseFields.generated";
import type {
  FieldInstanceValueFieldsFieldInstanceMultiValueDocument,
  FieldInstanceValueFieldsFieldInstanceMultiValueFloat,
  FieldInstanceValueFieldsFieldInstanceMultiValueInt,
  FieldInstanceValueFieldsFieldInstanceMultiValueParty,
  FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance,
  FieldInstanceValueFieldsFieldInstanceMultiValueString,
  FieldInstanceValueFieldsFieldInstanceMultiValueTime,
  FieldInstanceValueFieldsFieldInstanceValueBoolean,
  FieldInstanceValueFieldsFieldInstanceValueFloat,
  FieldInstanceValueFieldsFieldInstanceValueInt,
  FieldInstanceValueFieldsFieldInstanceValueParty,
  FieldInstanceValueFieldsFieldInstanceValueString,
  FieldInstanceValueFieldsFieldInstanceValueTime,
} from "./FieldInstanceValueFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceFields = {
  values: Array<
    | FieldInstanceValueFieldsFieldInstanceMultiValueDocument
    | FieldInstanceValueFieldsFieldInstanceMultiValueFloat
    | FieldInstanceValueFieldsFieldInstanceMultiValueInt
    | FieldInstanceValueFieldsFieldInstanceMultiValueParty
    | FieldInstanceValueFieldsFieldInstanceMultiValueRegrelloObjectInstance
    | FieldInstanceValueFieldsFieldInstanceMultiValueString
    | FieldInstanceValueFieldsFieldInstanceMultiValueTime
    | FieldInstanceValueFieldsFieldInstanceValueBoolean
    | FieldInstanceValueFieldsFieldInstanceValueFloat
    | FieldInstanceValueFieldsFieldInstanceValueInt
    | FieldInstanceValueFieldsFieldInstanceValueParty
    | FieldInstanceValueFieldsFieldInstanceValueString
    | FieldInstanceValueFieldsFieldInstanceValueTime
  >;
} & FieldInstanceBaseFields;

export const FieldInstanceFields = gql`
    fragment FieldInstanceFields on FieldInstance {
  ...FieldInstanceBaseFields
  values {
    ...FieldInstanceValueFields
  }
}
    `;
