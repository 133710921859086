import type * as Types from "../../../generated/graphqlBaseTypes";

import type { ActionItemFields } from "./ActionItemFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { ActionItemAuditHistoryFields } from "./ActionItemAuditHistoryFields.generated";
import type { ActionItemAdditionalFields } from "./ActionItemAdditionalFields.generated";
import type { FieldInstanceWithCrossWorkflowFields } from "./FieldInstanceWithCrossWorkflowFields.generated";
import type { RegrelloObjectInstancesAuditHistoryFields } from "./RegrelloObjectInstancesAuditHistoryFields.generated";
import type { LineFields } from "./LineFields.generated";
import type { DelegationFields } from "./DelegationFields.generated";
import type { ActionItemRegrelloObjectAuditHistoryFields } from "./ActionItemRegrelloObjectAuditHistoryFields.generated";
import type { RejectActionFields } from "./RejectActionFields.generated";
import { gql } from "@apollo/client";
export type ActionItemDetailFields = {
  isLocked: boolean;
  originalTemplateAssigneePartyIDs?: Array<number> | null;
  fieldInstances: Array<FieldInstanceWithCrossWorkflowFields>;
  auditHistory: Array<ActionItemAuditHistoryFields>;
  linesAuditHistory: Array<RegrelloObjectInstancesAuditHistoryFields>;
  lines: Array<LineFields>;
  delegations: Array<DelegationFields>;
  relatedRegrelloObjectAuditHistory: Array<ActionItemRegrelloObjectAuditHistoryFields>;
  linkedWorkflowReference?: {
    id: number;
    name: string;
    description?: string | null;
    daysDelayed?: number | null;
    endDate?: string | null;
    percentComplete?: number | null;
    startedAt?: string | null;
    completedAt?: string | null;
    deletedAt?: string | null;
    endedAt?: string | null;
    updatedAt: string;
    stageSummary?: string | null;
    scheduleStatus?: Types.WorkflowScheduleStatus | null;
    template?: { id: number; name: string; deletedAt?: string | null; permissions: { canRead: boolean } } | null;
    fieldInstances: Array<FieldInstanceFields>;
    stages: Array<{ id: number; name: string; status: Types.WorkflowStageStatus; hasException: boolean }>;
    createdBy: UserBaseFields;
    permissions: { canRead: boolean };
  } | null;
  rejectAction?: RejectActionFields | null;
} & ActionItemFields &
  ActionItemAdditionalFields;

export const ActionItemDetailFields = gql`
    fragment ActionItemDetailFields on ActionItem {
  ...ActionItemFields
  ...ActionItemAdditionalFields
  fieldInstances {
    ...FieldInstanceWithCrossWorkflowFields
  }
  auditHistory {
    ...ActionItemAuditHistoryFields
  }
  linesAuditHistory {
    ...RegrelloObjectInstancesAuditHistoryFields
  }
  lines {
    ...LineFields
  }
  delegations {
    ...DelegationFields
  }
  relatedRegrelloObjectAuditHistory {
    ...ActionItemRegrelloObjectAuditHistoryFields
  }
  isLocked
  originalTemplateAssigneePartyIDs
  linkedWorkflowReference {
    id
    name
    template {
      id
      name
      permissions {
        canRead
      }
      deletedAt
    }
    fieldInstances {
      ...FieldInstanceFields
    }
    stages {
      id
      name
      status
      hasException
    }
    description
    createdBy {
      ...UserBaseFields
    }
    permissions {
      canRead
    }
    daysDelayed
    endDate
    percentComplete
    startedAt
    completedAt
    deletedAt
    endedAt
    updatedAt
    stageSummary
    scheduleStatus
  }
  rejectAction {
    ...RejectActionFields
  }
}
    `;
