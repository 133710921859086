import type * as Types from "../../../generated/graphqlBaseTypes";

import type { NameTemplateWithSuffixFields } from "./NameTemplateWithSuffixFields.generated";
import type { UserBaseFields } from "./UserBaseFields.generated";
import type { PartyBaseFields } from "./PartyBaseFields.generated";
import type { WorkflowStageFields } from "./WorkflowStageFields.generated";
import type { TagFields } from "./TagFields.generated";
import type { WorkflowPermissionsFields } from "./WorkflowPermissionsFields.generated";
import type { LineFields } from "./LineFields.generated";
import type { ConditionalExpressionGroupFields } from "./ConditionalExpressionGroupFields.generated";
import type { FieldInstanceWithCrossWorkflowFields } from "./FieldInstanceWithCrossWorkflowFields.generated";
import type { WorkflowCollaborationFields } from "./WorkflowCollaborationFields.generated";
import type { StarWithoutEntityFields } from "./StarWithoutEntityFields.generated";
import type { WorkflowGraphFields } from "./WorkflowGraphFields.generated";
import type { NotificationCardBaseFields } from "./NotificationCardBaseFields.generated";
import { gql } from "@apollo/client";
export type WorkflowFields = {
  id: number;
  name: string;
  hasException: boolean;
  createdAt: string;
  endedAt?: string | null;
  updatedAt: string;
  description?: string | null;
  summary?: string | null;
  stageSummary?: string | null;
  scheduleStatus?: Types.WorkflowScheduleStatus | null;
  isVisibilityLimitedForExternalParties: boolean;
  startedAt?: string | null;
  completedAt?: string | null;
  referenceNumberPrefix?: string | null;
  referenceNumber?: number | null;
  isLocked: boolean;
  autoAdjustDueOn: boolean;
  plannedDueOn?: string | null;
  adjustedDueOn?: string | null;
  nameTemplate?: NameTemplateWithSuffixFields | null;
  createdBy: UserBaseFields;
  updatedBy: PartyBaseFields;
  scheduleHistory: Array<{
    id: number;
    createdAt: string;
    status: Types.WorkflowScheduleStatus;
    createdBy: { id: number; name?: string | null };
  }>;
  stages: Array<WorkflowStageFields>;
  tags: Array<TagFields>;
  blueprint?: {
    id: number;
    name: string;
    deletedAt?: string | null;
    versionNumber?: number | null;
    isVariantCreationEnabled: boolean;
    permissions: { canRead: boolean };
    variantData?: {
      standardBlueprint: {
        id: number;
        name: string;
        deletedAt?: string | null;
        versionNumber?: number | null;
        permissions: { canRead: boolean };
      };
      conditionGroup?: ConditionalExpressionGroupFields | null;
    } | null;
  } | null;
  fieldInstances: Array<FieldInstanceWithCrossWorkflowFields>;
  collaborators: Array<PartyBaseFields>;
  lines: Array<LineFields>;
  collaborations: Array<WorkflowCollaborationFields>;
  permissions: WorkflowPermissionsFields;
  currentUserStar?: StarWithoutEntityFields | null;
  createdByActionItem?: {
    isCreatedByActionItemWorkflowDeleted: boolean;
    isParentWorkflowPaused: boolean;
    isParentWorkflowEnded: boolean;
    isActivelyLinkedToWorkflow: boolean;
    createdByActionItemWorkflow: { id: number; name: string; permissions: WorkflowPermissionsFields };
  } | null;
  graph: WorkflowGraphFields;
  notificationCards?: { notificationCards: Array<NotificationCardBaseFields> } | null;
};

export const WorkflowFields = gql`
    fragment WorkflowFields on Workflow {
  id
  name
  hasException
  nameTemplate {
    ...NameTemplateWithSuffixFields
  }
  createdAt
  createdBy {
    ...UserBaseFields
  }
  endedAt
  updatedAt
  updatedBy {
    ...PartyBaseFields
  }
  description
  summary
  stageSummary
  scheduleStatus
  scheduleHistory {
    id
    createdBy {
      id
      name
    }
    createdAt
    status
  }
  stages {
    ...WorkflowStageFields
  }
  tags {
    ...TagFields
  }
  blueprint {
    id
    name
    deletedAt
    permissions {
      canRead
    }
    versionNumber
    variantData {
      standardBlueprint {
        id
        name
        deletedAt
        permissions {
          canRead
        }
        versionNumber
      }
      conditionGroup {
        ...ConditionalExpressionGroupFields
      }
    }
    isVariantCreationEnabled
  }
  fieldInstances {
    ...FieldInstanceWithCrossWorkflowFields
  }
  collaborators {
    ...PartyBaseFields
  }
  lines {
    ...LineFields
  }
  collaborations {
    ...WorkflowCollaborationFields
  }
  permissions {
    ...WorkflowPermissionsFields
  }
  isVisibilityLimitedForExternalParties
  currentUserStar {
    ...StarWithoutEntityFields
  }
  startedAt
  completedAt
  createdByActionItem {
    createdByActionItemWorkflow {
      id
      name
      permissions {
        ...WorkflowPermissionsFields
      }
    }
    isCreatedByActionItemWorkflowDeleted
    isParentWorkflowPaused
    isParentWorkflowEnded
    isActivelyLinkedToWorkflow
  }
  referenceNumberPrefix
  referenceNumber
  graph {
    ...WorkflowGraphFields
  }
  isLocked
  autoAdjustDueOn
  plannedDueOn
  adjustedDueOn
  notificationCards {
    notificationCards {
      ...NotificationCardBaseFields
    }
  }
}
    `;
