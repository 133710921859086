import type * as Types from "../../../generated/graphqlBaseTypes";

import type { FieldFields } from "./FieldFields.generated";
import type { FieldInstanceSpectrumMetadataFields } from "./FieldInstanceSpectrumMetadataFields.generated";
import type { FieldInstanceSpectrumFields } from "./FieldInstanceSpectrumFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceBaseFields = {
  formFieldID?: number | null;
  regrelloObjectPropertyId?: number | null;
  displayOrder?: number | null;
  controlsActionItemOrActionItemTemplateField?: Types.ActionItemOrActionItemTemplateField | null;
  inputType?: Types.FieldInstanceValueInputType | null;
  isCopy?: boolean | null;
  isMultiValued?: boolean | null;
  isSplitter?: boolean | null;
  shouldSplitAssignees?: boolean | null;
  controllerBehaviorModifier?: Types.ControllerBehaviorModifierType | null;
  field: FieldFields;
  actionItem?: {
    id: number;
    name: string;
    status: Types.ActionItemStatus;
    isSplitter?: boolean | null;
    splitterParentActionItemId?: number | null;
    displayOrder: number;
    workflowReference?: { stageId: number; stageName: string; stageExecutionOrder: number } | null;
  } | null;
  actionItemTemplate?: {
    id: number;
    name: string;
    isSplitter?: boolean | null;
    displayOrder: number;
    workflowReference?: { stageId: number; stageName: string; stageExecutionOrder: number } | null;
    workflowTemplateReference?: {
      stageTemplateId: number;
      stageTemplateName: string;
      stageTemplateExecutionOrder: number;
    } | null;
  } | null;
  workflow?: { id: number; name: string; scheduleStatus?: Types.WorkflowScheduleStatus | null } | null;
  workflowTemplate?: { id: number; name: string } | null;
  writtenByActionItem?: {
    id: number;
    name: string;
    workflowReference?: { stageId: number; stageName: string } | null;
  } | null;
  projection?: { selectedRegrelloObjectPropertyIds: Array<number> } | null;
  spectrumMetadata?: Array<FieldInstanceSpectrumMetadataFields> | null;
} & FieldInstanceSpectrumFields;

export const FieldInstanceBaseFields = gql`
    fragment FieldInstanceBaseFields on FieldInstance {
  field {
    ...FieldFields
  }
  actionItem {
    id
    name
    status
    isSplitter
    splitterParentActionItemId
    displayOrder
    workflowReference {
      stageId
      stageName
      stageExecutionOrder
    }
  }
  actionItemTemplate {
    id
    name
    isSplitter
    displayOrder
    workflowReference {
      stageId
      stageName
      stageExecutionOrder
    }
    workflowTemplateReference {
      stageTemplateId
      stageTemplateName
      stageTemplateExecutionOrder
    }
  }
  workflow {
    id
    name
    scheduleStatus
  }
  workflowTemplate {
    id
    name
  }
  writtenByActionItem {
    id
    name
    workflowReference {
      stageId
      stageName
    }
  }
  formFieldID
  regrelloObjectPropertyId
  displayOrder
  controlsActionItemOrActionItemTemplateField
  inputType
  isCopy
  isMultiValued
  projection {
    selectedRegrelloObjectPropertyIds
  }
  spectrumMetadata {
    ...FieldInstanceSpectrumMetadataFields
  }
  isSplitter
  shouldSplitAssignees
  controllerBehaviorModifier
  ...FieldInstanceSpectrumFields
}
    `;
