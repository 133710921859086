import type { FieldInstanceValueBooleanFields } from "./FieldInstanceValueBooleanFields.generated";
import type { AcyclicFieldInstanceValueBooleanFields } from "./AcyclicFieldInstanceValueBooleanFields.generated";
import { gql } from "@apollo/client";
export type FieldInstanceValueBooleanWithCrossWorkflowFields = {
  crossWorkflowSourceFieldInstanceValueBoolean?: AcyclicFieldInstanceValueBooleanFields | null;
  crossWorkflowSinksFieldInstanceValueBoolean?: Array<AcyclicFieldInstanceValueBooleanFields> | null;
} & FieldInstanceValueBooleanFields;

export const FieldInstanceValueBooleanWithCrossWorkflowFields = gql`
    fragment FieldInstanceValueBooleanWithCrossWorkflowFields on FieldInstanceValueBoolean {
  ...FieldInstanceValueBooleanFields
  crossWorkflowSourceFieldInstanceValueBoolean {
    ...AcyclicFieldInstanceValueBooleanFields
  }
  crossWorkflowSinksFieldInstanceValueBoolean {
    ...AcyclicFieldInstanceValueBooleanFields
  }
}
    `;
