import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type FieldInstanceMultiValueTimeBaseFields = {
  __typename: "FieldInstanceMultiValueTime";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  timeMultiValue: Array<string>;
};

export const FieldInstanceMultiValueTimeBaseFields = gql`
    fragment FieldInstanceMultiValueTimeBaseFields on FieldInstanceMultiValueTime {
  __typename
  id
  inputType
  timeMultiValue
}
    `;
