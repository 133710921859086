import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type FieldInstanceValueStringBaseFields = {
  __typename: "FieldInstanceValueString";
  id: number;
  inputType: Types.FieldInstanceValueInputType;
  stringValue?: string | null;
};

export const FieldInstanceValueStringBaseFields = gql`
    fragment FieldInstanceValueStringBaseFields on FieldInstanceValueString {
  __typename
  id
  inputType
  stringValue
}
    `;
