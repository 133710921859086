import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type NotificationCardBaseFields = {
  id: number;
  uuid: string;
  createdAt: string;
  dismissedAt?: string | null;
  feedbackSentiment?: Types.NotificationCardFeedbackSentiment | null;
  isFeedbackEnabled?: boolean | null;
  title?: string | null;
  body: string;
  type: Types.NotificationCardType;
  actionItem?: { id: number; name: string } | null;
  workflow?: { id: number; name: string } | null;
  comment?: { id: number } | null;
};

export const NotificationCardBaseFields = gql`
    fragment NotificationCardBaseFields on NotificationCard {
  id
  uuid
  createdAt
  dismissedAt
  feedbackSentiment
  isFeedbackEnabled
  actionItem {
    id
    name
  }
  workflow {
    id
    name
  }
  comment {
    id
  }
  title
  body
  type
}
    `;
