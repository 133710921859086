import type * as Types from "../../../generated/graphqlBaseTypes";

import type { DocumentVersionFields } from "./DocumentVersionFields.generated";
import type { TagFields } from "./TagFields.generated";
import type { WorkflowPermissionsFields } from "./WorkflowPermissionsFields.generated";
import { gql } from "@apollo/client";
export type DocumentFields = {
  id: number;
  name: string;
  currentVersion: DocumentVersionFields;
  documentType: { id: number; name: string; icon: string };
  comment?: { id: number; text: string } | null;
  actionItem?: { id: number; uuid: string; name: string; status: Types.ActionItemStatus } | null;
  actionItemTemplate?: { id: number; uuid: string; name: string } | null;
  tags: Array<TagFields>;
  permissions: { canDelete: boolean };
  workflow?: { id: number; name: string; permissions: WorkflowPermissionsFields } | null;
};

export const DocumentFields = gql`
    fragment DocumentFields on Document {
  id
  name
  currentVersion {
    ...DocumentVersionFields
  }
  documentType {
    id
    name
    icon
  }
  comment {
    id
    text
  }
  actionItem {
    id
    uuid
    name
    status
  }
  actionItemTemplate {
    id
    uuid
    name
  }
  tags {
    ...TagFields
  }
  permissions {
    canDelete
  }
  workflow {
    id
    name
    permissions {
      ...WorkflowPermissionsFields
    }
  }
}
    `;
