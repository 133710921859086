import type * as Types from "../../../generated/graphqlBaseTypes";

import type { FieldInstanceFields } from "./FieldInstanceFields.generated";
import type { RegrelloObjectPropertyFields } from "./RegrelloObjectPropertyFields.generated";
import { gql } from "@apollo/client";
export type AutomationRequestParamFields = {
  id: number;
  key: string;
  valueType: Types.AutomationRequestParamValueType;
  location: Types.AutomationRequestParamLocation;
  valueV2?: { valueFloat?: number | null } | { valueInt?: number | null } | { valueString?: string | null } | null;
  fieldInstance?: FieldInstanceFields | null;
  fieldInstanceProperty?: RegrelloObjectPropertyFields | null;
};

export const AutomationRequestParamFields = gql`
    fragment AutomationRequestParamFields on AutomationRequestParam {
  id
  key
  valueType
  valueV2 {
    ... on AutomationRequestParamValueFloat {
      valueFloat
    }
    ... on AutomationRequestParamValueInt {
      valueInt
    }
    ... on AutomationRequestParamValueString {
      valueString
    }
  }
  location
  fieldInstance {
    ...FieldInstanceFields
  }
  fieldInstanceProperty {
    ...RegrelloObjectPropertyFields
  }
}
    `;
