import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type FieldUnitFields = { id: number; type: Types.FieldUnitType; name: string; symbol: string };

export const FieldUnitFields = gql`
    fragment FieldUnitFields on FieldUnit {
  id
  type
  name
  symbol
}
    `;
