import type { ZonedDateTime } from "@internationalized/date";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useDateField } from "@react-aria/datepicker";
import { type TimeFieldStateOptions, useTimeFieldState } from "@react-stately/datepicker";
import { clsx, type WithClassName } from "@regrello/core-utils";
import { getI18NConfig } from "@regrello/i18n-llm-translator";
import { useRef } from "react";

import { RegrelloDateSegment } from "./RegrelloDateSegment";
import type { RegrelloIntent } from "../../utils/enums/RegrelloIntent";
import { INPUT_V2_DEFAULT_SIZE } from "../input/inputV2Utils";
import { RegrelloInput, type RegrelloInputProps } from "../input/RegrelloInput";

export interface RegrelloTimePickerProps
  extends Pick<TimeFieldStateOptions, "granularity" | "hideTimeZone">,
    Pick<RegrelloInputProps, "fullWidth" | "size" | "disabled">,
    WithClassName {
  /** Default value for uncontrolled input */
  defaultValue?: ZonedDateTime;

  /**
   * The semantic intent of this input.
   * @default RegrelloIntent.NEUTRAL
   */
  intent?: Extract<RegrelloIntent, "neutral" | "danger" | "warning">;

  /** Accessible name for the input */
  label?: string;

  /** Maximum selectable date */
  maxValue?: ZonedDateTime;

  /** Minimum selectable date */
  minValue?: ZonedDateTime;

  /** Change handler for the controlled value. Must be set along `value` property. */
  onChange?: (date?: ZonedDateTime) => void;

  /** Currently selected controlled value. Must be set along `onChange` property. */
  value?: ZonedDateTime | null;
}

/**
 * This component provides tokenized date input with the calendar popover.
 */
export function RegrelloTimePicker({
  className,
  defaultValue,
  disabled,
  fullWidth,
  hideTimeZone,
  intent = "neutral",
  // FIXME (swerner): This is the component internal label also used for sub labels and is expected in tests. This is tricky and might need some larger refactoring. #I18NCOMPLABEL
  // eslint-disable-next-line lingui/no-unlocalized-strings
  label = "time picker",
  minValue,
  maxValue,
  onChange,
  size = INPUT_V2_DEFAULT_SIZE,
  value,
  ...props
}: RegrelloTimePickerProps) {
  const state = useTimeFieldState({
    defaultValue,
    granularity: props.granularity,
    hideTimeZone: hideTimeZone,
    isDisabled: disabled,
    locale: i18n.locale,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    hourCycle: getI18NConfig("hour12") ? 12 : undefined,
    value,
    onChange,
    minValue,
    maxValue,
  });
  const ref = useRef<HTMLDivElement>(null);
  const { fieldProps } = useDateField(
    {
      isDisabled: disabled,
      "aria-label": label ?? t`time picker`,
    },
    state,
    ref,
  );

  return (
    <RegrelloInput
      asChild={true}
      className={clsx(className, {
        "px-1": size === "medium" || size === "small",
        "px-1.5": size === "large",
        "px-2": size === "x-large",
      })}
      disabled={disabled}
      fullWidth={fullWidth}
      intent={intent}
      size={size}
      {...props}
    >
      <div ref={ref} className="flex items-center" {...fieldProps}>
        {state.segments.map((segment, i) => (
          <RegrelloDateSegment key={i} segment={segment} size={size} state={state} />
        ))}
      </div>
    </RegrelloInput>
  );
}
