import type { SpectrumFieldFields } from "./SpectrumFieldFields.generated";
import type { PropertyTypeFields } from "./PropertyTypeFields.generated";
import type { SpectrumFieldValidationTypeFields } from "./SpectrumFieldValidationTypeFields.generated";
import type { SpectrumFieldConstraintFields } from "./SpectrumFieldConstraintFields.generated";
import type { SpectrumFieldAllowedValueFields } from "./SpectrumFieldAllowedValueFields.generated";
import type { FieldUnitFields } from "./FieldUnitFields.generated";
import type { FieldFields } from "./FieldFields.generated";
import { gql } from "@apollo/client";
export type SpectrumFieldVersionFields = {
  id: number;
  uuid: string;
  createdAt: string;
  name: string;
  helperText: string;
  description: string;
  internalName: string;
  versionNumber: number;
  spectrumField: SpectrumFieldFields;
  propertyType: PropertyTypeFields;
  validationType: SpectrumFieldValidationTypeFields;
  fieldConstraints: Array<SpectrumFieldConstraintFields>;
  allowedValues: Array<SpectrumFieldAllowedValueFields>;
  fieldUnit?: FieldUnitFields | null;
  field?: FieldFields | null;
};

export const SpectrumFieldVersionFields = gql`
    fragment SpectrumFieldVersionFields on SpectrumFieldVersion {
  id
  uuid
  createdAt
  name
  helperText
  description
  internalName
  versionNumber
  spectrumField {
    ...SpectrumFieldFields
  }
  propertyType {
    ...PropertyTypeFields
  }
  validationType {
    ...SpectrumFieldValidationTypeFields
  }
  fieldConstraints {
    ...SpectrumFieldConstraintFields
  }
  allowedValues {
    ...SpectrumFieldAllowedValueFields
  }
  fieldUnit {
    ...FieldUnitFields
  }
  field {
    ...FieldFields
  }
}
    `;
