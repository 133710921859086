import type * as Types from "../../../generated/graphqlBaseTypes";

import { gql } from "@apollo/client";
export type UserBaseFields = {
  __typename: "User";
  id: number;
  name?: string | null;
  email: string;
  userType: Types.UserType;
  isMuted: boolean;
  deletedAt?: string | null;
  accessRole?: { id: number; userScope?: Types.AccessRoleUserScope | null } | null;
  party: { id: number };
};

export const UserBaseFields = gql`
    fragment UserBaseFields on User {
  __typename
  id
  name
  email
  accessRole {
    id
    userScope
  }
  party {
    id
  }
  userType
  isMuted
  deletedAt
}
    `;
