import type { FormFieldConstraintFields } from "./FormFieldConstraintFields.generated";
import type { SpectrumFieldVersionFields } from "./SpectrumFieldVersionFields.generated";
import { gql } from "@apollo/client";
export type FormFieldFields = {
  id: number;
  uuid: string;
  createdAt: string;
  displayOrder: number;
  displayColumn: number;
  enableFieldPassing: boolean;
  isMultiValued: boolean;
  formSection: { id: number; uuid: string };
  formFieldConstraints: Array<FormFieldConstraintFields>;
  spectrumField: { id: number };
  spectrumFieldVersion: SpectrumFieldVersionFields;
};

export const FormFieldFields = gql`
    fragment FormFieldFields on FormField {
  id
  uuid
  createdAt
  formSection {
    id
    uuid
  }
  formFieldConstraints {
    ...FormFieldConstraintFields
  }
  spectrumField {
    id
  }
  spectrumFieldVersion {
    ...SpectrumFieldVersionFields
  }
  displayOrder
  displayColumn
  enableFieldPassing
  isMultiValued
}
    `;
